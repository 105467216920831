<template>
  <main class="dashboard">
    <Navigation />
    <Sidebar />
    <div class="dashboard__content">
      <router-view />
    </div>
  </main>
</template>

<script>
  import Navigation from "@/components/navigation/Navigation";
  import Sidebar from "@/components/sidebar/Sidebar";

  export default {
    components: {
      Navigation,
      Sidebar
    },
    data() {
      return {
        timeoutInMiliseconds: 1500000,
        timeoutId: ""
      };
    },
    computed: {
      drawerStatus() {
        return this.$store.getters.getDrawerStatus;
      }
    },
    created() {
      this.setupTimers();
    },
    methods: {
      startTimer() {
        // window.setTimeout returns an Id that can be used to start and stop a timer
        this.timeoutId = window.setTimeout(
          this.doLogout,
          this.timeoutInMiliseconds
        );
      },
      doLogout() {
        window.clearTimeout(this.timeoutId);

        window.localStorage.setItem("appToken", "");
        window.localStorage.setItem("adminInfo", "");
        window.localStorage.setItem("loginTime", "");
        this.$router.push({ name: "login", query: { sessionTimeout: "true" } });
        window.location.reload();
      },
      setupTimers() {
        document.addEventListener("mousemove", this.resetTimer, false);
        document.addEventListener("mousedown", this.resetTimer, false);
        document.addEventListener("keypress", this.resetTimer, false);
        document.addEventListener("touchmove", this.resetTimer, false);

        this.startTimer();
      },
      resetTimer() {
        window.clearTimeout(this.timeoutId);
        this.startTimer();
      }
    }
  };
</script>

<style scoped src="./index.css"></style>
