<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Terminal Stock">
      <button
        v-if="isAnyOperationPermitted(['uploadTerminalPurchaseStock'])"
        class="btn btn--primary"
        data-target="#uploadTerminalStock"
        data-toggle="modal"
      >
        Upload New Stock
      </button>
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <loader v-if="loading" />

            <div v-else>
              <div class="table table--padded">
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      Serial #
                    </div>
                    <div class="table__header">
                      Terminal Model
                    </div>
                    <div class="table__header">
                      Price
                    </div>
                    <div class="table__header">
                      Payer
                    </div>
                    <div class="table__header">
                      Payment Status
                    </div>
                    <div class="table__header" />
                  </div>
                </div>

                <div class="table__section table__section--body">
                  <template v-for="(terminal, i) in terminalStock">
                    <div
                      :key="i"
                      class="table__row"
                    >
                      <div class="table__data">
                        {{ terminal.serialNumber }}
                      </div>
                      <div class="table__data">
                        {{ terminal.terminalModel }}
                      </div>
                      <div class="table__data">
                        {{ terminal.amount | money }}
                      </div>
                      <div class="table__data">
                        {{ isBlank(terminal.merchant) ? "--" : terminal.merchant }}
                      </div>
                      <div class="table__data">
                        <span
                          v-if="terminal.status === 'PAID'"
                          class="tag tag--green"
                        >Paid</span>
                        <span
                          v-if="terminal.status === 'UNPAID'"
                          class="tag tag--gray"
                        >Unpaid</span>
                      </div>

                      <div class="table__data">
                        <span @click="toggleTable(terminal.serialNumber)">
                          <expand-icon />
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="opened.includes(terminal.serialNumber)"
                      :key="terminal.serialNumber"
                      class="collapsible-table"
                    >
                      <div class="card-details__content">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Terminal Owner</label>
                          <input
                            :value="terminal.sponsor || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Account Manager</label>
                          <input
                            :value="terminal.accountManager || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Payment Date</label>
                          <input
                            :value="getFormattedPurchaseDate(terminal.purchaseTime)"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <template v-for="provider in Object.entries(terminal.providerTerminalMap)">
                          <div
                            :key="provider[0]"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for=""
                            >{{ provider[0] }} TID</label>
                            <input
                              :value="Object.values(provider[1])"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <pagination
                :page-number="computedPageNumbers"
                data="terminal-stock"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="uploadTerminalStock"
      aria-labelledby="uploadTerminalsLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Upload Terminal Stock
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <close-icon />
            </button>
          </div>

          <div class="modal__body modal__body--pad">
            <form class="form">
              <div class="form__pad">
                <div class="flexCenter">
                  <input
                    id="file-1"
                    class="inputfile inputfile-1"
                    data-multiple-caption="{count} files selected"
                    name="file-1[]"
                    type="file"
                    @change="uploadFiles"
                  >
                  <label for="file-1">
                    <svg
                      height="17"
                      viewBox="0 0 20 17"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                      />
                    </svg>
                    <span>Choose a file&hellip;</span></label>
                </div>
              </div>

              <div class="form__footer" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import { pagination } from "@/mixins/Pagination";
  import { collapsibleTable, hasBlank } from "@/mixins/ViewHelper";
  import { formatQueryParam } from "@/mixins/ApiHelper";
  import Pagination from "@/components/Pagination";
  import Search from "@/components/Search";
  import Loader from "@/components/Loader";
  import ExpandIcon from "@/components/icons/ExpandIcon";
  import CloseIcon from "@/components/icons/CloseIcon";
  import { callGetApi, callPostApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";


  export default {
    name: "TerminalStock",
    components: {
      TitleBar,
      Pagination,
      Search,
      Loader,
      ExpandIcon,
      CloseIcon
    },
    mixins: [
      pagination,
      collapsibleTable,
      hasBlank,
      formatQueryParam,
      AdminPermissionsHelper
    ],
    data() {
      return {
        loading: false,
        search: this.$route.query.criteria ? this.$route.query.criteria : "",
        searchPlaceholder: "Search by serial number, terminal model, business name, provider tid",
        selectedTerminal: "",
        opened: [],
        terminalStock: [],
        pageNumber: ""
      };
    },
    watch: {
      $route: "fetchTerminalStock"
    },
    created() {
      this.fetchTerminalStock();
    },
    methods: {
      clearSearch() {
        this.search = "";
        this.$router.push({ name: "terminal-stock", query: { currentPage: 1 } });
      },
      searchQuery(criteria) {
        this.$router.push({ name: "terminal-stock", query: { criteria } });
      },
      uploadFiles(e) {
        const file = e.target.files;
        const label = e.target.nextElementSibling, labelVal = label.innerHTML;

        let fileName = "";
        if (file && file.length > 1)
          fileName = (this.getAttribute("data-multiple-caption") || "").replace("{count}", file.length);
        else
          fileName = e.target.value.split("\\").pop();

        if (fileName)
          label.querySelector("span").innerHTML = fileName;
        else
          label.innerHTML = labelVal;

        const body = new FormData();
        body.append("attachment", file[0]);

        const headers = new Headers({});

        callPostApi("terminal/upload-stock", { body, headers })
          .then(res => {
            const { code, description } = res.body.status;
            if (code === 0) {
              this.$notify({
                group: "general",
                title: "Upload Terminal Stock",
                text: "Terminal Stock has been uploaded successfully"
              });
            } else {
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(err => {
            const message = err ? JSON.stringify(err.message, null, 2) : "Error uploading stock";
            this.$notify({
              group: "general",
              title: "Upload Terminal Stock",
              type: "warn",
              text: message
            });
          })
          .finally(() => {
            $("#uploadTerminalStock").modal("hide");
            e.target.value = null;
            document.getElementById("file-1").value = null;
            label.querySelector("span").innerHTML = "Choose a file...";
            this.fetchTerminalStock();
          });
      },
      fetchTerminalStock() {
        this.loading = true;
        const query_params = this.convertToQueryString(this.$route.query);
        callGetApi(`terminal/stock?batchSize=20&${query_params}`)
          .then(res => {
            const { code, description } = res.body.status;
            if (code === 0) {
              this.terminalStock = res.body.entity;
              this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
              window.localStorage.setItem("terminalStockPage", this.pageNumber);
            } else {
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(err => {
            const message = err ? JSON.stringify(err.message, null, 2) : "Error fetching stock";
            this.$notify({
              group: "general",
              title: "Fetch Terminal Stock",
              type: "warn",
              text: message
            });
          })
          .finally(() => this.loading = false);
      },
      getFormattedPurchaseDate(date) {
        return this.isBlank(date) ? "--" : this.$options.filters.date(date);
      }
    }
  };
</script>
