
  import { Component, Vue } from "vue-property-decorator";
  import NoNetworkModal from "@/components/NoNetworkModal.vue";

  @Component({
    name: "App",
    components: { NoNetworkModal }
  })

  export default class App extends Vue {
    isOffline = !navigator.onLine;

    private updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    }

    mounted() {
      window.addEventListener("online", this.updateOnlineStatus);
      window.addEventListener("offline", this.updateOnlineStatus);
    }

    beforeDestroy() {
      window.removeEventListener("online", this.updateOnlineStatus);
      window.removeEventListener("offline", this.updateOnlineStatus);
    }
  }
