<template>
  <div>
    <p
      v-if="isAnyOperationPermitted(requiredPermissions)"
      class="sidebar__item cursor-pointer"
      @click="toggleDropdown"
    >
      <span class="dashboard__main__sidebar__item__text btn__text"> {{ name }} <DownwardArrowIcon /> </span>
    </p>

    <div
      :class="{ active: showDropDown, inactive: !showDropDown }"
      class="sidebar-dropdown"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import DownwardArrowIcon from "@/components/icons/DownwardArrowIcon";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "SidebarDropdown",
    components: {
      DownwardArrowIcon
    },
    mixins: [AdminPermissionsHelper],
    props: {
      showDropDown: {
        required: true,
        type: Boolean
      },
      requiredPermissions: {
        required: true,
        type: Array
      },
      name: {
        required: true,
        type: String
      },
      toggleDropdown: {
        required: true,
        type: Function
      }
    }
  };
</script>

<style scoped src="./sidebar.css"></style>
