<template>
  <div class="dashboard__main__content__container">
    <title-bar :title="agentData.businessName">
      <div class="dashboard__main__content__row__item">
        <router-link
          :to="{ name: 'agents', query: { currentPage: 1 } }"
          class="dashboard__main__content__back"
        >
          <back-icon />
          <span>Back to Agents</span>
        </router-link>
      </div>
    </title-bar>

    <div
      class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual"
    >
      <div class="dashboard__main__content__home__right">
        <div class="big-card">
          <div class="transaction__header">
            <div class="transaction__header__title">
              Personal Information
            </div>
            <div
              class="transaction__header__title"
            >
              <button
                class="btn btn--primary"
                data-target="#editAgent"
                data-toggle="modal"
              >
                Edit Agent
              </button>
            </div>
          </div>

          <div class="transaction__content transaction__content__agents-individual">
            <div class="transaction__content__agents-individual__left">
              <img
                v-if="agentData.profilePhotoUrl"
                :src="agentData.profilePhotoUrl"
                alt="Agent Photo"
              >
              <img
                v-else
                src="https://images.unsplash.com/photo-1539812820685-9c8b53786533?ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80"
              >
            </div>
            <div class="transaction__content__agents-individual__right">
              <div class="card-details__content">
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >First Name</label>
                  <input
                    :value="agentData.firstName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Surname</label>
                  <input
                    :value="agentData.surname"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="agentData.gender"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Gender</label>
                  <input
                    :value="agentData.gender"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Date of Birth</label>
                  <input
                    :value="agentData.dateOfBirth | agentDateOfBirth"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Phone Number</label>
                  <input
                    :value="agentData.phone"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Email Address</label>
                  <input
                    :value="agentData.email"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item form__item__bvn">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >BVN</label>
                  <input
                    :value="agentData.bvn ? agentData.bvn : '--'"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                  <template v-if="agentData.verificationStatus !== 'VERIFIED'">
                    <div
                      v-if="agentData.bvn"
                      class="cursor-pointer"
                    >
                      <router-link
                        :to="{ name: 'bvn-details', params: { agentId: agentData.id } }"
                        style="color: #2d72d9;"
                        tag="a"
                        target="_blank"
                      >
                        Check BVN Details
                      </router-link>
                    </div>
                  </template>
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Category</label>
                  <input
                    :value="`${agentData.category?.value} - ${agentData.category?.percentage}%`"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="clearfix" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual"
    >
      <div class="dashboard__main__content__home__right">
        <div class="big-card">
          <div class="transaction__header">
            <div class="transaction__header__title">
              Business Information
            </div>
          </div>

          <div class="transaction__content">
            <div class="transaction__content transaction__content__agents-individual">
              <div class="transaction__content__agents-individual__left">
                <img
                  v-if="agentData.outletPhotoUrl"
                  :src="agentData.outletPhotoUrl"
                  alt="Outlet Photo"
                >
                <img
                  v-else
                  src="https://images.unsplash.com/photo-1539812820685-9c8b53786533?ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80"
                >
              </div>
              <div class="transaction__content__agents-individual__right">
                <div class="card-details__content">
                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for=""
                    >Business Name</label>
                    <input
                      :value="agentData.businessName ? agentData.businessName : '--'"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>

                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for=""
                    >CAC Registration Number</label>
                    <input
                      :value="agentData.cacRegistrationNumber ? agentData.cacRegistrationNumber : '--'"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>

                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for=""
                    >Business Address</label>
                    <input
                      :value="agentData.businessAddress ? agentData.businessAddress : '--'"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>

                  <div
                    v-if="agentData.businessIndustry"
                    class="form__item"
                  >
                    <label
                      class="form__label form__label--2"
                      for=""
                    >Business Industry</label>
                    <input
                      :value="agentData.businessIndustry"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>

                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for=""
                    >Landmark</label>
                    <input
                      :value="agentData.landmark ? agentData.landmark : '--'"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>

                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for=""
                    >City</label>
                    <input
                      :value="agentData.city ? agentData.city : '--'"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>

                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for=""
                    >State</label>
                    <input
                      :value="agentData.state ? agentData.state : '--'"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>

                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for=""
                    >LGA</label>
                    <input
                      :value="agentData.lga ? agentData.lga : '--'"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>

                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for=""
                    >Signup Date</label>
                    <input
                      :value="agentData.signUpDate | date"
                      class="form__input capitalize"
                      disabled
                      type="text"
                    >
                  </div>
                </div>
              </div>

              <div class="clearfix" />
            </div>

            <div class="clearfix" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual"
    >
      <div class="dashboard__main__content__home__right">
        <div class="big-card">
          <div class="transaction__header">
            <div class="transaction__header__title">
              Tellerpoint Balance
            </div>
          </div>
          <div class="transaction__filters" />
          <div class="transaction__content">
            <div class="customer-info__left">
              <div class="customer-info__item">
                <div class="customer-info__item__title">
                  Agent ID
                </div>
                <div class="customer-info__item__value">
                  {{ agentData.id }}
                </div>
              </div>

              <div class="customer-info__item">
                <div class="customer-info__item__title">
                  Available Balance
                </div>
                <div class="customer-info__item__value">
                  N{{ agentData.availableBalance | money }}
                </div>
              </div>

              <div class="customer-info__item ">
                <div class="customer-info__item__title">
                  Ledger Balance
                </div>
                <div class="customer-info__item__value">
                  N{{ agentData.ledgerBalance | money }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual"
    >
      <div class="dashboard__main__content__home__right">
        <div class="big-card">
          <div class="transaction__header">
            <div class="transaction__header__title">
              Banking Information
            </div>
          </div>
          <div class="transaction__filters" />
          <div class="transaction__content">
            <div class="customer-info__left">
              <div class="customer-info__item">
                <div class="customer-info__item__title">
                  Bank Name
                </div>
                <div class="customer-info__item__value">
                  {{ agentData.bankAccountParams?.bankName }}
                </div>
              </div>

              <div class="customer-info__item ">
                <div class="customer-info__item__title">
                  Account Number
                </div>
                <div class="customer-info__item__value">
                  {{ agentData.bankAccountParams?.accountNumber }}
                </div>
              </div>

              <div
                class="customer-info__item"
                style="width: 400px;"
              >
                <div class="customer-info__item__title">
                  Account Name
                </div>
                <div
                  class="customer-info__item__value"
                  style="max-width: 400px;"
                >
                  {{ agentData.bankAccountParams?.accountName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual"
    >
      <div class="dashboard__main__content__home__right">
        <div class="big-card">
          <div class="transaction__header">
            <div class="transaction__header__title">
              Virtual NUBAN
            </div>
            <div
              v-if="isAnyOperationPermitted(['createMerchantVirtualAccount'])"
              class="transaction__header__title"
            >
              <button
                class="btn btn--primary"
                @click="createVirtualAccount"
              >
                Generate Virtual Account
              </button>
            </div>
          </div>
          <div class="transaction__filters" />
          <div class="transaction__content">
            <div class="customer-info__left">
              <div class="customer-info__item">
                <div class="customer-info__item__title">
                  Nuban
                </div>
                <div
                  v-for="account in virtualAccounts"
                  :key="account.id"
                  class="customer-info__item__value"
                >
                  {{ account.nuban }}
                </div>
              </div>

              <div class="customer-info__item ">
                <div class="customer-info__item__title">
                  Account Name
                </div>
                <div
                  v-for="account in virtualAccounts"
                  :key="account.id"
                  class="customer-info__item__value"
                >
                  {{ account.accountName }}
                </div>
              </div>

              <div
                class="customer-info__item"
                style="width: 400px;"
              >
                <div class="customer-info__item__title">
                  Bank Name
                </div>
                <div
                  v-for="account in virtualAccounts"
                  :key="account.id"
                  class="customer-info__item__value"
                  style="max-width: 400px;"
                >
                  {{ account.bankName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual"
    >
      <div class="dashboard__main__content__home__right">
        <div class="big-card">
          <div class="transaction__header">
            <div class="transaction__header__title">
              Terminals
            </div>
          </div>
          <div class="transaction__filters" />
          <div class="transaction__content">
            <div
              class="customer-info__left"
              style="flex-wrap: wrap;"
            >
              <div
                v-for="(item, i) in agentData.terminals"
                :key="i"
                class="customer-info__item"
                style="margin-bottom: 20px;"
              >
                <div class="customer-info__item__title">
                  Terminal ID
                </div>
                <div class="customer-info__item__value">
                  {{ item.terminalId }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual"
    >
      <div class="dashboard__main__content__home__right">
        <div class="big-card">
          <div class="transaction__header">
            <div class="transaction__header__title">
              Internal Control
            </div>
            <div class="transaction__header__title">
              <button
                v-if="isAnyOperationPermitted(['createAndSendMerchantApiCredentials'])"
                class="btn btn--primary m-r-10"
                data-target="#generateApiCredentials"
                data-toggle="modal"
              >
                Generate API Credentials
              </button>

              <button
                :disabled="submitDisabled"
                class="btn btn--primary"
                @click="saveChanges"
              >
                Save Changes
              </button>
            </div>
          </div>
          <div class="transaction__filters" />
          <div class="agent-info">
            <div class="agent-info__left">
              <div>
                <div class="customer-info__item__title m-r-20">
                  Enable Dispense Error
                </div>
                <div class="switch">
                  <input
                    id="enabledispenseerror"
                    v-model="dispenseError"
                    class="switch__input"
                    type="checkbox"
                  >
                  <label
                    class="switch__label"
                    for="enabledispenseerror"
                  />
                </div>
              </div>
              <div>
                <div class="customer-info__item__title m-r-20">
                  Verify Agent
                </div>
                <div class="switch">
                  <input
                    id="agentverified"
                    v-model="dataStatusVerified"
                    class="switch__input"
                    type="checkbox"
                  >
                  <label
                    class="switch__label"
                    for="agentverified"
                  />
                </div>
              </div>
              <div>
                <div class="customer-info__item__title m-r-20">
                  Enable Bank Transfer
                </div>
                <div class="switch">
                  <input
                    id="disbursementStatus"
                    v-model="disbursementEnabled"
                    class="switch__input"
                    type="checkbox"
                  >
                  <label
                    class="switch__label"
                    for="disbursementStatus"
                  />
                </div>
              </div>
              <div v-if="agentData.lockedOut">
                <div class="customer-info__item__title m-r-20">
                  Unlock Agent Account
                </div>
                <div class="switch">
                  <input
                    id="unlockAgentAccount"
                    v-model="unlockAgent"
                    class="switch__input"
                    type="checkbox"
                  >
                  <label
                    class="switch__label"
                    for="unlockAgentAccount"
                  />
                </div>
              </div>
              <div v-if="isAnyOperationPermitted(['setPassCashoutFeeToCustomerForMerchant'])">
                <div class="customer-info__item__title m-r-20">
                  Customer pays card fee
                </div>
                <div class="switch">
                  <input
                    id="passCashoutFeeToCustomerStatus"
                    v-model="passCashoutFeeToCustomerEnabled"
                    class="switch__input"
                    type="checkbox"
                  >
                  <label
                    class="switch__label"
                    for="passCashoutFeeToCustomerStatus"
                  />
                </div>
              </div>

              <div
                v-for="type in remainingCommissionStructureGroups"
                :key="type.key"
                class="block-display"
              >
                <div class="customer-info__item__title m-r-20">
                  Update {{ type.value.label }} Pricing
                </div>
                <div
                  class="customer-info__item__value"
                  style="max-width: 500px; overflow: unset; width: 300px;"
                >
                  <div
                    v-if="pricingOptions[type.key]"
                    class="form__item m-b-none weight400"
                  >
                    <v-select
                      v-model="pricingOptions[type.key].selected"
                      :options="pricingOptions[type.key].map(option => ({ label: transformToLabel(option), value: option }))"
                      :placeholder="transformToLabel(agentData[type.value.agentDataProperty])"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="agent-info__right">
              <div class="customer-info__item__title m-r-20">
                Assign Account Manager
              </div>
              <div
                class="customer-info__item__value"
                style="max-width: 500px;overflow: unset;width: 300px;"
              >
                <div class="form__item m-b-none">
                  <v-select
                    v-model="selectedAccManager"
                    :options="accountManagers"
                    :placeholder="agentData.accountManagerName"
                    label="fullName"
                    @search="searchAccountManager"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 1">
                        No results found for <em>{{ search }}</em>
                      </template>
                      <em
                        v-else
                        style="opacity: 0.5;"
                      >Start typing to search for account manager</em>
                    </template>
                  </v-select>
                </div>
              </div>

              <div
                v-for="type in firstThreeCommissionStructureGroups"
                :key="type.key"
                class="m-t-20"
              >
                <div class="customer-info__item__title m-r-20">
                  Update {{ type.value.label }} Pricing
                </div>
                <div
                  class="customer-info__item__value"
                  style="max-width: 500px; overflow: unset; width: 300px;"
                >
                  <div
                    v-if="pricingOptions[type.key]"
                    class="form__item m-b-none weight400"
                  >
                    <v-select
                      v-model="pricingOptions[type.key].selected"
                      :options="pricingOptions[type.key].map(option => ({ label: transformToLabel(option), value: option }))"
                      :placeholder="transformToLabel(agentData[type.value.agentDataProperty])"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual">
      <div class="dashboard__main__content__home__right">
        <div class="big-card">
          <div class="transaction__header">
            <div class="transaction__header__title">
              Cashiers
            </div>
          </div>
          <div class="transaction__content">
            <div
              v-if="agentData.cashiers && agentData.cashiers.length > 0"
              class="table"
            >
              <div class="table__section table__section--head">
                <div class="table__row">
                  <div class="table__header">
                    Cashier Name
                  </div>
                  <div class="table__header">
                    Email Address
                  </div>
                  <div class="table__header">
                    Phone Number
                  </div>
                  <div class="table__header">
                    Status
                  </div>
                  <div class="table__header">
                    Registration Date
                  </div>
                  <div class="table__header">
                    Last Login
                  </div>
                </div>
              </div>
              <AgentProfileCashierListing :agent-data="agentData" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="editAgent"
      aria-labelledby="editAgentLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--md"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Edit Agent
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form
              class="form"
              @submit.prevent="updateAgent"
            >
              <div class="form__pad">
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >First Name</label>
                  <input
                    v-model.trim="agentData.firstName"
                    class="form__input capitalize fontNormal"
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Last Name</label>
                  <input
                    v-model.trim="agentData.surname"
                    class="form__input capitalize fontNormal"
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Business Name</label>
                  <input
                    v-model.trim="agentData.businessName"
                    class="form__input capitalize fontNormal"
                    type="text"
                  >
                </div>

                <div
                  class="form__item"
                >
                  <!--  v-if="adminAccountInfo.clearanceLevel >= Constants.MINIMUM_UPDATE_MERCHANT_EMAIL"-->
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Email</label>
                  <input
                    v-model.trim="agentData.email"
                    class="form__input capitalize fontNormal"
                    type="text"
                  >
                </div>

                <div
                  class="form__item"
                >
                  <!--  v-if="adminAccountInfo.clearanceLevel >= Constants.MINIMUM_UPDATE_MERCHANT_PHONE"-->
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Phone Number</label>
                  <input
                    v-model.trim="agentData.phone"
                    class="form__input capitalize fontNormal"
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Date of Birth</label>
                  <date-picker
                    v-model.trim="agentData.dateOfBirth"
                    :lang="lang"
                    :placeholder="agentData.dateOfBirth"
                    :disabled-date="disallowDaysAfterToday"
                  />
                </div>
              </div>

              <div class="form__footer">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      id="generateApiCredentials"
      aria-labelledby="generateApiCredentialsLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--md"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Generate API Credentials
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form
              class="form"
              @submit.prevent="generateMerchantApiCredentials"
            >
              <div class="form__pad">
                <div
                  v-for="(ip, index) in allowedIpAddresses"
                  :key="index"
                  class="form__item flex"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Allowed IP Address {{ index + 1 }}</label>
                  <input
                    v-model.trim="allowedIpAddresses[index]"
                    class="form__input capitalize fontNormal"
                    type="text"
                    @input="validateInput(index)"
                  >
                  <button
                    v-if="allowedIpAddresses.length > 1"
                    type="button"
                    class="btn btn--deleteterminal btn--md m-b-20"
                    @click="removeIpAddress(index)"
                  >
                    X
                  </button>
                </div>
                <button
                  type="button"
                  class="btn btn--primary btn--md m-b-20"
                  :disabled="!canAddIpAddress"
                  @click="addIpAddress"
                >
                  Insert IP Address
                </button>
              </div>

              <div class="form__footer">
                <button
                  class="btn btn--primary btn--md btn--block"
                  :disabled="!canAddIpAddress"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import "vue-select/dist/vue-select.css";
  import "vue2-datepicker/index.css";
  import BackIcon from "@/components/icons/BackIcon";
  import DatePicker from "vue2-datepicker";
  import Vue from "vue";
  import moment from "moment";
  import vSelect from "vue-select";
  import { Constants } from "@/assets/constants";
  import { mapGetters } from "vuex";
  import { accountManagerOperations } from "@/mixins/MerchantOperations";
  import { callGetApi, callPostApi, callPutApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  import AgentProfileCashierListing from "@/components/dashboard/AgentsIndividual/CashierListingRow.vue";
  import { disallowDaysAfterToday } from "@/util/dates";


  export default {
    components: {
      AgentProfileCashierListing,
      TitleBar,
      BackIcon,
      DatePicker,
      vSelect
    },
    filters: {
      agentDateOfBirth(dob) {
        return moment(dob, "YYYY-MM-DD").format("DD-MMM-YYYY");
      }
    },
    mixins: [accountManagerOperations, AdminPermissionsHelper],
    data() {
      return {
        pricingOptions: {},
        commissionStructureGroups: {
          DISBURSE_TO_BANK: {
            label: "Transfer to Bank",
            agentDataProperty: "disbursementCommissionStructure",
            suffix: "DISBURSEMENT"
          },
          PTSA_CASHOUT: {
            label: "Cashout",
            agentDataProperty: "ptsaCashoutCommissionStructure",
            suffix: "PTSA_CASHOUT"
          },
          VIRTUAL_ACCOUNT_SALE: {
            label: "EFT Bank Transfer Sale",
            agentDataProperty: "virtualAccountSaleCommissionStructure",
            suffix: "VIRTUAL_ACCOUNT_SALE"
          },
          FUND_WALLET: {
            label: "Fund Wallet",
            agentDataProperty: "fundWalletCommissionStructure",
            suffix: "FUND_WALLET"
          },
          CARD_SALE: {
            label: "EFT Card Sale",
            agentDataProperty: "saleCommissionStructure",
            suffix: "CARD_SALE"
          }
        },
        agent: "",
        bvnDetails: "",
        statusVerified: "",
        dataStatusVerified: "",
        dispenseError: "",
        originalDispenseError: "",
        originalDataStatusVerified: "",
        submitDisabled: false,
        partnerOrgs: "",
        accountManagers: [],
        selectedAccManager: "",
        agentData: [],
        allowedIpAddresses: [],
        canAddIpAddress: false,
        virtualAccounts: [],
        Constants,
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          pickers: ["next 7 days", "next 30 days", "previous 7 days", "previous 30 days"],
          placeholder: {
            date: "Select Date",
            dateRange: "Select Date Range"
          }
        },
        shortcuts: [
          {
            text: "Today",
            onClick: () => {
              this.time3 = [new Date(), new Date()];
            }
          }
        ],
        timePickerOptions: {
          start: "00:00",
          step: "00:30",
          end: "23:30"
        },
        passCashoutFeeToCustomerEnabled: false,
        originalPassCashoutFeeToCustomerEnabled: false,
        disbursementEnabled: false,
        originalDisbursementEnabled: false,
        unlockAgent: false
      };
    },
    computed: {
      ...mapGetters(["adminAccountInfo"]),
      firstThreeCommissionStructureGroups() {
        const firstTwoTransactionKeys = Object.keys(this.commissionStructureGroups).slice(0, 3);
        return firstTwoTransactionKeys.map((key) => ({ key, value: this.commissionStructureGroups[key] }));
      },
      remainingCommissionStructureGroups() {
        const remainingTransactionKeys = Object.keys(this.commissionStructureGroups).slice(3);
        return remainingTransactionKeys.map((key) => ({ key, value: this.commissionStructureGroups[key] }));
      }
    },
    created() {
      this.getPricingOptions();
      this.validateAllInputs();
    },
    beforeRouteEnter(from, to, next) {
      Vue.http
        .get(`merchant?criteria=${from.params.id}`)
        .then(res => {
          const entity = res.body?.entity;
          const merchants = entity?.merchants;
          if (!entity || !merchants || merchants.length === 0 || res.body.status.code !== 0) {
            next(false);
          } else {
            next(vm => {
              const merchant = merchants[0];
              vm.agentData = merchant;
              vm.dataStatusVerified = merchant?.verificationStatus === "VERIFIED";
              vm.originalDataStatusVerified = merchant?.verificationStatus === "VERIFIED";
              vm.dispenseError = merchant?.dispenseErrorEnabled;
              vm.originalDispenseError = merchant?.dispenseErrorEnabled;
              vm.virtualAccounts = merchant?.virtualAccountDetails;
              vm.disbursementEnabled = merchant?.disbursementEnabled;
              vm.originalDisbursementEnabled = merchant?.disbursementEnabled;
              vm.passCashoutFeeToCustomerEnabled = merchant?.passCashoutFeeToCustomer;
              vm.originalPassCashoutFeeToCustomerEnabled = merchant?.passCashoutFeeToCustomer;
            });
          }
        })
        .catch(err => {
          console.error(err);
          next(false);
        });
    },
    methods: {
      disallowDaysAfterToday,
      transformToLabel: function(value) {
        if (!value) {
          return "";
        }

        for (const type in this.commissionStructureGroups) {
          if (value.endsWith(this.commissionStructureGroups[type].suffix)) {
            value = value.slice(0, -this.commissionStructureGroups[type].suffix.length);
          }
        }
        return value.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
      },
      getPricingOptions() {
        for (const type in this.commissionStructureGroups) {
          if (type in this.commissionStructureGroups) {
            this.getAgentPricingOptions(type, (options) => {
              this.$set(this.pricingOptions, type, options);
            });
          }
        }
      },
      updateAgent() {
        const body = {
          id: this.agentData.id,
          firstName: this.agentData.firstName,
          surname: this.agentData.surname,
          dateOfBirth: moment(this.agentData.dateOfBirth).format("YYYY-MM-DD"),
          businessName: this.agentData.businessName,
          email: this.agentData.email,
          phoneNumber: this.agentData.phone
        };

        callPutApi("merchant/", { body })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Update Agent",
                text: "Agent updated successfully"
              });
              $("#editAgent").modal("hide");
              this.fetchAgent();
            } else {
              this.$notify({
                group: "general",
                title: "Update Agent",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      fetchAgent() {
        callGetApi(`merchant?criteria=${this.$route.params.id}`)
          .then(res => {
            this.agentData = res.body.entity.merchants[0];
            this.dataStatusVerified = res.body.entity.merchants[0].verificationStatus === "VERIFIED";
            this.originalDataStatusVerified = res.body.entity.merchants[0].verificationStatus === "VERIFIED";
            this.dispenseError = res.body.entity.merchants[0].dispenseErrorEnabled;
            this.originalDispenseError = res.body.entity.merchants[0].dispenseErrorEnabled;
            this.virtualAccounts = res.body.entity.merchants[0].virtualAccountDetails;
            this.disbursementEnabled = res.body.entity.merchants[0].disbursementEnabled;
            this.originalDisbursementEnabled = res.body.entity.merchants[0].disbursementEnabled;
            this.passCashoutFeeToCustomerEnabled = res.body.entity.merchants[0].passCashoutFeeToCustomer;
            this.originalPassCashoutFeeToCustomerEnabled = res.body.entity.merchants[0].passCashoutFeeToCustomer;
          })
          .catch(console.error);
      },
      saveChanges() {
        if (this.selectedAccManager.id && this.selectedAccManager.id !== this.agentData.accountManagerId) {
          this.updateAccManager();
        }

        if (this.dataStatusVerified !== this.originalDataStatusVerified) {
          this.changeVerification();
        }

        if (this.dispenseError !== this.originalDispenseError) {
          this.changeDispenseError();
        }

        if (this.disbursementEnabled !== this.originalDisbursementEnabled) {
          this.changeDisbursementStatus();
        }

        if (this.passCashoutFeeToCustomerEnabled !== this.originalPassCashoutFeeToCustomerEnabled) {
          this.changepassCashoutFeeToCustomerStatus();
        }

        if (this.unlockAgent) {
          this.unlockAgentAccount();
        }

        for (const type in this.commissionStructureGroups) {
          const selectedOption = this.pricingOptions[type]?.selected;
          if (selectedOption && selectedOption.value) {
            this.updateMerchantCommissionStructure(selectedOption.value);
            this.agentData[this.commissionStructureGroups[type].agentDataProperty] = selectedOption.value;
            selectedOption.selected = [];
          }
        }

      },
      changeDispenseError() {
        const status = this.dispenseError ? "true" : "false";

        callPutApi(`dispense-error-enabled/merchant/${this.$route.params.id}/${status}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Dispense Error",
                text: "Dispense Error setting changed successfully"
              });
              this.fetchAgent();
            } else {
              this.$notify({
                group: "general",
                title: "Dispense Error",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      updateAccManager() {
        const body = {
          accountManagerId: this.selectedAccManager.id,
          merchantId: this.agentData.id
        };
        callPutApi("merchant/account-manager", { body })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Account Manager",
                text: "Account Manager changed successfully"
              });
              this.fetchAgent();
            } else {
              this.$notify({
                group: "general",
                title: "Account Manager",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      fetchPartnerOrgs() {
        callGetApi( "partner-organizations" )
          .then(res => {
            this.partnerOrgs = res.body.entity;
          })
          .catch(console.error);
      },
      verify() {
        callPutApi(`bvn/resolve/${this.agentData.id}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Merchant Verification",
                text: "Merchant verified successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Merchant Verification",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      changeVerification() {
        this.submitDisabled = true;
        let status;
        if (this.dataStatusVerified) {
          status = "VERIFIED";
        } else {
          status = "PENDING";
        }
        callPutApi(`merchant/verification/${this.agentData.id}/${status}`)
          .then(res => {
            this.submitDisabled = false;
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Agent Changes",
                text: "Changes made successfully"
              });
              this.fetchAgent();
            } else {
              this.$notify({
                group: "general",
                title: "Agent Changes",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(err => {
            this.submitDisabled = false;
            console.error(err);
          });
      },
      changeDisbursementStatus() {
        const status = this.disbursementEnabled ? " Enabled" : " Disabled";

        callPutApi(`merchant/disbursement-enabled/${this.$route.params.id}/${this.disbursementEnabled}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `Disbursement ${status}`,
                text: "Disbursement setting changed successfully"
              });
              this.fetchAgent();
            } else {
              this.$notify({
                group: "general",
                title: `Disbursement ${status}`,
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      changepassCashoutFeeToCustomerStatus() {
        const status = this.passCashoutFeeToCustomerEnabled ? " Enabled" : " Disabled";

        callPutApi(`merchant/pass-cashout-fee-to-customer/${this.$route.params.id}/${this.passCashoutFeeToCustomerEnabled}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `Customer pays card fee ${status}`,
                text: "Customer pays card fee setting changed successfully"
              });
              this.fetchAgent();
            } else {
              this.$notify({
                group: "general",
                title: `Customer pays card fee ${status}`,
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      unlockAgentAccount() {
        callPutApi(`merchant/unlock/${this.agentData.id}`).then(res => {
          if (res.body.status.code === 0) {
            this.$notify({
              group: "general",
              title: "Unlock Agent Account",
              text: "Agent account unlocked successfully"
            });
            this.fetchAgent();
          } else {
            this.$notify({
              group: "general",
              title: "Unlock Agent Account",
              type: "warn",
              text: res.body.status.description
            });
          }
        });
      },
      getAgentPricingOptions(transactionType, setPricing) {
        callGetApi(`merchant/commission-structure-groups/${transactionType}`)
          .then(res => {
            const { code, description } = res.body.status;
            if (code === 0) {
              setPricing(res.body.entity);
            } else {
              setPricing([]);
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(err => {
            const message = err ? JSON.stringify(err.message, null, 2) : "Error fetching pricing";
            this.$notify({
              group: "general",
              title: "Fetch Merchant Pricing",
              type: "warn",
              text: message
            });
          });
      },
      updateMerchantCommissionStructure(commissionStructure) {
        const id = this.agentData.id;

        callPutApi(`merchant/commission-structure-groups/${commissionStructure}/${id}`)
          .then(res => {
            const { code, description } = res.body.status;
            if (code === 0) {
              this.$notify({
                group: "general",
                title: "Agent Pricing",
                text: "Agent pricing changed successfully"
              });
            } else {
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(err => {
            const message = err ? JSON.stringify(err.message, null, 2) : "Error updating pricing";
            this.$notify({
              group: "general",
              title: "Agent Pricing",
              type: "warn",
              text: message
            });
          })
          .finally(() => this.fetchAgent());
      },
      createVirtualAccount() {
        const id = this.agentData.id;
        callGetApi(`fund-wallet/virtual-account-info/${id}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Create Virtual Account",
                text: "Virtual account created/retrieved successfully"
              });
              this.fetchAgent();
            } else {
              this.$notify({
                group: "general",
                title: "Create Virtual Account",
                type: "warn",
                text: res.body.status.description
              });
            }
          });
      },
      addIpAddress() {
        if (this.canAddIpAddress) {
          this.allowedIpAddresses.push("");
          this.canAddIpAddress = false;
        }
      },
      removeIpAddress(index) {
        this.allowedIpAddresses.splice(index, 1);
        this.validateAllInputs();
      },
      validateInput(index) {
        const ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        this.canAddIpAddress = ipRegex.test(this.allowedIpAddresses[index].trim());
      },
      validateAllInputs() {
        this.canAddIpAddress = this.allowedIpAddresses.every(ip => ip.trim() !== "");
      },
      generateMerchantApiCredentials() {
        const body = {
          merchantId: this.agentData.id,
          allowedIpAddresses: this.allowedIpAddresses.filter(ip => ip.trim() !== "")
        };
        if(body.allowedIpAddresses.length < 1) {
          return;
        }
        callPostApi("merchant/api-credentials/email", { body })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Generate API Credentials",
                text: res.body.status.description
              });
              $("#generateApiCredentials").modal("hide");
              this.fetchAgent();
              this.allowedIpAddresses = [];
            } else {
              this.$notify({
                group: "general",
                title: "Generate API Credentials",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      }
    }
  };
</script>
<style scoped>
.block-display {
  display: block;
  margin-top: 10px;
  padding-bottom: 10px;
}
.weight400{
  font-weight: 400;
}
.customer-info__item__value{
  max-width: 300px;
}
</style>
