export const pagination = {
  computed: {
    computedPageNumbers() {
      if (this.pageNumber !== "") {
        return this.pageNumber;
      } else if (window.localStorage.getItem(this.pageName)) {
        return JSON.parse(window.localStorage.getItem(this.pageName));
      } else {
        return "";
      }
    }
  },
  methods: {
    setPageDetails(totalNoOfRows, batchSize) {
      this.pageNumber = Math.ceil(totalNoOfRows / batchSize);
      window.localStorage.setItem(this.pageName, this.pageNumber);
    }
  }
};
