import moment from "moment";

export const Constants = Object.freeze({
  MINIMUM_UPDATE_MERCHANT_EMAIL: 5,
  MINIMUM_UPDATE_MERCHANT_PHONE: 5,
  MAXIMUM_RETRY_COUNT: 3,
  LATENCY_THRESHOLD: 10000,
  START_OF_DAY: () => {
    let from = moment().format("YYYY-MM-DD HH:mm:ss ZZ");
    const time = from.substring(11, 19);
    from = from.replace(time, "00:00:00");
    return encodeURIComponent(from);
  },
  END_OF_DAY: () => {
    let to = moment().format("YYYY-MM-DD HH:mm:ss ZZ");
    const time = to.substring(11, 19);
    to = to.replace(time, "23:59:59");
    return encodeURIComponent(to);
  }
});
