<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Add New Terminal">
      <div class="dashboard__main__content__row__item">
        <a
          href="javascript:history.go(-1)"
          class="dashboard__main__content__back"
        >
          <svg
            viewBox="0 0 100 125"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m81 50a4 4 0 0 0 -4-4h-43.87l16.26-15.16a4 4 0 1 0 -5.46-5.84l-23.69 22.07a4 4 0 0 0 0 5.85l23.69 22.08a4 4 0 0 0 5.46-5.85l-16.26-15.15h43.87a4 4 0 0 0 4-4z"
            />
          </svg>
          <span>Back to {{ terminalType === "ptsp" ? "Manage Terminals" : "TMS" }}</span>
        </a>
      </div>
    </title-bar>

    <div class="dashboard__main__content__home">
      <div
        class="dashboard__main__content__home__left dashboard__main__content__home__left__finance-report"
      >
        <div class="balance">
          <div class="balance__value">
            <form
              class="form"
              style="width: 100%;"
              @submit.prevent="whitelistTerminal"
            >
              <div class="form__item">
                <label
                  class="form__label form__label--2"
                  for=""
                >Terminal Owner</label>
                <v-select
                  v-model="sponsor"
                  :options="allsponsors"
                  label="businessName"
                />
              </div>

              <div class="form__item">
                <label
                  class="form__label form__label--2"
                  for=""
                >Agent</label>
                <v-select
                  v-model="selectedAgent"
                  :options="agents"
                  label="businessName"
                  @search="searchAgentByName"
                >
                  <template>Enter Agent Name</template>
                </v-select>
              </div>

              <div
                v-if="terminalType === 'ptsa'"
                class="form__item"
              >
                <label
                  class="form__label form__label--2"
                  for=""
                >Terminal Model</label>
                <v-select
                  v-model="terminalModel"
                  :options="terminalModels"
                  class="fontNormal"
                />
              </div>

              <div
                v-if="terminalType === 'ptsp'"
                class="form__item"
              >
                <label
                  class="form__label form__label--2"
                  for=""
                >Terminal ID</label>
                <input
                  v-model="terminal"
                  class="form__input capitalize fontNormal"
                  maxlength="8"
                  placeholder="Terminal ID"
                  type="text"
                >
              </div>

              <div class="form__item">
                <label
                  class="form__label form__label--2"
                  for=""
                >Terminal Serial Number</label>
                <input
                  v-model="device"
                  class="form__input capitalize fontNormal"
                  placeholder="Serial #"
                  type="text"
                >
              </div>

              <div v-if="terminalType === 'ptsa'">
                <div
                  v-for="provider in cashoutProviders"
                  :key="provider"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >{{ provider }} TID</label>
                  <input
                    v-model="providerTerminalIds[provider]"
                    class="form__input capitalize fontNormal"
                    placeholder="2xxxxxxx"
                    type="text"
                  >
                </div>
              </div>

              <div
                v-if="terminalType === 'ptsp'"
                class="form__item"
              >
                <label
                  class="form__label form__label--2"
                  for=""
                >MID</label>
                <v-select
                  v-model="mid"
                  :options="allmids"
                />
              </div>

              <button
                class="btn btn--primary btn--md btn--block"
                type="submit"
              >
                Add Terminal
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="dashboard__main__content__home__right" />
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { terminalOperations, providerOperations } from "@/mixins/TerminalOperations";
  import { merchantOperations } from "@/mixins/MerchantOperations";
  import { callGetApi } from "@/httpClient";

  export default {
    components: {
      TitleBar,
      vSelect
    },
    mixins: [
      terminalOperations,
      merchantOperations,
      providerOperations
    ],
    data() {
      return {
        sponsor: "",
        terminal: "",
        device: "",
        terminalModel: "",
        mid: "",
        allmids: [],
        allsponsors: [],
        terminalType: this.$route.params.terminalType,
        crowdPayTid: "",
        rubiesTid: "",
        threeLineTid: "",
        selectedAgent: "",
        agents: [],
        cashoutProviders: [],
        terminalModels: [],
        providerTerminalIds: {}
      };
    },
    created() {
      this.getMids();
      this.getSponsors();
      this.getPtsaProviders();
      this.getTerminalModels();
    },
    methods: {
      getMids() {
        callGetApi("mids")
          .then(res => {
            this.allmids = res.body.entity.filter(mid => mid);
          })
          .catch(console.error);
      },
      getSponsors() {
        callGetApi("sponsors")
          .then(res => {
            this.allsponsors = res.body.entity;
          })
          .catch(console.error);
      }
    }
  };
</script>
