<template>
  <div
    id="providerResponse"
    v-click-outside="closeModal"
    aria-labelledby="providerResponseLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal__dialog modal__dialog--lg"
      role="document"
    >
      <div class="modal__content">
        <div class="modal__header">
          <div class="modal__title text-center">
            Provider Response
          </div>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
            @click="closeModal"
          >
            <close-icon />
          </button>
        </div>
        <div
          class="modal__body modal__body--pad"
          style="padding-top: 0;"
        >
          <div
            v-if="providerResponseLoading"
            class="loaderContainer"
          >
            <div class="loader" />
          </div>
          <pre class="response-message">
            <p v-if="providerResponse">{{ providerResponse }}</p>
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CloseIcon from "@/components/icons/CloseIcon";
  import { callGetApi } from "@/httpClient";

  export default {
    name: "ProviderResponse",
    components: { CloseIcon },
    props: {
      obj: {
        type: [Object, String],
        required: true
      },
      isOpen: Boolean,
      transactionType: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        providerResponseLoading: false,
        providerResponse: ""
      };
    },
    watch: {
      isOpen(newValue) {
        if (newValue) {
          this.showProviderResponseModal();
        } else {
          this.providerResponse = "";
        }
      }
    },
    methods: {
      getTransactionStatus() {
        this.providerResponseLoading = true;
        callGetApi(`provider-transaction-status/${this.transactionType}/${this.obj.tellerpointRef}` )
          .then(response => {
            if (response.body.status.code === 0) {
              const providerResponse = response.body.entity;
              providerResponse.trim();
              try {
                this.providerResponse = JSON.parse(providerResponse);
              } catch (e) {
                this.providerResponse = JSON.stringify(providerResponse);
              }
            } else {
              this.$notify({
                group: "general",
                title: "Provider Transaction Status",
                type: "warn",
                text: response.body.status.description
              });
            }
          })
          .catch(error => {
            const message = error ? JSON.stringify(error, null, 2) : "Error retrieving provider response";
            this.$notify({
              group: "general",
              title: "Provider Transaction Status",
              type: "warn",
              text: message
            });
          })
          .finally(() => this.providerResponseLoading = false);
      },
      closeModal() {
        this.$emit("close-modal");
      },
      showProviderResponseModal() {
        this.providerResponse = "";
        $("#providerResponse").modal();
        this.getTransactionStatus();
      }
    }
  };
</script>

<style scoped>
  .response-message {
    overflow: auto;
    margin: 0;
  }
</style>
