<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Disable Services" />

    <div class="dashboard__main__content__home">
      <div
        class="dashboard__main__content__home__left dashboard__main__content__home__left__finance-report"
      >
        <div class="balance">
          <h2 class="fontNormal m-b-30">
            Disable Bank Transfers
            <svg
              height="15px"
              viewBox="0 0 20 20"
              width="15px"
            >
              <use xlink:href="../../assets/icons/global.svg#global_icon" />
            </svg>
          </h2>
          <div class="toggle__table">
            <div class="toggle__table__row">
              <p class="toggle__table__header">
                All Banks
              </p>

              <div class="d-flex">
                <v-select
                  v-model="allBankPayToBankProvider"
                  :options="payToBankProviders"
                  class="vselect-style width180 section--left"
                  return-object
                  placeholder="Select a Provider"
                  @input="changeAllBanksDisbursementProvider"
                >
                  <template #no-options="">
                    No provider available
                  </template>
                </v-select>
                <div class="switch switch-mt-3">
                  <input
                    id="switch1"
                    v-model="disableBankToggle"
                    class="switch__input"
                    type="checkbox"
                    @change="disableBankTransfers"
                  >
                  <label
                    class="switch__label"
                    for="switch1"
                  />
                </div>
              </div>
            </div>
            <div class="table toggle__table">
              <!-- Table Head -->
              <div class="table__header toggle__table__header">
                Bank
              </div>
              <!-- Table body -->
              <div class="table__section table__section--body">
                <div
                  v-for="(item, i) in banks"
                  :key="i"
                  class="table__row toggle__table__row"
                >
                  <p class="table__data--main">
                    {{ item.bankName }}
                  </p>

                  <div class="d-flex">
                    <v-select
                      v-model="item.preferredBankDisbursementProvider"
                      :options="payToBankProviders"
                      class="vselect-style width180 section--left"
                      return-object
                      placeholder="Select a Provider"
                      @input="changeBankDisbursementProvider(item)"
                    >
                      <template #no-options="">
                        No provider available
                      </template>
                    </v-select>
                    <div class="switch switch-mt-3">
                      <input
                        :id="i"
                        v-model="item.disbursementDisabled"
                        class="switch__input"
                        type="checkbox"
                        @change="disableBank(item)"
                      >
                      <label
                        :for="i"
                        class="switch__label"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="dashboard__main__content__home__right dashboard__main__content__home__right__disabletransfers"
      >
        <div class="balance">
          <h2 class="fontNormal m-b-30">
            Disable Wallet Transfers
          </h2>
          <div class="toggle__table">
            <div class="toggle__table__row">
              <p class="toggle__table__header">
                Wallet Transfers
              </p>
              <div class="switch">
                <input
                  id="switch2"
                  v-model="disableWalletToggle"
                  class="switch__input"
                  type="checkbox"
                  @change="disableWalletTransfers"
                >
                <label
                  class="switch__label"
                  for="switch2"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="balance">
          <h2 class="fontNormal m-b-30">
            Disable PTSA Cashout
          </h2>
          <div class="toggle__table">
            <div class="toggle__table__row">
              <p class="toggle__table__header">
                All Providers
              </p>
              <div class="switch">
                <input
                  id="cashoutSwitch"
                  v-model="disablePtsaCashoutToggle"
                  class="switch__input"
                  type="checkbox"
                  @change="disablePtsaCashouts"
                >
                <label
                  class="switch__label"
                  for="cashoutSwitch"
                />
              </div>
            </div>
            <div class="table toggle__table">
              <!-- Table Head -->
              <div class="table__header toggle__table__header">
                Providers
              </div>
              <!-- Table body -->
              <div class="table__section table__section--body">
                <div
                  v-for="(provider, index) in cashoutProviders"
                  :key="index"
                  class="table__row toggle__table__row"
                >
                  <p class="table__data--main">
                    {{ provider[0] }}
                  </p>
                  <div class="switch">
                    <input
                      :id="provider[0]"
                      v-model="disabledCashoutProviders"
                      :value="provider[0]"
                      class="switch__input"
                      type="checkbox"
                      @change="disablePtsaCashoutProviders(provider)"
                    >
                    <label
                      :for="provider[0]"
                      class="switch__label"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="balance">
          <h2 class="fontNormal m-b-30">
            Disable Bill Payment
          </h2>
          <div class="toggle__table">
            <div class="toggle__table__row">
              <p class="toggle__table__header">
                All Categories
              </p>
              <div class="switch">
                <input
                  id="billPaymentSwitch"
                  v-model="disableBillPaymentToggle"
                  class="switch__input"
                  type="checkbox"
                  @change="disableBillPayments"
                >
                <label
                  class="switch__label"
                  for="billPaymentSwitch"
                />
              </div>
            </div>
            <div class="table toggle__table">
              <!-- Table Head -->
              <div class="table__header toggle__table__header">
                Category
              </div>
              <!-- Table body -->
              <div class="table__section table__section--body">
                <div
                  v-for="(category, index) in billPaymentCategories"
                  :key="index"
                  class="table__row toggle__table__row"
                >
                  <p class="table__data--main customer-info__item__value__transfers">
                    {{ category.categoryDisplayName }}
                  </p>
                  <div class="switch">
                    <input
                      :id="category.categoryName"
                      v-model="category.status"
                      class="switch__input"
                      type="checkbox"
                      @change="disableBillPaymentCategory(category)"
                    >
                    <label
                      :for="category.categoryName"
                      class="switch__label"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="table toggle__table m-t-40">
              <!-- Table Head -->
              <div class="table__header toggle__table__header">
                Biller
              </div>
              <!-- Table body -->
              <div class="table__section table__section--body">
                <div
                  v-for="(biller, index) in billers"
                  :key="index"
                  class="table__row toggle__table__row"
                >
                  <p class="table__data--main customer-info__item__value__transfers">
                    {{ biller[0] }}
                  </p>
                  <div class="switch">
                    <input
                      :id="biller[0]"
                      v-model="disabledBillers"
                      :value="biller[0]"
                      class="switch__input"
                      type="checkbox"
                      @change="disableBiller(biller)"
                    >
                    <label
                      :for="biller[0]"
                      class="switch__label"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import { callGetApi, callPutApi } from "@/httpClient";
  import vSelect from "vue-select";

  export default {
    components: {
      TitleBar,
      vSelect
    },
    data() {
      return {
        banks: "",
        selectedBank: "",
        disableBankToggle: false,
        allBankPayToBankProvider: "",
        disableWalletToggle: false,
        disablePtsaCashoutToggle: false,
        disableBillPaymentToggle: false,
        disabledCashoutProviders: [],
        payToBankProviders: [],
        disabledBillers: [],
        cashoutProviders: {},
        billers: {},
        billPaymentCategories: {}
      };
    },
    created() {
      this.fetchBanks();
      this.fetchPayToBankProviders();
      this.fetchBankStatus();
      this.fetchWalletStatus();
      this.fetchPtsaCashoutStatus();
      this.fetchPtsaProviders();
      this.fetchBillPaymentStatus();
      this.fetchBillPaymentCategories();
      this.fetchBillPaymentBiller();
    },
    methods: {
      fetchBanks() {
        callGetApi("banks")
          .then(res => {
            this.noData = res.body.entity.length === 0;

            this.banks = res.body.entity;
            this.pageNumber = Math.ceil(
              res.body.pageMetaData.totalNoOfRows /
                res.body.pageMetaData.batchSize
            );
            window.localStorage.setItem(
              "disableBanksPage",
              Math.ceil(
                res.body.pageMetaData.totalNoOfRows /
                  res.body.pageMetaData.batchSize
              )
            );

            this.loading = false;
          })
          .catch(console.error);
      },
      disableBank(item) {
        const body = [
          {
            bankCode: item.bankCode,
            value: item.disbursementDisabled
          }
        ];
        callPutApi("banks/disable-disbursement", { body })
          .then(res => {
            if (res.body.status.code === 0) {
              const action = item.disbursementDisabled ? "Disabled" : "Enabled";
              this.$notify({
                group: "general",
                title: "Disable Bank Tranfer",
                text: `Successfully ${action} transfer for ${item.bankName}`
              });
            } else {
              this.$notify({
                group: "general",
                title: "Disable Bank Tranfer",
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchBanks();
          })
          .catch(console.error);
      },
      disableBankTransfers() {
        const status = this.disableBankToggle;

        callPutApi(`bank-transfer/disable/${status}`)
          .then(res => {
            if (res.body.status.code === 0) {
              const action = status ? "Disabled" : "Enabled";
              this.$notify({
                group: "general",
                title: "Disable Bank Tranfer",
                text: `Successfully ${action} Bank Tranfer`
              });
            } else {
              this.$notify({
                group: "general",
                title: "Disable Bank Tranfer",
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchBankStatus();
          })
          .catch(console.error);
      },
      disableWalletTransfers() {
        const status = this.disableWalletToggle;

        callPutApi(`wallet-transfer/disable/${status}`)
          .then(res => {
            if (res.body.status.code === 0) {
              const action = status ? "Disabled" : "Enabled";
              this.$notify({
                group: "general",
                title: "Disable Wallet Tranfer",
                text: `Successfully ${action} Wallet Tranfer`
              });
            } else {
              this.$notify({
                group: "general",
                title: "Disable Wallet Tranfer",
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchWalletStatus();
          })
          .catch(console.error);
      },
      disablePtsaCashouts() {
        const status = this.disablePtsaCashoutToggle;
        const action = status ? "Disable" : "Enable";
        callPutApi(`ptsa-cashout/disable/${status}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `${action} Ptsa Cashout`,
                text: `Successfully ${action}d Ptsa Cashout`
              });
            } else {
              this.$notify({
                group: "general",
                title: `${action} Ptsa Cashout`,
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchPtsaCashoutStatus();
          })
          .catch(console.error);
      },
      disablePtsaCashoutProviders(item) {
        const provider = item[0];
        const status = !item[1];
        const action = status ? "Enable" : "Disable";
        callPutApi(`ptsa/cashout/${provider}/${status}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `${action} cashout provider`,
                text: `Successfully ${action}d ${provider}`
              });
            } else {
              this.$notify({
                group: "general",
                title: `${action} ${provider}`,
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchPtsaProviders();
          })
          .catch(console.error);
      },
      disableBillPayments() {
        const status = this.disableBillPaymentToggle;
        const action = status ? "Disable" : "Enable";
        callPutApi(`bill-payment/disable/${status}`)
          .then(response => {
            const { code, description } = response.body.status;
            if (code !== 0) {
              throw new Error(`Error ${code}: ${description}`);
            }

            this.$notify({
              group: "general",
              title: `${action} Bill Payment`,
              text: `Successfully ${action}d Bill Payment`
            });
          })
          .catch(error => this.$notify({
            group: "general",
            title: `${action} Bill Payment`,
            type: "warn",
            text: error.message
          })).finally(() => this.fetchBillPaymentStatus());
      },
      disableBillPaymentCategory(category) {
        const categoryName = category.categoryName;
        const status = category.status;
        const action = status ? "Disable" : "Enable";
        callPutApi(`bill-payment/category/disable/${categoryName}/${status}`)
          .then(response => {
            const { code, description } = response.body.status;
            if (code !== 0) {
              throw new Error(`Error ${code}: ${description}`);
            }

            this.$notify({
              group: "general",
              title: `${action} Bill Payment Category`,
              text: `Successfully ${action}d ${categoryName.replace(/_/g, " ")}`
            });
          })
          .catch(error => this.$notify({
            group: "general",
            title: `${action} Bill Payment`,
            type: "warn",
            text: error.message
          })).finally(() => this.fetchBillPaymentCategories());
      },
      disableBiller(biller) {
        const billerDisplayName = biller[0];
        const status = !biller[1];
        const action = status ? "Enable" : "Disable";
        callPutApi(`bill-payment/biller/disable/${billerDisplayName}/${status}`)
          .then(response => {
            const { code, description } = response.body.status;
            if (code !== 0) {
              throw new Error(`Error ${code}: ${description}`);
            }

            this.$notify({
              group: "general",
              title: `${action} Biller`,
              text: `Successfully ${action}d ${billerDisplayName}`
            });
          })
          .catch(error => this.$notify({
            group: "general",
            title: `${action} Biller`,
            type: "warn",
            text: error.message
          })).finally(() => this.fetchBillPaymentBiller());
      },
      fetchBankStatus() {
        callGetApi("bank-transfer/status")
          .then(res => {
            this.disableBankToggle = res.body.entity;
          })
          .catch(console.error);
      },
      fetchWalletStatus() {
        callGetApi("wallet-transfer/status")
          .then(res => {
            this.disableWalletToggle = res.body.entity;
          })
          .catch(console.error);
      },
      fetchPtsaCashoutStatus() {
        callGetApi("ptsa-cashout/status")
          .then(res => {
            this.disablePtsaCashoutToggle = res.body.entity;
          })
          .catch(console.error);
      },
      fetchPayToBankProviders() {
        callGetApi("service-providers/pay-to-bank")
          .then(res => {
            this.payToBankProviders = res.body.entity;
          })
          .catch(console.error);
      },
      fetchPtsaProviders() {
        callGetApi("service-providers/ptsa")
          .then(res => {
            this.cashoutProviders = Object.entries(res.body.entity);
            this.disabledCashoutProviders = this.cashoutProviders
              .filter(provider => provider[1] === false)
              .map(key => key[0]);
          })
          .catch(console.error);
      },
      fetchBillPaymentStatus() {
        callGetApi("bill-payment/status")
          .then(res => {
            this.disableBillPaymentToggle = res.body.entity;
          })
          .catch(console.error);
      },
      fetchBillPaymentCategories() {
        callGetApi("bill-payment/category/status")
          .then(res => {
            this.billPaymentCategories = res.body.entity;
          })
          .catch(console.error);
      },
      fetchBillPaymentBiller() {
        callGetApi("bill-payment/biller/status")
          .then(res => {
            this.billers = Object.entries(res.body.entity);
            this.disabledBillers = this.billers
              .filter(provider => provider[1] === false)
              .map(key => key[0]);
          })
          .catch(console.error);
      },
      changeBankDisbursementProvider(bank) {
        callPutApi(`preferred-provider/pay-to-bank/${bank.bankCode}/${bank.preferredBankDisbursementProvider}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `${bank.bankName} Provider`,
                text: "Updated successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: `${bank.bankName} Provider`,
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchBanks();
          })
          .catch(console.error);
      },
      changeAllBanksDisbursementProvider() {
        const preferredBankDisbursementProvider = this.allBankPayToBankProvider;
        if(preferredBankDisbursementProvider === "") return;
        callPutApi(`preferred-provider/pay-to-bank/${preferredBankDisbursementProvider}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "All banks Provider",
                text: "Updated successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "All Banks Provider",
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchBanks();
          })
          .catch(console.error);
      }
    }
  };
</script>
<style scoped>
  .width180 {
    width: 180px;
  }
  .d-flex{
    display: flex;
    justify-content: space-between;
  }
  .vselect-style {
     margin-top: 1px;
     margin-bottom: 1px;
  }
  .switch-mt-3{
    margin-top: 4px;
  }
</style>
