<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Revenue Summary">
      <records-filter
        v-click-outside="closeDropdown"
        data="revenue-summary"
        :selected="['date']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="table table--padded transaction half-width">
      <div class="table__section table__section--head">
        <div class="table__row">
          <div class="table__header">
            Service
          </div>
          <div class="table__header">
            Revenue (₦)
          </div>
        </div>
      </div>
      <div class="table__section table__section--body">
        <template v-for="(item, i) in Object.entries(revenueSummary)">
          <div
            :key="i"
            class="table__row"
          >
            <div class="table__data table__data--full">
              <p class="table__data--main">
                {{ nameMap[item[0]] || item[0] }}
              </p>
            </div>
            <div class="table__data">
              {{ item[1] | money }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import RecordsFilter from "@/components/Filter";
  import { callGetApi } from "@/httpClient";

  export default {
    components: {
      RecordsFilter,
      TitleBar
    },
    data() {
      return {
        loading: true,
        nameMap: {
          cashout: "Cashout",
          dispenseErrorRefund: "Dispense Error Refund",
          bankTransfer: "Bank Transfer",
          bvnUsage: "BVN Validation",
          fundWallet: "Fund Wallet",
          billPayment: "Bill Payment",
          sale: "EFT (Sale)",
        },
        revenueSummary: {},
        showDropdown: false
      };
    },
    watch: {
      $route: "fetchRevenueSummary"
    },
    created() {
      this.fetchRevenueSummary();
    },
    methods: {
      closeDropdown() {
        this.showDropdown = false;
      },
      updateDropDownValue(value) {
        this.showDropdown = value;
      },
      fetchRevenueSummary() {
        this.loading = true;
        const query_params = this.convertToQueryString(this.$route.query);
        callGetApi("revenue?" + query_params)
          .then(res => {
            this.revenueSummary = res.body.entity;
            console.log(this.revenueSummary);
            const { code, description } = res.body.status;
            if (code !== 0) {
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(error =>
            this.$notify({
              group: "general",
              type: "warn",
              text: error.message
            })).finally(() => this.loading = false );
      },
      convertToQueryString(myData) {
        return Object.entries(myData)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
      }
    }
  };
</script>
