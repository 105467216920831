import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Resource from "vue-resource";
import Notifications from "vue-notification";
import NProgress from "vue-nprogress";
import moment from "moment";
import { clickOutsideBind, clickOutsideUnBind } from "./main.utils";
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;
Vue.use(Resource);
Vue.use(NProgress);
Vue.use(Notifications);

Vue.directive("click-outside", {
  bind: clickOutsideBind,
  unbind: clickOutsideUnBind
});

// Format Money values into commas
Vue.filter("money", (_amount: any) => {
  let amount = _amount;
  //normalize
  amount += "";
  amount = amount.replace(/,/g, ""); // This is so filter still works if it's used on an already formatted amount
  amount = amount * 1;

  amount = amount.toLocaleString("en", {
    minimumFractionDigits: 2
  }); // EN so we always get the commafied version.

  // If amount is whole, pad with .00 so 2dp is consistent.
  if (!~amount.indexOf(".")) {
    amount += ".00";
  }

  return amount;
});
// Format milliseconds to date
Vue.filter("millisecondsDate", (value: number) => {
  return moment.unix(value).format("L");
});

Vue.filter("splitByCamelCase", (camelCaseValue: String) => {
  return camelCaseValue.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
    return str.toUpperCase();
  });
});

// Format date to date
Vue.filter("date", (value: string) => {
  const date = moment(value, "YYYY-MM-DD HH:mm:ss Z");
  return date.isValid() ? date.format("MMMM D, YYYY h:mm A") : "Invalid Date";
});

// API ROOT
// @ts-ignore http is added by the VueResource dependency
Vue.http.options.root = process.env.VUE_APP_API_BASE ||
  "https://dev.tellerpoint.hextremelabs.net/Tellerpoint/rpc/admin";
// Vue.http.options.root = 'https://dev.tellerpoint.hextremelabs.net/Tellerpoint/rpc/admin';
//Vue.http.options.emulateJSON = true;

// API Call interceptor
// @ts-ignore http is added by the VueResource dependency
Vue.http.interceptors.push((request, next) => {
  request.headers.set("Content-Type", "application/json");

  if (request.url.includes("cashout/receipt/")) {
    request.headers.set("Content-Type", "application/pdf");
  }

  if (window.localStorage.getItem("appToken")) {
    request.headers.set("sessionId", window.localStorage.getItem("appToken"));
  }

  // @ts-ignore
  next(response => {
    if (response.headers.map["content-type"][0].includes("application/pdf")) {
      return;
    }
    if (response.status === 200) {
      if (response.body.status.code === 211 || response.body.status.description ===
        "Unauthenticated. Authentication failure") {

        // Clear LocalStage
        window.localStorage.setItem("appToken", "");
        window.localStorage.setItem("adminInfo", "");
        window.localStorage.setItem("loginTime", "");

        router.push({
          name: "login"
        });
      }
    }
  });
});

const nprogress = new NProgress({
  parent: ".nprogress-container"
});

Sentry.init({
  Vue,
  dsn: "https://3f195d03137214e1672d9b51ac834e52@o4506933321728000.ingest.us.sentry.io/4506933323104256",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/tellerpoint\.hextremelabs\.net\/Tellerpoint\/rpc\/admin/,
    /^https:\/\/dev\.tellerpoint\.hextremelabs\.com\/Tellerpoint\/rpc\/admin/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default new Vue({
  router,
  store,
  // @ts-ignore
  nprogress,
  render: h => h(App)
}).$mount("#app");
