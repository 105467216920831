import { callGetApi, callPostApi, callPutApi } from "@/httpClient";

export const terminalOperations = {
  methods: {
    getTerminals() {
      const query_params = this.convertToQueryString(this.$route.query);

      callGetApi(`${this.terminalType}/terminals?batchSize=20&${query_params}`)
        .then(res => {
          this.loading = false;
          this.terminals = res.body.entity;

          // Pass Total number of pages
          this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);

          // cache response to localstorage
          window.localStorage.setItem(this.pageName, this.pageNumber);
        })
        .catch(console.error);
    },
    mapTerminal() {
      const body = [this.selectedTerminal.terminalId];
      if (this.selectedTerminal.merchantId === this.selectedAgent.id) {
        return;
      }
      callPutApi(`merchant/map-terminals/${this.selectedAgent.id}`, { body })
        .then(res => {
          if (res.body.status.code === 0) {
            this.$notify({
              group: "general",
              title: "Map Terminal",
              text: "Terminal mapped successfully"
            });
            setTimeout(this.$router.go, 1000);
          } else {
            this.$notify({
              group: "general",
              title: "Map Terminal",
              type: "warn",
              text: res.body.status.description
            });
          }
        })
        .catch(console.error);
    },
    whitelistTerminal: function() {
      let requestObject;

      if (this.terminalType === "ptsp") {
        requestObject = {
          id: this.terminal,
          serialNumber: this.device,
          mid: this.mid,
          sponsor: this.sponsor.appId,
          merchantId: this.selectedAgent.id
        };
      } else {
        requestObject = {
          sponsorAppId: this.sponsor.appId,
          merchantId: this.selectedAgent.id,
          terminalMap: {},
          terminalModel: this.terminalModel
        };

        Object.entries(this.providerTerminalIds).forEach(entry => {
          const [provider, tId] = entry;
          requestObject.terminalMap[provider] = {
            [this.device]: tId
          };
        });

        callPostApi(`${this.terminalType}/whitelist-terminals`, { body: requestObject })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "New Terminal",
                text: "New Terminal created successfully"
              });
              this.$router.go(-1);
            } else {
              this.$notify({
                group: "general",
                title: "New Terminal",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(err => {
            if (!err.response) {
              this.$notify({
                group: "general",
                title: "New Terminal",
                type: "error",
                text: "Request Timed out"
              });
            }
            setTimeout(() => {
              this.$router.go();
            }, 5000);
            console.error(err);
          });
      }
    },
    disableTerminal() {
      callPutApi(`terminal/${this.selectedTerminal.terminalId}/${!this.terminalEnabled}`)
        .then(res => {
          if (res.body.status.code === 0) {
            this.$notify({
              group: "general",
              title: "Disable Terminal",
              text: `Terminal has been ${!this.terminalEnabled ? "enabled " : "disabled "} successfully`
            });
          } else {
            this.$notify({
              group: "general",
              title: "Disable Terminal",
              type: "warn",
              text: res.body.status.description
            });
          }
          this.$router.go();
        })
        .catch(console.log);
    },
    getTerminalModels() {
      this.loading = true;
      callGetApi("terminal/version")
        .then(res => {
          if (res.body && res.body.entity) {
            this.terminalModels = Object.keys(res.body.entity);
          } else {
            console.error("Response body or entity is undefined or null");
          }
          this.loading = false;
        });
    }
  }
};

export const providerOperations = {
  methods: {
    getPtsaProviders() {
      callGetApi("service-providers/ptsa")
        .then(res => {
          this.cashoutProviders = Object.keys(res.body.entity);
        })
        .catch(console.error);
    }
  }
};
