<template>
  <div class="table__section table__section--body">
    <template v-for="(cashier, i) in agentData.cashiers">
      <div
        :key="i"
        class="table__row"
      >
        <div class="table__data table__data--full">
          <p class="table__data--main">
            {{ cashier.firstName }} {{ cashier.surname }}
          </p>
        </div>
        <div class="table__data">
          {{ cashier.email }}
        </div>
        <div class="table__data">
          {{ cashier.phone }}
        </div>
        <div class="table__data">
          <span :class="{'tag tag--green': cashier.enabled, 'tag tag--red': !cashier.enabled}">
            {{ cashier.enabled ? 'Enabled' : 'Disabled' }}
          </span>
        </div>
        <div class="table__data">
          {{ cashier.registrationDate | date }}
        </div>
        <div
          v-if="cashier.lastLoginDate"
          class="table__data"
        >
          {{ cashier.lastLoginDate | date }}
        </div>
        <div
          v-else
          class="table__data"
        >
          Never
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    name: "AgentProfileCashierListing",
    props: {
      agentData: {
        type: Object,
        required: true,
        default: () => ({ cashiers: [] })
      },
    }
  };
</script>
