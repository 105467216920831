export const formatQueryParam = {
  methods: {
    convertToQueryString(myData) {
      let url = "";
      for (const key in myData) {
        url += key + "=" + myData[key] + "&";
      }
      return url.trim("&");
    }
  }
};
