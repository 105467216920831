<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Balance Enquiry">
      <button
        class="btn btn--primary"
        @click="fetchProviders(true)"
      >
        Refresh Balances
      </button>
    </title-bar>

    <div class="dashboard__main__content__home__left dashboard__main__content__home__left__provider">
      <div
        v-for="(balance, provider) in providerBalance"
        :key="provider"
        class="balance-card unicolumn"
      >
        <div class="balance-card__header">
          <p class="balance-card__date">
            {{ balance.time }}
          </p>
          <p class="balance-card__providerName gap-s">
            {{ provider }}
          </p>
        </div>

        <div class="unicolumn gap-lm">
          <div class="unicolumn gap-m">
            <div class="balance-card__title">
              Available Balance
            </div>
            <div class="balance-card__amount">
              ₦ {{ balance.availableBalance ? balance.availableBalance : 0 | money }}
            </div>
          </div>
          <div class="unicolumn gap-m">
            <div class="balance-card__title">
              Ledger Balance
            </div>
            <div class="balance-card__amount">
              ₦ {{ balance.ledgerBalance ? balance.ledgerBalance : 0 | money }}
            </div>
          </div>
        </div>

        <div
          class="align-flex-end cursor-pointer"
          @click="fetchProviderBalance(provider)"
        >
          <reload-icon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import ReloadIcon from "@/components/icons/ReloadIcon";
  import moment from "moment";
  import { mapGetters } from "vuex";
  import { callGetApi } from "@/httpClient";

  export default {
    components: {
      TitleBar,
      ReloadIcon
    },
    computed: {
      ...mapGetters(["providerBalance", "currentContext"])
    },
    created() {
      this.fetchProviders();
    },
    methods: {
      async fetchPaytoBankProviders() {
        return callGetApi("service-providers/pay-to-bank");
      },
      async fetchBillPaymentProviders() {
        return callGetApi("service-providers/bill-payment");
      },
      async fetchVirtualAccountProviders() {
        return callGetApi("service-providers/virtual-account");
      },
      fetchProviders(fetchAll) {
        const hasProviderBalance = Boolean(this.providerBalance && Object.keys(this.providerBalance).length);
        if (!hasProviderBalance || fetchAll) {
          this.fetchPaytoBankProviders()
            .then(res => {
              res.body.entity.forEach(provider => this.fetchProviderBalance(provider));
            });
          this.fetchBillPaymentProviders()
            .then(res => {
              res.body.entity.forEach(provider => this.fetchProviderBalance(provider));
            });
          this.fetchVirtualAccountProviders()
            .then(res => {
              res.body.entity.forEach(provider => this.fetchProviderBalance(provider));
            });
        }
      },
      async fetchProviderBalance(providerName) {
        return callGetApi(`service-providers/balance?providerName=${providerName}`)
          .then(res => {
            const { status, entity } = res.body;
            const { code, description } = status;

            if (code === 0 && entity?.[0].status?.code === 0) {
              const balance = entity[0].entity;
              this.$store.dispatch("setProviderBalance", {
                "provider":providerName,
                "context": this.currentContext,
                "balance": {
                  "availableBalance": balance ? balance.availableBalance : 0,
                  "ledgerBalance": balance ? balance.ledgerBalance : 0,
                  "time": moment().format("MMM Do, YYYY. HH:mm:ss a")
                }
              });
              this.$notify({
                group: "general",
                title: "Fetch Provider Balance",
                text: `Successfully fetched balance for ${providerName}`
              });
            } else {
              const message = entity?.[0].status?.description ?? description;
              this.$notify({
                group: "general",
                title: "Fetch Provider Balance",
                type: "warn",
                text: message
              });
            }
          });
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      }
    }
  };
</script>
