<template>
  <div class="unicolumn gap-xl">
    <title-bar title="Services" />

    <div class="cards-grid">
      <div class="big-card unicolumn gap-xl">
        <h2 class="fontNormal">
          BVN Validation
        </h2>

        <div class="unicolumn">
          <div>
            <label>Select a Provider</label>
            <v-select
              v-model="selectedBvnProvider"
              :options="bvnProviders"
              class="vselect-style"
              placeholder="Select a Provider"
            />
          </div>

          <button
            class="btn btn--primary"
            @click="changeBvnProvider"
          >
            Save
          </button>
        </div>
      </div>

      <div class="big-card unicolumn gap-xl">
        <h2 class="fontNormal">
          NUBAN Resolve
        </h2>

        <div class="unicolumn">
          <div>
            <label>Select a Provider</label>
            <v-select
              v-model="selectedNubanProvider"
              :options="nubanProviders"
              class="vselect-style"
              placeholder="Select a Provider"
            />
          </div>

          <button
            class="btn btn--primary"
            @click="changeNubanProvider"
          >
            Save
          </button>
        </div>
      </div>

      <div
        v-for="item in Object.keys(settlementBanks)"
        :key="item"
        class="big-card unicolumn gap-xl"
      >
        <h2 class="fontNormal">
          ISW {{ capitalize(item) }} Settlement
        </h2>

        <div class="unicolumn">
          <div>
            <label>Select a Bank</label>
            <v-select
              v-model="settlementBanks[item].selected"
              :options="settlementBanks[item].list"
              class="vselect-style"
              placeholder="Select a Bank"
              label="bankName"
            >
              <template v-slot:option="{bankName, accountNumber }">
                {{ bankName }} - {{ accountNumber }}
              </template>
              <template #selected-option="{bankName, accountNumber }">
                {{ bankName }} - {{ accountNumber }}
              </template>
              <template #no-options="">
                No settlement bank available
              </template>
            </v-select>
          </div>

          <button
            class="btn btn--primary"
            @click="changeSettlementBank(item)"
          >
            Save
          </button>
        </div>
      </div>

      <div
        v-for="(item, index) in Object.keys(settlementBankPtsaProviders)"
        :key="index"
        class="big-card unicolumn gap-xl"
      >
        <h2 class="fontNormal">
          {{ capitalize(item) }} Cashout
        </h2>
        <div class="unicolumn">
          <div>
            <label>Select a provider</label>
            <v-select
              v-model="settlementBankPtsaProviders[item].selected"
              :options="cashoutProviders"
              class="vselect-style"
              placeholder="Select a PTSA provider"
            >
              <template #no-options="">
                No provider available
              </template>
            </v-select>
          </div>

          <button
            class="btn btn--primary"
            @click="changeSettlementBankPtsaProvider(item)"
          >
            Save
          </button>
        </div>
      </div>

      <div class="big-card unicolumn gap-xl">
        <h2 class="fontNormal">
          Fund Wallet
        </h2>

        <div class="unicolumn">
          <div>
            <label>Select a Provider</label>
            <v-select
              v-model="selectedFundWalletProvider"
              :options="fundWalletProviders"
              class="vselect-style"
              placeholder="Select a Provider"
            />
          </div>

          <button
            class="btn btn--primary"
            @click="changeFundWalletProvider"
          >
            Save
          </button>
        </div>
      </div>
    </div>

    <div class="cards-grid">
      <div class="big-card unicolumn gap-xl">
        <h2 class="fontNormal">
          Billers and Providers
        </h2>
        <div class="unicolumn">
          <div>
            <div class="table__section">
              <div
                v-for="(biller, index) in billers"
                :key="index"
                class="table__row toggle__table__row"
              >
                <label>{{ biller.billerDisplayName }}</label>
                <v-select
                  v-model="biller.preferredBillPaymentProvider"
                  :options="billPaymentsProviders"
                  class="vselect-style width140"
                  return-object
                  placeholder="Select a Provider"
                  @input="changeBillerBillPaymentProvider(biller)"
                >
                  <template #no-options="">
                    No provider available
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import { providerOperations } from "@/mixins/TerminalOperations";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { callGetApi, callPutApi } from "@/httpClient";

  const settlementBankTypes = Object.freeze({
    MASTERCARD: "MASTERCARD",
    VERVE: "VERVE",
    VISA: "VISA"
  });

  export default {
    components: {
      TitleBar,
      vSelect
    },
    mixins: [providerOperations],
    data() {
      return {
        bvnProviders: [],
        selectedBvnProvider: "",

        cashoutProviders: [],

        fundWalletProviders: [],
        selectedFundWalletProvider: "",

        nubanProviders: [],
        selectedNubanProvider: "",

        settlementBanks: {
          [settlementBankTypes.MASTERCARD]: {
            list: undefined,
            selected: undefined
          },
          [settlementBankTypes.VERVE]: {
            list: undefined,
            selected: undefined
          },
          [settlementBankTypes.VISA]: {
            list: undefined,
            selected: undefined
          }
        },

        settlementBankPtsaProviders: {
          [settlementBankTypes.MASTERCARD]: {
            selected: undefined
          },
          [settlementBankTypes.VERVE]: {
            selected: undefined
          },
          [settlementBankTypes.VISA]: {
            selected: undefined
          }
        },

        billers: [],
        billPaymentsProviders: [],
        selectedBillPaymentProvider: [],
      };
    },
    created() {
      this.fetchBillPaymentProviders();

      this.fetchBVNProviders();
      this.fetchCurrentBVNProvider();

      this.fetchFundWalletProviders();
      this.fetchCurrentFundWalletProvider();

      this.fetchNubanProviders();
      this.fetchCurrentNubanProvider();

      Object.keys(settlementBankTypes).forEach(this.fetchSettlementBanks);
      Object.keys(settlementBankTypes).forEach(this.fetchCurrentSettlementBank);

      this.getPtsaProviders();
      Object.keys(settlementBankTypes).forEach(this.fetchCurrentSettlementBankPtsaProvider);

      this.fetchBillPaymentBillerProviders();
    },
    methods: {
      fetchCurrentNubanProvider() {
        callGetApi("preferred-provider/nuban")
          .then(res => {
            this.selectedNubanProvider = res.body.entity;
          })
          .catch(console.error);
      },
      fetchCurrentBVNProvider() {
        callGetApi("preferred-provider/bvn")
          .then(res => {
            this.selectedBvnProvider = res.body.entity;
          })
          .catch(console.error);
      },
      fetchCurrentFundWalletProvider() {
        callGetApi("preferred-provider/virtual-account")
          .then(res => {
            this.selectedFundWalletProvider = res.body.entity;
          })
          .catch(console.error);
      },
      fetchFundWalletProviders() {
        callGetApi("service-providers/virtual-account")
          .then(res => {
            this.fundWalletProviders = res.body.entity;
          })
          .catch(console.error);
      },
      fetchNubanProviders() {
        callGetApi("service-providers/nuban")
          .then(res => {
            this.nubanProviders = res.body.entity;
          })
          .catch(console.error);
      },
      fetchBVNProviders() {
        callGetApi("service-providers/bvn")
          .then(res => {
            this.bvnProviders = res.body.entity;
          })
          .catch(console.error);
      },
      fetchBillPaymentProviders() {
        callGetApi("service-providers/bill-payment")
          .then(res => {
            this.billPaymentsProviders = res.body.entity;
          })
          .catch(console.error);
      },
      fetchCurrentSettlementBank(type) {
        callGetApi(`${type}/settlement-bank/preferred`)
          .then(res => {
            if (res.body.entity) {
              const targetObject = this.settlementBanks[type];
              targetObject.selected = res.body.entity;
            }
          })
          .catch(console.error);
      },
      fetchSettlementBanks(type) {
        callGetApi(`${type}/settlement-bank`)
          .then(res => {
            if (res.body.entity) {
              const targetObject = this.settlementBanks[type];
              targetObject.list = res.body.entity;
            }
          })
          .catch(console.error);
      },
      fetchCurrentSettlementBankPtsaProvider(type) {
        callGetApi(`${type}/ptsa-provider/preferred`)
          .then(res => {
            if (res.body.entity) {
              const targetObject = this.settlementBankPtsaProviders[type];
              targetObject.selected = res.body.entity;
            }
          })
          .catch(console.error);
      },
      fetchBillPaymentBillerProviders() {
        callGetApi("bill-payment/biller/providers")
          .then(res => {
            this.billers = res.body.entity;
          })
          .catch(console.error);
      },
      changeNubanProvider() {
        callPutApi(`preferred-provider/nuban/${this.selectedNubanProvider}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Nuban Resolve Service Provider",
                text: "Updated successfully"
              });
              this.fetchCurrentNubanProvider();
            } else {
              this.$notify({
                group: "general",
                title: "Nuban Resolve Service Provider",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      changeFundWalletProvider() {
        callPutApi(`preferred-provider/virtual-account/${this.selectedFundWalletProvider}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Fund Wallet Service Provider",
                text: "Updated successfully"
              });
              this.fetchFundWalletProviders();
            } else {
              this.$notify({
                group: "general",
                title: "Fund Wallet Service Provider",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      changeBvnProvider() {
        callPutApi(`preferred-provider/bvn/${this.selectedBvnProvider}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "BVN Service Provider",
                text: "Updated successfully"
              });
              this.fetchCurrentBVNProvider();
            } else {
              this.$notify({
                group: "general",
                title: "BVN Service Provider",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      changeBillerBillPaymentProvider(biller) {
        callPutApi(`preferred-provider/bill-payment/${biller.billerId}/${biller.preferredBillPaymentProvider}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `${biller.billerDisplayName} Bill Payment Provider`,
                text: "Updated successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: `${biller.billerDisplayName} Bill Payment Provider`,
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchBillPaymentProviders();
          })
          .catch(console.error);
      },
      changeSettlementBank(type) {
        callPutApi(`${type}/settlement-bank/preferred/${this.settlementBanks[type].selected.id}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `ISW ${type} settlement bank`,
                text: "Updated successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: `ISW ${type} settlement bank`,
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchSettlementBanks(type);
          })
          .catch(console.error);
      },
      changeSettlementBankPtsaProvider(type) {
        callPutApi(`${type}/ptsa-provider/preferred/${this.settlementBankPtsaProviders[type].selected}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `${type} cashout provider`,
                text: "Updated successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: `${type} cashout provider`,
                type: "warn",
                text: res.body.status.description
              });
            }
            this.fetchCurrentSettlementBankPtsaProvider(type);
          })
          .catch(console.error);
      },
      capitalize(text) {
        if ((typeof text) !== "string") return text;
        if (text.length <= 1) return text.toUpperCase;
        return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
      }
    }
  };
</script>

<style scoped>
  .width140 {
    width: 140px;
  }
</style>
