<template>
  <div class="table__section table__section--head">
    <div class="table__row">
      <div
        v-for="(row, i) in headerData"
        :key="i"
        class="table__header"
      >
        {{ row }}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "DataTableHead",
    props: {
      headerData : {
        type : Array,
        required: true
      }
    }
  };
</script>
