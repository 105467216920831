<template>
  <div class="navigation">
    <div class="navigation__left">
      <div
        class="cursor-pointer"
        @click="toggleSidebar"
      >
        <MenuIcon />
      </div>
      <div class="navigation__left__avatar">
        <router-link :to="{ name: 'failed-transfers' }">
          <img
            alt
            src="../../assets/logo.jpg"
          >
        </router-link>
      </div>
    </div>
    <div class="navigation__right">
      <div
        v-if="adminAccountInfo.partnerOrganizations.length > 1"
        class="navigation__right__contextSelector"
      >
        <v-select
          v-model="selectedPartnerOrganization"
          :clearable="false"
          :options="partnerOrganizations"
          :searchable="false"
          @input="updateAdminContext"
        />
      </div>

      <p class="">
        {{ adminAccountInfo.firstName }} {{ adminAccountInfo.surname }}
      </p>

      <span
        class="cursor-pointer"
        title="Logout"
        @click="logout"
      >
        <LogoutIcon />
      </span>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import MenuIcon from "@/components/icons/MenuIcon";
  import LogoutIcon from "@/components/icons/LogoutIcon";
  import { callPutApi } from "@/httpClient";

  export default {
    name: "Navigation",
    components: {
      LogoutIcon,
      MenuIcon,
      vSelect
    },
    data() {
      return {
        drawerStatus: false,
        selectedPartnerOrganization: "",
        partnerOrganizations: []
      };
    },
    computed: {
      ...mapGetters(["adminAccountInfo"])
    },
    mounted() {
      this.selectedPartnerOrganization = this.adminAccountInfo.currentPartnerOrganizationContext;
      this.getPartnerOrganizationAppIds();
      const showSidebar = window.innerWidth < 700;
      this.hideSidebar(showSidebar);
    },
    methods: {
      toggleSidebar() {
        this.hideSidebar(!this.drawerStatus);
      },
      hideSidebar(hide) {
        this.drawerStatus = hide;
        this.$store.commit("setDrawerStatus", this.drawerStatus);
      },
      logout() {
        this.$router.push({ name: "login" });

        window.localStorage.setItem("appToken", "");
        window.localStorage.setItem("adminInfo", "");
        window.localStorage.setItem("loginTime", "");
      },
      updateAdminContext() {
        const partnerOrganization = this.selectedPartnerOrganization;
        callPutApi(`partner-org-context/${partnerOrganization}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$router.go();
              this.$store.dispatch("updateAdminContext", partnerOrganization);
            } else {
              this.$notify({
                group: "general",
                title: "Login",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      getPartnerOrganizationAppIds() {
        this.partnerOrganizations = this.adminAccountInfo.partnerOrganizations.map(partner => partner.appId);
      }
    }
  };
</script>

<style scoped src="./navigation.css"></style>
