<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Agents">
      <router-link
        v-if="isAnyOperationPermitted(['registerMerchant'])"
        :to="{ name: 'new-merchant' }"
        class="dropdown__item"
        tag="div"
      >
        <button
          class="btn btn--primary btn--flex"
          data-target="#newAgent"
          data-toggle="modal"
        >
          <span class="btn__icon">
            <svg
              height="14"
              width="14"
            >
              <use xlink:href="../../assets/icons/addIcon.svg#add_icon" />
            </svg>
          </span>
          <span class="btn__text">Register Merchant</span>
        </button>
      </router-link>
    </title-bar>
    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <!-- Table Data -->
              <div class="table table--padded">
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      Agent Name
                    </div>
                    <div class="table__header">
                      Email Address
                    </div>
                    <div class="table__header">
                      Phone Number
                    </div>
                    <div class="table__header">
                      BVN
                    </div>
                    <div class="table__header">
                      Verification Status
                    </div>
                    <div class="table__header" />
                  </div>
                </div>
                <div class="table__section table__section--body">
                  <template v-for="(item, i) in agents.merchants">
                    <div
                      :key="i"
                      class="table__row"
                    >
                      <div class="table__data table__data--full">
                        <p class="table__data--main">
                          {{ item.businessName }}
                        </p>
                      </div>
                      <div class="table__data">
                        <p class="table__data--main">
                          {{ item.email }}
                        </p>
                      </div>
                      <div class="table__data">
                        <span class="link">{{ item.phone }}</span>
                      </div>
                      <div class="table__data">
                        {{ item.bvn ? item.bvn : "--" }}
                      </div>
                      <div class="table__data">
                        <span
                          v-if="item.verificationStatus === 'VERIFIED'"
                          class="tag tag--green"
                        >Verified</span>
                        <span
                          v-if="item.verificationStatus === 'IN_PROGRESS'"
                          class="tag tag--yellow"
                        >Authorized</span>
                        <span
                          v-if="item.verificationStatus === 'PENDING'"
                          class="tag tag--yellow"
                        >Pending</span>
                      </div>
                      <div class="table__data">
                        <button
                          class="btn btn--primary btn--sm"
                          @click="navigateToAgentIndividual(item)"
                        >
                          View
                        </button>
                      </div>
                    </div>
                    <div
                      v-if="opened.includes(item.id)"
                      :key="item.id"
                      class="collapsible-table"
                    >
                      <p>Name</p>
                    </div>
                  </template>
                </div>
              </div>

              <pagination
                data="agents"
                :page-number="computedPageNumbers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "../../components/Search";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  import { callGetApi } from "@/httpClient";

  export default {
    components: {
      TitleBar,
      Pagination,
      Search
    },
    mixins: [AdminPermissionsHelper],
    data() {
      return {
        agents: "",
        searchPlaceholder: "Search agent by business name, phone number, email, bvn",
        opened: [],
        loading: true,
        pageNumber: ""
      };
    },
    computed: {
      randomKey() {
        return Date.now();
      },
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("agentsPage")) {
          return JSON.parse(window.localStorage.getItem("agentsPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      $route: "fetchAgents"
    },
    created() {
      this.fetchAgents();
    },
    methods: {
      clearSearch() {
        this.$router.push({ name: "agents", query: { currentPage: 1 } });
      },
      navigateToAgentIndividual(value) {
        window.localStorage.setItem("individualAgent", JSON.stringify(value));
        this.$store.commit("setIndividualAgent", value);
        this.$router.push({ name: "agents-individual", params: { id: value.id } });
      },
      fetchAgents() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi(`merchant?batchSize=20&${query_params}`)
          .then(res => {
            this.loading = false;
            this.agents = res.body.entity;
            this.pageNumber = Math.ceil(
              res.body.pageMetaData.totalNoOfRows /
                res.body.pageMetaData.batchSize
            );
            window.localStorage.setItem(
              "agentsPage",
              Math.ceil(
                res.body.pageMetaData.totalNoOfRows /
                  res.body.pageMetaData.batchSize
              )
            );
          })
          .catch(console.error);
      },
      searchQuery(criteria) {
        this.$router.push({ name: "agents", query: { criteria } });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      }
    }
  };
</script>
