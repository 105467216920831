import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { BankList } from "@/assets/BankList";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
  state: {
    drawerOpen: false,
    individualAgent: "",
    adminAccountInfo: {},
    bankList: BankList,
    providerBalance: {},
    statistics: {}
  },
  mutations: {
    setDrawerStatus(state, payload) {
      state.drawerOpen = payload;
    },
    setIndividualAgent(state, payload) {
      state.individualAgent = payload;
    },
    adminAccountInfo(state, payload) {
      state.adminAccountInfo = payload;
    },
    selectedContext(state, payload) {
      state.adminAccountInfo.currentPartnerOrganizationContext = payload;
    },
    setProviderBalance(state, payload) {
      const context = payload.context;
      if (!state.providerBalance[context]) {
        Vue.set(state.providerBalance, context, {});
      }
      Vue.set(state.providerBalance[context], payload.provider, payload.balance);
    },
    setStatistics(state, payload) {
      const context = payload.context;
      if (!state.statistics[context]) {
        Vue.set(state.statistics, context, {});
      }
      Vue.set(state.statistics[context], payload.type, payload.stats);
    }
  },
  actions: {
    setAdminAccountInfo({ commit }, payload) {
      commit("adminAccountInfo", payload);
    },
    updateAdminContext({ commit }, payload) {
      commit("selectedContext", payload);
    },
    setProviderBalance({ commit }, payload) {
      commit("setProviderBalance", payload);
    },
    setStatistics({ commit }, payload) {
      commit("setStatistics", payload);
    }
  },
  getters: {
    getDrawerStatus: state => state.drawerOpen,
    getIndividualAgent: state => state.individualAgent,
    getBanksList: state => state.bankList,
    adminAccountInfo: state => state.adminAccountInfo,
    currentContext: state => state.adminAccountInfo.currentPartnerOrganizationContext,
    providerBalance: (state, getters) => state.providerBalance[getters.currentContext],
    getStatistics: (state, getters) => state.statistics[getters.currentContext],
  },
  plugins: [vuexLocal.plugin]
});
