<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Messaging">
      <div
        v-click-outside="hideDropdown"
        class="dashboard__main__content__row__item posRelative"
      >
        <button
          id="exampleColorDropdown1"
          class="btn btn--primary btn--flex"
          @click="showDropdown"
        >
          <span class="btn__icon">
            <svg
              height="14"
              width="14"
            >
              <use xlink:href="../../assets/icons/addIcon.svg#add_icon" />
            </svg>
          </span>
          <span class="btn__text">Compose</span>
        </button>

        <div
          :class="{ 'active' : activeDropdown }"
          class="dropdown refundsDropdown"
        >
          <div
            class="dropdown__item"
            data-toggle="modal"
          >
            <div
              class="dropdown__item__link flex flex--default"
              @click="showComposeMessage('Email')"
            >
              <span>Email</span>
            </div>
          </div>
          <div class="dropdown__item">
            <div
              class="dropdown__item__link flex flex--default"
              @click="showComposeMessage('Push Notification')"
            >
              <span>Push Notification</span>
            </div>
          </div>
        </div>
      </div>
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__header" />
          <div class="transaction__filters" />
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <div class="empty__state">
                <template>
                  <div>Your sent items will be displayed here (coming soon)</div>
                </template>
              </div>
            </div>

            <div
              id="composeMessage"
              aria-labelledby="editAgentLabel"
              class="modal fade"
              role="dialog"
              tabindex="-1"
            >
              <div
                class="modal__dialog modal__dialog--md composeMessage_modal"
                role="document"
              >
                <div class="modal__content">
                  <div class="modal__header">
                    <div class="modal__title text-center">
                      Compose {{ selectedObject }}
                    </div>
                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                    >
                      <svg
                        height="13"
                        viewBox="0 0 13 13"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                          fill="#C0C6D8"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </button>
                  </div>

                  <div class="modal__body modal__body--pad">
                    <div class="editor">
                      <form
                        class="login__section__form"
                        @submit.prevent=""
                      >
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="title"
                          >Subject</label>
                          <input
                            id="title"
                            v-model="title"
                            autofocus
                            class="form__input fontNormal"
                            type="text"
                          >
                        </div>

                        <div
                          v-if="selectedObject==='Push Notification'"
                          class="form__item"
                        >
                          <label class="form__label form__label--2 m-b-30">Topic</label>
                          <div class="select select--sm">
                            <select
                              v-model="selectedTopic"
                              class="select__input select__input--noborder"
                            >
                              <option
                                disabled
                                selected
                                value=""
                              >
                                Select Topic
                              </option>
                              <option
                                v-for="topic in topics"
                                :key="topic"
                              >
                                {{ topic }}
                              </option>
                            </select>

                            <div class="select__icon">
                              <svg
                                height="10"
                                viewBox="0 0 6 10"
                                width="6"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.03 3.03L3.025.025.02 3.03h6.01zM5.88 7.071L2.975 9.975.07 7.07H5.88z"
                                  fill="#637381"
                                  fill-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div class="form__item">
                          <label class="form__label form__label--2 m-b-30">Message</label>

                          <editor-menu-bar
                            v-slot="{ commands, isActive }"
                            :editor="editor"
                          >
                            <div class="menubar">
                              <button
                                :class="{ 'is-active': isActive.bold() }"
                                class="menubar__button"
                                title="bold"
                                @click="commands.bold"
                              >
                                <svg
                                  height="12pt"
                                  viewBox="-42 0 426 426.66667"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m279.734375 197.566406c24.597656-20.5625 40.597656-51.070312 40.597656-85.566406 0-61.761719-50.238281-112-112-112h-186.664062c-11.777344 0-21.335938 9.535156-21.335938 21.332031s9.558594 21.335938 21.335938 21.335938h21.332031v341.332031h-21.332031c-11.777344 0-21.335938 9.535156-21.335938 21.332031s9.558594 21.335938 21.335938 21.335938h197.332031c67.625 0 122.667969-55.019531 122.667969-122.667969 0-45.546875-25.003907-85.269531-61.933594-106.433594zm-71.402344-154.898437c38.230469 0 69.335938 31.101562 69.335938 69.332031s-31.105469 69.332031-69.335938 69.332031h-122.664062v-138.664062zm10.667969 341.332031h-133.332031v-160h133.332031c44.117188 0 80 35.902344 80 80s-35.882812 80-80 80zm0 0"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.italic() }"
                                class="menubar__button"
                                title="italic"
                                @click="commands.italic"
                              >
                                <svg
                                  height="12pt"
                                  viewBox="-106 0 426 426.70233"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m91.019531 426.703125h-74.667969c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h74.667969c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
                                  />
                                  <path
                                    d="m197.683594 32.035156h-74.664063c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-15.9999998 16-15.9999998h74.664063c8.832031 0 16 7.1679688 16 15.9999998 0 8.832032-7.167969 16-16 16zm0 0"
                                  />
                                  <path
                                    d="m53.683594 426.703125c-1.386719 0-2.792969-.171875-4.179688-.554687-8.535156-2.285157-13.570312-11.09375-11.265625-19.605469l106.667969-394.667969c2.304688-8.554688 11.09375-13.714844 19.625-11.261719 8.535156 2.28125 13.570312 11.089844 11.265625 19.605469l-106.667969 394.664062c-1.917968 7.125-8.382812 11.820313-15.445312 11.820313zm0 0"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.strike() }"
                                class="menubar__button"
                                title="strike"
                                @click="commands.strike"
                              >
                                <svg
                                  id="Capa_1"
                                  enable-background="new 0 0 458.353 458.353"
                                  height="12pt"
                                  viewBox="0 0 458.353 458.353"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m311.353 272.147c19.987 16.411 32.412 41.044 32.412 68.554 0 49.083-39.921 89.004-89.004 89.004h-168.82v28.647h168.819c64.876 0 117.651-52.776 117.651-117.651 0-25.554-8.302-49.137-22.235-68.554z"
                                  />
                                  <path
                                    d="m147 186.206c-19.987-16.411-32.412-41.044-32.412-68.554 0-49.083 39.921-89.004 89.004-89.004h168.819v-28.648h-168.818c-64.876 0-117.652 52.776-117.652 117.651 0 25.554 8.302 49.137 22.235 68.554.001.001 38.824.001 38.824.001z"
                                  />
                                  <path d="m57.294 214.853h343.765v28.647h-343.765z" />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.underline() }"
                                class="menubar__button"
                                title="underline"
                                @click="commands.underline"
                              >
                                <svg
                                  id="Capa_1"
                                  enable-background="new 0 0 458.353 458.353"
                                  height="12pt"
                                  viewBox="0 0 458.353 458.353"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m57.294 429.706h343.765v28.647h-343.765z" />
                                  <path
                                    d="m315.118 0v28.647h28.647v200.529c0 63.197-51.405 114.588-114.588 114.588s-114.588-51.391-114.588-114.588v-200.529h28.647v-28.647h-85.942v28.647h28.647v200.529c0 78.975 64.26 143.235 143.235 143.235s143.235-64.26 143.235-143.235v-200.529h28.647v-28.647z"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.code() }"
                                class="menubar__button"
                                title="code"
                                @click="commands.code"
                              >
                                <svg
                                  height="12pt"
                                  viewBox="0 -43 512.01067 512"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m309.167969.675781c-11.414063-2.941406-23.019531 4.074219-25.855469 15.53125l-96 384c-2.878906 11.414063 4.074219 22.996094 15.53125 25.855469 1.726562.425781 3.476562.640625 5.183594.640625 9.558594 0 18.238281-6.464844 20.671875-16.171875l96-384c2.859375-11.410156-4.097657-22.996094-15.53125-25.855469zm0 0"
                                  />
                                  <path
                                    d="m164.421875 70.289062c-8.34375-8.34375-21.824219-8.34375-30.164063 0l-128 128c-8.34375 8.339844-8.34375 21.824219 0 30.164063l128 128c4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25 8.339844-8.339844 8.339844-21.824219 0-30.164063l-112.917969-112.917968 112.917969-112.917969c8.339844-8.339844 8.339844-21.824219 0-30.164063zm0 0"
                                  />
                                  <path
                                    d="m505.753906 198.289062-128-128c-8.339844-8.34375-21.824218-8.34375-30.164062 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l112.917968 112.917969-112.917968 112.917968c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.160156 4.160156 9.621094 6.25 15.082031 6.25s10.921875-2.089844 15.082031-6.25l128-128c8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.paragraph() }"
                                class="menubar__button"
                                title="paragraph"
                                @click="commands.paragraph"
                              >
                                <svg
                                  id="Layer"
                                  enable-background="new 0 0 64 64"
                                  height="12pt"
                                  viewBox="0 0 64 64"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m50 18c1.104 0 2-.896 2-2v-6c0-1.104-.896-2-2-2h-36c-1.104 0-2 .896-2 2v6c0 1.104.896 2 2 2s2-.896 2-2v-4h10v40h-6c-1.104 0-2 .896-2 2s.896 2 2 2h24c1.104 0 2-.896 2-2s-.896-2-2-2h-6v-40h10v4c0 1.104.896 2 2 2zm-16 34h-4v-40h4z"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                                class="menubar__button"
                                @click="commands.heading({ level: 1 })"
                              >
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  height="12pt"
                                  preserveAspectRatio="xMidYMid meet"
                                  style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                                  viewBox="0 0 24 24"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                  <path
                                    d="M11 7h2v10h-2v-4H7v4H5V7h2v4h4V7zm6.57 0A4.737 4.737 0 0 1 15 9v1h2v7h2V7h-1.43z"
                                    fill="#000"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                                class="menubar__button"
                                @click="commands.heading({ level: 2 })"
                              >
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  height="12pt"
                                  preserveAspectRatio="xMidYMid meet"
                                  style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                                  viewBox="0 0 24 24"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                  <path
                                    d="M9 7h2v10H9v-4H5v4H3V7h2v4h4V7zm8 8c.51-.41.6-.62 1.06-1.05c.437-.4.848-.828 1.23-1.28c.334-.39.62-.82.85-1.28c.2-.39.305-.822.31-1.26a3.01 3.01 0 0 0-.27-1.28a2.902 2.902 0 0 0-.76-1a3.485 3.485 0 0 0-1.17-.63a4.761 4.761 0 0 0-1.5-.22c-.36 0-.717.033-1.07.1c-.343.06-.678.158-1 .29a4.38 4.38 0 0 0-.86.49a8.88 8.88 0 0 0-.82.68l1.24 1.22a5.829 5.829 0 0 1 1-.7c.35-.2.747-.304 1.15-.3c.455-.03.906.106 1.27.38c.31.278.477.684.45 1.1c-.014.396-.14.78-.36 1.11a7.08 7.08 0 0 1-1 1.25c-.44.43-.98.92-1.59 1.43c-.61.51-1.41 1.06-2.16 1.65V17h8v-2h-4z"
                                    fill="#000"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                                class="menubar__button"
                                @click="commands.heading({ level: 3 })"
                              >
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  height="12pt"
                                  preserveAspectRatio="xMidYMid meet"
                                  style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                                  viewBox="0 0 24 24"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                  <path
                                    d="M14.11 14.218c.355.287.75.523 1.17.7c.434.18.9.273 1.37.27c.484.017.965-.086 1.4-.3a.919.919 0 0 0 .55-.84a1.146 1.146 0 0 0-.15-.58c-.123-.19-.3-.34-.51-.43a3.66 3.66 0 0 0-1-.27a9.786 9.786 0 0 0-1.52-.1v-1.57a4.717 4.717 0 0 0 2.17-.36a1.06 1.06 0 0 0 .63-.93a.903.903 0 0 0-.38-.81c-.34-.203-.734-.3-1.13-.28a2.836 2.836 0 0 0-1.14.28a5.6 5.6 0 0 0-1.06.62l-1.22-1.39c.5-.377 1.053-.68 1.64-.9a5.354 5.354 0 0 1 1.9-.33c.525-.007 1.05.05 1.56.17c.43.1.84.277 1.21.52c.325.21.595.495.79.83c.19.342.287.73.28 1.12c.01.48-.177.943-.52 1.28a3.88 3.88 0 0 1-1.46.86v.06a3.88 3.88 0 0 1 1.65.83c.437.382.68.94.66 1.52c.005.42-.113.835-.34 1.19c-.23.357-.538.657-.9.88c-.408.253-.853.44-1.32.55a6.39 6.39 0 0 1-1.57.19a6.395 6.395 0 0 1-2.31-.37a4.29 4.29 0 0 1-1.57-1l1.12-1.41zM9 11H5V7H3v10h2v-4h4v4h2V7H9v4z"
                                    fill="#000"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.link() }"
                                class="menubar__button"
                                @click="showLinkMenu(getMarkAttrs('link'))"
                              >
                                <svg
                                  height="12pt"
                                  viewBox="0 0 444 444.76925"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m140.257812 210.304688c-19.09375-.074219-37.425781 7.492187-50.90625 21.015624l-67.917968 67.921876c-28.066406 28.132812-28.066406 73.671874 0 101.804687l22.636718 22.640625c28.117188 28.109375 73.695313 28.109375 101.808594 0l67.921875-67.917969c23.464844-23.882812 27.585938-60.703125 9.976563-89.175781l-23.769532 23.789062c5.832032 14.765626 2.335938 31.578126-8.894531 42.792969l-67.839843 67.914063c-15.636719 15.609375-40.957032 15.609375-56.59375 0l-22.640626-22.640625c-15.605468-15.636719-15.605468-40.957031 0-56.59375l67.921876-67.839844c11.207031-11.234375 28.015624-14.734375 42.773437-8.902344l23.785156-23.785156c-11.445312-7.238281-24.71875-11.0625-38.261719-11.023437zm0 0"
                                  />
                                  <path
                                    d="m294.902344 161.535156-11.316406-11.320312c-3.125-3.121094-8.191407-3.121094-11.3125 0l-124.449219 124.457031c-3.125 3.125-3.125 8.1875 0 11.3125l11.3125 11.3125c3.167969 3.015625 8.144531 3.015625 11.3125 0l124.453125-124.449219c3.125-3.121094 3.125-8.1875 0-11.3125zm0 0"
                                  />
                                  <path
                                    d="m356.121094 213.449219 67.917968-67.921875c28.066407-28.132813 28.066407-73.671875 0-101.808594l-22.640624-22.636719c-28.113282-28.109375-73.691407-28.109375-101.804688 0l-67.921875 67.917969c-23.464844 23.882812-27.585937 60.699219-9.976563 89.175781l23.769532-23.792969c-5.832032-14.761718-2.335938-31.574218 8.894531-42.789062l67.839844-67.914062c15.636719-15.609376 40.957031-15.609376 56.59375 0l22.640625 22.640624c15.605468 15.636719 15.605468 40.957032 0 56.59375l-67.921875 67.839844c-11.191407 11.265625-28.027344 14.757813-42.777344 8.878906l-23.796875 23.800782c28.425781 17.835937 65.40625 13.695312 89.183594-9.992188zm0 0"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.bullet_list() }"
                                class="menubar__button"
                                @click="commands.bullet_list"
                              >
                                <svg
                                  id="Layer"
                                  enable-background="new 0 0 64 64"
                                  height="12pt"
                                  viewBox="0 0 64 64"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m50.5 9h-21c-3.032 0-5.5 2.467-5.5 5.5s2.468 5.5 5.5 5.5h21c3.032 0 5.5-2.467 5.5-5.5s-2.468-5.5-5.5-5.5zm0 7h-21c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5h21c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5z"
                                  />
                                  <path
                                    d="m50.5 26h-21c-3.032 0-5.5 2.467-5.5 5.5 0 3.032 2.468 5.5 5.5 5.5h21c3.032 0 5.5-2.468 5.5-5.5 0-3.033-2.468-5.5-5.5-5.5zm0 7h-21c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5h21c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5z"
                                  />
                                  <path
                                    d="m50.5 43h-21c-3.032 0-5.5 2.468-5.5 5.5s2.468 5.5 5.5 5.5h21c3.032 0 5.5-2.468 5.5-5.5s-2.468-5.5-5.5-5.5zm0 7h-21c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5h21c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5z"
                                  />
                                  <path
                                    d="m10 21h8c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2h-8c-1.104 0-2 .896-2 2v8c0 1.104.896 2 2 2zm2-8h4v4h-4z"
                                  />
                                  <path
                                    d="m10 38h8c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2h-8c-1.104 0-2 .896-2 2v8c0 1.104.896 2 2 2zm2-8h4v4h-4z"
                                  />
                                  <path
                                    d="m10 55h8c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2h-8c-1.104 0-2 .896-2 2v8c0 1.104.896 2 2 2zm2-8h4v4h-4z"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.ordered_list() }"
                                class="menubar__button"
                                @click="commands.ordered_list"
                              >
                                <svg
                                  id="Layer"
                                  enable-background="new 0 0 64 64"
                                  height="12pt"
                                  viewBox="0 0 64 64"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m11 56h7c1.104 0 2-.896 2-2s-.896-2-2-2h-6v-4h5c1.654 0 3-1.346 3-3v-6c0-1.654-1.346-3-3-3h-7c-1.104 0-2 .896-2 2s.896 2 2 2h6v4h-5c-1.654 0-3 1.346-3 3v6c0 1.654 1.346 3 3 3z"
                                  />
                                  <path
                                    d="m10 28h4 4c1.104 0 2-.896 2-2s-.896-2-2-2h-2v-14c0-1.104-.896-2-2-2h-3c-1.104 0-2 .896-2 2s.896 2 2 2h1v12h-2c-1.104 0-2 .896-2 2s.896 2 2 2z"
                                  />
                                  <path
                                    d="m29.5 22h21c3.032 0 5.5-2.467 5.5-5.5s-2.468-5.5-5.5-5.5h-21c-3.032 0-5.5 2.467-5.5 5.5s2.468 5.5 5.5 5.5zm0-7h21c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5h-21c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5z"
                                  />
                                  <path
                                    d="m50.5 39h-21c-3.032 0-5.5 2.467-5.5 5.5s2.468 5.5 5.5 5.5h21c3.032 0 5.5-2.467 5.5-5.5s-2.468-5.5-5.5-5.5zm0 7h-21c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5h21c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5z"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.blockquote() }"
                                class="menubar__button"
                                @click="commands.blockquote"
                              >
                                <svg
                                  id="Capa_1"
                                  enable-background="new 0 0 409.294 409.294"
                                  height="12pt"
                                  viewBox="0 0 409.294 409.294"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m233.882 29.235v175.412h116.941c0 64.48-52.461 116.941-116.941 116.941v58.471c96.728 0 175.412-78.684 175.412-175.412v-175.412z"
                                  />
                                  <path
                                    d="m0 204.647h116.941c0 64.48-52.461 116.941-116.941 116.941v58.471c96.728 0 175.412-78.684 175.412-175.412v-175.412h-175.412z"
                                  />
                                </svg>
                              </button>

                              <button
                                :class="{ 'is-active': isActive.code_block() }"
                                class="menubar__button"
                                @click="commands.code_block"
                              >
                                <svg
                                  height="12pt"
                                  viewBox="0 -43 512.01067 512"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m309.167969.675781c-11.414063-2.941406-23.019531 4.074219-25.855469 15.53125l-96 384c-2.878906 11.414063 4.074219 22.996094 15.53125 25.855469 1.726562.425781 3.476562.640625 5.183594.640625 9.558594 0 18.238281-6.464844 20.671875-16.171875l96-384c2.859375-11.410156-4.097657-22.996094-15.53125-25.855469zm0 0"
                                  />
                                  <path
                                    d="m164.421875 70.289062c-8.34375-8.34375-21.824219-8.34375-30.164063 0l-128 128c-8.34375 8.339844-8.34375 21.824219 0 30.164063l128 128c4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25 8.339844-8.339844 8.339844-21.824219 0-30.164063l-112.917969-112.917968 112.917969-112.917969c8.339844-8.339844 8.339844-21.824219 0-30.164063zm0 0"
                                  />
                                  <path
                                    d="m505.753906 198.289062-128-128c-8.339844-8.34375-21.824218-8.34375-30.164062 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l112.917968 112.917969-112.917968 112.917968c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.160156 4.160156 9.621094 6.25 15.082031 6.25s10.921875-2.089844 15.082031-6.25l128-128c8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
                                  />
                                </svg>
                              </button>

                              <button
                                class="menubar__button"
                                @click="commands.horizontal_rule"
                              >
                                <svg
                                  id="Capa_1"
                                  height="12pt"
                                  style="enable-background:new 0 0 512 512;"
                                  version="1.1"
                                  viewBox="0 0 512 512"
                                  width="12pt"
                                  x="0px"
                                  xml:space="preserve"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  y="0px"
                                ><g><g>
                                  <path
                                    class="active-path"
                                    d="M500.364,267.636H11.636C5.21,267.636,0,262.427,0,256c0-6.427,5.21-11.636,11.636-11.636h488.727   c6.427,0,11.636,5.21,11.636,11.636C512,262.427,506.79,267.636,500.364,267.636z"
                                    data-old_color="#428DFF"
                                    data-original="#428DFF"
                                    fill="#080808"
                                  />
                                </g>
                                </g>
                                </svg>
                              </button>

                              <button
                                class="menubar__button"
                                title="undo"
                                @click="commands.undo"
                              >
                                <svg
                                  id="Layer"
                                  enable-background="new 0 0 64 64"
                                  height="12pt"
                                  viewBox="0 0 64 64"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m10 36h8.485c1.104 0 2-.896 2-2s-.896-2-2-2h-3.657l16.444-16.444c4.733-4.733 12.435-4.733 17.172.003 4.733 4.733 4.733 12.436 0 17.169l-19.858 19.858c-.781.781-.781 2.047 0 2.828.39.391.902.586 1.414.586s1.024-.195 1.414-.586l19.858-19.858c6.293-6.293 6.293-16.532-.003-22.828-6.293-6.293-16.532-6.293-22.825 0l-16.444 16.444v-3.657c0-1.104-.896-2-2-2s-2 .896-2 2v8.485c0 1.104.896 2 2 2z"
                                  />
                                </svg>
                              </button>

                              <button
                                class="menubar__button"
                                title="redo"
                                @click="commands.redo"
                              >
                                <svg
                                  id="Layer"
                                  enable-background="new 0 0 64 64"
                                  height="12pt"
                                  viewBox="0 0 64 64"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g>
                                    <path
                                      class="active-path"
                                      d="m54 23.515c-1.104 0-2 .896-2 2v3.657l-16.444-16.444c-6.292-6.292-16.531-6.293-22.828.003-6.293 6.293-6.293 16.532 0 22.825l19.858 19.858c.39.391.902.586 1.414.586s1.024-.195 1.414-.586c.781-.781.781-2.047 0-2.828l-19.858-19.858c-4.733-4.733-4.733-12.436.003-17.172 4.733-4.733 12.436-4.733 17.169 0l16.444 16.444h-3.657c-1.104 0-2 .896-2 2s.896 2 2 2h8.485c1.104 0 2-.896 2-2v-8.485c0-1.105-.896-2-2-2z"
                                      data-original="#000000"
                                      fill="#000000"
                                    />
                                  </g>
                                </svg>
                              </button>

                              <button
                                class="menubar__button"
                                title="table"
                                @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
                              >
                                <svg
                                  height="12pt"
                                  viewBox="-19 -19 600 600"
                                  width="12pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m251.25 12.5c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 213.75h-201.25v-201.25h201.25zm0 0"
                                  />
                                  <path
                                    d="m562.5 12.5c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 213.75h-201.25v-201.25h201.25zm0 0"
                                  />
                                  <path
                                    d="m251.25 323.75c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 212.5h-201.25v-200h201.25zm0 0"
                                  />
                                  <path
                                    d="m562.5 323.75c0-6.90625-5.59375-12.5-12.5-12.5h-226.25c-6.90625 0-12.5 5.59375-12.5 12.5v226.25c0 6.90625 5.59375 12.5 12.5 12.5h226.25c6.90625 0 12.5-5.59375 12.5-12.5zm-25 212.5h-201.25v-200h201.25zm0 0"
                                  />
                                </svg>
                              </button>

                              <span v-if="isActive.table()">

                                <button
                                  class="menubar__button"
                                  @click="commands.deleteTable"
                                >
                                  <svg
                                    height="12pt"
                                    viewBox="-48 0 407 407"
                                    width="12pt"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m89.199219 37c0-12.132812 9.46875-21 21.601562-21h88.800781c12.128907 0 21.597657 8.867188 21.597657 21v23h16v-23c0-20.953125-16.644531-37-37.597657-37h-88.800781c-20.953125 0-37.601562 16.046875-37.601562 37v23h16zm0 0"
                                    /><path
                                      d="m60.601562 407h189.199219c18.242188 0 32.398438-16.046875 32.398438-36v-247h-254v247c0 19.953125 14.15625 36 32.402343 36zm145.597657-244.800781c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm0 0"
                                    /><path
                                      d="m20 108h270.398438c11.046874 0 20-8.953125 20-20s-8.953126-20-20-20h-270.398438c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20zm0 0"
                                    />
                                  </svg>
                                </button>

                                <button
                                  class="menubar__button"
                                  @click="commands.addColumnBefore"
                                >
                                  add col before
                                </button>

                                <button
                                  class="menubar__button"
                                  @click="commands.addColumnAfter"
                                >
                                  add col after
                                </button>

                                <button
                                  class="menubar__button"
                                  @click="commands.deleteColumn"
                                >
                                  delete col
                                </button>

                                <button
                                  class="menubar__button"
                                  @click="commands.addRowBefore"
                                >
                                  add row before
                                </button>

                                <button
                                  class="menubar__button"
                                  @click="commands.addRowAfter"
                                >
                                  add row after
                                </button>

                                <button
                                  class="menubar__button"
                                  @click="commands.deleteRow"
                                >
                                  delete row
                                </button>

                                <button
                                  class="menubar__button"
                                  @click="commands.toggleCellMerge"
                                >
                                  merge cells
                                </button>
                              </span>
                            </div>
                          </editor-menu-bar>

                          <editor-content
                            :editor="editor"
                            class="editor__content"
                          />
                        </div>

                        <div class="form__item form__item--more">
                          <button
                            class="btn btn--primary"
                            @click="sendNotifications"
                          >
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import { Editor, EditorContent, EditorMenuBar } from "tiptap";
  import {
    Blockquote,
    Bold,
    BulletList,
    Code,
    CodeBlock,
    HardBreak,
    Heading,
    History,
    HorizontalRule,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Strike,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    TodoItem,
    TodoList,
    Underline
  } from "tiptap-extensions";
  import { callPostApi } from "@/httpClient";

  export default {
    name: "Messaging",
    components: {
      TitleBar,
      EditorContent,
      EditorMenuBar
    },
    data: function() {
      return {
        linkUrl: null,
        linkMenuIsActive: false,
        selectedTopic: "",
        title: "",
        outputHtml: "",
        topics: [],
        editor: new Editor({
          extensions: [
            new Blockquote(),
            new BulletList(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new HorizontalRule(),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Link(),
            new Bold(),
            new Code(),
            new Italic(),
            new Strike(),
            new Underline(),
            new History(),
            new Table({
              resizable: true
            }),
            new TableHeader(),
            new TableCell(),
            new TableRow()
          ],
          onUpdate: ({ getHTML }) => this.outputHtml = getHTML()
        }),
        activeDropdown: false,
        loading: false,
        selectedObject: ""
      };
    },
    created() {
      this.getBroadcastTopics();
    },
    beforeDestroy() {
      this.editor.destroy();
    },
    methods: {
      clearFields() {
        this.selectedTopic = "";
        this.title = "";
        this.outputHtml = "";
        this.editor.clearContent();
      },
      hideDropdown() {
        this.activeDropdown = false;
      },
      showDropdown() {
        this.activeDropdown = !this.activeDropdown;
      },
      showLinkMenu(attrs) {
        this.linkUrl = attrs.href;
        this.linkMenuIsActive = true;
        this.$nextTick(() => {
          this.$refs.linkInput.focus();
        });
      },
      hideLinkMenu() {
        this.linkUrl = null;
        this.linkMenuIsActive = false;
      },
      setLinkUrl(command, url) {
        command({ href: url });
        this.hideLinkMenu();
      },
      sendNotifications() {
        if (this.selectedObject === "Push Notification") {
          const broadcastMessage = {
            title: this.title,
            message: this.outputHtml.replace(/(?<=^.{3})/, "<br>"),
            topic: this.selectedTopic
          };
          this.broadcastPushNotification(broadcastMessage);
        }
        if (this.selectedObject === "Email") {
          const emailParams = {
            subject: this.title,
            message: this.outputHtml,
            placeHolder: "",
            placeHolderValue: ""
          };
          this.sendBulkEmail(emailParams);
        }
        this.clearFields();
      },
      sendBulkEmail(emailParam) {
        callPostApi("bulk-email", { body: emailParam })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Bulk Email",
                text: "Bulk Email Sent Successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Bulk Email",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      broadcastPushNotification(broadcastMessage) {
        callPostApi("broadcast-message", { body: broadcastMessage })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Push Notification",
                text: "Push Notifications Sent Successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Push Notification",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      getBroadcastTopics() {
        this.topics = this.$store.getters.adminAccountInfo.partnerOrganizations
          .map(partner => partner.appId);
      },
      showComposeMessage(item) {
        this.selectedObject = item;
        $("#composeMessage").modal();
      }
    }
  };
</script>

<style scoped>
  label {
    font-size: 14px;
  }

  .editor {
    width: 75%;
    margin: 0 auto;
  }

  .editor__content {
    border: 1px solid #afafaf;
    padding: 10px;
    min-height: 300px;
  }

  .select__input {
    border: 1px solid #afafaf;
  }
</style>
