<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Add New Merchant">
      <div class="dashboard__main__content__row__item">
        <a
          href="javascript:history.go(-1)"
          class="dashboard__main__content__back"
        >
          <svg
            viewBox="0 0 100 125"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m81 50a4 4 0 0 0 -4-4h-43.87l16.26-15.16a4 4 0 1 0 -5.46-5.84l-23.69 22.07a4 4 0 0 0 0 5.85l23.69 22.08a4 4 0 0 0 5.46-5.85l-16.26-15.15h43.87a4 4 0 0 0 4-4z"
            />
          </svg>
          <span>Back to Merchants</span>
        </a>
      </div>
    </title-bar>

    <div class="dashboard__main__content__home">
      <div
        class="dashboard__main__content__home__left dashboard__main__content__home__left__finance-report"
      >
        <div class="balance">
          <div class="balance__value">
            <form
              class="form"
              style="width: 100%;"
              @submit.prevent="registerAgent"
            >
              <div class="form__pad">
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="firstName"
                  >Primary Contact First Name <span class="red">*</span></label>
                  <input
                    id="firstName"
                    v-model="newAgent.firstName"
                    class="form__input fontNormal"
                    type="text"
                    required
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="surname"
                  >Primary Contact Last Name <span class="red">*</span></label>
                  <input
                    id="surname"
                    v-model="newAgent.surname"
                    class="form__input fontNormal"
                    type="text"
                    required
                  >
                </div>
                <div class="form__item">
                  <label class="form__label--2">Gender <span class="red">*</span></label>
                  <v-select
                    v-model="newAgent.gender"
                    :options="genderList"
                    class="m-t-20"
                    height="34px"
                    label="gender"
                    placeholder="Select Gender"
                  />
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="email"
                  >Email <span class="red">*</span></label>
                  <input
                    id="email"
                    v-model="newAgent.email"
                    class="form__input fontNormal"
                    type="email"
                    required
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="phone"
                  >Phone <span class="red">*</span></label>
                  <input
                    id="phone"
                    v-model="newAgent.phone"
                    class="form__input fontNormal"
                    type="text"
                    required
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="bvn"
                  >BVN</label>
                  <input
                    id="bvn"
                    v-model="newAgent.bvn"
                    class="form__input fontNormal"
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="dateOfBirth"
                  >Date of Birth <span class="red">*</span></label>
                  <date-picker
                    v-model.trim="newAgent.dateOfBirth"
                    :lang="lang"
                    :placeholder="newAgent.dateOfBirth"
                    :disabled-date="disallowDaysAfterToday"
                  />
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="businessName"
                  >Business Name <span class="red">*</span></label>
                  <input
                    id="businessName"
                    v-model="newAgent.businessName"
                    class="form__input fontNormal"
                    type="text"
                    required
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="businessAddress"
                  >Business Address <span class="red">*</span></label>
                  <input
                    id="businessAddress"
                    v-model="newAgent.businessAddress"
                    class="form__input fontNormal"
                    type="text"
                    required
                  >
                </div>
                <div class="form__item">
                  <label class="form__label--2">Country <span class="red">*</span></label>
                  <v-select
                    v-model="newAgent.country"
                    :options="countries"
                    class="m-t-20"
                    height="34px"
                    label="name"
                    placeholder="Select Country"
                    @input="handleCountryChange"
                  />
                </div>
                <div class="form__item">
                  <label class="form__label--2">State/Province/Region <span class="red">*</span></label>
                  <v-select
                    v-model="newAgent.state"
                    :options="states"
                    class="m-t-20"
                    height="34px"
                    label="name"
                    placeholder="Select State/Province/Region"
                    @input="loadLgas"
                  />
                </div>
                <div
                  v-if="showLga"
                  class="form__item"
                >
                  <label class="form__label--2">LGA <span class="red">*</span></label>
                  <v-select
                    v-model="newAgent.lga"
                    :options="lgas"
                    class="m-t-20"
                    height="34px"
                    label="name"
                    placeholder="Select LGA"
                  />
                </div>
                <div
                  v-if="showPostalCode"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for="postalCode"
                  >Postal Code <span class="red">*</span></label>
                  <input
                    id="postalCode"
                    v-model="postalCode"
                    class="form__input fontNormal"
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="city"
                  >City <span class="red">*</span></label>
                  <input
                    id="city"
                    v-model="newAgent.city"
                    class="form__input fontNormal"
                    type="text"
                    required
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="apiEmail"
                  >API Email</label>
                  <input
                    id="apiEmail"
                    v-model="newAgent.apiEmail"
                    class="form__input fontNormal"
                    type="email"
                  >
                </div>
              </div>

              <div class="form__footer m-t-30">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="dashboard__main__content__home__right" />
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import "vue-select/dist/vue-select.css";
  import "vue2-datepicker/index.css";
  import { callExternalApi, callGetApi, callPostApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  import vSelect from "vue-select";
  import { mapGetters } from "vuex";
  import DatePicker from "vue2-datepicker";
  import { disallowDaysAfterToday } from "@/util/dates";
  import { Constants } from "@/assets/constants";
  import moment from "moment/moment";

  export default {
    components: {
      vSelect,
      TitleBar,
      DatePicker
    },
    mixins: [AdminPermissionsHelper],
    data() {
      return {
        agents: "",
        opened: [],
        loading: true,
        pageNumber: "",
        postalCode: "",
        newAgent: {},
        NIGERIA: "Nigeria",
        countries: [],
        states: [],
        lgas: [],
        genderList: ["Male", "Female"],
        Constants,
        showLga: false,
        showPostalCode: true,
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          pickers: ["next 7 days", "next 30 days", "previous 7 days", "previous 30 days"],
          placeholder: {
            date: "Select Date",
            dateRange: "Select Date Range"
          }
        },
        shortcuts: [
          {
            text: "Today",
            onClick: () => {
              this.time3 = [new Date(), new Date()];
            }
          }
        ],
        timePickerOptions: {
          start: "00:00",
          step: "00:30",
          end: "23:30"
        }
      };
    },
    computed: {
      randomKey() {
        return Date.now();
      },
      ...mapGetters(["adminAccountInfo"])
    },
    created() {
      this.fetchCountries();
    },
    methods: {
      disallowDaysAfterToday,
      onCountryChange(country) {
        if (country.name === this.NIGERIA) {
          this.showLga = true;
          this.showPostalCode = false;
        } else {
          this.showLga = false;
          this.showPostalCode = true;
        }
      },
      handleCountryChange(country) {
        this.loadStates();
        this.onCountryChange(country);
      },
      registerAgent() {
        let newAgent = this.newAgent;
        newAgent.partnerOrganizationId = this.adminAccountInfo.currentPartnerOrganizationContext;
        newAgent.state = this.newAgent.state?.name;
        newAgent.lga = this.showLga ? this.newAgent.lga?.name : this.postalCode;
        newAgent.country = this.newAgent.country?.name;

        if (!newAgent.gender || !newAgent.country || !newAgent.state || !newAgent.lga || !newAgent.dateOfBirth) {
          this.$notify({
            group: "general",
            title: "Validation Error",
            type: "warn",
            text: "Please fill all required fields (gender, country, state/province/region, LGA/postal code, date of birth)."
          });
          return;
        }

        newAgent.dateOfBirth = moment(newAgent.dateOfBirth).format("YYYY-MM-DD");

        callPostApi("merchant", { body: newAgent })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Register Merchant",
                text: `${newAgent.firstName} ${newAgent.surname} registered successfully`
              });
              this.$router.go(-1);
            } else {
              this.$notify({
                group: "general",
                title: "Register Merchant",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(err => {
            console.error(err);
            this.$notify({
              group: "general",
              title: "Server Error",
              type: "error",
              text: err
            });
          });
      },
      fetchNigerianStates() {
        callGetApi("countries-states" )
          .then(res => {
            if (res.body.status.code === 0) {
              const entity = res.body?.entity;
              this.states = entity?.states;
            }else{
              this.$notify({
                group: "general",
                title: "Get countries and states",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      fetchCountries() {
        callExternalApi("https://countriesnow.space/api/v0.1/countries/positions" )
          .then(res => {
            if (res.body.error === false) {
              this.countries = res.body?.data;
            }else{
              this.$notify({
                group: "general",
                title: "Get countries",
                type: "warn",
                text: res.body.msg
              });
            }
          })
          .catch(console.error);
      },
      loadStates() {
        const selectedCountry = this.countries.find(country => country.name === this.newAgent.country?.name)?.name;

        if (!selectedCountry) return;

        if(selectedCountry === this.NIGERIA) {
          return this.fetchNigerianStates();
        }

        callExternalApi("https://countriesnow.space/api/v0.1/countries/states" , "POST", { country: selectedCountry })
          .then(res => {
            if (res.body.error === false) {
              this.states = res.body?.data?.states;
            }else{
              this.$notify({
                group: "general",
                title: `Get states for ${selectedCountry}`,
                type: "warn",
                text: res.body.msg
              });
            }
          })
          .catch(console.error);
      },
      loadNigerianStateLgas() {
        const selectedState = this.states.find(state => state.name === this.newAgent.state?.name);
        this.lgas = selectedState ? selectedState.lgas : [];
      },
      loadLgas() {
        const selectedCountry = this.newAgent.country?.name;
        if (!selectedCountry) return;

        const selectedState = this.states.find(state => state.name === this.newAgent.state?.name)?.name;
        if (!selectedState) return;

        if (selectedCountry === this.NIGERIA) {
          return this.loadNigerianStateLgas();
        }

        const body = { country: selectedCountry, state: selectedState };

        callExternalApi("https://countriesnow.space/api/v0.1/countries/state/cities", "POST", body)
          .then(res => {
            if (!res.body.error) {
              this.lgas = res.body?.data;
            } else {
              this.$notify({
                group: "general",
                title: `Get cities/LGAs for ${selectedState}`,
                type: "warn",
                text: res.body.msg
              });
            }
          })
          .catch(console.error);
      }
    }
  };
</script>
