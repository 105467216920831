import Store from "@/store";

export default {
  data() {
    return {
      allowedOperations: Store.getters.adminAccountInfo.allowedOperations ? Store.getters.adminAccountInfo.allowedOperations : []
    };
  },
  methods: {
    isAnyOperationPermitted(requiredAllowedOperations) {
      return this.allowedOperations.some(operation => requiredAllowedOperations.includes(operation));
    }
  }
};
