<template>
  <div class="dashboard__main__content__container" />
</template>

<script>
  import { callGetApi } from "@/httpClient";
  import { endOfDay, startOfDay } from "@/api/dates";
  import moment from "moment/moment";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  const start = encodeURIComponent(startOfDay(moment().format()));
  const end = encodeURIComponent(endOfDay(moment().format()));

  export default {
    name: "Dashboard",
    mixins: [AdminPermissionsHelper],
    created() {
      this.validateSession();
    },
    methods: {
      validateSession() {
        if (window.localStorage.getItem("appToken") === "") {
          return this.$router.push({ name: "login" });
        }

        if (this.isAnyOperationPermitted(["getWebhookFailedTransfers"])) {
          return this.$router.push({ name: "failed-transfers", query: { start, end } });
        } else if (this.isAnyOperationPermitted(["getDispenseError", "uploadDispenseError"])) {
          return this.$router.push({ name: "refunds" });
        }

        callGetApi("failed-bank-transfer");
      }
    }
  };
</script>
