import adminClient from "@/api/AdminClient";
import { callPutApi } from "@/httpClient";

export default {
  data() {
    return {
      markSuccessfulDisabled: false
    };
  },
  methods: {
    markAsSuccessful() {
      this.disableModalButton = true;
      callPutApi(`failed-bank-transfer/successful/${this.selectedObject.tellerpointRef}`)
        .then(res => {
          this.markSuccessfulDisabled = false;
          if (res.body.status.code === 0) {
            this.$notify({
              group: "general",
              title: "Mark Transfer as Successful",
              text: "Transfer marked as successful successfully"
            });
          } else {
            this.$notify({
              group: "general",
              title: "Mark Transfer as Successful",
              type: "warn",
              text: res.body.status.description
            });
          }
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.disableModalButton = false;
          $("#confirmMarkAsSuccessful").modal("hide");
          this.fetchTransactions();
        });
    },
    async updateDisbursementStatusToWebhookFailed() {
      try {
        this.disableModalButton = true;
        const response = await adminClient.updateDisbursementStatusToWebhookFailed(this.selectedObject.tellerpointRef);
        if (!response.ok) {
          throw new Error(`HTTP ${response.status}: ${response.statusText}`);
        }
        const baseResponse = await response.json();
        const { code, description } = baseResponse.status;
        if (code === 0) {
          this.$notify({
            group: "general",
            title: "Update to WEBHOOK_FAILED",
            text: "Updated successfully"
          });
        } else {
          throw new Error(`Error ${code}: ${description}`);
        }
      } catch (e) {
        this.$notify({
          group: "general",
          title: "Update to WEBHOOK_FAILED",
          type: "warn",
          text: e.message
        });
      } finally {
        this.disableModalButton = false;
        $("#confirmUpdateToWebhookFailed").modal("hide");
        this.fetchTransactions();
      }
    },
    async commitHeldDisbursement() {
      try {
        this.disableModalButton = true;
        const response = await adminClient.commitHeldDisbursements(this.selectedObject.tellerpointRef);
        if (!response.ok) {
          throw new Error(`HTTP ${response.status}: ${response.statusText}`);
        }
        const baseResponse = await response.json();
        const { code, description } = baseResponse.status;
        if (code === 0) {
          this.$notify({
            group: "general",
            title: "Commit held disbursement",
            text: "Committed successfully"
          });
        } else {
          throw new Error(`Error ${code}: ${description}`);
        }
      } catch (e) {
        this.$notify({
          group: "general",
          title: "Commit held disbursement",
          type: "warn",
          text: e.message
        });
      } finally {
        this.disableModalButton = false;
        $("#confirmCommitHeldDisbursement").modal("hide");
        this.fetchTransactions();
      }
    },
    showMarkAsSuccessful(item) {
      this.selectedObject = item;
      $("#confirmMarkAsSuccessful").modal();
    },
    showConfirmCommitHeldDisbursement(item) {
      this.selectedObject = item;
      $("#confirmCommitHeldDisbursement").modal();
    },
    showUpdateToWebhookFailed(disbursement) {
      this.selectedObject = disbursement;
      $("#confirmUpdateToWebhookFailed").modal();
    },
    showResetRequeryCount(disbursement) {
      this.selectedObject = disbursement;
      $("#resetQueryCount").modal();
    },
    resetRequeryCount(transactionId) {
      callPutApi(`disbursements/processing/reset-retry-count?transactionId=${transactionId}`)
        .then(res => {
          if (res.body.status.code === 0) {
            this.$notify({
              group: "general",
              title: "Reset Requery Count",
              text: "Requery count reset successfully"
            });
          } else {
            this.$notify({
              group: "general",
              title: "Reset Requery Count",
              type: "warn",
              text: res.body.status.description
            });
          }
          $("#resetQueryCount").modal("hide");
          this.fetchTransactions();
        })
        .catch(err => {
          console.error(err);
        });
    },
  }
};
