<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Dispense Error">
      <button
        v-if="isAnyOperationPermitted(['uploadDispenseError'])"
        class="btn btn--primary"
        data-target="#uploadTerminals"
        data-toggle="modal"
      >
        Upload Dispense Errors
      </button>
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>

          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <div class="table table--padded">
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      Transaction Date
                    </div>
                    <div class="table__header">
                      STAN
                    </div>
                    <div class="table__header">
                      Amount
                    </div>
                    <div class="table__header">
                      RRN
                    </div>
                    <div class="table__header">
                      Status
                    </div>
                    <div class="table__header" />
                    <div class="table__header" />
                    <div class="table__header" />
                    <div class="table__header" />
                    <div class="table__header" />
                  </div>
                </div>

                <div class="table__section table__section--body">
                  <template v-for="(item, i) in dispense">
                    <div
                      :key="i"
                      class="table__row"
                    >
                      <div class="table__data">
                        {{ item.providerTransactionTime | date }}
                      </div>

                      <div class="table__data">
                        <p class="table__data--main">
                          {{ item.stan }}
                        </p>
                      </div>

                      <div class="table__data">
                        {{ item.amount | money }}
                      </div>
                      <div class="table__data">
                        {{ item.traceRef }}
                      </div>

                      <div class="table__data">
                        <span
                          v-if="item.status === 'SUCCESSFUL'"
                          class="tag tag--green"
                        >Refunded</span>
                        <span
                          v-if="item.status === 'REFUNDED' || item.status === 'PROCESSING'"
                          class="tag tag--yellow"
                        >
                          <template v-if="item.status === 'PROCESSING'">Processing</template>
                          <template v-else>Refunded</template>
                        </span>
                        <span
                          v-if="item.status === 'REVERSED'"
                          class="tag tag--gray"
                        >Reversed</span>
                        <span
                          v-if="item.status === 'CANCELED' || item.status === 'FAILED'"
                          class="tag tag--red"
                        >
                          <template v-if="item.status === 'CANCELED'">Accepted</template>
                          <template v-else>Failed</template>
                        </span>
                      </div>

                      <div class="table__data table__data__dispense">
                        <span
                          v-if="item.status === 'SUCCESSFUL'"
                          class="cursor-pointer"
                          data-target="#confirmReverse"
                          data-toggle="modal"
                          title="Reverse from Agent"
                          @click="selectItem(item)"
                        >
                          <svg
                            height="18"
                            viewBox="0 0 19 18"
                            width="18"
                            xmlns="http://www.w3.org/2000/svg"
                          ><path
                            d="m.58582 5 4.41418-4.41422 1.41419 1.41422-2 2h7.58583c3.866 0 7 3.134 7 6.99998 0 3.866-3.134 7-7 7h-9.00002v-2h9.00002c2.7615 0 5-2.2386 5-5 0-2.76141-2.2385-4.99998-5-4.99998h-7.58583l2 2-1.41419 1.41421z"
                          /></svg>
                        </span>
                      </div>

                      <div class="table__data table__data__dispense">
                        <span
                          v-if="item.status === 'PROCESSING' && item.nuban"
                          class="cursor-pointer"
                          data-target="#refundCardholder"
                          data-toggle="modal"
                          title="Refund cardholder"
                          @click="resolveNuban(item)"
                        >
                          <svg
                            height="16px"
                            width="14px"
                          >
                            <use xlink:href="../../assets/icons/refund-to-card-holder.svg#refund_cardholder" />
                          </svg>
                        </span>
                      </div>

                      <div class="table__data table__data__dispense">
                        <span
                          v-if="item.status === 'PROCESSING'"
                          class="cursor-pointer"
                          data-target="#confirmAccept"
                          data-toggle="modal"
                          title="Accept claim at bank"
                          @click="selectItem(item)"
                        >
                          <svg
                            height="22"
                            viewBox="0 0 32 40"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          ><path
                            d="m28 24h-1v-13h2a1 1 0 0 0 .47-1.88l-13-7a1 1 0 0 0 -.94 0l-13 7a1 1 0 0 0 .47 1.88h2v13h-1a2 2 0 0 0 -2 2v2a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2v-2a2 2 0 0 0 -2-2zm-3 0h-3v-13h3zm-8 0v-13h3v13zm-5 0v-13h3v13zm4-19.86 9 4.86h-18zm-9 6.86h3v13h-3zm-3 17v-2h24v2z"
                          /></svg>
                        </span>
                      </div>

                      <div class="table__data posRelative">
                        <download-receipt-options
                          v-if="item.status === 'SUCCESSFUL' || item.status === 'PROCESSING' || (item.nuban)"
                          class="cursor-pointer"
                          title="Generate Receipt"
                          receipt-type="dispense-error"
                          @download-pdf="generateReceipt(item)"
                          @download-pdf-as-image="generateReceiptAsImage(item)"
                        />
                      </div>

                      <div class="table__data">
                        <span @click="toggleTable(item.tellerpointRef)">
                          <svg
                            height="14"
                            viewBox="0 0 90 90"
                            width="14"
                            xmlns="http://www.w3.org/2000/svg"
                          ><path
                            d="m80 0h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10v-70c0-5.5-4.5-10-10-10zm6 80c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6v-70c0-3.3 2.7-6 6-6h70c3.3 0 6 2.7 6 6zm-12-62.4v.4 14c0 1.1-.9 2-2 2s-2-.9-2-2v-9.2l-14.6 14.6c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14.6-14.6h-9.2c-1.1 0-2-.9-2-2s.9-2 2-2h14 .4c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1.2.1.4.3.6.6 0 0 .1.1.1.2s.1.1.1.2 0 .1.1.2c-.1-.1-.1-.1 0 0zm-36.6 35c.8.8.8 2 0 2.8l-14.6 14.6h9.2c1.1 0 2 .9 2 2s-.9 2-2 2h-14c-.1 0-.3 0-.4 0s-.1 0-.2-.1c-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.1-.4-.3-.6-.6 0 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1 0-.1-.1-.2 0-.1 0-.3 0-.4v-13.9c0-1.1.9-2 2-2s2 .9 2 2v9.2l14.6-14.6c1-.8 2.2-.8 3 0z"
                          /></svg>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="opened.includes(item.tellerpointRef)"
                      :key="item.tellerpointRef"
                      class="collapsible-table"
                    >
                      <div class="card-details__content">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Terminal ID</label>
                          <input
                            :value="item.terminalId || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Tlp Ref</label>
                          <input
                            :value="item.tellerpointRef || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Card PAN</label>
                          <input
                            :value="item.maskedCardPan || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Card Type</label>
                          <input
                            :value="item.cardType || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Agent Name</label>
                          <input
                            :value="item.merchantName || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Processed Date</label>
                          <input
                            :value="item.creationTime | date"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="nuban"
                          >Nuban</label>
                          <input
                            id="nuban"
                            :value="item.nuban || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label class="form__label form__label--2">Bank Code</label>
                          <input
                            :value="item.bankCode || '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                        <div class="form__item posRelative form__item__download">
                          <label
                            class="form__label form__label--2"
                            for=""
                          >Cardholder Photo</label>
                          <template v-if="item.photoUrl">
                            <button
                              class="btn btn--primary fontNormal"
                              @click="downloadImage(item.photoUrl, item.stan)"
                            >
                              Download
                            </button>
                          </template>
                          <input
                            v-else
                            :value="'--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <pagination
                :page-number="computedPageNumbers"
                data="refunds"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <confirmation-modal
      id="confirmReverse"
      body="Are you sure you want to reverse this transaction?"
      :on-submit="reverseTransaction"
      button-text="Yes"
      header="Confirm Reverse"
      :disable-button="disableModalButton"
    />

    <confirmation-modal
      id="confirmRefund"
      body="Are you sure you want to refund claim to agent?"
      :on-submit="refundTransaction"
      button-text="Yes"
      header="Confirm Refund"
      :disable-button="disableModalButton"
    />

    <confirmation-modal
      id="confirmAccept"
      body="Are you sure you want to accept claim at bank?"
      :on-submit="acceptClaim"
      button-text="Yes"
      header="Accept Claim"
      :disable-button="disableModalButton"
    />


    <div
      id="uploadTerminals"
      aria-labelledby="uploadTerminalsLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Upload Dispense Errors
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>

          <div class="modal__body modal__body--pad">
            <form class="form">
              <div class="form__pad">
                <div class="flexCenter">
                  <input
                    id="file-1"
                    class="inputfile inputfile-1"
                    data-multiple-caption="{count} files selected"
                    name="file-1[]"
                    type="file"
                    @change="uploadFiles"
                  >
                  <label for="file-1">
                    <svg
                      height="17"
                      viewBox="0 0 20 17"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                      />
                    </svg>
                    <span>Choose a file&hellip;</span></label>
                </div>
              </div>

              <div class="form__footer" />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      id="refundCardholder"
      aria-labelledby="refundCardholderLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Refund Cardholder
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <div
              v-if="resolvingNuban"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>
            <form
              v-else
              class="form"
              @submit.prevent="refundDispenseErrorToCardholder"
            >
              <div class="form__pad">
                <div class="form__item">
                  <label
                    class="form__label"
                    for="maskedCardPan"
                  >Masked Card PAN</label>
                  <input
                    id="maskedCardPan"
                    :value="selectedObject.maskedCardPan"
                    class="form__input fontNormal"
                    disabled
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label"
                    for="rrn"
                  >RRN</label>
                  <input
                    id="rrn"
                    :value="selectedObject.traceRef"
                    class="form__input fontNormal"
                    disabled
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label"
                    for="stan"
                  >Stan</label>
                  <input
                    id="stan"
                    :value="selectedObject.stan"
                    class="form__input fontNormal"
                    disabled
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label"
                    for="bankName"
                  >Bank Name</label>
                  <input
                    id="bankName"
                    :value="selectedObject.bankName"
                    class="form__input fontNormal"
                    disabled
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label"
                    for="accountName"
                  >Account Name</label>
                  <input
                    id="accountName"
                    :value=" isBlank(selectedObject.accountName) ? '' : `${selectedObject.accountName} / ${selectedObject.nuban}`"
                    class="form__input fontNormal font-italic"
                    disabled
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label"
                    for="amount"
                  >Refund Amount</label>
                  <input
                    id="amount"
                    :value="selectedObject.amount | money"
                    class="form__input fontNormal"
                    disabled
                  >
                </div>
              </div>

              <div class="form__footer">
                <button
                  :disabled="refundToCardholder"
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Refund
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import adminClient from "@/api/AdminClient";
  import TitleBar from "@/components/TitleBar";
  import JsPdf from "jspdf";
  import DownloadReceiptOptions from "@/components/DownloadReceiptOptions";
  import Pagination from "@/components/Pagination";
  import ConfirmationModal from "@/components/ConfirmationModal";
  import Search from "../../components/Search";
  import { hasBlank } from "@/mixins/ViewHelper";
  import { savePdfAsImage } from "@/downloads/pdfToImage";
  import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "@/httpClient";
  import Vue from "@/main";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "DispenseError",
    components: {
      TitleBar,
      Pagination,
      Search,
      DownloadReceiptOptions,
      ConfirmationModal
    },
    mixins: [
      hasBlank, AdminPermissionsHelper
    ],
    data() {
      return {
        dispense: "",
        loading: true,
        search: this.$route.query.criteria ? this.$route.query.criteria : "",
        searchPlaceholder: "Search by terminal id, stan, masked card pan, rrn",
        selectedObject: "",
        opened: [],
        pageNumber: "",
        refundToCardholder: false,
        resolvingNuban: false,
        disableModalButton: false
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("refundsPage")) {
          return JSON.parse(window.localStorage.getItem("refundsPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      "$route": "fetchDispense"
    },
    created() {
      this.fetchDispense();
    },
    methods: {
      downloadImage(uri, name) {
        getDataUri(uri, function(dataUri) {
          generar(dataUri);
        });

        function getDataUri(url, cb) {
          const image = new Image();
          image.crossOrigin = "anonymous";

          image.onload = function() {
            const canvas = document.createElement("canvas");
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight;

            canvas.getContext("2d").drawImage(this, 0, 0);

            cb(canvas.toDataURL("image/jpeg"));
          };
          image.src = url;
        }

        function generar(img) {
          const pdf = new JsPdf();
          pdf.addImage(img, "JPG", 15, 40, 100, 100);
          pdf.save("photo_" + name);
        }
      },
      clearSearch() {
        this.search = "";
        this.$router.push({ name: "refunds", query: { currentPage: 1 } });
      },
      uploadFiles(e) {
        const file = e.target.files;
        const label = e.target.nextElementSibling, labelVal = label.innerHTML;

        let fileName = "";
        if (file && file.length > 1)
          fileName = (this.getAttribute("data-multiple-caption") || "").replace("{count}", file.length);
        else
          fileName = e.target.value.split("\\").pop();

        if (fileName)
          label.querySelector("span").innerHTML = fileName;
        else
          label.innerHTML = labelVal;

        const body = new FormData();
        body.append("attachment", file[0]);

        // Empty headers here so the browser can set multipart form-data and boundary by itself.
        const headers = new Headers({});
        callPostApi("merchant/dispense-error", { body, headers })
          .then(res => {
            if (res.body.status.code === 0) {
              $("#uploadTerminals").modal("hide");
              this.$notify({
                group: "general",
                title: "Upload Dispense Errors",
                text: "Dispense Errors have been uploaded successfully"
              });
              e.target.value = null;
              document.getElementById("file-1").value = null;
              label.querySelector("span").innerHTML = "Choose a file...";
              this.fetchDispense();
            } else {
              e.target.value = null;
              document.getElementById("file-1").value = null;
              label.querySelector("span").innerHTML = "Choose a file...";
              this.$notify({
                group: "general",
                title: "Upload Dispense Errors",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.log);
      },
      selectItem(value) {
        this.selectedObject = value;
      },
      fetchDispense() {
        let queryString = "";
        const query_params = this.convertToQueryString(this.$route.query);

        // Determine query string
        if (this.search !== "") {
          queryString += "criteria=" + this.search;
        }

        callGetApi(`merchant/dispense-error?${queryString}&batchSize=20&${query_params}`)
          .then(res => {
            this.loading = false;
            this.dispense = res.body.entity;
            this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            window.localStorage.setItem("refundsPage", Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize));
          })
          .catch(console.error);
      },
      refundTransaction() {
        this.disableModalButton = true;
        callPutApi(`merchant/dispense-error/refund?tellerpointRef=${this.selectedObject.tellerpointRef}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Refund Transaction",
                text: "Transaction refunded successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Refund Transaction",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmRefund").modal("hide");
            this.fetchDispense();
          });
      },
      reverseTransaction() {
        this.disableModalButton = true;
        callPutApi(`merchant/dispense-error/reverse?tellerpointRef=${this.selectedObject.tellerpointRef}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Reverse Transaction",
                text: "Transaction reversed successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Reverse Transaction",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmReverse").modal("hide");
            this.fetchDispense();
          });
      },
      acceptClaim() {
        this.disableModalButton = true;
        callDeleteApi(`merchant/dispense-error?tellerpointRef=${this.selectedObject.tellerpointRef}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Accept Claim",
                text: "Claim accepted successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Accept Claims",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmAccept").modal("hide");
            this.fetchDispense();
          });
      },
      showFile(blob, file) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], { type: "application/pdf" });

        console.log(newBlob);

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = data;
        link.download = file;
        link.click();
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
      generateReceipt(item) {
        Vue.$http.get(`merchant/dispense-error/receipt/${item.tellerpointRef}`,{
          responseType: "blob",
          headers: { "Content-Type": "application/pdf" }
        } )
          .then(res => {
            this.showFile(res.body, item.traceRef);
          })
          .catch(console.error);
      },
      async generateReceiptAsImage(item) {
        try {
          const fileUrl = await adminClient.fetchReceiptUrl(item.tellerpointRef, "dispense-error");
          savePdfAsImage(fileUrl, item.traceRef);
        } catch (e) {
          console.error(e);
        }
      },
      searchQuery(criteria) {
        this.$router.push({ name: "refunds", query: { criteria } });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");

      },
      resolveNuban(dispenseError) {
        this.refundToCardholder = true;
        this.resolvingNuban = true;

        this.selectedObject = dispenseError;
        const nuban = dispenseError.nuban;
        const bankCode = dispenseError.bankCode;

        callGetApi(`nuban/resolve/${nuban}/${bankCode}`)
          .then(res => {
            if (res.body.status.code === 0) {
              const entity = res.body.entity;
              this.selectedObject.bankName = entity.bankName;
              this.selectedObject.accountName = entity.accountName;
              this.refundToCardholder = false;
            } else {
              this.$notify({
                group: "general",
                title: "Resolve Nuban",
                type: "warn",
                text: res.body.status.description
              });
              this.refundToCardholder = true;
            }
          })
          .catch(console.error)
          .finally(() => {
            this.resolvingNuban = false;
          });
      },
      refundDispenseErrorToCardholder() {
        this.refundToCardholder = true;

        if (this.isBlank(this.selectedObject.accountName)) {
          return;
        }
        callPostApi(`merchant/dispense-error/disburse/${this.selectedObject.tellerpointRef}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Refund to Cardholder",
                text: "Refunded successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Refund to Cardholder",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(error => {
            const message = error ? JSON.stringify(error, null, 2) : "Error refunding to cardholder";
            this.$notify({
              group: "general",
              title: "Refund to Cardholder",
              type: "warn",
              text: message
            });
          })
          .finally(() => {
            this.refundToCardholder = false;
            $("#refundCardholder").modal("hide");
            this.fetchDispense();
          });
      }
    }
  };
</script>

<style scoped>
  .font-italic {
    font-style: italic;
    overflow: auto;
  }
</style>
