<template>
  <span v-if="latency"> |
    <span :class="{green: !latencyAboveTreshold(latency), red: latencyAboveTreshold(latency)}">
      {{ latency | latencyToSeconds }}s
    </span>
  </span>
</template>

<script>
  import { latencyCheck } from "@/mixins/ViewHelper";

  export default {
    name: "Latency",
    mixins: [latencyCheck],
    props: {
      latency: {
        type: Number,
        default: null
      }
    }
  };
</script>
