<template>
  <div class="table table--padded">
    <div class="table__section table__section--head">
      <div class="table__row">
        <div class="table__header">
          Transaction Date
        </div>
        <div class="table__header">
          Terminal ID
        </div>
        <div class="table__header">
          Order Number
        </div>
        <div class="table__header">
          Amount
        </div>
        <div class="table__header">
          Status
        </div>
        <div class="table__header" />
        <div class="table__header" />
      </div>
    </div>
    <div class="table__section table__section--body">
      <template v-for="(item, i) in cardSales">
        <div
          :key="i"
          class="table__row"
        >
          <div class="table__data table__data--full">
            <p class="table__data--main">
              {{ item.saleDetails.creationTime | date }}
              <latency :latency="item.paymentDetails.latency" />
            </p>
          </div>
          <div class="table__data table__data--full">
            <p class="table__data--main">
              {{ item.saleDetails.terminalId }}
            </p>
          </div>
          <div class="table__data table__data--full">
            <p class="table__data--main">
              {{ item.saleDetails.id }}
            </p>
          </div>
          <div class="table__data">
            {{ item.saleDetails.totalAmount | money }}
          </div>
          <div class="table__data">
            <span
              v-if="item.paymentDetails.status === 'SUCCESSFUL'"
              class="tag tag--green"
            >
              Successful
            </span>
            <span
              v-if="item.paymentDetails.status === 'PROCESSING'"
              class="tag tag--yellow"
            >
              Processing
            </span>
            <span
              v-if="item.paymentDetails.status === 'REVERSED'"
              class="tag tag--gray"
            >
              Reversed
            </span>
            <span
              v-if="item.paymentDetails.status === 'CANCELED'"
              class="tag tag--red"
            >
              Declined
            </span>
          </div>
          <div class="table__data posRelative width130">
            <download-receipt-options
              v-if="(item.saleDetails.status === 'PAID' || item.saleDetails.status === 'CANCELED')
                && isAnyOperationPermitted(['fetchSalesReceipt'])"
              class="cursor-pointer"
              receipt-type="sales"
              @download-pdf="downloadReceipt(item)"
              @download-pdf-as-image="downloadReceiptAsImage(item)"
            />
          </div>
          <div class="table__data">
            <span @click="toggleTable(item.saleDetails.id)">
              <svg
                height="14"
                width="14"
              >
                <use xlink:href="../../../assets/icons/expand-icon.svg#expand_icon" />
              </svg>
            </span>
          </div>
        </div>
        <div
          v-if="opened.includes(item.saleDetails.id)"
          :key="item.saleDetails.id"
          class="collapsible-table"
        >
          <div class="card-details__content">
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Merchant</label>
              <p class="form__input capitalize">
                {{ item.saleDetails.businessName ? item.saleDetails.businessName : '--' }}
              </p>
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Cardholder Name</label>
              <p class="form__input capitalize">
                {{ item.paymentDetails.cardHolderName ? item.paymentDetails.cardHolderName : '--' }}
              </p>
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Card PAN</label>
              <p class="form__input capitalize">
                {{ item.paymentDetails.maskedCardPan ? item.paymentDetails.maskedCardPan : '--' }}
              </p>
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >RRN</label>
              <p class="form__input capitalize">
                {{ item.paymentDetails.traceRef ? item.paymentDetails.traceRef : '--' }}
              </p>
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Provider</label>
              <p class="form__input capitalize">
                {{ item.paymentDetails.provider ? item.paymentDetails.provider : '--' }}
              </p>
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Provider Response</label>
              <p class="form__input capitalize">
                {{ item.paymentDetails.providerResponseCode
                  ? `${item.paymentDetails.providerResponseCode} - ${item.paymentDetails.providerResponseMessage}`
                  : '--' }}
              </p>
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Tlp Ref</label>
              <p class="form__input capitalize">
                {{ item.paymentDetails.tellerpointRef ? item.paymentDetails.tellerpointRef : '--' }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import { dropdownOperations, providerResponseOperations } from "@/mixins/DropdownOperations";
  import Latency from "@/components/Latency.vue";
  import DownloadReceiptOptions from "@/components/DownloadReceiptOptions.vue";
  import adminClient from "@/api/AdminClient";
  import { savePdfAsImage } from "@/downloads/pdfToImage";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "CardSalesTable",
    components: { DownloadReceiptOptions, Latency },
    mixins: [providerResponseOperations, dropdownOperations, AdminPermissionsHelper],
    props: {
      cardSales: {
        type : Array,
        required: true
      },
      loading: {
        type : Boolean,
        default: false,
        required: true
      }
    },
    data() {
      return {
        opened: []
      };
    },
    methods: {
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      async downloadReceipt(item) {
        this.loading = true;
        await adminClient.fetchReceiptUrl(item.saleDetails.id, "sales")
          .then(fileUrl => {
            const link = document.createElement("a");
            link.href = fileUrl;
            link.download = item.paymentDetails.traceRef || "--";
            link.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(fileUrl);
            }, 100);
            this.loading = false;
          })
          .catch(error => {
            console.error(error);
            this.loading = false;
          });
      },
      async downloadReceiptAsImage(item) {
        this.loading = true;
        try {
          const fileUrl = await adminClient.fetchReceiptUrl(item.saleDetails.id, "sales");
          await savePdfAsImage(fileUrl, item.paymentDetails.traceRef);
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }

    }
  };
</script>
<style scoped>
.width130{
  width: 130px;
}
</style>
