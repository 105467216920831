import { callGetApi } from "@/httpClient";

export const merchantOperations = {
  methods: {
    getMerchants(size) {
      return callGetApi(`merchant?batchSize=${size}` );
    },
    searchMerchants(criteria) {
      return callGetApi(`merchant?batchSize=20&criteria=${criteria}`);
    },
    searchAgentByName(search, loading) {
      const businessName = search;
      if (businessName.length < 2) {
        return;
      }
      loading(true);
      this.searchMerchants(businessName)
        .then(res => this.agents = res.body.entity.merchants)
        .catch(console.error);
      loading(false);
    }
  }
};

export const accountManagerOperations = {
  methods: {
    searchAccountManger(criteria) {
      return callGetApi(`account-manager?criteria=${criteria}` );
    },
    searchAccountManager(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.searchAccountManger(search)
          .then(res => {
            this.accountManagers = res.body.entity;
            this.accountManagers.forEach(am => am["fullName"] = `${am.firstName} ${am.lastName}`);
          })
          .catch(console.error);
        loading(false);
      }
    }
  }
};
