<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Manage Terminals">
      <div
        v-click-outside="hideDropdown"
        class="dashboard__main__content__row__item posRelative"
      >
        <button
          v-if="isAnyOperationPermitted(['whitelistPtspTerminals'])"
          id="exampleColorDropdown1"
          class="btn btn--primary btn--flex"
          @click="openUploadDropdown"
        >
          <span class="btn__icon">
            <svg
              height="14"
              width="14"
            >
              <use xlink:href="../../assets/icons/addIcon.svg#add_icon" />
            </svg>
          </span>
          <span class="btn__text">Add New Terminals</span>
        </button>
        <!-- Dropdown -->
        <div
          :class="{ 'active' : activeDropdown }"
          class="dropdown refundsDropdown"
        >
          <router-link
            :to="{name: 'new-terminal', params: {terminalType: 'ptsp'}}"
            class="dropdown__item"
            tag="div"
          >
            <div class="dropdown__item__link flex flex--default">
              <span>Add Single Terminal</span>
            </div>
          </router-link>
          <div
            class="dropdown__item"
            data-target="#bulkTerminal"
            data-toggle="modal"
          >
            <div class="dropdown__item__link flex flex--default">
              <span>Bulk Terminal Upload</span>
            </div>
          </div>
        </div>
      </div>
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <!-- Table Data -->
              <div class="table table--padded">
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      TID
                    </div>
                    <div class="table__header">
                      Agent
                    </div>
                    <div class="table__header">
                      Account Manager
                    </div>
                    <div class="table__header">
                      Last Seen
                    </div>
                    <div class="table__header" />
                    <div class="table__header" />
                    <div class="table__header" />
                    <div class="table__header" />
                  </div>
                </div>
                <div class="table__section table__section--body">
                  <template v-for="(item, i) in terminals">
                    <div
                      :key="i"
                      class="table__row"
                    >
                      <div class="table__data table__data--full">
                        <p class="table__data--main">
                          {{ item.terminalId ? item.terminalId : "--" }}
                        </p>
                      </div>
                      <div class="table__data">
                        {{ item.agentName ? item.agentName : "--" }}
                      </div>
                      <div class="table__data">
                        {{ item.accountManager ? item.accountManager : "--" }}
                      </div>
                      <div class="table__data">
                        <template v-if="item.lastSeen">
                          <span class="tag tag--yellow">{{ item.lastSeen | date }}</span>
                        </template>
                        <template v-else>
                          <span class="tag tag--gray">Offline</span>
                        </template>
                      </div>
                      <div
                        class="table__data"
                        @click="setSelectedTerminal(item)"
                      >
                        <span
                          v-if="selectedTerminal.merchantId !== null"
                          data-target="#mapTerminal"
                          data-toggle="modal"
                          title="Map Terminal"
                        >
                          <svg
                            height="20"
                            viewBox="0 0 91 90"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path
                                d="m46.75977 7.59-13.06983 13.07 8.48 8.49.08985-.09 12.99-12.99c3.5154934-3.5137357 8.638499-4.885068 13.4392301-3.5974293 4.8007311 1.2876388 8.5498424 5.0386256 9.83508 9.8400001 1.2852377 4.8013745-.0886566 9.9236936-3.6041501 13.4374292l-12.99 12.99-.08008.08 8.48 8.49.09009-.09 12.99-12.99c10.1075907-10.1224286 10.1004371-26.5205995-.0159816-36.63420542-10.1164186-10.11360594-26.5145908-10.1161997-36.6342084-.00579458z"
                                fill="#7c889d"
                              />
                              <path
                                d="m8.58984 82.41c4.8582979 4.8597016 11.4484065 7.5899716 18.32007 7.5899716s13.4617721-2.73027 18.32007-7.5899716l12.99002-12.99.08984-.09-8.49-8.49c-.0238351.0324231-.0506411.0625535-.08007.09l-12.99 12.99c-5.4340416 5.4209238-14.2317534 5.4167191-19.6606109-.0093964s-5.4375068-14.223824-.0193291-19.6606036l12.99-12.99.09009-.09-8.48-8.48c-.0324529.0238065-.0626137.0505892-.09009.08l-12.99 12.99c-4.87134617 4.8541115-7.60949342 11.4480522-7.60949342 18.325s2.73814725 13.4708885 7.60949342 18.325z"
                                fill="#7c889d"
                              />
                              <path
                                d="m90.77124 70.65723c.4320429-1.2626051.2372446-2.6554696-.5246083-3.7511032-.7618529-1.0956335-1.9997608-1.7631678-3.3337917-1.7977268l-13.36889-.346 10.77832 7.9166c1.0755391.7899674 2.4628927 1.0207666 3.7362616.6215619 1.273369-.3992047 2.2806554-1.3807302 2.7127084-2.6433319z"
                                fill="#7c889d"
                              />
                              <path
                                d="m66.10864 85.9126c.0467078 1.805168 1.2439525 3.3776486 2.9716223 3.902977 1.7276698.5253285 3.5976121-.1145199 4.6412921-1.5881363 1.0436799-1.4736164 1.0266156-3.4499258-.0423544-4.9053007l-7.91663-10.77844z"
                                fill="#7c889d"
                              />
                              <path
                                d="m20.343.22888c-1.2626283.43202852-2.2441849 1.43931289-2.6434075 2.71269433-.3992226 1.27338145-.1684243 2.66075571.6215575 3.73630567l7.91664 10.77842-.34594-13.3689c-.0345583-1.33404165-.7020886-2.57196246-1.7977211-3.33383565-1.0956326-.76187319-2.4885028-.95669913-3.7511289-.52468435z"
                                fill="#7c889d"
                              />
                              <path
                                d="m5.08765 24.8916 13.36889.346-10.77854-7.9166c-1.45539404-1.0689834-3.43173164-1.0860379-4.90535679-.0423299-1.47362516 1.0437079-2.1134553 2.9136861-1.58808654 4.6413681.52536875 1.727682 2.09790066 2.9249057 3.90309333 2.9715618z"
                                fill="#7c889d"
                              />
                            </g>
                          </svg>
                        </span>
                        <span
                          v-else
                          data-target="#mapTerminal"
                          data-toggle="modal"
                          title="Unmap Terminal"
                        >
                          <svg
                            height="20"
                            viewBox="0 0 96 96"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              fill="none"
                              fill-rule="evenodd"
                              transform="matrix(0 1 -1 0 98 -2)"
                            >
                              <path d="m0 0h100v100h-100z" />
                              <g
                                fill="#000"
                                fill-rule="nonzero"
                              >
                                <path
                                  d="m60.26 60.26c-2.1953319 2.1953317-5.754668 2.1953317-7.9499998-.0000002-2.1953319-2.1953318-2.1953319-5.7546679-.0000002-7.9499998 2.1855411-2.1832703 3.4135564-5.1457835 3.4135564-8.235s-1.2280153-6.0517297-3.4135564-8.235l-19.2-19.21c-4.5508263-4.5508263-11.9291737-4.5508263-16.48 0s-4.5508263 11.9291737 0 16.48l11.47 11.46c-1.67 5.16-2.24 10.16-.57 15.32l-18.84-18.84c-8.93596725-8.9359673-8.93596725-23.4240327 0-32.36 8.9359673-8.93596725 23.4240327-8.93596725 32.36 0l19.21 19.21c8.920522 8.9423594 8.920522 23.4176406 0 32.36z"
                                  fill="#53bc3f"
                                />
                                <path
                                  d="m39.74 39.74c2.1953319-2.1953319 5.7546681-2.1953319 7.95 0s2.1953319 5.7546681 0 7.95c-2.1855411 2.1832703-3.4135564 5.1457835-3.4135564 8.235s1.2280153 6.0517297 3.4135564 8.235l19.2 19.21c4.5508263 4.5508263 11.9291737 4.5508263 16.48 0s4.5508263-11.9291737 0-16.48l-12-12c1.55-4.78 2.23-9.35.91-14.18.7838278.2694406 1.4977869.7101139 2.09 1.29l16.94 17c8.1190601 9.0473522 7.7457508 22.8642577-.8499957 31.4600043-8.5957466 8.5957465-22.4126521 8.9690558-31.4600043.8499957l-19.26-19.21c-8.920522-8.9423594-8.920522-23.4176406 0-32.36z"
                                  fill="#53bc3f"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div class="table__data">
                        <span
                          data-target="#updateTerminal"
                          data-toggle="modal"
                          title="Edit Terminal"
                          @click="setUpdateTerminalParams(item)"
                        >
                          <svg
                            height="15px"
                            width="15px"
                          >
                            <use xlink:href="../../assets/icons/edit-icon.svg#edit_icon" />
                          </svg>
                        </span>
                      </div>
                      <div class="table__data">
                        <span
                          :title="item.enabled ? 'Disable Terminal' : 'Enable Terminal'"
                          data-target="#disableTerminal"
                          data-toggle="modal"
                          @click="setSelectedTerminal(item)"
                        >
                          <svg
                            height="15px"
                            width="15px"
                            :class="{enableTerminal: item.enabled, disableTerminal: !item.enabled}"
                          >
                            <use xlink:href="../../assets/icons/disable-icon.svg#disable_icon" />
                          </svg>
                        </span>
                      </div>
                      <div class="table__data">
                        <span @click="toggleTable(item.terminalId)">
                          <svg
                            height="14"
                            viewBox="0 0 90 90"
                            width="14"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m80 0h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10v-70c0-5.5-4.5-10-10-10zm6 80c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6v-70c0-3.3 2.7-6 6-6h70c3.3 0 6 2.7 6 6zm-12-62.4v.4 14c0 1.1-.9 2-2 2s-2-.9-2-2v-9.2l-14.6 14.6c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14.6-14.6h-9.2c-1.1 0-2-.9-2-2s.9-2 2-2h14 .4c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1.2.1.4.3.6.6 0 0 .1.1.1.2s.1.1.1.2 0 .1.1.2c-.1-.1-.1-.1 0 0zm-36.6 35c.8.8.8 2 0 2.8l-14.6 14.6h9.2c1.1 0 2 .9 2 2s-.9 2-2 2h-14c-.1 0-.3 0-.4 0s-.1 0-.2-.1c-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.1-.4-.3-.6-.6 0 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1 0-.1-.1-.2 0-.1 0-.3 0-.4v-13.9c0-1.1.9-2 2-2s2 .9 2 2v9.2l14.6-14.6c1-.8 2.2-.8 3 0z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="opened.includes(item.terminalId)"
                      :key="item.terminalId"
                      class="collapsible-table"
                    >
                      <div class="card-details__content">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for
                          >Terminal Owner</label>
                          <input
                            :value="item.sponsor ? item.sponsor : '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>

                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for
                          >Serial Number</label>
                          <input
                            :value="item.serialNumber ? item.serialNumber : '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>

                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for
                          >is Activated</label>
                          <input
                            :value="item.activated !== null ? item.activated : '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>

                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for
                          >Is Enabled</label>
                          <input
                            :value="item.enabled !== null ? item.enabled : '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <pagination
                :page-number="computedPageNumbers"
                data="manage-terminals"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="bulkTerminal"
      aria-labelledby="uploadTerminalsLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Upload Terminals
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form class="form">
              <div class="form__pad">
                <div class="flexCenter">
                  <input
                    id="file-1"
                    class="inputfile inputfile-1"
                    data-multiple-caption="{count} files selected"
                    name="file-1[]"
                    type="file"
                    @change="uploadFiles"
                  >
                  <label for="file-1">
                    <svg
                      height="17"
                      viewBox="0 0 20 17"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                      />
                    </svg>
                    <span>Choose a file&hellip;</span>
                  </label>
                </div>
              </div>

              <div class="form__footer">
                <!-- <button class="btn btn--primary btn--md btn--block" type="submit">Upload Terminals</button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      id="disableTerminal"
      aria-labelledby="disableTerminalLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title width70percent text-center">
              {{ terminalEnabled ? "Disable " : "Enable " }} Terminal {{ selectedTerminal.terminalId }}
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form
              class="form"
              @submit.prevent="disableTerminal"
            >
              <div class="form__pad">
                <p class="m-b-20 textCenter">
                  Are you sure you want to {{
                    terminalEnabled ? "disable"
                    : "enable"
                  }} this terminal?
                </p>
              </div>

              <div class="form__footer">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      id="deleteTerminal"
      aria-labelledby="deleteTerminalLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div
              class="modal__title width70percent text-center"
            >
              Delete {{ selectedTerminal.terminalId }}
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form
              class="form"
              @submit.prevent="deleteTerminal"
            >
              <div class="form__pad">
                <p class="m-b-20 textCenter">
                  Are you sure you want to delete this terminal?
                </p>
              </div>

              <div class="form__footer">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      id="mapTerminal"
      aria-labelledby="mapTerminalLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center width70percent">
              <span>Remap {{ selectedTerminal.terminalId }}</span>
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form
              class="form"
              @submit.prevent="mapTerminal"
            >
              <div class="form__pad">
                <div class="form__item">
                  <v-select
                    v-model="selectedAgent"
                    :options="agents"
                    label="businessName"
                    @search="searchAgentByName"
                  >
                    <template>
                      Enter Agent Name
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="form__footer">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Map to Agent
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      id="updateTerminal"
      aria-labelledby="updateTerminalLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center width70percent">
              <span>Edit {{ selectedTerminal.terminalId }}</span>
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form
              class="form"
              @submit.prevent="updateTerminal"
            >
              <div class="form__pad">
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="terminalId"
                  >Terminal ID</label>
                  <input
                    id="terminalId"
                    v-model="newTerminalId"
                    class="form__input fontNormal"
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="serialNumber"
                  >Serial Number</label>
                  <input
                    id="serialNumber"
                    v-model="selectedTerminal.serialNumber"
                    class="form__input fontNormal"
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="mid"
                  >Mid</label>
                  <input
                    id="mid"
                    v-model="selectedTerminal.mid"
                    class="form__input fontNormal"
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="sponsor"
                  >Sponsor</label>
                  <v-select
                    id="sponsor"
                    v-model="selectedTerminal.sponsor"
                    :options="sponsors"
                    :reduce="sponsor => sponsor.appId"
                    height="34px"
                    label="businessName"
                  />
                </div>
              </div>

              <div class="form__footer">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "../../components/Search";
  import { merchantOperations } from "@/mixins/MerchantOperations";
  import { terminalOperations } from "@/mixins/TerminalOperations";
  import { pagination } from "@/mixins/Pagination";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { callDeleteApi, callGetApi, callPutApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "ManageTerminals",
    components: {
      TitleBar,
      Pagination,
      vSelect,
      Search
    },
    mixins: [terminalOperations, pagination, merchantOperations, AdminPermissionsHelper],
    data() {
      return {
        terminals: "",
        searchPlaceholder: "Search by tid, serial number, agent, account manager",
        loading: true,
        selectedTerminal: "",
        opened: [],
        activeDropdown: false,
        pageNumber: "",
        pageName: "manageterminalsPage",
        terminalType: "ptsp",
        terminalEnabled: false,
        agents: [],
        selectedAgent: {},
        newTerminalId: "",
        sponsors: []
      };
    },
    watch: {
      //watch for route parameter change and execute method
      $route: "getTerminals"
    },
    created() {
      this.getTerminals();
    },
    methods: {
      hideDropdown() {
        this.activeDropdown = false;
      },
      openUploadDropdown() {
        this.activeDropdown = !this.activeDropdown;
      },
      uploadFiles(e) {
        const file = e.target.files;
        const label = e.target.nextElementSibling,
              labelVal = label.innerHTML;

        let fileName = "";
        if (file && file.length > 1)
          fileName = (this.getAttribute("data-multiple-caption") || "").replace(
            "{count}",
            file.length
          );
        else fileName = e.target.value.split("\\").pop();

        if (fileName) label.querySelector("span").innerHTML = fileName;
        else label.innerHTML = labelVal;

        const body = new FormData();
        body.append("attachment", file[0]);

        const headers = new Headers({});

        callPutApi("ptsp/whitelist-terminals", { body, headers })
          .then(res => {
            if (res.body.status.code === 0) {
              $("#bulkTerminal").modal("hide");
              this.getTerminals();
              this.$notify({
                group: "general",
                title: "Upload Terminals",
                text: "Terminals have been uploaded successfully"
              });
              e.target.value = null;
              document.getElementById("file-1").value = null;
              label.querySelector("span").innerHTML = "Choose a file...";
            } else {
              this.$notify({
                group: "general",
                title: "Upload Terminals",
                type: "warn",
                text: res.body.status.description
              });
              e.target.value = null;
              document.getElementById("file-1").value = null;
              label.querySelector("span").innerHTML = "Choose a file...";
            }
          })
          .catch(console.log);
      },
      setSelectedTerminal(terminal) {
        this.selectedTerminal = terminal;
        this.terminalEnabled = terminal.enabled;
        this.newTerminalId = this.selectedTerminal.terminalId;
      },
      setUpdateTerminalParams(terminal) {
        this.selectedTerminal = terminal;
        this.newTerminalId = this.selectedTerminal.terminalId;
        this.getSponsors();
      },
      deleteTerminal() {
        callDeleteApi(`terminals/${this.selectedTerminal.terminalId}`)
          .then(res => {
            if (res.body.status.code === 0) {
              $("#deleteTerminal").modal("hide");
              this.getTerminals();
              this.$notify({
                group: "general",
                title: "Delete Terminal",
                text: "Terminal deleted successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Delete Terminal",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      searchQuery(criteria) {
        this.$router.push({
          name: "manage-terminals",
          query: { criteria }
        });
      },
      clearSearch() {
        this.$router.push({ name: "manage-terminals", query: { currentPage: 1 } });
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      },
      updateTerminal() {
        let terminal = this.selectedTerminal;
        let terminalId = terminal.terminalId;
        const body = {
          currentTerminalId: terminalId,
          newTerminalId: this.newTerminalId,
          serialNumber: terminal.serialNumber,
          mid: terminal.mid,
          sponsorAppId: this.getSponsorAppIdByBusinessName(terminal.sponsor)
        };

        callPutApi("terminals", { body })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Update Terminal",
                text: `${terminalId} updated successfully`
              });
            } else {
              this.$notify({
                group: "general",
                title: "Update Terminal",
                type: "warn",
                text: res.body.status.description
              });
            }
            this.getTerminals();
            $("#updateTerminal").modal("hide");
          })
          .catch(err => {
            console.log(err);
          });
      },
      getSponsors() {
        callGetApi("sponsors")
          .then(res => {
            this.sponsors = res.body.entity;
          })
          .catch(console.error);
      },
      getSponsorAppIdByBusinessName(businessName) {
        const [first] = this.sponsors.filter(s => s.businessName === businessName);
        return first.appId ? first.appId : businessName;
      }
    }
  };
</script>
