<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Order Lookup" />

    <div class="dashboard__main__content__home">
      <div class="dashboard__main__content__home__left">
        <div class="big-card unicolumn gap-xl">
          <form @submit.prevent="searchOrder">
            <div class="form__item">
              <input
                v-model="orderNumber"
                class="form__input form__input--2 fontNormal"
                placeholder="Enter Order Number"
                required="required"
                type="text"
              >
            </div>
            <button
              class="btn btn--primary m-r-20"
              type="submit"
            >
              Search
            </button>
            <button
              v-if="orderNumber && orderDetails"
              class="btn btn--primary"
              type="submit"
              @click="clearSearch"
            >
              Clear
            </button>
          </form>
        </div>
      </div>

      <div
        v-if="orderDetails.saleDetails"
        class="dashboard__main__content__home__right big-card"
      >
        <div class="transaction m-b-40">
          <div class="transaction__content">
            <table class="p-b-20">
              <tr>
                <td
                  class="section-title"
                  colspan="3"
                >
                  Order #{{ saleDetails.id }}
                </td>
              </tr>
              <tr>
                <td
                  :class="['payment-status', 'capitalize', { 'paid': saleDetails.status === 'PAID' }]"
                  colspan="3"
                >
                  {{ saleDetails.status }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  Order Date
                </td>
                <td>{{ saleDetails.creationTime | date }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  Payment Method
                </td>
                <td>
                  {{ saleDetails.paymentSource }}
                  <span v-if="saleDetails.paymentSource === 'CARD'"> ({{ paymentDetails.maskedCardPan }})</span>
                </td>
              </tr>
              <tr>
                <th
                  colspan="3"
                  class="sub-title"
                >
                  Items
                </th>
              </tr>

              <OrderProductSoldRow
                v-if="orderDetails.productsSold"
                :products-sold="orderDetails.productsSold"
              />

              <tr>
                <td
                  colspan="2"
                  class="sub-title"
                >
                  Subtotal
                </td>
                <td class="right-align subtotal-value">
                  {{ saleDetails.subTotal | money }}
                </td>
              </tr>

              <OrderDeductionsRow
                v-if="orderDetails.saleDeductions && orderDetails.saleDeductions.length > 0"
                :sale-deductions="orderDetails.saleDeductions"
              />
              <tr v-else>
                <td colspan="2">
                  -
                </td>
                <td class="right-align">
                  -
                </td>
              </tr>

              <tr class="sub-title">
                <td colspan="2">
                  Total Amount
                </td>
                <td class="right-align total-amount-value">
                  {{ saleDetails.totalAmount | money }}
                </td>
              </tr>
              <tr><td /></tr>
              <tr><td /></tr>
            </table>
          </div>
        </div>

        <OrderLookupTable
          :sale-details="saleDetails"
          :payment-details="paymentDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import { callGetApi } from "@/httpClient";
  import OrderLookupTable from "@/components/dashboard/salebooks/OrderLookupTable.vue";
  import OrderProductSoldRow from "@/components/dashboard/salebooks/OrderProductSoldRow.vue";
  import OrderDeductionsRow from "@/components/dashboard/salebooks/OrderDeductionsRow.vue";

  export default {
    name: "OrderLookup",
    components: {
      OrderDeductionsRow,
      OrderProductSoldRow,
      OrderLookupTable,
      TitleBar
    },
    data() {
      return {
        orderDetails: [],
        saleDetails: [],
        paymentDetails: [],
        orderNumber: ""
      };
    },
    methods: {
      clearSearch() {
        this.orderNumber = "";
        this.orderDetails = [];
        this.saleDetails = [];
        this.paymentDetails = [];
      },
      searchOrder() {
        callGetApi(`sales/history/${this.orderNumber}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.orderDetails = res.body.entity;
              this.saleDetails = this.orderDetails.saleDetails;
              this.paymentDetails = this.orderDetails.paymentDetails ?? {};
            } else {
              this.$notify({
                group: "general",
                title: "Order Lookup",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      }
    }
  };
</script>
<style scoped>
.m-b-40 {
  margin-bottom: 40px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: left;
}

th:nth-child(1),
td:nth-child(1) {
  text-align: left;
}

td:nth-child(2),
td:nth-child(3) {
  text-align: right;
}

.payment-status {
  font-size: 18px;
  color: red;
}

.section-title {
  font-size: 20px;
}

.total-amount-value {
  border-top: 1px solid #000;
  position: relative;
}

.total-amount-value::before {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}

.total-amount-value::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}

.subtotal-value {
  border-top: 1px solid #000;
}

.total-row td {
  font-weight: bold;
}

.sub-title {
  font-weight: bold;
}
.paid{
  color: green !important;
}
</style>
