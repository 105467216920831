<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Receipts" />

    <div class="dashboard__main__content__home">
      <div class="dashboard__main__content__home__left">
        <div class="balance">
          <div class="balance__value">
            <form @submit.prevent="downloadReceipt">
              <div class="form__item">
                <input
                  v-model="tellerRef"
                  type="text"
                  required="required"
                  class="form__input form__input--2 fontNormal width300"
                  placeholder="Enter Tellerpoint Ref"
                >
              </div>

              <button
                type="submit"
                class="btn btn--primary"
                :disabled="submitDisabled"
              >
                Get PDF
                Receipt
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import { callGetApi } from "@/httpClient";

  export default {
    components: {
      TitleBar
    },
    data() {
      return {
        submitDisabled: false,
        tellerRef: "309C656DFAFB609"
      };
    },
    methods: {
      showFile(blob) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = data;
        link.download = "receipt.pdf";
        link.click();
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
      downloadReceipt() {
        this.submitDisabled = true;
        const headers= new Headers({
          "Content-Type": "application/pdf"
        });
        callGetApi(`cashout/receipt/${this.tellerRef}`, { headers })
          .then(res => {
            this.showFile(res);
            this.submitDisabled = false;
          })
          .catch(err => {
            console.error(err);
            this.submitDisabled = false;
          });
      }
    }
  };
</script>
