<template>
  <div class="dashboard__main__content__container">
    <title-bar title="BVN Details" />

    <div class="dashboard__main__content__home dashboard__main__content__home__agents dashboard__main__content__home__agents-individual">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__content transaction__content__agents-individual">
            <div class="transaction__content__agents-individual__left">
              <div v-if="bvnDetails.photoUrl != null">
                <img
                  :src="bvnDetails.photoUrl"
                  class="form__input capitalize fontNormal"
                  :alt="bvnDetails.photoUrl"
                >
              </div>
            </div>
            <div class="transaction__content__agents-individual__right">
              <div
                class="card-details__content"
              >
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >First Name</label>
                  <input
                    :value="bvnDetails.firstName"
                    class="form__input capitalize fontNormal"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Last Name</label>
                  <input
                    :value="bvnDetails.lastName"
                    class="form__input capitalize fontNormal"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Middle Name</label>
                  <input
                    :value="bvnDetails.middleName"
                    class="form__input capitalize fontNormal"
                    disabled
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >BVN</label>
                  <input
                    :value="bvnDetails.bvn"
                    class="form__input capitalize fontNormal"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Phone Number</label>
                  <input
                    :value="bvnDetails.phoneNumber"
                    class="form__input capitalize fontNormal"
                    disabled
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Date of Birth</label>
                  <input
                    :value="bvnDetails.dateOfBirth | date"
                    class="form__input capitalize fontNormal"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Bank Name</label>
                  <input
                    :value="bvnDetails.enrollmentBank ? getBankName(bvnDetails.enrollmentBank): ''"
                    class="form__input capitalize fontNormal"
                    disabled
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Enrollment Branch</label>
                  <input
                    :value="bvnDetails.enrollmentBranch"
                    class="form__input capitalize fontNormal"
                    disabled
                    type="text"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import { callGetApi } from "@/httpClient";

  export default {
    name: "BvnDetails",
    components: {
      TitleBar
    },
    data() {
      return {
        bvnDetails: {},
        banks: this.$store.getters.getBanksList
      };
    },
    created() {
      this.getBvnDetails();
    },
    methods: {
      getBvnDetails() {
        callGetApi(`bvn/resolve/${this.$route.params.agentId}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.bvnDetails = res.body.entity;
              this.appendImageMetaData();
            } else {
              this.$notify({
                group: "general",
                title: "BVN Details",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      getBankName(value) {
        let bank = this.banks;
        bank = bank.find(item => item.Code === value);
        return bank.Name;
      }
    }
  };
</script>

<style scoped>
  img {
    margin-top: 10px;
    border-style: none;
    width: 100%;
    height: 100%;
  }

  .transaction__content__agents-individual__left {
    width: 50%;
  }
</style>
