<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Wallet Transfer">
      <records-filter
        v-click-outside="closeDropdown"
        data="wallet-log"
        :selected="['date']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No transaction performed by agents today. See
                    <router-link
                      class="empty__state__link"
                      :to="{ name: 'wallet-log', query: { currentPage: '1' }}"
                    >
                      all
                      transactions
                    </router-link>
                    instead
                  </div>
                </template>
              </div>

              <div v-else>
                <div class="table table--padded">
                  <div class="table__section table__section--head">
                    <div class="table__row">
                      <div class="table__header">
                        Transaction Date
                      </div>
                      <div class="table__header">
                        Beneficiary Agent
                      </div>
                      <div class="table__header">
                        Tlp Ref
                      </div>
                      <div class="table__header">
                        Amount
                      </div>
                      <div class="table__header">
                        Status
                      </div>
                      <div class="table__header" />
                    </div>
                  </div>
                  <div class="table__section table__section--body">
                    <template v-for="(item, i) in cashout">
                      <div
                        :key="i"
                        class="table__row"
                      >
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            {{ item.transactionDate | date }}
                          </p>
                        </div>
                        <div class="table__data">
                          {{ item.destinationAgentName }} |
                          {{ item.destinationAccountNumber }}
                        </div>
                        <div class="table__data">
                          {{ item.tellerpointRef }}
                        </div>
                        <div class="table__data">
                          {{ item.amount | money }}
                        </div>
                        <div class="table__data">
                          <span
                            v-if="item.status === 'SUCCESSFUL'"
                            class="tag tag--green"
                          >Successful</span>
                          <span
                            v-if="item.status === 'PROCESSING'"
                            class="tag tag--yellow"
                          >Processing</span>
                          <span
                            v-if="item.status === 'REVERSED' || item.status === 'REFUNDED'"
                            class="tag tag--yellow"
                          >
                            <template v-if="item.status === 'REVERSED'">Reversed</template>
                            <template v-else>Refunded</template>
                          </span>
                          <span
                            v-if="item.status === 'CANCELED' || item.status === 'FAILED'"
                            class="tag tag--red"
                          >
                            <template v-if="item.status === 'CANCELED'">Declined</template>
                            <template v-else>Failed</template>
                          </span>
                        </div>
                        <div class="table__data">
                          <span @click="toggleTable(item.tellerpointRef)">
                            <svg
                              height="14"
                              viewBox="0 0 90 90"
                              width="14"
                              xmlns="http://www.w3.org/2000/svg"
                            ><path
                              d="m80 0h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10v-70c0-5.5-4.5-10-10-10zm6 80c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6v-70c0-3.3 2.7-6 6-6h70c3.3 0 6 2.7 6 6zm-12-62.4v.4 14c0 1.1-.9 2-2 2s-2-.9-2-2v-9.2l-14.6 14.6c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14.6-14.6h-9.2c-1.1 0-2-.9-2-2s.9-2 2-2h14 .4c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1.2.1.4.3.6.6 0 0 .1.1.1.2s.1.1.1.2 0 .1.1.2c-.1-.1-.1-.1 0 0zm-36.6 35c.8.8.8 2 0 2.8l-14.6 14.6h9.2c1.1 0 2 .9 2 2s-.9 2-2 2h-14c-.1 0-.3 0-.4 0s-.1 0-.2-.1c-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.1-.4-.3-.6-.6 0 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1 0-.1-.1-.2 0-.1 0-.3 0-.4v-13.9c0-1.1.9-2 2-2s2 .9 2 2v9.2l14.6-14.6c1-.8 2.2-.8 3 0z"
                            /></svg>
                          </span>
                        </div>
                      </div>

                      <div
                        v-if="opened.includes(item.tellerpointRef)"
                        :key="item.tellerpointRef"
                        class="collapsible-table"
                      >
                        <div class="card-details__content">
                          <div class="form__item">
                            <label
                              for=""
                              class="form__label form__label--2"
                            >Source Agent</label>
                            <input
                              type="text"
                              class="form__input capitalize"
                              :value="item.agentName ? item.agentName : '--'"
                              disabled
                            >
                          </div>
                          <div class="form__item">
                            <label
                              for=""
                              class="form__label form__label--2"
                            >Source Account
                              Number</label>
                            <input
                              type="text"
                              class="form__input capitalize"
                              :value="item.agentAccountNumber ? item.agentAccountNumber : '--'"
                              disabled
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <pagination
                  data="wallet-log"
                  :page-number="computedPageNumbers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="mapTerminal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mapTerminalLabel"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              2058KD12
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
              >
                <path
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form class="form">
              <div class="form__pad">
                <div class="form__item">
                  <div class="select select--sm">
                    <select class="select__input select__input--noborder">
                      <option
                        value="today"
                        selected
                      >
                        Select Agent
                      </option>
                    </select>
                    <div class="select__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                      >
                        <path
                          fill="#637381"
                          fill-rule="evenodd"
                          d="M6.03 3.03L3.025.025.02 3.03h6.01zM5.88 7.071L2.975 9.975.07 7.07H5.88z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form__footer">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Map to Agent
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      id="uploadTerminals"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="uploadTerminalsLabel"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Upload Terminals
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
              >
                <path
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form class="form">
              <div class="form__pad" />

              <div class="form__footer">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                >
                  Upload Terminals
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "@/components/Search";
  import RecordsFilter from "@/components/Filter";
  import { dropdownOperations } from "@/mixins/DropdownOperations";
  import moment from "moment";
  import { callGetApi } from "@/httpClient";

  export default {
    components: {
      TitleBar,
      Pagination,
      Search,
      RecordsFilter
    },
    mixins: [dropdownOperations],
    data() {
      return {
        cashout: "",
        loading: true,
        searchPlaceholder: "Search by tlp ref, agent id",
        opened: [],
        pageNumber: "",
        noData: false
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("walletlogPage")) {
          return JSON.parse(window.localStorage.getItem("walletlogPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      "$route": "fetchCashOuts"
    },
    created() {
      this.fetchCashOuts();
    },
    methods: {
      clearSearch() {
        let from = moment(this.from).format("YYYY-MM-DD HH:mm:ss ZZ");
        let to = moment(this.to).format("YYYY-MM-DD HH:mm:ss ZZ");

        // Make sure that the from date has the time set to 00:00:00. this is done by using the .replace function below
        const newdate = from;
        const time = newdate.substring(11, 19);
        from = from.replace(time, "00:00:00");

        to = to.replace(time, "23:59:59");

        from = encodeURIComponent(from);
        to = encodeURIComponent(to);

        this.$router.push({ name: "wallet-log", query: { currentPage: 1, start: from, end: to } });
      },
      fetchCashOuts() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi(`wallet-transfer?batchSize=20&${query_params}`)
          .then(res => {
            this.noData = res.body.entity.length === 0;
            this.cashout = res.body.entity;
            this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            window.localStorage.setItem("walletlogPage", Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize));

            this.loading = false;
          })
          .catch(console.error);
      },
      searchQuery(criteria) {
        this.$router.push({ name: "wallet-log", query: { criteria } });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");

      }
    }
  };
</script>
