<template>
  <div
    :id="id"
    :aria-labelledby="`${id}Label`"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal__dialog modal__dialog--sm"
      role="document"
    >
      <div class="modal__content">
        <div class="modal__header">
          <div class="modal__title text-center">
            {{ header }}
          </div>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <svg
              height="13"
              width="13"
            >
              <use xlink:href="../assets/icons/close-dropdown.svg#close_dropdown" />
            </svg>
          </button>
        </div>
        <div class="modal__body modal__body--pad">
          <form
            class="form"
            @submit.prevent="onSubmit"
          >
            <div class="form__pad">
              <p class="m-b-20 textCenter">
                {{ body }}
              </p>
            </div>

            <div class="form__footer">
              <button
                class="btn btn--primary btn--md btn--block"
                type="submit"
                :disabled="disableButton"
              >
                {{ buttonText }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ConfirmationModal",
    props: {
      id: {
        type: String,
        required: true
      },
      header: {
        type: String,
        required: true
      },
      body: {
        type: String,
        required: true
      },
      buttonText: {
        type: String,
        required: true
      },
      onSubmit: {
        type: Function,
        required: true
      },
      disableButton: {
        type: Boolean,
        default: false
      },
    },
  };
</script>
