<template>
  <div class="table table--padded">
    <div class="table__section table__section--head">
      <div class="table__row">
        <div class="table__header">
          Transaction Date
        </div>
        <div class="table__header">
          Source Bank
        </div>
        <div class="table__header">
          Merchant
        </div>
        <div class="table__header">
          Order Number
        </div>
        <div class="table__header">
          Amount
        </div>
        <div class="table__header">
          Status
        </div>
        <div class="table__header" />
        <div class="table__header" />
      </div>
    </div>
    <div class="table__section table__section--body">
      <template v-for="(item, i) in bankTransferSales">
        <div
          :key="i"
          class="table__row"
        >
          <div class="table__data table__data--full">
            <p class="table__data--main">
              {{ item.saleDetails.creationTime | date }}
            </p>
          </div>
          <div class="table__data table__data--full">
            <p class="table__data--main">
              {{ item.paymentDetails.sourceBank }}
            </p>
          </div>
          <div class="table__data table__data--full">
            <p class="table__data--main">
              {{ item.paymentDetails.merchantName }}
            </p>
          </div>
          <div class="table__data table__data--full">
            <p class="table__data--main">
              {{ item.saleDetails.id }}
            </p>
          </div>
          <div class="table__data">
            {{ item.paymentDetails.amount | money }}
          </div>
          <div class="table__data">
            <span
              v-if="item.paymentDetails.status === 'SUCCESSFUL'"
              class="tag tag--green"
            >
              Successful
            </span>
            <span
              v-if="item.paymentDetails.status === 'PROCESSING'"
              class="tag tag--yellow"
            >
              Processing
            </span>
            <span
              v-if="item.paymentDetails.status === 'REVERSED'"
              class="tag tag--gray"
            >
              Reversed
            </span>
            <span
              v-if="item.paymentDetails.status === 'FAILED'"
              class="tag tag--red"
            >
              Failed
            </span>
          </div>
          <div class="table__data posRelative width130">
            <download-receipt-options
              v-if="(item.saleDetails.status === 'PAID' || item.saleDetails.status === 'CANCELED')
                && isAnyOperationPermitted(['fetchSalesReceipt'])"
              class="cursor-pointer"
              receipt-type="sales"
              @download-pdf="downloadReceipt(item)"
              @download-pdf-as-image="downloadReceiptAsImage(item)"
            />
          </div>
          <div class="table__data">
            <span @click="toggleTable(item.saleDetails.id)">
              <svg
                height="14"
                width="14"
              >
                <use xlink:href="../../../assets/icons/expand-icon.svg#expand_icon" />
              </svg>
            </span>
          </div>
        </div>
        <div
          v-if="opened.includes(item.saleDetails.id)"
          :key="item.saleDetails.id"
          class="collapsible-table"
        >
          <div class="card-details__content">
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Tlp Ref</label>
              <input
                :value="item.paymentDetails.transactionId ? item.paymentDetails.transactionId : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Source Account
                Number</label>
              <input
                :value="item.paymentDetails.sourceNuban ? item.paymentDetails.sourceNuban : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Source Account
                Name</label>
              <input
                :value="item.paymentDetails.sourceAccountName ? item.paymentDetails.sourceAccountName : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Provider</label>
              <input
                :value="item.paymentDetails.providerName ? item.paymentDetails.providerName : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Provider Ref</label>
              <input
                :value="item.paymentDetails.paymentRef ? item.paymentDetails.paymentRef : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Virtual NUBAN</label>
              <input
                :value="item.paymentDetails.virtualNuban ? item.paymentDetails.virtualNuban : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Session ID</label>
              <input
                :value="item.paymentDetails.sessionId ? item.paymentDetails.sessionId : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import { dropdownOperations, providerResponseOperations } from "@/mixins/DropdownOperations";
  import DownloadReceiptOptions from "@/components/DownloadReceiptOptions.vue";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  import adminClient from "@/api/AdminClient";
  import { savePdfAsImage } from "@/downloads/pdfToImage";

  export default {
    name: "BankTransferSalesTable",
    components: { DownloadReceiptOptions },
    mixins: [providerResponseOperations, dropdownOperations, AdminPermissionsHelper],
    props: {
      bankTransferSales: {
        type : Array,
        required: true
      },
      loading: {
        type : Boolean,
        default: false,
        required: true
      }
    },
    data() {
      return {
        opened: [],
        selectedObject: "",
        refundFundWalletDisabled: false
      };
    },
    methods: {
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      async downloadReceipt(item) {
        this.loading = true;
        await adminClient.fetchReceiptUrl(item.saleDetails.id, "sales")
          .then(fileUrl => {
            const link = document.createElement("a");
            link.href = fileUrl;
            link.download = item.saleDetails.id || "--";
            link.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(fileUrl);
            }, 100);
            this.loading = false;
          })
          .catch(error => {
            console.error(error);
            this.loading = false;
          });
      },
      async downloadReceiptAsImage(item) {
        this.loading = true;
        try {
          const fileUrl = await adminClient.fetchReceiptUrl(item.saleDetails.id, "sales");
          await savePdfAsImage(fileUrl, item.saleDetails.id);
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    }
  };
</script>
