<template>
  <div class="dashboard__main__content__container">
    <title-bar title="EFT - Bank Transfer Sales">
      <records-filter
        v-click-outside="closeDropdown"
        :selected="['date']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>
            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No transaction performed by agents today. See
                    <router-link
                      :to="{ name: 'bank-transfer-sales', query: { currentPage: '1' } }"
                      class="empty__state__link"
                    >
                      all transactions
                    </router-link>
                    instead
                  </div>
                </template>
              </div>

              <div v-else>
                <BankTransferSalesTable
                  :bank-transfer-sales="bankTransferSales"
                  :loading="loading"
                  :opened="opened.includes(selectedObject.tellerpointRef)"
                />

                <pagination
                  :page-number="computedPageNumbers"
                  data="bank-transfer-sales"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "@/components/Search";
  import moment from "moment";
  import { dropdownOperations, providerResponseOperations } from "@/mixins/DropdownOperations";
  import RecordsFilter from "@/components/Filter";
  import { callGetApi } from "@/httpClient";
  import BankTransferSalesTable from "@/components/dashboard/salebooks/BankTransferSalesTable.vue";

  export default {
    components: {
      BankTransferSalesTable,
      TitleBar,
      Pagination,
      Search,
      RecordsFilter
    },
    mixins: [providerResponseOperations, dropdownOperations],
    data() {
      return {
        bankTransferSales: [],
        loading: true,
        disableModalButton: false,
        searchPlaceholder: "Search by order number, source account number, provider ref, tlp ref, nuban, session id",
        opened: [],
        pageNumber: "",
        noData: false
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("bankTransferSalesPage")) {
          return JSON.parse(window.localStorage.getItem("bankTransferSalesPage"));
        } else {
          return "";
        }
      },
    },
    watch: {
      $route: "fetchBankTransferSales"
    },
    created() {
      this.fetchBankTransferSales();
    },
    methods: {
      clearSearch() {
        let from = moment(this.from).format("YYYY-MM-DD HH:mm:ss ZZ");
        let to = moment(this.to).format("YYYY-MM-DD HH:mm:ss ZZ");

        const newdate = from;
        const time = newdate.substring(11, 19);
        from = from.replace(time, "00:00:00");
        to = to.replace(time, "23:59:59");

        from = encodeURIComponent(from);
        to = encodeURIComponent(to);

        this.$router.push({
          name: "bank-transfer-sales",
          query: { currentPage: 1, start: from, end: to }
        });
      },
      fetchBankTransferSales() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi("sales/history/virtual-account?batchSize=20&" + query_params)
          .then(res => {
            this.noData = res.body.entity.length === 0;
            this.bankTransferSales = res.body.entity;
            this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            window.localStorage.setItem(
              "bankTransferSalesPage",
              Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize)
            );
            this.loading = false;
          })
          .catch(console.error);
      },
      searchQuery(criteria) {
        this.$router.push({
          name: "bank-transfer-sales",
          query: { criteria }
        });
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      }
    }
  };
</script>
