<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Agent Summary">
      <records-filter
        v-click-outside="closeDropdown"
        data="agent-summary"
        :selected="['date','name','transactiontype']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <!-- Table Data -->
              <div class="table table--padded">
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      #
                    </div>
                    <div class="table__header">
                      Signup Date
                    </div>
                    <div class="table__header">
                      Agent Name
                    </div>
                    <div class="table__header">
                      Account Manager
                    </div>
                    <div class="table__header">
                      Count
                    </div>
                    <div class="table__header">
                      Value
                    </div>
                  </div>
                </div>
                <div class="table__section table__section--body">
                  <div
                    v-for="(item, i) in transactions"
                    :key="i"
                    class="table__row"
                  >
                    <div class="table__data table__data--full">
                      {{ i + 1 }}
                    </div>
                    <div class="table__data table__data--full">
                      <p class="table__data--main">
                        {{ item.signUpDate | date }}
                      </p>
                    </div>
                    <div class="table__data table__data--full">
                      <p class="table__data--main">
                        {{ item.businessName }}
                      </p>
                    </div>
                    <div class="table__data">
                      <p class="table__data--main">
                        {{ item.accountManager }}
                      </p>
                    </div>
                    <div class="table__data">
                      {{ item.count }}
                    </div>
                    <div class="table__data">
                      {{ item.value | money }}
                    </div>
                  </div>
                </div>
              </div>

              <pagination
                data="agent-summary"
                :page-number="computedPageNumbers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import RecordsFilter from "@/components/Filter";
  import Pagination from "@/components/Pagination";
  import { dropdownOperations } from "@/mixins/DropdownOperations";
  import moment from "moment";
  import { callGetApi } from "@/httpClient";

  export default {
    components: {
      TitleBar,
      RecordsFilter,
      Pagination
    },
    mixins: [
      dropdownOperations
    ],
    data() {
      return {
        transactions: "",
        loading: true,
        to: moment().format(),
        from: moment().format(),
        businessName: "",
        partnerOrg: "",
        transactionType: "CASHOUT",
        sort: "DESC",
        // custom lang
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          pickers: [
            "next 7 days",
            "next 30 days",
            "previous 7 days",
            "previous 30 days"
          ],
          placeholder: {
            date: "Select Date",
            dateRange: "Select Date Range"
          }
        },
        // custom range shortcuts
        shortcuts: [
          {
            text: "Today",
            onClick: () => {
              this.time3 = [new Date(), new Date()];
            }
          }
        ],
        timePickerOptions: {
          start: "00:00",
          step: "00:30",
          end: "23:30"
        },
        pageNumber: ""
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("agenttransactionsPage")) {
          return JSON.parse(window.localStorage.getItem("agenttransactionsPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      $route: "fetchAgents"
    },
    created() {
      this.fetchAgents();
    },
    methods: {
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      },
      fetchAgents() {
        this.loading = true;
        // Page number variable
        let params;

        const allQuery = this.$route.query;

        if (allQuery.start && allQuery.end) {
          // Make sure that the from date has the time set to 00:00:00. this is done by using the .replace function below
          let newdate = decodeURIComponent(allQuery.start);
          let time = newdate.substring(11, 19);
          allQuery.start = encodeURIComponent(newdate.replace(time, "00:00:00"));

          const newdateend = decodeURIComponent(allQuery.end);
          const timeend = newdateend.substring(11, 19);
          allQuery.end = encodeURIComponent(
            newdateend.replace(timeend, "23:59:59")
          );
        } else {
          let from = moment(this.from).format("YYYY-MM-DD HH:mm:ss ZZ");
          let to = moment(this.to).format("YYYY-MM-DD HH:mm:ss ZZ");
          // Make sure that the from date has the time set to 00:00:00. this is done by using the .replace function below
          const newdate = from;
          const time = newdate.substring(11, 19);
          from = from.replace(time, "00:00:00");

          const newdateto = to;
          const timeto = newdateto.substring(11, 19);
          to = to.replace(timeto, "23:59:59");

          allQuery.start = encodeURIComponent(from);
          allQuery.end = encodeURIComponent(to);
        }

        if (allQuery.partnerOrg) allQuery.criteria = allQuery.partnerOrg;
        else if (allQuery.businessName) allQuery.criteria = allQuery.businessName;

        delete allQuery.businessName;
        delete allQuery.partnerOrg;

        params = "?" + this.convertToQueryString(allQuery);

        callGetApi(`merchant/transactions${params}batchSize=20`)
          .then(res => {
            this.loading = false;

            if (res.body.status.code === 0) {
              this.transactions = res.body.entity.merchantTransaction;
              this.pageNumber = Math.ceil(
                res.body.pageMetaData.totalNoOfRows /
                  res.body.pageMetaData.batchSize
              );
              window.localStorage.setItem(
                "agenttransactionsPage",
                Math.ceil(
                  res.body.pageMetaData.totalNoOfRows /
                    res.body.pageMetaData.batchSize
                )
              );
            } else {
              this.$notify({
                group: "general",
                title: "Agent Transactions",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      updateDropDownValue(value) {
        this.showDropdown = value;
      }
    }
  };
</script>
