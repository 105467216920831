<template>
  <div
    id="viewMerchants"
    ref="share-modal-ref"
    aria-labelledby="viewMerchantsLabel"
    class="modal fade modal-lg"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal__dialog modal__dialog--lg"
      role="document"
    >
      <div class="modal__content">
        <div class="modal__header">
          <div class="modal__title text-center">
            <span class="text-center">Manage {{ selectedTerminal.terminalId }}</span>
          </div>

          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <svg
              height="13"
              viewBox="0 0 13 13"
              width="13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                fill="#C0C6D8"
                fill-rule="nonzero"
              />
            </svg>
          </button>
        </div>

        <div class="modal__body modal__body--pad">
          <form
            class="form"
            @submit.prevent="mapMerchantToTerminal"
          >
            <div class="form__pad">
              <div class="form__item">
                <v-select
                  v-model="selectedMappingAgent"
                  :options="agents"
                  label="businessName"
                  @search="searchAgentByName"
                >
                  <template>Enter Agent Name</template>
                </v-select>
              </div>
            </div>

            <div class="form__footer">
              <button
                class="btn btn--primary btn--md btn--block"
                type="submit"
              >
                Map agent
              </button>
            </div>
          </form>
        </div>

        <div class="modal__body modal__body--pad pt-5">
          <div
            v-if="loadingMerchants"
            class="loaderContainer"
          >
            <div class="loader" />
          </div>

          <div
            v-else
            class="table table--padded"
          >
            <div class="table__section table__section--head">
              <div class="table__row">
                <div class="table__header">
                  Mapped agents
                </div>
                <div class="table__header">
                  Remove agent
                </div>
              </div>
            </div>
            <div class="table__section table__section--body">
              <template v-for="(item, i) in terminalMerchants">
                <div
                  :key="i"
                  class="table__row"
                >
                  <div class="table__data">
                    {{ item.businessName ? item.businessName : "--" }}
                  </div>
                  <div class="table__data pl-50">
                    <span
                      v-if="isAnyOperationPermitted(['removeMerchantFromTerminal'])"
                      title="Remove Merchant from terminal"
                      @click="removeMerchantFromMappedTerminal(item)"
                    >
                      <cancel-icon />
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  import { callDeleteApi, callPutApi } from "@/httpClient";
  import vSelect from "vue-select";
  import CancelIcon from "@/components/icons/CancelIcon.vue";

  export default {
    name: "MapAgentToTerminalModal",
    components: { CancelIcon, vSelect },
    mixins: [AdminPermissionsHelper],
    props: {
      agents: {
        type : Array,
        required: true
      },
      loadingMerchants: {
        type : Boolean,
        default: false,
        required: true
      },
      selectedTerminal: {
        type : Object,
        required: true
      },
      getMerchantsMappedToTerminal: {
        type : Function,
        required: true
      },
      setMappedAgent: {
        type : Function,
        required: true
      },
      getTerminals: {
        type : Function,
        required: true
      },
      terminalMerchants: {
        type : Array,
        required: true
      },
      searchAgentByName: {
        type : Function,
        required: true
      }
    },
    data: () => {
      return {
        selectedMappingAgent: {}
      };
    },
    watch: {
      selectedMappingAgent: function(newValue) {
        this.setMappedAgent(newValue);
      }
    },
    methods: {
      mapMerchantToTerminal() {
        if (this.terminalMerchants.find(agent => agent.id === this.selectedMappingAgent.id)) {
          this.$notify({
            group: "general",
            title: "Map Agent to Terminal",
            type: "warn",
            text: `"${this.selectedMappingAgent.businessName}" already mapped to this terminal`
          });
          return;
        }
        callPutApi(`ptsa/terminals/${this.selectedTerminal.terminalId}/${this.selectedMappingAgent.id}`)
          .then(res => {
            if (res.body.status.code === 0) {
              $("#addMerchantToTerminal").modal("hide");
              this.getMerchantsMappedToTerminal();
              this.getTerminals();
              this.$notify({
                group: "general",
                title: "Map Agent to Terminal",
                text: "Agent mapped to terminal successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Map Agent to Terminal",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      removeMerchantFromMappedTerminal(selectedAgent) {
        callDeleteApi(`ptsa/terminals/${this.selectedTerminal.terminalId}/${selectedAgent.id}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.getMerchantsMappedToTerminal();
              this.getTerminals();
              this.$notify({
                group: "general",
                title: "Remove Mapped Agent",
                text: "Agent removed from terminal successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Remove Mapped Agent",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      }
    }
  };
</script>
<style scoped>
  .pl-50 {
    padding-left: 50px;
  }

  .pt-5 {
    padding-top: 5px;
  }
</style>
