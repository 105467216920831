<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Stats">
      <button
        class="btn btn--primary m-r-10"
        @click="fetchAllStats(true)"
      >
        Refresh Stats
      </button>
      <records-filter
        v-click-outside="closeDropdown"
        data="stats"
        :selected="['date']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home__left dashboard__main__content__home__left__provider">
      <stats-card
        v-for="([type, stats]) in getOrderedStatistics"
        :key="type"
        :header="titles[type].heading"
        :presentation-filter="titles[type].filter"
        :sub-title="titles[type].subtitle"
        :time="stats.time"
        :title="titles[type].title"
        :total-count="stats.totalCount"
        :total-value="stats.totalValue"
        @fetch-stats="fetchStats(type)"
      />
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import StatsCard from "@/components/StatsCard";
  import moment from "moment";
  import RecordsFilter from "@/components/Filter";
  import { dropdownOperations } from "@/mixins/DropdownOperations";
  import { mapGetters } from "vuex";
  import { callGetApi } from "@/httpClient";

  const transactionType = Object.freeze({
    BILL_PAYMENT: "BILL_PAYMENT",
    CASHOUT: "CASHOUT",
    DISBURSE_TO_BANK: "DISBURSE_TO_BANK",
    DISPENSE_ERROR_REFUND: "DISPENSE_ERROR_REFUND",
    FUND_WALLET: "FUND_WALLET",
    MERCHANT_STATS: "MERCHANT_STATS",
    TERMINAL_STATS: "TERMINAL_STATS",
    WALLET_STATS: "WALLET_STATS",
    EFT_STATS: "EFT_STATS",
  });

  export default {
    components: {
      RecordsFilter,
      TitleBar,
      StatsCard
    },
    mixins: [dropdownOperations],
    data() {
      return {
        transactionType,
        loading: true,
        titles: {
          [transactionType.MERCHANT_STATS]: {
            heading: "Agents",
            title: "VERIFIED AGENTS",
            subtitle: "TOTAL NO OF AGENTS",
            filter: "count"
          },
          [transactionType.WALLET_STATS]: {
            heading: "Agent Wallet",
            title: "",
            subtitle: "AGENTS WALLET BALANCE",
            filter: "money"
          },
          [transactionType.DISPENSE_ERROR_REFUND]: {
            heading: "Unclaimed Disputes",
            title: "UNCLAIMED DISPUTE COUNT",
            subtitle: "UNCLAIMED DISPUTE VALUE",
            filter: "money"
          },
          [transactionType.FUND_WALLET]: {
            heading: "Fund Wallet",
            title: "FUND WALLET COUNT",
            subtitle: "FUND WALLET VALUE",
            filter: "money"
          },
          [transactionType.CASHOUT]: {
            heading: "Cashouts",
            title: "CASHOUT COUNT",
            subtitle: "CASHOUT VALUE",
            filter: "money"
          },
          [transactionType.DISBURSE_TO_BANK]: {
            heading: "Bank Transfer",
            title: "BANK TRANSFER COUNT",
            subtitle: "BANK TRANSFER VALUE",
            filter: "money"
          },
          [transactionType.BILL_PAYMENT]: {
            heading: "Bill Payment",
            title: "BILL PAYMENT COUNT",
            subtitle: "BILL PAYMENT VALUE",
            filter: "money"
          },
          [transactionType.TERMINAL_STATS]: {
            heading: "Terminals",
            title: "REGISTERED TERMINALS",
            subtitle: "ACTIVE TERMINALS",
            filter: "count"
          },
          [transactionType.EFT_STATS]: {
            heading: "EFT Sales",
            title: "EFT SALES COUNT",
            subtitle: "EFT SALES VALUE",
            filter: "money"
          }
        },
      };
    },
    computed: {
      ...mapGetters(["getStatistics", "currentContext"]),
      getOrderedStatistics: function() {
        const entries = Object.entries(this.getStatistics ?? {});
        return entries.sort((a, b) => a[0].localeCompare(b[0]));
      }
    },
    created() {
      this.fetchAllStats();
    },
    methods: {
      getParams() {
        let query_params = this.convertToQueryString(this.$route.query);
        const allquery = this.$route.query;

        if (!allquery.start && !allquery.end) {
          let from = moment(this.from).format("YYYY-MM-DD HH:mm:ss ZZ");
          let to = moment(this.to).format("YYYY-MM-DD HH:mm:ss ZZ");

          // Ensure that the from date has the time set to 00:00:00. This is done by using the .replace function below
          const newdate = from;
          const time = newdate.substring(11, 19);
          from = from.replace(time, "00:00:00");

          const newdateto = to;
          const timeto = newdateto.substring(11, 19);
          to = to.replace(timeto, "23:59:59");

          allquery.start = encodeURIComponent(from);
          allquery.end = encodeURIComponent(to);

          query_params = this.convertToQueryString(allquery);
        }
        return query_params;
      },

      async fetchTransactionStatistics(type, showNotification = false) {
        let query_params = this.getParams();
        const title = this.titles[type].heading;
        callGetApi(`transaction-stats/${type}?${query_params}`)
          .then(res => {
            this.loading = false;
            const stats = res.body.entity;

            this.$store.dispatch("setStatistics", {
              "type": transactionType[type],
              "context": this.currentContext,
              "stats": {
                "totalCount": stats?.totalCount ?? 0,
                "totalValue": stats?.totalValue ?? 0,
                "time": moment().format("MMM Do, YYYY. HH:mm:ss a")
              }
            });

            if (showNotification) {
              this.$notify({
                group: "general",
                title: `Fetch ${title} Stats`,
                text: `Successfully fetched stats for ${title}`
              });
            }
          })
          .catch(error => {
            console.log("error", error);
            const message = error ? error : `Error fetching stats for ${title}`;
            this.$notify({
              group: "general",
              title: `Fetch ${title} Stats`,
              type: "warn",
              text: message
            });
          });
      },
      async fetchTerminalStatistics(showNotification = false) {
        const query_params = this.getParams();
        callGetApi(`terminal-stats?${query_params}`)
          .then(res => {
            this.loading = false;
            const stats = res.body.entity;

            this.$store.dispatch("setStatistics", {
              "type": transactionType.TERMINAL_STATS,
              "context": this.currentContext,
              "stats": {
                "totalCount": stats?.totalNumberOfWhitelistedTerminals ?? 0,
                "totalValue": stats?.totalNumberOfActive ?? 0,
                "time": moment().format("MMM Do, YYYY. HH:mm:ss a")
              }
            });

            if (showNotification) {
              this.$notify({
                group: "general",
                title: "Fetch Terminal Stats",
                text: "Successfully fetched stats for terminals"
              });
            }
          })
          .catch(error => {
            console.log("error", error);
            const message = error ? error : "Error fetching stats for terminals";
            this.$notify({
              group: "general",
              title: "Fetch Terminal Stats",
              type: "warn",
              text: message
            });
          });
      },

      async fetchMerchantStatistics(showNotification = false) {
        const query_params = this.getParams();
        callGetApi(`merchant-stats?${query_params}`)
          .then(res => {
            this.loading = false;
            const stats = res.body.entity;

            this.$store.dispatch("setStatistics", {
              "type": transactionType.MERCHANT_STATS,
              "context": this.currentContext,
              "stats": {
                "totalCount": stats?.totalNumberOfVerifiedMerchants ?? 0,
                "totalValue": stats?.totalNumberOfMerchants ?? 0,
                "time": moment().format("MMM Do, YYYY. HH:mm:ss a")
              }
            });

            if (showNotification) {
              this.$notify({
                group: "general",
                title: "Fetch Merchant Stats",
                text: "Successfully fetched stats for merchants"
              });
            }
          })
          .catch(error => {
            console.log("error", error);
            const message = error ? error : "Error fetching stats for merchants";
            this.$notify({
              group: "general",
              title: "Fetch Merchant Stats",
              type: "warn",
              text: message
            });
          });
      },
      async fetchWalletStatistics(showNotification = false) {
        const query_params = this.getParams();
        callGetApi(`wallet-stats?${query_params}`)
          .then(res => {
            this.loading = false;
            const stats = res.body.entity;

            this.$store.dispatch("setStatistics", {
              "type": transactionType.WALLET_STATS,
              "context": this.currentContext,
              "stats": {
                "totalCount": 0,
                "totalValue": stats ?? 0,
                "time": moment().format("MMM Do, YYYY. HH:mm:ss a")
              }
            });

            if (showNotification) {
              this.$notify({
                group: "general",
                title: "Fetch Wallet Stats",
                text: "Successfully fetched stats for wallet"
              });
            }
          })
          .catch(error => {
            console.log("error", error);
            const message = error ? error : "Error fetching stats for wallet";
            this.$notify({
              group: "general",
              title: "Fetch Wallet Stats",
              type: "warn",
              text: message
            });
          });
      },
      async fetchEftSalesStatistics(showNotification = false) {
        const query_params = this.getParams();
        callGetApi(`sales-stats?${query_params}`)
          .then(res => {
            this.loading = false;
            const stats = res.body.entity;

            this.$store.dispatch("setStatistics", {
              "type": transactionType.EFT_STATS,
              "context": this.currentContext,
              "stats": {
                "totalCount": stats?.count ?? 0,
                "totalValue": stats?.value ?? 0,
                "time": moment().format("MMM Do, YYYY. HH:mm:ss a")
              }
            });

            if (showNotification) {
              this.$notify({
                group: "general",
                title: "Fetch EFT Stats",
                text: "Successfully fetched stats for EFT"
              });
            }
          })
          .catch(error => {
            console.log("error", error);
            const message = error ? error : "Error fetching stats for EFT";
            this.$notify({
              group: "general",
              title: "Fetch EFT Stats",
              type: "warn",
              text: message
            });
          });
      },

      fetchStats(type) {
        switch (type) {
          case transactionType.DISPENSE_ERROR_REFUND:
          case transactionType.FUND_WALLET:
          case transactionType.CASHOUT:
          case transactionType.DISBURSE_TO_BANK:
          case transactionType.BILL_PAYMENT:
            this.fetchTransactionStatistics(type, true);
            break;
          case transactionType.MERCHANT_STATS:
            this.fetchMerchantStatistics(true);
            break;
          case transactionType.WALLET_STATS:
            this.fetchWalletStatistics(true);
            break;
          case transactionType.TERMINAL_STATS:
            this.fetchTerminalStatistics(true);
            break;
          case transactionType.EFT_STATS:
            this.fetchEftSalesStatistics(true);
            break;
        }
      },
      fetchAllStats(forceRefresh) {
        const hasStatsData = Object.keys(this.getStatistics ?? {}).length > 0;
        if (hasStatsData && !forceRefresh) return;

        this.fetchMerchantStatistics();
        this.fetchWalletStatistics();
        this.fetchTransactionStatistics(transactionType.DISPENSE_ERROR_REFUND);
        this.fetchTransactionStatistics(transactionType.FUND_WALLET);
        this.fetchTransactionStatistics(transactionType.CASHOUT);
        this.fetchTransactionStatistics(transactionType.DISBURSE_TO_BANK);
        this.fetchTransactionStatistics(transactionType.BILL_PAYMENT);
        this.fetchEftSalesStatistics();
        this.fetchTerminalStatistics();
      },
      updateDropDownValue(value) {
        this.showDropdown = value;
        if (!value) {
          this.fetchAllStats(true);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      }
    }
  };
</script>
