<template>
  <tbody>
    <tr
      v-for="(item, i) in productsSold"
      :key="i"
    >
      <td>{{ item.name }}</td>
      <td class="left-align">
        {{ item.quantity }} x {{ item.sellingPrice | money }}
      </td>
      <td class="right-align">
        {{ item.totalAmount | money }}
      </td>
    </tr>
  </tbody>
</template>


<script>
  export default {
    name: "OrderProductSoldRow",
    props: {
      productsSold: {
        type : Array,
        default: undefined
      }
    }
  };
</script>
<style scoped>
td {
  padding: 12px;
  text-align: left;
}

td:nth-child(2),
td:nth-child(3) {
  text-align: right;
}

.total-row td {
  font-weight: bold;
}

.left-align {
  text-align: justify !important;
}
</style>
