import Vue from "vue";
import Router from "vue-router";
import Login from "./views/accounts/Login";
import Forgot from "./views/accounts/ForgotPassword";

Vue.use(Router);

const landingPage = { name: "dashboard" };

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
  routes: [
    // Pre-login chunk.
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/forgot",
      name: "forgot",
      component: Forgot
    },

    // Application chunk.
    {
      path: "/",
      name: "landing-page",
      component: () => import(/* webpackChunkName: "application" */ "./views/Index"),
      redirect: landingPage,
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/Dashboard.vue")
        },
        {
          path: "stats",
          name: "stats",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/Stats")
        },
        {
          path: "settings",
          name: "settings",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/settings/Main")
        },
        {
          path: "agents",
          name: "agents",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/Agents")
        },
        {
          path: "agents/:id",
          name: "agents-individual",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/AgentsIndividual")
        },
        {
          path: "new-merchant",
          name: "new-merchant",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/NewMerchant")
        },
        {
          path: "agent-summary",
          name: "agent-summary",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/AgentTransactions")
        },
        {
          path: "finance-report",
          name: "finance-report",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/FinanceReport")
        },
        {
          path: "cashin",
          name: "cashin",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/CashIn")
        },
        {
          path: "cashout",
          name: "cashout",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/CashOut")
        },
        {
          path: "failed-transfers",
          name: "failed-transfers",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/FailedTransfers")
        },
        {
          path: "wallet-log",
          name: "wallet-log",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/WalletLog")
        },
        {
          path: "manage-terminals",
          name: "manage-terminals",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/ManageTerminals")
        },
        {
          path: "terminal-health",
          name: "terminal-health",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/TerminalHealth")
        },
        {
          path: "new-terminal/:terminalType",
          name: "new-terminal",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/NewTerminal")
        },
        {
          path: "receipts",
          name: "receipts",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/Receipts")
        },
        {
          path: "balance-enquiry",
          name: "balance-enquiry",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/ServiceProviderBalance")
        },
        {
          path: "disable-services",
          name: "disable-services",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/DisableTransfers")
        },
        {
          path: "services",
          name: "services",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/Services")
        },
        {
          path: "refunds",
          name: "refunds",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/Refund")
        },
        {
          path: "fund-wallet",
          name: "fund-wallet",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/FundWallet")
        },
        {
          path: "transaction-lookup",
          name: "transaction-lookup",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/TransactionLookup")
        },
        {
          path: "messaging",
          name: "messaging",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/Messaging")
        },
        {
          path: "agent-wallet-history",
          name: "agent-wallet-history",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/AgentWalletHistory")
        },
        {
          path: "bvn-details/:agentId",
          name: "bvn-details",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/BvnDetails")
        },
        {
          path: "tellerpoint-tms",
          name: "tellerpoint-tms",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/TellerpointTms")
        },
        {
          path: "fund-sweep",
          name: "fund-sweep",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/FundSweep")
        },
        {
          path: "admins",
          name: "admins",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/Admins")
        },
        {
          path: "admins/:id",
          name: "admin-permissions",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/AdminPermissions")
        },
        {
          path: "account-managers",
          name: "account-managers",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/AccountManagers")
        },
        {
          path: "revenue-summary",
          name: "revenue-summary",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/RevenueSummary")
        },
        {
          path: "bill-payment",
          name: "bill-payment",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/BillPayment")
        },
        {
          path: "terminal-stock",
          name: "terminal-stock",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/TerminalStock")
        },
        {
          path: "terminal-app",
          name: "terminal-app",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/TerminalApp")
        },
        {
          path: "disbursements/processing",
          name: "pending-bank-transfer",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/PendingBankTransfer")
        },
        {
          path: "rewards/store",
          name: "rewards-store",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/RewardsStore")
        },
        {
          path: "rewards/redemption",
          name: "rewards-redemption",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/RewardsRedemption")
        },
        {
          path: "receipt-reports",
          name: "receipt-reports",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/ReceiptReports")
        },
        {
          path: "card-sales",
          name: "card-sales",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/CardSales")
        },
        {
          path: "bank-transfer-sales",
          name: "bank-transfer-sales",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/BankTransferSales")
        },
        {
          path: "order-lookup",
          name: "order-lookup",
          component: () => import(/* webpackChunkName: "application" */ "./views/dashboard/OrderLookup")
        }
      ]
    }
  ]
});
