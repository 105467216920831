<template>
  <div
    :class="{ 'sidebar-hidden': drawerStatus }"
    class="sidebar"
  >
    <sidebar-menu-item
      menu-name="Failed Transfers"
      router-name="failed-transfers"
      :query="{ currentPage: '1', start: from, end: to }"
      :required-permissions="['getWebhookFailedTransfers', 'autoRefundFailedBankDisbursement']"
    />

    <sidebar-menu-item
      menu-name="Pending Transfers"
      router-name="pending-bank-transfer"
      :query="{ currentPage: '1', start: from, end: to }"
      :required-permissions="['getPendingTransfers', 'resetRetryCountForDisbursementsInProcessing']"
    />

    <sidebar-menu-item
      menu-name="Stats"
      router-name="stats"
      :required-permissions="[
        'getMerchantStatistics',
        'getTerminalStatistics',
        'getTransactionStatistics',
        'getWalletStatistics'
      ]"
    />

    <sidebar-dropdown
      name="User Management"
      :show-drop-down="showUserManagementDropdown"
      :toggle-dropdown="() => (showUserManagementDropdown = !showUserManagementDropdown)"
      :required-permissions="[
        'getAccountManagers',
        'getAdmin',
        'getMerchants'
      ]"
    >
      <sidebar-menu-item
        menu-name="Agents"
        router-name="agents"
        :query="{ currentPage: '1' }"
        :required-permissions="['getMerchants']"
      />

      <sidebar-menu-item
        menu-name="Admins"
        router-name="admins"
        :query="{ currentPage: '1' }"
        :required-permissions="['getAdmin','registerAdmin']"
      />

      <sidebar-menu-item
        menu-name="Account Managers"
        router-name="account-managers"
        :query="{ currentPage: '1' }"
        :required-permissions="['getAccountManagers', 'registerAccountManager']"
      />
    </sidebar-dropdown>

    <sidebar-menu-item
      menu-name="Agent Summary"
      router-name="agent-summary"
      :query="{ currentPage: '1', start: from, end: to, transactionType: 'CASHOUT', ranking: 'DESC' }"
      :required-permissions="['getTransactionSummary']"
    />
    <sidebar-menu-item
      menu-name="Balance Enquiry"
      router-name="balance-enquiry"
      :required-permissions="['getServiceProviderBalance']"
    />

    <sidebar-dropdown
      :required-permissions="[
        'generateFinanceReport',
        'getAgentWalletHistory',
        'getBankTransfer',
        'getBillPaymentTransactions',
        'getCashouts',
        'getDispenseError',
        'getFundWalletTransactions',
        'getRevenue',
        'getWalletTransfer',
        'uploadDispenseError'
      ]"
      name="Reports"
      :show-drop-down="showReportDropdown"
      :toggle-dropdown="() => (showReportDropdown = !showReportDropdown)"
    >
      <sidebar-menu-item
        menu-name="Cashout"
        router-name="cashout"
        :query="{ currentPage: '1', start: from, end: to }"
        :required-permissions="['getCashouts']"
      />

      <sidebar-menu-item
        menu-name="Bank Transfer"
        router-name="cashin"
        :query="{ currentPage: '1', start: from, end: to }"
        :required-permissions="['getBankTransfer']"
      />

      <sidebar-menu-item
        menu-name="Bill Payment"
        router-name="bill-payment"
        :query="{ start: from, end: to, currentPage: '1' }"
        :required-permissions="['getBillPaymentTransactions']"
      />

      <sidebar-menu-item
        menu-name="Dispense Error"
        router-name="refunds"
        :query="{ currentPage: '1' }"
        :required-permissions="['getDispenseError', 'uploadDispenseError']"
      />

      <sidebar-menu-item
        menu-name="Fund Wallet"
        router-name="fund-wallet"
        :query="{ currentPage: '1', start: from, end: to }"
        :required-permissions="['getFundWalletTransactions']"
      />

      <sidebar-menu-item
        menu-name="Wallet Transfer"
        router-name="wallet-log"
        :query="{ currentPage: '1', start: from, end: to }"
        :required-permissions="['getWalletTransfer']"
      />

      <sidebar-menu-item
        menu-name="Wallet History"
        router-name="agent-wallet-history"
        :query="{ currentPage: '1' }"
        :required-permissions="['getAgentWalletHistory']"
      />

      <sidebar-menu-item
        menu-name="Revenue"
        router-name="revenue-summary"
        :query="{ start: from, end: to }"
        :required-permissions="['getRevenue']"
      />

      <sidebar-menu-item
        menu-name="Finance Report"
        router-name="finance-report"
        :required-permissions="['generateFinanceReport']"
      />
    </sidebar-dropdown>

    <sidebar-dropdown
      :required-permissions="[
        'getCardSalesHistory',
        'getSaleHistoryDetails',
        'getVirtualAccountSalesHistory'
      ]"
      name="Salesbook"
      :show-drop-down="showSalesbookDropdown"
      :toggle-dropdown="() => (showSalesbookDropdown = !showSalesbookDropdown)"
    >
      <sidebar-menu-item
        menu-name="Card Sales"
        router-name="card-sales"
        :query="{ currentPage: '1', start: from, end: to }"
        :required-permissions="['getCardSalesHistory']"
      />
      <sidebar-menu-item
        menu-name="Transfer Sales"
        router-name="bank-transfer-sales"
        :query="{ currentPage: '1', start: from, end: to }"
        :required-permissions="['getVirtualAccountSalesHistory']"
      />
      <sidebar-menu-item
        menu-name="Order Lookup"
        router-name="order-lookup"
        :required-permissions="['getSaleHistoryDetails']"
      />
    </sidebar-dropdown>

    <sidebar-dropdown
      :required-permissions="[
        'currentTerminalAppVersion',
        'getPtsaTerminalHeatlth',
        'getPtsaTerminals',
        'getPtspTerminals',
        'getTerminalStock',
        'transactionLookup',
        'uploadTerminalApplication',
        'uploadTerminalPurchaseStock',
        'whitelistPtsaTerminals',
        'whitelistPtspTerminals'
      ]"
      name="Terminal"
      :show-drop-down="showTerminalDropdown"
      :toggle-dropdown="() => (showTerminalDropdown = !showTerminalDropdown)"
    >
      <sidebar-menu-item
        menu-name="Tellerpoint TMS"
        router-name="tellerpoint-tms"
        :query="{ currentPage: '1' }"
        :required-permissions="['getPtsaTerminals', 'whitelistPtsaTerminals']"
      />

      <sidebar-menu-item
        menu-name="Manage Terminals"
        router-name="manage-terminals"
        :query="{ currentPage: '1' }"
        :required-permissions="['getPtspTerminals', 'whitelistPtspTerminals']"
      />

      <sidebar-menu-item
        menu-name="Terminal Health"
        router-name="terminal-health"
        :query="{ currentPage: '1' }"
        :required-permissions="['getPtsaTerminalHeatlth']"
      />

      <sidebar-menu-item
        menu-name="Terminal Stock"
        router-name="terminal-stock"
        :query="{ currentPage: '1' }"
        :required-permissions="['getTerminalStock', 'uploadTerminalPurchaseStock']"
      />

      <sidebar-menu-item
        menu-name="Terminal App"
        router-name="terminal-app"
        :query="{ currentPage: '1' }"
        :required-permissions="['currentTerminalAppVersion', 'uploadTerminalApplication']"
      />
    </sidebar-dropdown>

    <sidebar-menu-item
      menu-name="Transaction Lookup"
      router-name="transaction-lookup"
      :required-permissions="['transactionLookup']"
    />

    <sidebar-menu-item
      menu-name="Fund Sweep"
      router-name="fund-sweep"
      :query="{ currentPage: '1', start: from, end: to }"
      :required-permissions="['getFundsSweep','sweepProviderFunds']"
    />

    <sidebar-dropdown
      :required-permissions="['getRewards','createRewards']"
      name="Rewards"
      :show-drop-down="showRewardsDropdown"
      :toggle-dropdown="() => (showRewardsDropdown = !showRewardsDropdown)"
    >
      <sidebar-menu-item
        menu-name="Store"
        router-name="rewards-store"
        :required-permissions="['getRewards','createRewards']"
        :query="{ currentPage: '1' }"
      />

      <sidebar-menu-item
        menu-name="Redemption"
        router-name="rewards-redemption"
        :required-permissions="['getRedeemedRewards']"
        :query="{ currentPage: '1', start: from, end: to }"
      />
    </sidebar-dropdown>

    <sidebar-dropdown
      :required-permissions="[
        'billPaymentCategoryStatus',
        'billerStatus',
        'disableBankDisbursement',
        'disableBillPayment',
        'disablePtsaCashout',
        'disableWalletDisbursement',
        'getBanks',
        'getBillPaymentBillerProviders',
        'getBillPaymentClients',
        'getBvnClients',
        'getNubanClients',
        'getPayToBankClients',
        'getPtsaClients',
        'getPtsaClients',
        'getSettlementBanks',
        'getVirtualAccountClients'
      ]"
      name="Settings"
      :show-drop-down="showServiceDropdown"
      :toggle-dropdown="() => (showServiceDropdown = !showServiceDropdown)"
    >
      <sidebar-menu-item
        menu-name="Services"
        router-name="services"
        :required-permissions="[
          'getBillPaymentBillerProviders',
          'getBillPaymentClients',
          'getBvnClients',
          'getNubanClients',
          'getPayToBankClients',
          'getPtsaClients',
          'getSettlementBanks',
          'getVirtualAccountClients'
        ]"
      />

      <sidebar-menu-item
        menu-name="Disable Services"
        router-name="disable-services"
        :required-permissions="[
          'billPaymentCategoryStatus',
          'billerStatus',
          'disableBankDisbursement',
          'disableBillPayment',
          'disablePtsaCashout',
          'disableWalletDisbursement',
          'getBanks',
          'getPtsaClients',
        ]"
      />

      <sidebar-menu-item
        menu-name="Messaging"
        router-name="messaging"
        :required-permissions="['sendBulkEmail','broadcastMessage']"
      />
    </sidebar-dropdown>

    <sidebar-menu-item
      menu-name="Receipts"
      router-name="receipt-reports"
      :required-permissions="['searchDispenseErrorCashoutReceipts']"
      class="top-gap"
    />
  </div>
</template>

<script>
  import moment from "moment";
  import { Constants } from "@/assets/constants";
  import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";
  import SidebarDropdown from "@/components/sidebar/SidebarDropdown";

  export default {
    name: "Sidebar",
    components: {
      SidebarMenuItem,
      SidebarDropdown
    },
    data() {
      return {
        to: moment().format(),
        from: moment().format(),
        showReportDropdown: false,
        showServiceDropdown: false,
        showTerminalDropdown: false,
        showUserManagementDropdown: false,
        showRewardsDropdown: false,
        showSalesbookDropdown: false,
        Constants
      };
    },
    computed: {
      drawerStatus() {
        return this.$store.getters.getDrawerStatus;
      }
    },
    created() {
      let from = moment(this.from).format("YYYY-MM-DD HH:mm:ss ZZ");
      let to = moment(this.to).format("YYYY-MM-DD HH:mm:ss ZZ");

      // Make sure that the from date has the time set to 00:00:00. this is done by using the .replace function below
      const time = from.substring(11, 19);
      from = from.replace(time, "00:00:00");
      to = to.replace(time, "23:59:59");

      this.from = encodeURIComponent(from);
      this.to = encodeURIComponent(to);
    }
  };
</script>

<style scoped src="./sidebar.css"></style>
<style scoped>
.top-gap {
  margin-top: 30px;
}
</style>
