<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Redeemed Items">
      <div class="dashboard__main__content__row__item posRelative">
        <records-filter
          v-click-outside="closeDropdown"
          data="rewards-redemption"
          :selected="['date']"
          :show-dropdown="showDropdown"
          @toggle-filter-dropdown="updateDropDownValue($event)"
        />
      </div>
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchRewards($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>
            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.search">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No Items redeemed by agents today. See
                    <router-link
                      :to="{ name: 'rewards-redemption', query: { currentPage: '1', batchSize: '20', start: startOfYear, end } }"
                      class="empty__state__link"
                    >
                      all transactions
                    </router-link>
                    instead
                  </div>
                </template>
              </div>

              <div v-else>
                <div class="table table--padded">
                  <div class="table__section table__section--head">
                    <div class="table__row">
                      <div class="table__header">
                        Reward
                      </div>
                      <div class="table__header">
                        Agent Name
                      </div>
                      <div class="table__header">
                        City
                      </div>
                      <div class="table__header">
                        Tellerpoint Ref
                      </div>
                      <div class="table__header">
                        Creation Time
                      </div>
                    </div>
                  </div>
                  <div class="table__section table__section--body">
                    <template v-for="(item, i) in redeemedItems">
                      <div
                        :key="i"
                        class="table__row"
                      >
                        <div class="table__data width35percent">
                          <div class="rewards_store__card flexCenter">
                            <div class="rewards_store__card_image">
                              <img
                                :src="item.reward.photoUrl"
                                alt="Agent Photo"
                              >
                            </div>
                            <div class="rewards_store__card_details">
                              <p class="balance-card__providerName">
                                {{ item.reward.name }}
                              </p>
                              <p class="rewards_store__card_details_amount">
                                <strong>₵</strong>
                                <strong>{{ item.reward.coin | count }}</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="table__data">
                          {{ item.merchantName }}
                        </div>
                        <div class="table__data">
                          {{ item.merchantCity }}
                        </div>
                        <div class="table__data">
                          {{ item.tellerpointRef }}
                        </div>
                        <div class="table__data">
                          {{ item.creationTime | date }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <pagination
                  :page-number="computedPageNumbers"
                  data="rewards-redemption"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import RecordsFilter from "@/components/Filter";
  import Pagination from "@/components/Pagination";
  import Search from "@/components/Search";
  import { pagination } from "@/mixins/Pagination";
  import { dropdownOperations } from "@/mixins/DropdownOperations";
  import { startOfYear, endOfDay, startOfDay } from "@/api/dates";
  import { callGetApi } from "@/httpClient";

  export default {
    name: "RewardsRedemption",
    components: {
      TitleBar,
      RecordsFilter,
      Search,
      Pagination
    },
    filters: {
      count: (number) => new Intl.NumberFormat().format(number)
    },
    mixins: [pagination, dropdownOperations],
    data() {
      return {
        loading: false,
        noData: false,
        searchPlaceholder: "Search by reward name, tellerpoint ref, agent name",
        redeemedItems: [],
        pageNumber: "",
        pageName: "rewardsRedemptionPage",
        startOfYear: encodeURIComponent(startOfYear()),
        startOfDay: encodeURIComponent(startOfDay()),
        end: encodeURIComponent(endOfDay())
      };
    },
    watch: {
      //watch for route parameter change and execute method
      $route: "getRedeemedItems"
    },
    created() {
      this.getRedeemedItems();
    },
    methods: {
      getRedeemedItems() {
        this.loading = true;
        const query_params = this.convertToQueryString(this.$route.query);
        callGetApi(`reward/redeemed?batchSize=20&${query_params}`)
          .then(res => {
            let response = res.body;
            const pageMetaData = response.pageMetaData;

            this.noData = response.entity.length === 0;
            this.redeemedItems = response.entity;
            this.setPageDetails(pageMetaData.totalNoOfRows, pageMetaData.batchSize);
            this.loading = false;
          })
          .catch(console.error);
      },
      searchRewards(criteria) {
        this.loading = true;
        this.$router.push({
          name: "rewards-redemption",
          query: {
            search: criteria,
            currentPage: 1,
            batchSize: 20,
          }
        });
        this.loading = false;
      },
      clearSearch() {
        this.searchQuery = "";
        this.$router.push({
          name: "rewards-redemption",
          query: {
            currentPage: 1,
            batchSize: 20,
            start: this.startOfDay,
            end: this.end
          }
        });
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      }
    }
  };
</script>
