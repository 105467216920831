<template>
  <div
    v-if="computedPageNumbers > 1"
    class="pagination pagination--pageContext"
  >
    <div class="pagination__container">
      <div
        v-if="currentPage !== 1"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery( currentPage - 1 ) }"
          exact
          class="pagination__item__link"
        >
          <svg
            height="12"
            viewBox="0 0 16 12"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m15.707 5.293-5-5c-.2525868-.25258677-.62074-.35123314-.96577996-.25877996-.34503995.09245318-.61454683.36196005-.707.707-.09245318.34503995.00619319.71319319.25877996.96577996l3.293 3.293h-11.586c-.55228475 0-1 .44771525-1 1s.44771525 1 1 1h11.586l-3.293 3.293c-.39046532.3904653-.39046532 1.0235347 0 1.414s1.0235347.3904653 1.414 0l5-5c.1877164-.18739744.2932012-.4417544.2932012-.707s-.1054848-.51960256-.2932012-.707"
              transform="matrix(-.99999504 .00314878 -.00314878 -.99999504 16.018853 11.97478)"
            />
          </svg>
        </router-link>
      </div>
      <div
        v-if="currentPage - 9 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query:getPaginationQuery(currentPage - 9) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 9 }}
        </router-link>
      </div>
      <div
        v-if="currentPage - 8 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query:getPaginationQuery(currentPage - 8) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 8 }}
        </router-link>
      </div>
      <div
        v-if="currentPage - 7 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query:getPaginationQuery(currentPage - 7) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 7 }}
        </router-link>
      </div>
      <div
        v-if="currentPage - 6 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query:getPaginationQuery(currentPage - 6) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 6 }}
        </router-link>
      </div>
      <div
        v-if="currentPage - 5 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query:getPaginationQuery(currentPage - 5) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 5 }}
        </router-link>
      </div>
      <div
        v-if="currentPage - 4 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query:getPaginationQuery(currentPage - 4) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 4 }}
        </router-link>
      </div>
      <div
        v-if="currentPage - 3 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query:getPaginationQuery(currentPage - 3) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 3 }}
        </router-link>
      </div>
      <div
        v-if="currentPage - 2 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery( currentPage - 2) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 2 }}
        </router-link>
      </div>
      <div
        v-if="currentPage - 1 > 0"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery( currentPage - 1 ) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage - 1 }}
        </router-link>
      </div>
      <div class="pagination__item">
        <router-link
          :to="{ name: data, query: getPaginationQuery( currentPage ) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 1 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 1) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 1 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 2 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 2 ) }"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 2 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 3 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 3 )}"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 3 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 4 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 4 )}"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 4 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 5 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 5 )}"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 5 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 6 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 6 )}"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 6 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 7 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 7 )}"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 7 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 8 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 8 )}"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 8 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 9 <= computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery(currentPage + 9 )}"
          exact
          class="pagination__item__link"
        >
          {{ currentPage + 9 }}
        </router-link>
      </div>
      <div
        v-if="currentPage + 1 < computedPageNumbers"
        class="pagination__item"
      >
        <router-link
          :to="{ name: data, query: getPaginationQuery( currentPage + 1 ) }"
          exact
          class="pagination__item__link"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: String,
        required: true
      },
      pageNumber: {
        type: Number,
        required: true
      }

    },
    computed: {
      currentPage() {
        if (typeof this.$route.query.currentPage === "undefined") {
          return 1;
        } else {
          return parseInt(this.$route.query.currentPage);
        }
      },

      computedPageNumbers() {
        return this.pageNumber || 1;
      }
    },
    methods: {
      convertToQueryString(array) {
        let url = "";
        for (const key in array) {
          url += key + "=" + array[key] + "&";
        }
        return url.trim("&");
      },
      getPaginationQuery(page) {
        const query = { ...this.$route.query };
        query.currentPage = page;
        return query;
      }
    }
  };
</script>
