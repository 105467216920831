<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Receipts" />

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>

          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that RRN.</div>
                </template>
                <template v-else>
                  <div>
                    Type an RRN to start a search.
                  </div>
                </template>
              </div>
              <div v-else>
                <receipt-records
                  :dispense="dispense"
                  :accept-claim="acceptClaim"
                  :show-confirm-modal="showConfirmModal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <confirmation-modal
      id="confirmAccept"
      body="Are you sure you want to accept claim at bank?"
      :on-submit="acceptClaim"
      button-text="Yes"
      header="Accept Claim"
      :disable-button="disableModalButton"
    />
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import ConfirmationModal from "@/components/ConfirmationModal";
  import Search from "../../components/Search";
  import { callDeleteApi, callGetApi } from "@/httpClient";
  import ReceiptRecords from "@/components/ReceiptReports";

  export default {
    name: "ReceiptReports",
    components: {
      ReceiptRecords,
      TitleBar,
      Search,
      ConfirmationModal
    },
    data() {
      return {
        dispense: "",
        loading: false,
        noData: true,
        search: this.$route.query.criteria ? this.$route.query.criteria : "",
        searchPlaceholder: "Search by rrn",
        selectedObject: "",
        disableModalButton: false
      };
    },
    watch: {
      //watch for route parameter change and execute method
      "$route": "fetchDispense"
    },
    methods: {
      clearSearch() {
        this.search = "";
        this.$router.push({ name: "receipt-reports" });
      },
      selectItem(value) {
        this.selectedObject = value;
      },
      fetchDispense() {
        this.loading = true;
        const traceRef = this.$route.query.criteria;
        if (traceRef !=null) {
          callGetApi(`dispense-error-cashout-receipts/${traceRef}`)
            .then(res => {
              this.noData = res.body.entity == null;
              this.loading = false;
              this.dispense = res.body.entity;
            })
            .catch(console.error);
        }else{
          this.noData = null;
          this.loading = false;
          this.dispense = {};
          this.loading = false;
        }
      },
      acceptClaim() {
        this.disableModalButton = true;
        callDeleteApi(`merchant/dispense-error?tellerpointRef=${this.selectedObject.tellerpointRef}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Accept Claim",
                text: "Claim accepted successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Accept Claims",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            this.disableModalButton = false;
            this.closeConfirmModal();
            this.fetchDispense();
          });
      },
      searchQuery(criteria) {
        this.clearSearch();
        this.$router.push({ name: "receipt-reports", query: { criteria } });
      },
      showConfirmModal(item) {
        this.selectedObject = item;
        $("#confirmAccept").modal();
      },
      closeConfirmModal() {
        $("#confirmAccept").modal("hide");
      }
    }
  };
</script>
