<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Admins">
      <button
        v-if="isAnyOperationPermitted(['registerAdmin'])"
        class="btn btn--primary btn--flex"
        data-target="#newAdmin"
        data-toggle="modal"
      >
        <span class="btn__icon">
          <svg
            height="14"
            width="14"
          >
            <use xlink:href="../../assets/icons/addIcon.svg#add_icon" />
          </svg>
        </span>
        <span class="btn__text">Register Admin</span>
      </button>
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <!-- Table Data -->
              <div class="table table--padded">
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      First Name
                    </div>
                    <div class="table__header">
                      Last Name
                    </div>
                    <div class="table__header">
                      Email Address
                    </div>
                    <div class="table__header">
                      Phone Number
                    </div>
                    <div class="table__header" />
                    <div class="table__header" />
                    <div class="table__header" />
                    <div class="table__header" />
                  </div>
                </div>
                <div class="table__section table__section--body">
                  <template v-for="(admin, i) in admins">
                    <div
                      :key="i"
                      class="table__row"
                    >
                      <div class="table__data table__data--full">
                        <p class="table__data--main">
                          {{ admin.firstName }}
                        </p>
                      </div>
                      <div class="table__data">
                        <p class="table__data--main">
                          {{ admin.surname }}
                        </p>
                      </div>
                      <div class="table__data">
                        <span class="link">{{ admin.email }}</span>
                      </div>
                      <div class="table__data">
                        {{ admin.phone }}
                      </div>
                      <div class="table__data">
                        <span
                          data-toggle="modal"
                          data-target="#updateAdmin"
                          title="Edit Admin"
                          @click="setSelectedAdmin(admin)"
                        >
                          <svg
                            height="14px"
                            width="14px"
                          >
                            <use xlink:href="../../assets/icons/edit-icon.svg#edit_icon" />
                          </svg>
                        </span>
                      </div>
                      <div class="table__data">
                        <span
                          data-target="#disableAdmin"
                          data-toggle="modal"
                          :title="selectedAdmin.enabled ? 'Disable' : 'Enable' + ' admin' "
                          @click="setSelectedAdmin(admin)"
                        >
                          <svg
                            height="14"
                            width="14"
                            :class="{enableTerminal: admin.enabled, disableTerminal: !admin.enabled}"
                          >>
                            <use xlink:href="../../assets/icons/disable-icon.svg#disable_icon" />
                          </svg>
                        </span>
                      </div>
                      <div class="table__data">
                        <button
                          class="btn btn--primary btn--sm button-min-width-160px"
                          @click="navigateToAdminPermissions(admin.id)"
                        >
                          {{ admin.allowedOperations ? admin.allowedOperations.length : 0 }}
                          {{ (admin.allowedOperations ? admin.allowedOperations.length : 0) === 1 ? 'Permission' : 'Permissions' }}
                        </button>
                      </div>
                      <div class="table__data">
                        <span @click="toggleTable(admin.id)">
                          <svg
                            height="14"
                            width="14"
                          >
                            <use xlink:href="../../assets/icons/expand-icon.svg#expand_icon" />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="opened.includes(admin.id)"
                      :key="admin.globalId"
                      class="collapsible-table"
                    >
                      <div class="card-details__content">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for
                          >Partner Organizations</label>
                          <template v-for="partner in admin.partnerOrganizations">
                            <input
                              :key="partner.appId"
                              :value="partner.appId"
                              class="form__input"
                              disabled
                              type="text"
                            >
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <pagination
                :page-number="computedPageNumbers"
                data="admins"
              />
            </div>

            <div
              id="disableAdmin"
              aria-labelledby="disableAdminLabel"
              class="modal fade"
              role="dialog"
              tabindex="-1"
            >
              <div
                class="modal__dialog modal__dialog--sm"
                role="document"
              >
                <div class="modal__content">
                  <div class="modal__header">
                    <div class="modal__title width70percent text-center">
                      {{ selectedAdmin.enabled ? "Disable " : "Enable " }} {{ selectedAdmin.firstName }}
                      {{ selectedAdmin.surname }}
                    </div>
                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                    >
                      <svg
                        height="13"
                        viewBox="0 0 13 13"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                          fill="#C0C6D8"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="modal__body modal__body--pad">
                    <form
                      class="form"
                      @submit.prevent="disableAdmin"
                    >
                      <div class="form__pad">
                        <p class="m-b-20 textCenter">
                          Are you sure you want to {{ selectedAdmin.enabled ? "disable" : "enable" }} this admin?
                        </p>
                      </div>

                      <div class="form__footer">
                        <button
                          class="btn btn--primary btn--md btn--block"
                          type="submit"
                        >
                          Yes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="newAdmin"
              aria-labelledby="newAdminLabel"
              class="modal fade"
              role="dialog"
              tabindex="-1"
            >
              <div
                class="modal__dialog modal__dialog--sm"
                role="document"
              >
                <div class="modal__content">
                  <div class="modal__header">
                    <div class="modal__title width70percent text-center">
                      <p>Register Admin</p>
                    </div>
                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                    >
                      <svg
                        height="13"
                        viewBox="0 0 13 13"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                          fill="#C0C6D8"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="modal__body modal__body--pad">
                    <form
                      class="form"
                      @submit.prevent="registerAdmin"
                    >
                      <div class="form__pad">
                        <div class="form__item">
                          <label class="form__label form__label--2">Partner Organization</label>
                          <v-select
                            v-model="newAdmin.appIds"
                            label="appId"
                            :options="partnerOrganizations"
                            placeholder="Select Partner Organization"
                            :reduce="partner => partner.appId"
                            height="34px"
                            multiple
                          />
                        </div>
                        <div class="form__item">
                          <label
                            for="firstName"
                            class="form__label form__label--2"
                          >First Name</label>
                          <input
                            id="firstName"
                            v-model="newAdmin.firstName"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            for="surname"
                            class="form__label form__label--2"
                          >Last Name</label>
                          <input
                            id="surname"
                            v-model="newAdmin.surname"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            for="email"
                            class="form__label form__label--2"
                          >Email</label>
                          <input
                            id="email"
                            v-model="newAdmin.email"
                            class="form__input fontNormal"
                            type="email"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            for="phone"
                            class="form__label form__label--2"
                          >Phone</label>
                          <input
                            id="phone"
                            v-model="newAdmin.phone"
                            class="form__input fontNormal"
                            type="number"
                            required
                          >
                        </div>
                      </div>

                      <div class="form__footer">
                        <button
                          class="btn btn--primary btn--md btn--block"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="updateAdmin"
              aria-labelledby="updateAdminLabel"
              class="modal fade"
              role="dialog"
              tabindex="-1"
            >
              <div
                class="modal__dialog modal__dialog--sm"
                role="document"
              >
                <div class="modal__content">
                  <div class="modal__header">
                    <div class="modal__title width70percent text-center">
                      <p>Update {{ selectedAdmin.firstName }} {{ selectedAdmin.surname }}</p>
                    </div>
                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                    >
                      <svg
                        height="13"
                        viewBox="0 0 13 13"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                          fill="#C0C6D8"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="modal__body modal__body--pad">
                    <form
                      class="form"
                      @submit.prevent="updateAdmin"
                    >
                      <div class="form__pad">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="firstNameUpdate"
                          >First Name</label>
                          <input
                            id="firstNameUpdate"
                            v-model="selectedAdmin.firstName"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="lastNameUpdate"
                          >Surname</label>
                          <input
                            id="lastNameUpdate"
                            v-model="selectedAdmin.surname"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="emailUpdate"
                          >Email</label>
                          <input
                            id="emailUpdate"
                            v-model="selectedAdmin.email"
                            class="form__input fontNormal"
                            type="email"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="phoneUpdate"
                          >Phone</label>
                          <input
                            id="phoneUpdate"
                            v-model="selectedAdmin.phone"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label class="form__label form__label--2">Partner Organizations</label>
                          <v-select
                            v-model="selectedAdmin.partnerOrganizations"
                            label="appId"
                            :options="partnerOrganizations"
                            :reduce="partner => partner.appId"
                            height="34px"
                            multiple
                          />
                        </div>
                      </div>

                      <div class="form__footer m-t-30">
                        <button
                          class="btn btn--primary btn--md btn--block"
                          type="submit"
                        >
                          Yes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "../../components/Search";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { callGetApi, callPostApi, callPutApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "Admins",
    components: {
      TitleBar,
      Pagination,
      Search,
      vSelect
    },
    mixins: [AdminPermissionsHelper],
    data() {
      return {
        admins: "",
        searchPlaceholder: "Search admin by name, email",
        opened: [],
        loading: true,
        pageNumber: "",
        selectedAdmin: {},
        newAdmin: {},
        partnerOrganizations: this.$store.getters.adminAccountInfo.partnerOrganizations,
        allowedOperations: this.$store.getters.adminAccountInfo.allowedOperations
      };
    },
    computed: {
      randomKey() {
        return Date.now();
      },
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("agentsPage")) {
          return JSON.parse(window.localStorage.getItem("agentsPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      $route: "fetchAdmins"
    },
    created() {
      this.fetchAdmins();
    },
    methods: {
      clearSearch() {
        this.$router.push({ name: "admins", query: { currentPage: 1 } });
      },
      navigateToAdminPermissions(adminId) {
        if (adminId) {
          this.$router.push({ name: "admin-permissions", params: { id: adminId } });
        }
      },
      fetchAdmins() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi(`admin?batchSize=20&${query_params}`)
          .then(res => {
            this.loading = false;
            this.admins = res.body.entity;
            let pages = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            this.pageNumber = pages;
            window.localStorage.setItem("adminsPages", pages);
          })
          .catch(console.error);
      },
      searchQuery(criteria) {
        this.$router.push({ name: "admins", query: { criteria } });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      },
      setSelectedAdmin(admin) {
        this.selectedAdmin = admin;
      },
      disableAdmin() {
        callPutApi(`admin/disable/${this.selectedAdmin.id}/${!this.selectedAdmin.enabled}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Disable Admin",
                text: "Admin Disabled successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Disable Admin",
                type: "warn",
                text: res.body.status.description
              });
            }
            $("#disableAdmin").modal("hide");
            this.fetchAdmins();
          })
          .catch(console.error);
      },
      registerAdmin() {
        const admin = this.newAdmin;
        admin.transactionPin = Math.floor(1000 + Math.random() * 9000).toString();
        admin.password = Math.floor(100000 + Math.random() * 900000).toString();
        admin.extraData = {};

        callPostApi("admin", { body: admin })
          .then(res => {
            if (res.body.status.code === 0) {
              const newAdminId = res.body.entity.id;
              this.$notify({
                group: "general",
                title: "Register Admin",
                text: `${admin.firstName} ${admin.surname} registered successfully`,
                speed: 50
              });
              this.navigateToAdminPermissions(newAdminId);
            } else {
              this.$notify({
                group: "general",
                title: "Register Admin",
                type: "warn",
                text: res.body.status.description
              });
            }
            $("#newAdmin").modal("hide");
            this.fetchAdmins();
          })
          .catch(err => {
            console.error(err);
            this.$notify({
              group: "general",
              title: "Server Error",
              type: "error",
              text: err
            });
            setTimeout(this.$router.go, 1500);
          });
      },
      updateAdmin() {
        const admin = this.selectedAdmin;

        const body = {
          firstName: admin.firstName,
          surname: admin.surname,
          phone: admin.phone,
          email: admin.email,
          appIds: admin.partnerOrganizations.map(p => p.appId ? p.appId : p)
        };

        callPutApi(`admin/${admin.id}`, { body })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Update Admin",
                text: "Admin updated successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Update Admin",
                type: "warn",
                text: res.body.status.description
              });
            }
            $("#updateAdmin").modal("hide");
            this.fetchAdmins();
          })
          .catch(console.error);
      }
    }
  };
</script>

<style scoped>
  .form__input {
    font-size: 14px;
  }

  .button-min-width-160px {
    min-width: 160px;
  }
</style>
