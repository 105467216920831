import Vue from "vue";
import router from "@/router";
import NProgress from "vue-nprogress";

const baseUrl = process.env.VUE_APP_API_BASE || "https://dev.tellerpoint.hextremelabs.net/Tellerpoint/rpc/admin";
const TIMEOUT = 60000;
const JSON_MIME_TYPE = "application/json";

const nprogress = new NProgress({
  parent: ".nprogress-container"
});

type RequestPayload = {
  headers?: Headers,
  body?: any
}

export const callGetApi = (url: string, requestPayload?: RequestPayload) => {
  return callApi(url, "GET", requestPayload);
};

export const callPostApi = (url: string, requestPayload?: RequestPayload) => {
  return callApi(url, "POST", requestPayload);
};

export const callPutApi = (url: string, requestPayload?: RequestPayload) => {
  return callApi(url, "PUT", requestPayload);
};

export const callDeleteApi = (url: string, requestPayload?: RequestPayload) => {
  return callApi(url, "DELETE", requestPayload);
};

async function callApi(url: string, method: string, payload?: RequestPayload) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

  const headers = payload?.headers ?? new Headers({ "Content-Type": JSON_MIME_TYPE });
  const sessionId = window.localStorage.getItem("appToken");
  if (sessionId) {
    headers.append("sessionId", sessionId);
  }

  const body = headers.get("Content-Type") === JSON_MIME_TYPE ? JSON.stringify(payload?.body) : payload?.body;

  const options: RequestInit = {
    body,
    headers,
    method,
    signal: controller.signal
  };

  try {
    nprogress.start();
    const responseBody = await (await fetch(`${baseUrl}/${url}`, options)).json();

    if (responseBody.status.code === 211 ||
      responseBody.status.description === "Unauthenticated. Authentication failure") {
      window.localStorage.setItem("appToken", "");
      window.localStorage.setItem("adminInfo", "");
      window.localStorage.setItem("loginTime", "");
      await router.push({ name: "login" });
    } else if (responseBody.status.code === 10) {
      Vue.notify({
        group: "general",
        title: "Partial success",
        type: "warn",
        text: responseBody.status.description
      });
    }

    clearTimeout(timeoutId);
    await nprogress.done();
    return { body: responseBody };
  } catch (error) {
    if (error.name === "AbortError") {
      Vue.notify({
        group: "general",
        title: "System Timeout",
        type: "warn",
        text: "System timeout. Please try again.",
        duration: -100
      });
    }

    return Promise.reject(error.message);
  }
}
export const callExternalApi = async (url: string, method: string = "GET", payload?: RequestPayload) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

  const headers =  new Headers({ "Content-Type": JSON_MIME_TYPE });
  const body = payload ? JSON.stringify(payload) : undefined;

  const options: RequestInit = {
    body,
    headers,
    method,
    signal: controller.signal
  };

  try {
    nprogress.start();
    const response = await fetch(url, options);
    const responseBody = await response.json();
    return { body: responseBody };
  } catch (error) {
    if (error.name === "AbortError") {
      Vue.notify({
        group: "general",
        title: "System Timeout",
        type: "warn",
        text: "System timeout. Please try again.",
        duration: -100
      });
    }
    return Promise.reject(error.message);
  } finally {
    clearTimeout(timeoutId);
    nprogress.done();
  }
};
