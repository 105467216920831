<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Bill Payment">
      <records-filter
        v-click-outside="closeDropdown"
        data="bill-payment"
        :selected="['date']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchBillPayments($event)"
            />
          </div>
          <div class="transaction__content">
            <loader v-if="loading" />

            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No transaction performed by agents today. See
                    <router-link
                      :to="{ name: 'bill-payment', query: { currentPage: '1' }}"
                      class="empty__state__link"
                    >
                      all bill payment transactions
                    </router-link>
                    instead
                  </div>
                </template>
              </div>

              <div v-else>
                <div class="table table--padded">
                  <div class="table__section table__section--head">
                    <div class="table__row">
                      <div class="table__header">
                        Transaction Date
                      </div>
                      <div class="table__header">
                        Biller
                      </div>
                      <div class="table__header">
                        Amount
                      </div>
                      <div class="table__header">
                        Tlp Ref
                      </div>
                      <div class="table__header">
                        Status
                      </div>
                      <div class="table__header" />
                      <div class="table__header" />
                      <div class="table__header" />
                      <div class="table__header" />
                    </div>
                  </div>

                  <div class="table__section table__section--body">
                    <template v-for="(billPayment, i) in billPayments">
                      <div
                        :key="i"
                        class="table__row"
                      >
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            {{ billPayment.creationTime | date }}
                            <latency :latency="billPayment.latency" />
                          </p>
                        </div>
                        <div class="table__data">
                          {{ billPayment.billPaymentMerchantDisplayName }} |
                          {{ billPayment.serviceName }}
                        </div>
                        <div class="table__data">
                          {{ billPayment.amount | money }}
                        </div>
                        <div class="table__data">
                          {{ billPayment.tellerpointRef }}
                        </div>
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            <span
                              v-if="billPayment.status === 'SUCCESSFUL'"
                              class="tag tag--green"
                            >Successful</span>
                            <span
                              v-if="billPayment.status === 'PROCESSING'"
                              class="tag tag--yellow"
                            >Processing</span>
                            <span
                              v-if="billPayment.status === 'REFUNDED'"
                              class="tag tag--gray"
                            >Refunded</span>
                            <span
                              v-if="billPayment.status === 'FAILED'"
                              class="tag tag--red"
                            >Failed</span>
                          </p>
                        </div>

                        <div class="table__data">
                          <span
                            v-if="billPayment.status === 'PROCESSING'"
                            class="cursor-pointer"
                            data-target="#confirmRevertBillPayment"
                            data-toggle="modal"
                            title="Refund agent"
                            @click="setselectedObject(billPayment)"
                          >
                            <svg
                              height="23px"
                              viewBox="0 0 23 23"
                              width="23px"
                            >
                              <use xlink:href="../../assets/icons/refund.svg#refund_icon" />
                            </svg>
                          </span>
                        </div>

                        <div class="table__data">
                          <span
                            v-if="billPayment.status === 'PROCESSING'"
                            class="cursor-pointer"
                            data-target="#confirmCommitBillPayment"
                            data-toggle="modal"
                            title="Payment successful"
                            @click="setselectedObject(billPayment)"
                          >
                            <svg
                              height="20px"
                              viewBox="0 0 20 20"
                              width="20px"
                            >
                              <use xlink:href="../../assets/icons/commit.svg#commit_icon" />
                            </svg>
                          </span>
                        </div>

                        <div class="table__data">
                          <span
                            v-if="billPayment.status === 'PROCESSING'"
                            class="cursor-pointer"
                            title="Confirm status"
                            @click="showProviderResponseModal(billPayment, 'providerResponse')"
                          >
                            <svg
                              height="18px"
                              width="18px"
                            >
                              <use xlink:href="../../assets/icons/provider-response.svg#provider_response_icon" />
                            </svg>
                          </span>
                        </div>

                        <div class="table__data">
                          <span @click="toggleTable(billPayment.tellerpointRef)">
                            <svg
                              height="14"
                              width="14"
                            >
                              <use xlink:href="../../assets/icons/expand-icon.svg#expand_icon" />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="opened.includes(billPayment.tellerpointRef)"
                        :key="billPayment.tellerpointRef"
                        class="collapsible-table"
                      >
                        <div class="card-details__content">
                          <div class="form__item">
                            <label class="form__label form__label--2">Customer</label>
                            <input
                              :value="isBlank(billPayment.customerIdentifier) ? '--' : `${billPayment.customerIdentifier} | ${billPayment.customerName}`"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Provider</label>
                            <input
                              :value="isBlank(billPayment.serviceProvider) ? '--' : billPayment.serviceProvider"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Provider Response</label>
                            <input
                              :value="isBlank(billPayment.serviceProviderResponseMessage) ? '--' :
                                billPayment.serviceProviderResponseMessage"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Provider Ref</label>
                            <input
                              :value="isBlank(billPayment.serviceProviderRef) ? '--' : billPayment.serviceProviderRef"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Biller Ref</label>
                            <input
                              :value="isBlank(billPayment.billPaymentMerchantRef) ? '--' : billPayment.billPaymentMerchantRef"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Agent Name</label>
                            <input
                              :value="isBlank(billPayment.tellerpointMerchantName) ? '--' : billPayment.tellerpointMerchantName"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Channel</label>
                            <input
                              :value="isBlank(billPayment.channel) ? '--' : billPayment.channel"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Phone Number</label>
                            <input
                              :value="!hasExtraDataField(billPayment, 'beneficiaryPhone') ? '--' : billPayment.extraData.beneficiaryPhone"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Units</label>
                            <input
                              :value="!hasExtraDataField(billPayment, 'units') ? '--' : billPayment.extraData.units"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label class="form__label form__label--2">Debt Payment</label>
                            <input
                              :value="!hasExtraDataField(billPayment, 'debtPayment') ? '--' : billPayment.extraData.debtPayment"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                        </div>
                      </div>
                    </template>

                    <provider-response
                      :is-open="openProviderResponseModal"
                      :obj="selectedObject"
                      transaction-type="BILL_PAYMENT"
                      @close-modal="hideProviderResponseModal('providerResponse')"
                    />

                    <confirmation-modal
                      id="confirmRevertBillPayment"
                      :body="`Refund ${selectedObject.tellerpointRef} to agent?`"
                      :on-submit="revertBillPayment"
                      button-text="Yes"
                      header="Refund Failed Bill Payment"
                      :disable-button="disableModalButton"
                    />

                    <confirmation-modal
                      id="confirmCommitBillPayment"
                      :body="`Update ${selectedObject.tellerpointRef} to successful?`"
                      :on-submit="commitBillPayment"
                      button-text="Yes"
                      header="Update To Successful"
                      :disable-button="disableModalButton"
                    />
                  </div>
                </div>

                <pagination
                  :page-number="computedPageNumbers"
                  data="bill-payment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import ProviderResponse from "@/components/ProviderResponse";
  import Loader from "@/components/Loader";
  import Search from "@/components/Search";
  import RecordsFilter from "@/components/Filter";
  import { pagination } from "@/mixins/Pagination";
  import { collapsibleTable, hasBlank } from "@/mixins/ViewHelper";
  import { dropdownOperations, providerResponseOperations } from "@/mixins/DropdownOperations";
  import Latency from "@/components/Latency";
  import ConfirmationModal from "@/components/ConfirmationModal";
  import moment from "moment";
  import { callGetApi, callPutApi } from "@/httpClient";

  export default {
    name: "BillPayment",
    components: {
      Latency,
      TitleBar,
      Pagination,
      Search,
      ProviderResponse,
      Loader,
      RecordsFilter,
      ConfirmationModal
    },
    mixins: [
      collapsibleTable,
      hasBlank,
      pagination,
      providerResponseOperations,
      dropdownOperations
    ],
    data() {
      return {
        loading: true,
        disableModalButton: false,
        noData: false,
        billPayments: [],
        criteria: "",
        searchPlaceholder: "Search by tlp ref, agent name, customer name, customer id, provider ref",
        opened: [],
        openRevertModal: false,
        openCommitModal: false,
        commit: "commit",
        revert: "revert",
        pageNumber: "",
        pageName: "billPaymentsPage",
      };
    },
    watch: {
      $route: "fetchBillPayments"
    },
    created() {
      this.fetchBillPayments();
    },
    methods: {
      fetchBillPayments() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi("bill-payment?batchSize=20&" + query_params)
          .then(res => {
            const pageMetaData = res.body.pageMetaData;
            this.setPageDetails(pageMetaData.totalNoOfRows, pageMetaData.batchSize);
            this.noData = res.body.entity.length === 0;
            this.billPayments = res.body.entity;

            const { code, description } = res.body.status;
            if (code !== 0) {
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(error =>
            this.$notify({
              group: "general",
              type: "warn",
              text: error.message
            })).finally(() => this.loading = false );
      },
      searchBillPayments(criteria) {
        this.criteria = criteria;
        this.$router.push({ name: "bill-payment", query: { criteria } });
      },
      clearSearch() {
        let from = moment(this.from).format("YYYY-MM-DD HH:mm:ss ZZ");
        let to = moment(this.to).format("YYYY-MM-DD HH:mm:ss ZZ");

        const newdate = from;
        const time = newdate.substring(11, 19);
        from = from.replace(time, "00:00:00");

        to = to.replace(time, "23:59:59");

        from = encodeURIComponent(from);
        to = encodeURIComponent(to);

        this.$router.push({ name: "bill-payment", query: { currentPage: 1, start: from, end: to } });
      },
      hasExtraDataField(billPayment, key) {
        if (billPayment.extraData) {
          if (billPayment.extraData[key]) {
            return true;
          }
        }
        return false;
      },
      commitBillPayment() {
        this.disableModalButton = true;
        callPutApi(`bill-payment/commit/${this.selectedObject.tellerpointRef}`)
          .then(res => {
            const { code, description } = res.body.status;
            if (code === 0) {
              this.$notify({
                group: "general",
                title: "Commit Bill Payment",
                text: "Committed successfully"
              });
            } else {
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(err => {
            const message = err ? JSON.stringify(err.message, null, 2) : "Error committing bill payment";
            this.$notify({
              group: "general",
              title: "Commit Bill Payment",
              type: "warn",
              text: message
            });
          })
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmCommitBillPayment").modal("hide");
            this.fetchBillPayments();
          });
      },
      revertBillPayment() {
        this.disableModalButton = true;
        callPutApi(`bill-payment/revert/${this.selectedObject.tellerpointRef}`)
          .then(res => {
            const { code, description } = res.body.status;
            if (code === 0) {
              this.$notify({
                group: "general",
                title: "Revert Bill Payment",
                text: "Refunded successfully"
              });
            } else {
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(err => {
            const message = err ? JSON.stringify(err.message, null, 2) : "Error reverting bill payment";
            this.$notify({
              group: "general",
              title: "Revert Bill Payment",
              type: "warn",
              text: message
            });
          })
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmRevertBillPayment").modal("hide");
            this.fetchBillPayments();
          });
      },
      setselectedObject(billPayment) {
        this.selectedObject = billPayment;
      },
      convertToQueryString(myData) {
        return Object.entries(myData)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
      }
    }
  };
</script>
