<template>
  <div class="dashboard__main__content__container">
    <loader v-if="loading" />
    <div v-else-if="version != null">
      <title-bar title="Terminal App">
        <div class="dashboard__main__content__row__item posRelative">
          <button
            v-if="isAnyOperationPermitted(['uploadTerminalApplication'])"
            id="exampleColorDropdown1"
            class="btn btn--primary btn--flex"
            data-target="#terminalApp"
            data-toggle="modal"
          >
            <span class="btn__icon">
              <svg
                height="14"
                width="14"
              >
                <use xlink:href="../../assets/icons/addIcon.svg#add_icon" />
              </svg>
            </span>
            <span class="btn__text">Upload Terminal App</span>
          </button>
        </div>
      </title-bar>

      <div class="table table--padded transaction half-width">
        <div class="table__section table__section--head">
          <div class="table__row">
            <div class="table__header">
              Model
            </div>
            <div class="table__header">
              Version
            </div>
          </div>
        </div>
        <div class="table__section table__section--body">
          <template v-for="[model, mVersion] of Object.entries(models)">
            <div
              :key="model"
              class="table__row"
            >
              <div class="table__data table__data--full">
                <p class="table__data--main">
                  {{ model }}
                </p>
              </div>
              <div class="table__data">
                {{ mVersion }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        id="terminalApp"
        aria-labelledby="uploadTerminalAppLabel"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div
          class="modal__dialog modal__dialog--sm"
          role="document"
        >
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__title text-center">
                Upload Terminal App
              </div>
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <close-icon />
              </button>
            </div>
            <div class="modal__body modal__body--pad">
              <form
                class="form"
                @submit.prevent="uploadTerminal"
              >
                <div class="form__pad">
                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for="model"
                    >Model</label>
                    <v-select
                      id="model"
                      v-model="modelToUpload"
                      :options="Object.keys(models)"
                      class="fontNormal"
                      @input="updateModelVersion(modelToUpload)"
                    />
                  </div>
                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for="version"
                    >Version</label>
                    <input
                      id="version"
                      v-model="version"
                      class="form__input fontNormal"
                      type="text"
                    >
                  </div>
                  <div class="form__item">
                    <label
                      class="form__label form__label--2"
                      for="file-1"
                    >File</label>
                    <input
                      id="file-1"
                      class="inputfile"
                      data-multiple-caption="{count} files selected"
                      name="file-1[]"
                      type="file"
                      @change="updateFileName"
                    >
                    <label
                      for="file-1"
                      class="outline-none p-l-none"
                    >
                      <svg
                        height="17"
                        viewBox="0 0 20 17"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                        />
                      </svg>
                      <span>Select application</span>
                    </label>
                  </div>
                </div>

                <div class="form__footer">
                  <button
                    class="btn btn--primary btn--md btn--block"
                    type="submit"
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Operation not available for {{ currentContext }}</p>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import CloseIcon from "@/components/icons/CloseIcon";
  import Loader from "@/components/Loader";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { mapGetters } from "vuex";
  import { dropdownOperations } from "@/mixins/DropdownOperations";
  import { callGetApi, callPutApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "TerminalAppVue",
    components: {
      TitleBar,
      CloseIcon,
      Loader,
      vSelect
    },
    mixins: [dropdownOperations, AdminPermissionsHelper],
    data() {
      return {
        version: "",
        modelToUpload: "",
        models: {},
        loading: true,
        uploadedFile: {}
      };
    },
    computed: {
      ...mapGetters([
        "currentContext"
      ])
    },
    created() {
      this.getTerminalVersion();
    },
    methods: {
      getTerminalVersion() {
        this.loading = true;
        callGetApi("terminal/version")
          .then(res => {
            this.models = res.body.entity;
            this.loading = false;
          });
      },
      updateFileName(e) {
        const file = e.target.files;
        const label = e.target.nextElementSibling, labelVal = label.innerHTML;

        let fileName = "";
        if (file && file.length > 1)
          fileName = (this.getAttribute("data-multiple-caption") || "").replace("{count}", file.length);
        else fileName = e.target.value.split("\\").pop();

        if (fileName) label.querySelector("span").innerHTML = fileName;
        else label.innerHTML = labelVal;

        this.uploadedFile = file;
      },
      uploadTerminal(e) {
        const file =this.uploadedFile;
        const body = new FormData();
        body.append("attachment", file[0]);

        const headers = new Headers({});

        callPutApi(`terminal/upload-binary/${this.modelToUpload}/${this.version}`, { body, headers })
          .then(res => {
            if (res.body.status.code === 0) {
              $("#terminalApp").modal("hide");
              this.getTerminalVersion();
              this.$notify({
                group: "general",
                title: "Upload Terminal App",
                text: "Terminal app has been uploaded successfully"
              });
              e.target.value = null;
              let input = document.getElementById("file-1");
              input.value = null;
              input.nextElementSibling.querySelector("span").innerHTML = "Choose a file...";
            } else {
              this.$notify({
                group: "general",
                title: "Upload Terminal app",
                type: "warn",
                text: res.body.status.description
              });
              e.target.value = null;
              let input = document.getElementById("file-1");
              input.value = null;
              input.nextElementSibling.querySelector("span").innerHTML = "Choose a file...";
            }
          })
          .catch(console.log);
      },
      updateModelVersion(model) {
        for (const [key, value] of Object.entries(this.models)) {
          if (key === model) {
            this.version = value;
          }
        }
      }
    }
  };
</script>
