<template>
  <div class="title-bar-container">
    <h1 class="title">
      {{ title }}
    </h1>
    <div class="controls">
      <slot />
    </div>
  </div>
</template>

<style scoped>
  .title-bar-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .title {
    color: $title-color;
    font-weight: lighter;
    font-size: 30px;
  }

  .controls {
    display: flex;
  }
</style>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: null
      }
    }
  };
</script>
