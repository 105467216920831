<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Rewards Store">
      <div class="dashboard__main__content__row__item posRelative">
        <button
          v-if="isAnyOperationPermitted(['createRewards'])"
          id="newTerminals"
          class="btn btn--primary btn--flex"
          @click="openAddRewardModal"
        >
          <span class="btn__text">Add New Item</span>
        </button>
      </div>
    </title-bar>

    <search
      :search-placeholder="searchPlaceholder"
      @clear-search="clearSearch"
      @search="searchRewards($event)"
    />
    <template v-if="this.$route.query.search && noData">
      <div>No results found for that search query.</div>
    </template>

    <div class="dashboard__main__content__home__left__provider">
      <div
        v-for="reward in rewards"
        :key="reward.id"
        class="balance-card"
      >
        <div class="rewards_store__card">
          <div class="rewards_store__card_image">
            <img
              :src="reward.photoUrl"
              alt="Agent Photo"
            >
          </div>
          <div class="rewards_store__card_details">
            <p class="balance-card__providerName">
              {{ reward.name }}
            </p>
            <p class="rewards_store__card_details_amount">
              <strong>₵</strong>
              <strong>{{ reward.coin | count }}</strong>
            </p>
          </div>
          <span
            :title="reward.enabled ? 'Disable Reward' : 'Enable Reward'"
            @click="disableReward(reward)"
          >
            <svg
              height="15px"
              width="15px"
              :class="{ enableTerminal: reward.enabled, disableTerminal: !reward.enabled, 'cursor-pointer': true }"
            >
              <use xlink:href="../../assets/icons/disable-icon.svg#disable_icon" />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <pagination
      class="m-t-20"
      :page-number="computedPageNumbers"
      data="rewards-store"
    />

    <div
      id="addReward"
      aria-labelledby="newRewardLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--md"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Add Rewards
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                  fill="#C0C6D8"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
          </div>

          <div class="modal__body modal__body--pad">
            <div class="editor">
              <form
                class="login__section__form"
                @submit.prevent="addReward"
              >
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="name"
                  >Name</label>
                  <input
                    id="name"
                    v-model="newReward.name"
                    autofocus
                    required
                    class="form__input fontNormal"
                    type="text"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="value"
                  >Coin Amount</label>
                  <input
                    id="value"
                    v-model="newReward.value"
                    autofocus
                    required
                    class="form__input fontNormal"
                    type="number"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="photo"
                  >Photo</label>
                  <input
                    id="photo"
                    class="inputfile"
                    name="photo"
                    type="file"
                    required
                    @change="setPhoto"
                    @invalid="showImageRequiredToast"
                  >
                  <label
                    for="photo"
                    class="outline-none p-l-none"
                  >
                    <svg
                      height="17"
                      viewBox="0 0 20 17"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                      />
                    </svg>
                    <span>Upload Item Photo</span>
                  </label>
                </div>
                <div class="form__item form__item--more flexCenter m-t-40">
                  <button
                    class="btn btn--primary full-width"
                    :disabled="disableButton"
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "@/components/Search";
  import { pagination } from "@/mixins/Pagination";
  import { callGetApi, callPostApi, callPutApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  export default {
    name: "RewardsStore",
    components: {
      TitleBar,
      Pagination,
      Search
    },
    filters: {
      count: (number) => new Intl.NumberFormat().format(number)
    },
    mixins: [pagination, AdminPermissionsHelper],
    data() {
      return {
        loading: false,
        noData: false,
        rewards: [],
        newReward: {},
        pageNumber: "",
        pageName: "rewardsStorePage",
        disableButton: false,
        searchPlaceholder: "Search by reward name"
      };
    },
    watch: {
      $route: "getRewards"
    },
    created() {
      this.getRewards();
    },
    methods: {
      showImageRequiredToast() {
        this.$notify({
          group: "general",
          title: "Add Reward",
          type: "warn",
          text: "Image required"
        });
      },
      getRewards() {
        this.loading = true;
        const query_params = this.convertToQueryString(this.$route.query);
        callGetApi(`reward?batchSize=9&${query_params}`)
          .then(res => {
            let response = res.body;
            const pageMetaData = response.pageMetaData;

            this.noData = response.entity.length === 0;
            this.rewards = response.entity;
            this.setPageDetails(pageMetaData.totalNoOfRows, pageMetaData.batchSize);
            this.loading = false;
          })
          .catch(console.error);
      },
      searchRewards(criteria) {
        this.loading = true;
        this.$router.push({
          name: "rewards-store",
          query: {
            search: criteria,
            currentPage: 1,
            batchSize: 9,
          }
        });
        this.loading = false;
      },
      disableReward(reward) {
        this.loading = true;
        const enabled = !reward.enabled;
        const action = enabled ? "Enable" : "Disable";
        callPutApi(`reward/${reward.id}/${enabled}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `${action} Reward`,
                text: `Reward ${action.toLowerCase()}d successfully`
              });
            } else {
              this.$notify({
                group: "general",
                title: `${action} Reward`,
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => this.getRewards());
      },
      addReward() {
        this.disableButton = true;
        let formData = new FormData();
        formData.append("name", this.newReward.name);
        formData.append("value", this.newReward.value);
        formData.append("attachment", this.newReward.photo[0]);
        formData.append("enabled", false);

        const headers = new Headers({});

        callPostApi("reward", { body: formData, headers })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Add New Reward",
                text: "Reward added successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Add New Reward",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.log)
          .finally(() => {
            $("#addReward").modal("hide");
            setTimeout(this.$router.go, 1500);
          });
      },
      openAddRewardModal() {
        $("#addReward").modal();
      },
      setPhoto(e) {
        const file = e.target.files;
        const label = e.target.nextElementSibling,
              labelVal = label.innerHTML;

        let fileName = e.target.value.split("\\").pop();

        if (fileName) label.querySelector("span").innerHTML = fileName;
        else label.innerHTML = labelVal;

        this.newReward.photo = file;
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      },
      clearSearch() {
        this.searchQuery = "";
        this.$router.push({
          name: "rewards-store",
          query: {
            currentPage: 1,
            batchSize: 9,
          }
        });
      },
    }
  };
</script>
