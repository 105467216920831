<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Cashout">
      <records-filter
        v-click-outside="closeDropdown"
        :selected="['date']"
        :show-dropdown="showDropdown"
        data="cashout"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>
            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No transaction performed by agents today. See
                    <router-link
                      :to="{ name: 'cashout', query: { currentPage: '1' } }"
                      class="empty__state__link"
                    >
                      all transactions
                    </router-link>
                    instead
                  </div>
                </template>
              </div>

              <div v-else>
                <div class="table table--padded">
                  <div class="table__section table__section--head">
                    <div class="table__row">
                      <div class="table__header">
                        Transaction Date
                      </div>
                      <div class="table__header">
                        Terminal ID
                      </div>
                      <div class="table__header">
                        RRN
                      </div>
                      <div class="table__header">
                        Amount
                      </div>
                      <div class="table__header">
                        Status
                      </div>
                      <div class="table__header" />
                      <div class="table__header" />
                      <div class="table__header" />
                      <div class="table__header" />
                      <div class="table__header" />
                    </div>
                  </div>
                  <div class="table__section table__section--body">
                    <template v-for="(item, i) in cashout">
                      <div
                        :key="i"
                        class="table__row"
                      >
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            {{ item.transactionDate | date }}
                            <latency :latency="item.latency" />
                          </p>
                        </div>
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            {{ item.terminalId }}
                          </p>
                        </div>
                        <div class="table__data">
                          {{ item.traceRef }}
                        </div>
                        <div class="table__data">
                          {{ item.amount | money }}
                        </div>
                        <div class="table__data">
                          <span
                            v-if="item.status === 'SUCCESSFUL'"
                            class="tag tag--green"
                          >
                            Successful
                          </span>
                          <span
                            v-if="item.status === 'PROCESSING'"
                            class="tag tag--yellow"
                          >
                            Processing
                          </span>
                          <span
                            v-if="item.status === 'REVERSED'"
                            class="tag tag--gray"
                          >
                            Reversed
                          </span>
                          <span
                            v-if="item.status === 'CANCELED'"
                            class="tag tag--red"
                          >
                            Declined
                          </span>
                        </div>
                        <div class="table__data posRelative">
                          <download-receipt-options
                            v-if="item.status === 'SUCCESSFUL' || item.statusMessage === 'DECLINED'"
                            class="cursor-pointer"
                            receipt-type="cashout"
                            @download-pdf="downloadReceipt(item)"
                            @download-pdf-as-image="downloadReceiptAsImage(item)"
                          />
                        </div>
                        <div class="table__data">
                          <span
                            v-if="isReversible(item)"
                            class="cursor-pointer"
                            data-target="#confirmRevertCashout"
                            data-toggle="modal"
                            title=" Update to Declined"
                            @click="setselectedObject(item)"
                          >
                            <svg
                              height="10pt"
                              viewBox="0 0 329.26933 329"
                              width="10pt"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="#f44336">
                                <path
                                  d="m21.339844 329.398438c-5.460938 0-10.925782-2.089844-15.082032-6.25-8.34375-8.339844-8.34375-21.824219 0-30.164063l286.589844-286.59375c8.339844-8.339844 21.824219-8.339844 30.164063 0 8.34375 8.339844 8.34375 21.824219 0 30.164063l-286.589844 286.59375c-4.183594 4.179687-9.621094 6.25-15.082031 6.25zm0 0"
                                />
                                <path
                                  d="m307.929688 329.398438c-5.460938 0-10.921876-2.089844-15.082032-6.25l-286.589844-286.59375c-8.34375-8.339844-8.34375-21.824219 0-30.164063 8.339844-8.339844 21.820313-8.339844 30.164063 0l286.589844 286.59375c8.34375 8.339844 8.34375 21.824219 0 30.164063-4.160157 4.179687-9.621094 6.25-15.082031 6.25zm0 0"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                        <div class="table__data">
                          <span
                            v-if="isReversible(item)"
                            class="cursor-pointer"
                            data-target="#confirmCommitCashout"
                            data-toggle="modal"
                            title="Update to Successful"
                            @click="setselectedObject(item)"
                          >
                            <svg
                              height="20px"
                              viewBox="0 0 20 20"
                              width="20px"
                            >
                              <use xlink:href="../../assets/icons/commit.svg#commit_icon" />
                            </svg>
                          </span>
                        </div>
                        <div class="table__data">
                          <span
                            v-if="item.status === 'PROCESSING'"
                            class="cursor-pointer"
                            title="Reconfirm Status"
                            @click="showProviderResponseModal(item)"
                          >
                            <svg
                              height="18px"
                              width="18px"
                            >
                              <use xlink:href="../../assets/icons/provider-response.svg#provider_response_icon" />
                            </svg>
                          </span>
                        </div>
                        <div class="table__data">
                          <span @click="toggleTable(item.tellerpointRef)">
                            <svg
                              height="14"
                              width="14"
                            >
                              <use xlink:href="../../assets/icons/expand-icon.svg#expand_icon" />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="opened.includes(item.tellerpointRef)"
                        :key="item.id"
                        class="collapsible-table"
                      >
                        <div class="card-details__content">
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Agent Name</label>
                            <input
                              :value="item.agentName ? item.agentName : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Card PAN</label>
                            <input
                              :value="item.maskedCardPan ? item.maskedCardPan : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Tlp Ref</label>
                            <input
                              :value="item.tellerpointRef ? item.tellerpointRef : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >STAN</label>
                            <input
                              :value="item.stan ? item.stan : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Provider</label>
                            <input
                              :value="item.provider"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Provider Response</label>
                            <input
                              :value="
                                item.providerResponseCode
                                  ? `${item.providerResponseCode} - ${item.providerResponseMessage}`
                                  : '--'
                              "
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Acquiring Bank</label>
                            <input
                              :value="item.acquiringBank ? `${item.acquiringBank}` : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Settlement Bank</label>
                            <input
                              :value="item.settlementBankName ? `${item.settlementBankName} - ${item.settlementBankAccountNumber}` : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Cardholder Photo</label>
                            <template v-if="item.photoUrl">
                              <button
                                class="btn btn--primary fontNormal"
                                @click="downloadImage(item.photoUrl, item.stan)"
                              >
                                Download
                              </button>
                            </template>
                            <input
                              v-else
                              :value="'--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                        </div>
                      </div>
                    </template>

                    <confirmation-modal
                      id="confirmRevertCashout"
                      :body="`${selectedObject.tellerpointRef} - sure?`"
                      :on-submit="revertCashout"
                      button-text="Yes"
                      header="Update to Declined"
                      :disable-button="loading"
                    />

                    <confirmation-modal
                      id="confirmCommitCashout"
                      :body="`${selectedObject.tellerpointRef} - sure?`"
                      :on-submit="commitCashout"
                      button-text="Yes"
                      header="Update to Successful"
                      :disable-button="loading"
                    />
                    <provider-response
                      :is-open="openProviderResponseModal"
                      :obj="selectedObject"
                      transaction-type="CASHOUT"
                      @close-modal="hideProviderResponseModal"
                    />
                  </div>
                </div>

                <pagination
                  :page-number="computedPageNumbers"
                  data="cashout"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import adminClient from "@/api/AdminClient";
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "@/components/Search";
  import ProviderResponse from "@/components/ProviderResponse";
  import ConfirmationModal from "@/components/ConfirmationModal";
  import DownloadReceiptOptions from "@/components/DownloadReceiptOptions";
  import JsPdf from "jspdf";
  import moment from "moment";
  import { dropdownOperations, providerResponseOperations } from "@/mixins/DropdownOperations";
  import Latency from "@/components/Latency";
  import RecordsFilter from "@/components/Filter";
  import { savePdfAsImage } from "@/downloads/pdfToImage";
  import { callGetApi, callPutApi } from "@/httpClient";

  export default {
    components: {
      TitleBar,
      Pagination,
      Search,
      ProviderResponse,
      Latency,
      RecordsFilter,
      DownloadReceiptOptions,
      ConfirmationModal
    },
    mixins: [providerResponseOperations, dropdownOperations],
    data() {
      return {
        cashout: "",
        loading: true,
        disableModalButton: false,
        searchPlaceholder: "Search by rrn, tlp ref, masked card pan",
        opened: [],
        pageNumber: "",
        noData: false
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("cashoutPage")) {
          return JSON.parse(window.localStorage.getItem("cashoutPage"));
        } else {
          return "";
        }
      },
    },
    watch: {
      //watch for route parameter change and execute method
      $route: "fetchCashOuts"
    },
    created() {
      this.fetchCashOuts();
    },
    methods: {
      /**
       * See if this method can be deleted.
       */
      downloadImage(uri, name) {
        getDataUri(uri, function(dataUri) {
          generar(dataUri);
        });

        function getDataUri(url, cb) {
          const image = new Image();
          image.crossOrigin = "anonymous";

          image.onload = function() {
            const canvas = document.createElement("canvas");
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight;

            canvas.getContext("2d").drawImage(this, 0, 0);

            cb(canvas.toDataURL("image/jpeg"));
          };
          image.src = url;
        }

        function generar(img) {
          const pdf = new JsPdf();
          pdf.addImage(img, "JPG", 15, 40, 100, 100);
          pdf.save("photo_" + name);
        }
      },
      clearSearch() {
        let from = moment(this.from).format("YYYY-MM-DD HH:mm:ss ZZ");
        let to = moment(this.to).format("YYYY-MM-DD HH:mm:ss ZZ");

        // Make sure that the from date has the time set to 00:00:00. this is done by using the .replace function below
        const newdate = from;
        const time = newdate.substring(11, 19);
        from = from.replace(time, "00:00:00");

        to = to.replace(time, "23:59:59");

        from = encodeURIComponent(from);
        to = encodeURIComponent(to);

        this.$router.push({
          name: "cashout",
          query: { currentPage: 1, start: from, end: to }
        });
      },
      fetchCashOuts() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi("cashout?batchSize=20&" + query_params)
          .then(res => {
            this.noData = res.body.entity.length === 0;
            this.cashout = res.body.entity;
            this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            window.localStorage.setItem(
              "cashoutPage",
              Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize)
            );

            this.loading = false;
          })
          .catch(console.error);
      },
      showFile(blob, name) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        const link = document.createElement("a");
        link.href = data;
        link.download = name || "--";
        link.click();
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
      downloadReceipt(item) {
        this.submitDisabled = true;
        adminClient.fetchReceiptUrl(item.tellerpointRef).then(fileUrl => {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = item.traceRef || "--";
          link.click();
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(fileUrl);
          }, 100);
        });
      },
      async downloadReceiptAsImage(item) {
        const fileUrl = await adminClient.fetchReceiptUrl(item.tellerpointRef);
        savePdfAsImage(fileUrl, item.traceRef);
      },
      searchQuery(criteria) {
        this.$router.push({
          name: "cashout",
          query: { criteria }
        });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      },
      isReversible(item) {
        const isProcessing = item.status === "PROCESSING";
        return item.retryCount >= 3 && isProcessing;
      },
      revertCashout() {
        this.disableModalButton = true;
        const queryString = this.convertToQueryString({ tellerpointRef: this.selectedObject.tellerpointRef });
        callPutApi(`merchant/revert-staged-cashout?${queryString}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Revert Cashout",
                text: "Cashout Reverted successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Revert Cashout",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmRevertCashout").modal("hide");
            this.fetchCashOuts();
          });
      },
      commitCashout() {
        this.disableModalButton = true;
        const queryString = this.convertToQueryString({ tellerpointRef: this.selectedObject.tellerpointRef });
        callPutApi(`merchant/commit-staged-cashout?${queryString}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Commit Cashout",
                text: "Cashout Commited successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Commit Cashout",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmCommitCashout").modal("hide");
            this.fetchCashOuts();
          });
      },
      setselectedObject(cashout) {
        this.selectedObject = cashout;
      }
    }
  };
</script>
