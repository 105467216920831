<template>
  <main>
    <div class="login">
      <div class="login__container">
        <h1 class="login__title">
          Forgot Password
        </h1>
        <div class="login__section">
          <div class="login__section__logo">
            <img
              src="../../assets/logo.jpg"
              width="80"
              alt
            >
          </div>
          <template v-if="otpStep">
            <form
              class="login__section__form"
              @submit.prevent="confirmOTP"
            >
              <div class="form__item">
                <input
                  v-model="otp"
                  type="text"
                  class="form__input fontNormal"
                  placeholder="OTP"
                >
              </div>

              <div class="form__item">
                <vue-password
                  v-model="password"
                  classes="form__input fontNormal"
                  :disable-strength="disablestrength"
                  placeholder="New Password"
                />
              </div>

              <div class="form__item form__item--more">
                <button
                  type="submit"
                  class="btn btn--primary btn--block"
                >
                  Create New Password
                </button>
              </div>
            </form>
          </template>
          <template v-else>
            <form
              class="login__section__form"
              @submit.prevent="reset"
            >
              <div class="form__item">
                <input
                  v-model="email"
                  type="email"
                  class="form__input fontNormal"
                  placeholder="Work Email"
                >
                <span
                  class="forgot cursor-pointer"
                  @click="gotoLogin"
                >Login?</span>
              </div>
              <div class="form__item form__item--more">
                <button
                  type="submit"
                  class="btn btn--primary btn--block"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import VuePassword from "vue-password";
  import { callPostApi, callPutApi } from "@/httpClient";

  export default {
    name: "Forgot",
    components: { VuePassword },
    data() {
      return {
        disablestrength: true,
        otpStep: false,
        email: "",
        password: "",
        otp: "",
        stagingId: ""
      };
    },
    methods: {
      gotoLogin() {
        this.$router.push({ name: "login" });
      },
      reset() {
        callPostApi(`password/reset?email=${this.email}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.otpStep = true;
              this.stagingId = res.body.entity.stagingId;
            } else {
              this.$notify({
                group: "general",
                title: "Forgot Password",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      confirmOTP() {
        const body = {
          otp: this.otp,
          stagingId: this.stagingId,
          newPassword: this.password
        };
        callPutApi("password/reset/confirm", { body })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Forgot Password",
                text: "Password changed successfully"
              });
              window.localStorage.setItem("appToken", res.body.entity.sessionId);
              window.localStorage.setItem(
                "adminInfo",
                JSON.stringify(res.body.entity.adminAccountInfo)
              );
              this.$router.push({ name: "dashboard" });
            } else {
              this.$notify({
                group: "general",
                title: "Forgot Password",
                type: "warn",
                text: res.body.status.description
              });
            }
            // this.$router.push({ name: 'login'})
          })
          .catch(console.error);
      }
    }
  };
</script>
