<template>
  <span>
    <transition name="slide-fade">
      <span
        v-click-outside="closeDropdown"
        @click="toggleDropdown"
      >
        <svg
          height="21px"
          width="15px"
        >
          <use xlink:href="../assets/icons/generate-receipt.svg#generate_receipt" />
        </svg>
      </span>
    </transition>

    <transition name="dropdown">
      <div
        v-if="showReceipt"
        class="download-receipt__dropdown__menu"
        :class="{ active: showReceipt }"
      >
        <ul class="download-receipt__dropdown__menu-nav">
          <li class="download-receipt__dropdown__menu-item">
            <a
              class="download-receipt__dropdown__menu-link"
              href="#"
              title="Download Pdf"
              @click="downloadPdf"
            >
              Download PDF
            </a>
          </li>
          <li class="dropdown__menu-item">
            <a
              class="download-receipt__dropdown__menu-link"
              href="#"
              title="Download Pdf"
              @click="downloadPdfAsImage"
            >
              Download Image
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </span>
</template>

<script>
  export default {
    name: "DownloadReceiptOptions",
    props: {
      receiptType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showReceipt: false
      };
    },
    methods: {
      toggleDropdown() {
        this.showReceipt = !this.showReceipt;
      },
      closeDropdown() {
        this.showReceipt = false;
      },
      downloadPdf() {
        this.$emit("download-pdf");
      },
      downloadPdfAsImage() {
        this.$emit("download-pdf-as-image");
      }
    }
  };
</script>

