export const dropdownOperations = {
  data() {
    return {
      showDropdown: false
    };
  },
  methods: {
    closeDropdown() {
      this.showDropdown = false;
    },
    updateDropDownValue(value) {
      this.showDropdown = value;
    }
  }
};

export const providerResponseOperations = {
  data() {
    return {
      openProviderResponseModal: false,
      selectedObject: ""
    };
  },
  methods: {
    hideProviderResponseModal() {
      this.openProviderResponseModal = false;
    },
    showProviderResponseModal(item) {
      this.selectedObject = item;
      this.openProviderResponseModal = true;
    }
  }
};
