<template>
  <main>
    <div class="login">
      <div class="login__container">
        <h1 class="login__title">
          Login
        </h1>
        <div class="login__section">
          <div class="login__section__logo">
            <img
              src="../../assets/logo.jpg"
              width="80"
              alt
            >
          </div>
          <template v-if="otpStep">
            <form
              class="login__section__form"
              @submit.prevent="sendOTP"
            >
              <div class="form__item">
                <input
                  v-model="otp"
                  type="text"
                  class="form__input fontNormal"
                  placeholder="OTP"
                >
              </div>

              <div class="form__item form__item--more">
                <button class="btn btn--primary btn--block">
                  Confirm OTP
                </button>
              </div>
            </form>
          </template>
          <template v-else>
            <form
              class="login__section__form"
              @submit.prevent="login"
            >
              <div class="form__item">
                <input
                  v-model="email"
                  type="email"
                  class="form__input fontNormal"
                  placeholder="Work Email"
                >
              </div>
              <div class="form__item">
                <vue-password
                  id="login__password"
                  v-model="password"
                  classes="fontNormal"
                  :disable-strength="disablestrength"
                  placeholder="Your Password"
                  autocomplete="off"
                />
              </div>
              <div class="form__row">
                <div class="form__row__left">
                  <div class="form__item">
                    <input
                      id="checkbox"
                      v-model="remember"
                      type="checkbox"
                    >
                    <label
                      for="checkbox"
                      style="margin-left: 5px;"
                    >Remember Me</label>
                  </div>
                </div>
                <div class="form__row__right textRight">
                  <span
                    class="forgot cursor-pointer"
                    @click="gotoForgot"
                  >Forgot password?</span>
                </div>
              </div>

              <div class="form__item form__item--more">
                <button class="btn btn--primary btn--block">
                  Log in
                </button>
              </div>
            </form>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import VuePassword from "vue-password";
  import moment from "moment";
  import { callPostApi, callPutApi } from "@/httpClient";

  export default {
    name: "Login",
    components: { VuePassword },
    data() {
      return {
        disablestrength: true,
        otpStep: false,
        email: window.localStorage.getItem("rememberMeEmail") || "",
        password: "",
        otp: "",
        stagingId: "",
        remember: false
      };
    },
    mounted() {
      if (this.$route.query.sessionTimeout === "true") {
        this.showSessionTimeout();
      }
    },
    methods: {
      showSessionTimeout() {
        this.$notify({
          group: "general",
          title: "Session Timeout",
          type: "warn",
          text: "Session timeout. Please log in again to continue",
          duration: -100
        });
      },
      gotoForgot() {
        this.$router.push({ name: "forgot" });
      },
      login() {
        const body = {
          email: this.email,
          password: this.password
        };
        callPostApi("login", { body })
          .then(res => {
            // Removes any notifications if there are any
            this.$notify({
              group: "general",
              clean: true
            });
            if (res.body.status.code === 0) {
              this.otpStep = true;
              this.stagingId = res.body.entity.stagingId;
            } else {
              this.$notify({
                group: "general",
                title: "Login",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      sendOTP() {
        const body = {
          otp: this.otp,
          stagingId: this.stagingId
        };
        callPutApi("login/confirm", { body })
          .then(res => {
            if (res.body.status.code === 0) {
              if (this.remember) {
                window.localStorage.setItem("rememberMeEmail", this.email);
              }
              window.localStorage.setItem("appToken", res.body.entity.sessionId);
              const adminAccountInfo = res.body.entity.adminAccountInfo;
              window.localStorage.setItem("adminInfo", JSON.stringify(adminAccountInfo));
              this.$store.dispatch("setAdminAccountInfo", adminAccountInfo);
              window.localStorage.setItem("loginTime", moment().format());
              this.$router.push({ name: "dashboard" });

            } else {
              this.$notify({
                group: "general",
                title: "Login",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      }
    }
  };
</script>
