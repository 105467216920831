import moment from "moment";

const SERVER_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss ZZ";

export const startOfDay = (date) => {
  return (date ? moment(date) : moment()).startOf("day").format(SERVER_DATE_FORMAT);
};

export const endOfDay = (date) => {
  return (date ? moment(date) : moment()).endOf("day").format(SERVER_DATE_FORMAT);
};

export const startOfYear = () => {
  return moment().startOf("year").format(SERVER_DATE_FORMAT);
};
