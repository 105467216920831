import { Constants } from "@/assets/constants";

export const collapsibleTable = {
  methods: {
    toggleTable(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    }
  }
};

export const hasBlank = {
  methods: {
    isBlank(data) {
      return !(data && data !== "N/A");
    }
  }
};

export const latencyCheck = {
  methods: {
    latencyAboveTreshold(latency) {
      return latency > Constants.LATENCY_THRESHOLD;
    }
  },
  filters: {
    latencyToSeconds(latency) {
      return (latency / 1000).toFixed(2);
    }
  }
};
