<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Transaction Lookup" />

    <div class="dashboard__main__content__home">
      <div class="dashboard__main__content__home__left">
        <div class="big-card unicolumn gap-xl">
          <form @submit.prevent="searchTransaction">
            <div class="form__item">
              <input
                v-model="ref"
                class="form__input form__input--2 fontNormal"
                placeholder="Enter Tlp Ref"
                required="required"
                type="text"
              >
            </div>
            <button
              class="btn btn--primary m-r-20"
              type="submit"
            >
              Search
            </button>
            <button
              v-if="ref && transactions"
              class="btn btn--primary"
              type="submit"
              @click="clearSearch"
            >
              Clear
            </button>
          </form>
        </div>
      </div>

      <div
        v-if="transactions.transactions"
        class="dashboard__main__content__home__right big-card"
      >
        <div class="big-card">
          <div
            v-for="(item, i) in transactions.transactions"
            :key="i"
          >
            <div class="transaction__header">
              <div class="transaction__header__title">
                <template v-if="i === 0 && !isRefunded(item)">
                  <button
                    v-if="item.transactionEvent === 'DISPENSE_ERROR_REFUND'"
                    class="btn btn--primary"
                    @click="reverse()"
                  >
                    Reverse
                  </button>

                  <button
                    v-if="item.transactionEvent === 'CASHOUT' && !['REFUNDED', 'REVERSED', 'CANCELED'].includes(item.extraData.status)"
                    class="btn btn--primary"
                    @click="reverse()"
                  >
                    Reverse
                  </button>

                  <button
                    v-if="item.transactionEvent === 'DISBURSE_TO_BANK' && (item.extraData.status !== 'REFUNDED' || item.extraData.status !== 'REVERSED')"
                    class="btn btn--primary"
                    @click="refund()"
                  >
                    Refund
                  </button>

                  <button
                    v-if="item.transactionEvent === 'BILL_PAYMENT' && (item.extraData.status !== 'REFUNDED' || item.extraData.status !== 'REVERSED')"
                    class="btn btn--primary"
                    @click="refundBillPayment()"
                  >
                    Refund
                  </button>

                  <button
                    v-if="item.transactionEvent === 'FUND_WALLET' && (item.extraData.status !== 'REFUNDED' || item.extraData.status !== 'REVERSED')"
                    class="btn btn--primary"
                    @click="reverseFundWallet()"
                  >
                    Reverse
                  </button>
                </template>
              </div>
            </div>
            <div class="transaction__filters" />
            <div class="transaction__content">
              <div class="card-details__content">
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Agent Name</label>
                  <input
                    :value="transactions.merchantName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Transaction type</label>
                  <input
                    :value="item.transactionEventDescription"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.providerRef"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Transaction Ref</label>
                  <input
                    :value="item.extraData.providerRef"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Date</label>
                  <input
                    :value="item.creationTime | date"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Balance Before</label>
                  <input
                    :value="item.balanceBefore | money"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Transaction Amount</label>
                  <input
                    :value="item.amount | money"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Balance After</label>
                  <input
                    :value="item.balanceAfter | money"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.terminalId"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Terminal ID</label>
                  <input
                    :value="item.extraData.terminalId"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.beneficiaryId"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Beneficiary ID</label>
                  <input
                    :value="item.extraData.beneficiaryId"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.beneficiaryName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Beneficiary Name</label>
                  <input
                    :value="item.extraData.beneficiaryName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.beneficiaryProviderId"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Beneficiary Provider ID</label>
                  <input
                    :value="item.extraData.beneficiaryProviderId"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.beneficiaryProviderName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Beneficiary Provider Name</label>
                  <input
                    :value="item.extraData.beneficiaryProviderName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.sourceId"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Source ID</label>
                  <input
                    :value="item.extraData.sourceId"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.sourceName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Source Name</label>
                  <input
                    :value="item.extraData.sourceName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.sourceProviderId"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Source Provider ID</label>
                  <input
                    :value="item.extraData.sourceProviderId"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.sourceProviderName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Source Provider Name</label>
                  <input
                    :value="item.extraData.sourceProviderName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.dispenseErrorDate"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Dispense Error Date</label>
                  <input
                    :value="item.extraData.dispenseErrorDate"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.stan"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >STAN</label>
                  <input
                    :value="item.extraData.stan"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.traceRef"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >RRN</label>
                  <input
                    :value="item.extraData.traceRef"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.cardHolderName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Cardholder Name</label>
                  <input
                    :value="item.extraData.cardHolderName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.maskedCardPan"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Card Number</label>
                  <input
                    :value="item.extraData.maskedCardPan"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.cardHolderIssuingBank"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Cardholder Issuing Bank</label>
                  <input
                    :value="item.extraData.cardHolderIssuingBank"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.finalizationId"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Finalization ID</label>
                  <input
                    :value="item.extraData.finalizationId"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.surcharge"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Surcharge</label>
                  <input
                    :value="item.extraData.surcharge"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.channel"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Channel</label>
                  <input
                    :value="item.extraData.channel"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.serviceProvider"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Provider</label>
                  <input
                    :value="item.extraData.serviceProvider"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.sessionId"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Session ID</label>
                  <input
                    :value="item.extraData.sessionId"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Status</label>
                  <input
                    :value="item.extraData.status"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.sourceBankName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Source Bank</label>
                  <input
                    :value="item.extraData.sourceBankName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.sourceAccountNumber"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Source Account Number</label>
                  <input
                    :value="item.extraData.sourceAccountNumber"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.sourceAccountName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Source Account Name</label>
                  <input
                    :value="item.extraData.sourceAccountName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.virtualNuban"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Virtual NUBAN</label>
                  <input
                    :value="item.extraData.virtualNuban"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.customerName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Customer Name</label>
                  <input
                    :value="item.extraData.customerName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.customerIdentifier"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Customer Identifier</label>
                  <input
                    :value="item.extraData.customerIdentifier"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>

                <div
                  v-if="item.extraData.merchantDisplayName"
                  class="form__item"
                >
                  <label
                    class="form__label form__label--2"
                    for=""
                  >Biller</label>
                  <input
                    :value="item.extraData.merchantDisplayName"
                    class="form__input capitalize"
                    disabled
                    type="text"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import adminClient from "@/api/AdminClient";
  import { callGetApi, callPutApi } from "@/httpClient";

  export default {
    name: "TransactionLookup",
    components: {
      TitleBar
    },
    data() {
      return {
        transactions: "",
        ref: ""
      };
    },
    watch: {
      "$route": {
        immediate: true,
        handler() {
          this.ref = this.$route.query.ref || "";
          if (this.ref) {
            this.searchTransaction();
          }
        }
      }
    },
    methods: {
      clearSearch() {
        this.ref = "";
        this.transactions = "";
      },
      isRefunded() {
        const allData = this.transactions;
        if (allData) {
          if (allData.transactions.find(item => item.extraData.status === "REFUNDED")) {
            return true;
          } else return !!allData.transactions.find(item => item.extraData.status === "REVERSED");
        }
      },
      searchTransaction() {
        callGetApi(`merchant/transaction/lookup?tellerpointRef=${this.ref}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.transactions = res.body.entity;
            } else {
              this.$notify({
                group: "general",
                title: "Transaction Lookup",
                type: "warn",
                text: res.body.status.description
              });
            }

          })
          .catch(console.error);
      },
      reverse() {
        callPutApi(`merchant/reverse-cashout?tellerpointRef=${this.ref}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Reverse Transaction",
                text: "Transaction reversed successfully"
              });
              this.searchTransaction();
            } else {
              this.$notify({
                group: "general",
                title: "Reverse Transaction",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      refund() {
        callPutApi(`merchant/reverse-disbursement?transactionId=${this.ref}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Refund Transaction",
                text: "Transaction refunded successfully"
              });
              this.searchTransaction();
            } else {
              this.$notify({
                group: "general",
                title: "Refund Transaction",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      async refundBillPayment() {
        await adminClient.refundBillPayment(this.ref)
          .then(res => {
            const { code, description } = res.body.status;
            if (code === 0) {
              this.$notify({
                group: "general",
                title: "Refund Bill Payment",
                text: "Refunded successfully"
              });
            } else {
              throw new Error(`Error ${code}: ${description}`);
            }
          })
          .catch(err => {
            const message = err ? JSON.stringify(err.message, null, 2) : "Error refunding bill payment";
            this.$notify({
              group: "general",
              title: "Refund Bill Payment",
              type: "warn",
              text: message
            });
          })
          .finally(() => this.searchTransaction());
      },
      reverseFundWallet() {
        callPutApi(`fund-wallet/reverse/${this.ref}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Reverse Fund Wallet",
                text: "Transaction reversed successfully"
              });
              this.searchTransaction();
            } else {
              this.$notify({
                group: "general",
                title: "Reverse Fund Wallet",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      }
    }
  };
</script>
