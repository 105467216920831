<template>
  <router-link
    v-if="isAnyOperationPermitted(requiredPermissions)"
    :to="{ name: routerName, query }"
    :class="{ sidebar__item: true, active: $route.name === routerName }"
    exact
  >
    <div>{{ menuName }}</div>
  </router-link>
</template>

<script>
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "SidebarMenuItem",
    mixins: [AdminPermissionsHelper],
    props: {
      routerName: {
        type: String,
        required: true
      },
      menuName: {
        type: String,
        required: true
      },
      query: {
        type: Object,
        default: () => ({})
      },
      requiredPermissions: {
        required: true,
        type: Array
      },
    }
  };
</script>

<style scoped src="./sidebar.css"></style>
