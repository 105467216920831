<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Terminal Health" />

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <!-- Table Data -->
              <div class="table table--padded">
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      TID
                    </div>
                    <div class="table__header">
                      Serial Number
                    </div>
                    <div class="table__header">
                      Power Status
                    </div>
                    <div class="table__header">
                      Battery Status
                    </div>
                    <div class="table__header">
                      Printer Status
                    </div>
                    <div class="table__header" />
                  </div>
                </div>
                <div class="table__section table__section--body">
                  <template v-for="(item, i) in terminals">
                    <div
                      :key="i"
                      class="table__row"
                    >
                      <div class="table__data table__data--full">
                        <p class="table__data--main">
                          {{ item.terminalId ? item.terminalId : "--" }}
                        </p>
                      </div>
                      <div class="table__data">
                        {{ item.serialNumber ? item.serialNumber : "--" }}
                      </div>
                      <div class="table__data">
                        {{ item.powerStatus ? item.powerStatus : "--" }}
                      </div>
                      <div class="table__data">
                        {{ item.batteryStatus ? item.batteryStatus : "--" }}
                      </div>
                      <div class="table__data">
                        {{ item.printerStatus ? item.printerStatus : "--" }}
                      </div>

                      <div class="table__data">
                        <span @click="toggleTable(item.terminalId)">
                          <svg
                            height="14"
                            viewBox="0 0 90 90"
                            width="14"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m80 0h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10v-70c0-5.5-4.5-10-10-10zm6 80c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6v-70c0-3.3 2.7-6 6-6h70c3.3 0 6 2.7 6 6zm-12-62.4v.4 14c0 1.1-.9 2-2 2s-2-.9-2-2v-9.2l-14.6 14.6c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14.6-14.6h-9.2c-1.1 0-2-.9-2-2s.9-2 2-2h14 .4c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1.2.1.4.3.6.6 0 0 .1.1.1.2s.1.1.1.2 0 .1.1.2c-.1-.1-.1-.1 0 0zm-36.6 35c.8.8.8 2 0 2.8l-14.6 14.6h9.2c1.1 0 2 .9 2 2s-.9 2-2 2h-14c-.1 0-.3 0-.4 0s-.1 0-.2-.1c-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.1-.4-.3-.6-.6 0 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1 0-.1-.1-.2 0-.1 0-.3 0-.4v-13.9c0-1.1.9-2 2-2s2 .9 2 2v9.2l14.6-14.6c1-.8 2.2-.8 3 0z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="opened.includes(item.terminalId)"
                      :key="item.terminalId"
                      class="collapsible-table"
                    >
                      <div class="card-details__content">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for
                          >Cloc</label>
                          <input
                            :value="item.cloc ? item.cloc : '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>

                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for
                          >Gps Location</label>
                          <input
                            :value="item.gpsLocation ? item.gpsLocation : '--'"
                            class="form__input capitalize"
                            disabled
                            type="text"
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <pagination
                :page-number="computedPageNumbers"
                data="terminal-health"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "../../components/Search";
  import { callGetApi } from "@/httpClient";

  export default {
    name: "TerminalHealth",
    components: {
      TitleBar,
      Pagination,
      Search
    },
    data() {
      return {
        terminals: [],
        searchPlaceholder: "Search by tid, serial number",
        loading: true,
        pageNumber: "",
        opened: [],
        terminalType: "ptsa"
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("terminalhealthPage")) {
          return JSON.parse(window.localStorage.getItem("terminalhealthPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      "$route": function() {
        this.getTerminalHealth();
      }
    },
    created() {
      this.getTerminalHealth();
    },
    methods: {
      getTerminalHealth() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi(`${this.terminalType}/terminal-health?batchSize=20&${query_params}`)
          .then(res => {
            this.loading = false;
            this.terminals = res.body.entity;
            this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            window.localStorage.setItem(this.pageName, this.pageNumber);
          })
          .catch(console.error);
      },
      searchQuery(criteria) {
        this.$router.push({
          name: "terminal-health",
          query: { criteria }
        });
      },
      clearSearch() {
        this.$router.push({ name: "terminal-health", query: { currentPage: 1 } });
      },
      convertToQueryString(query) {
        let url = "";
        for (const key in query) {
          url += key + "=" + query[key] + "&";
        }
        return url.trim("&");
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      }
    }
  };
</script>
