<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Pending Bank Transfers">
      <button
        v-if="isAnyOperationPermitted(['resetRetryCountForDisbursementsInProcessing'])"
        class="btn btn--primary btn--flex fontNormal m-r-10"
        @click="() => resetRequeryCount('')"
      >
        <span class="btn__text">Reset Retry Count</span>
      </button>
      <records-filter
        v-click-outside="closeDropdown"
        :selected="['date']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>

          <div class="transaction__content">
            <loader v-if="loading" />

            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No pending transfer.
                  </div>
                </template>
              </div>

              <div v-else>
                <div class="table table--padded">
                  <div class="table__section table__section--head">
                    <div class="table__row">
                      <div class="table__header">
                        Transaction Date
                      </div>
                      <div class="table__header">
                        Account Name
                      </div>
                      <div class="table__header">
                        Tlp Ref
                      </div>
                      <div class="table__header">
                        Amount
                      </div>
                      <div class="table__header">
                        Status
                      </div>
                      <div class="table__header" />
                      <div class="table__header" />
                      <div class="table__header" />
                      <div class="table__header" />
                      <div class="table__header" />
                    </div>
                  </div>
                  <div class="table__section table__section--body">
                    <template v-for="(item, i) in transactions">
                      <div
                        :key="i"
                        class="table__row"
                      >
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            {{ item.transactionDate | date }}
                            <latency :latency="item.latency" />
                          </p>
                        </div>
                        <div class="table__data">
                          {{ item.destinationAccountName }} | {{ item.nuban }}
                        </div>
                        <div class="table__data">
                          {{ item.tellerpointRef }}
                        </div>
                        <div class="table__data">
                          {{ item.amount | money }}
                        </div>
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            <span
                              v-if="item.status === 'SUCCESSFUL'"
                              class="tag tag--green"
                            >Successful</span>
                            <span
                              v-if="item.status === 'PROCESSING'"
                              class="tag tag--yellow"
                            >Processing</span>
                            <span
                              v-if="item.status === 'REFUNDED'"
                              class="tag tag--gray"
                            >Refunded</span>
                            <span
                              v-if="item.status === 'FAILED'"
                              class="tag tag--red"
                            >Failed</span>
                          </p>
                        </div>
                        <div
                          class="table__data"
                          title="Update to Successful"
                          @click="showConfirmCommitHeldDisbursement(item)"
                        >
                          <commit-icon />
                        </div>
                        <div
                          class="table__data"
                          title="Reconfirm Status"
                          @click="showProviderResponseModal(item)"
                        >
                          <maximize-icon />
                        </div>
                        <div
                          class="table__data"
                          title="Update to Failed"
                          @click="showUpdateToWebhookFailed(item)"
                        >
                          <cancel-icon />
                        </div>
                        <div
                          class="table__data"
                          title="Reset Query Count"
                          @click="showResetRequeryCount(item)"
                        >
                          <reset-icon />
                        </div>
                        <div class="table__data">
                          <span @click="toggleTable(item.tellerpointRef)">
                            <svg
                              height="14"
                              viewBox="0 0 90 90"
                              width="14"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m80 0h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10v-70c0-5.5-4.5-10-10-10zm6 80c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6v-70c0-3.3 2.7-6 6-6h70c3.3 0 6 2.7 6 6zm-12-62.4v.4 14c0 1.1-.9 2-2 2s-2-.9-2-2v-9.2l-14.6 14.6c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14.6-14.6h-9.2c-1.1 0-2-.9-2-2s.9-2 2-2h14 .4c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1.2.1.4.3.6.6 0 0 .1.1.1.2s.1.1.1.2 0 .1.1.2c-.1-.1-.1-.1 0 0zm-36.6 35c.8.8.8 2 0 2.8l-14.6 14.6h9.2c1.1 0 2 .9 2 2s-.9 2-2 2h-14c-.1 0-.3 0-.4 0s-.1 0-.2-.1c-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.1-.4-.3-.6-.6 0 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1 0-.1-.1-.2 0-.1 0-.3 0-.4v-13.9c0-1.1.9-2 2-2s2 .9 2 2v9.2l14.6-14.6c1-.8 2.2-.8 3 0z"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="opened.includes(item.tellerpointRef)"
                        :key="item.tellerpointRef"
                        class="collapsible-table"
                      >
                        <div class="card-details__content">
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for="bankName"
                            >Bank Name</label>
                            <input
                              id="bankName"
                              :value="item.destinationBank ? item.destinationBank : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for="providerName"
                            >Provider Name</label>
                            <input
                              id="providerName"
                              :value="item.providerName ? item.providerName : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for="providerRef"
                            >Provider Ref</label>
                            <input
                              id="providerRef"
                              :value="item.providerRef ? item.providerRef : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for="providerResponse"
                            >Provider Response</label>
                            <input
                              id="providerResponse"
                              :value="item.providerResponseMessage ? item.providerResponseMessage : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for="agentName"
                            >Agent Name</label>
                            <input
                              id="agentName"
                              :value="item.agentName ? item.agentName : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for="phoneNumber"
                            >Phone Number</label>
                            <input
                              id="phoneNumber"
                              :value="item.phoneNumber !== 'N/A' && item.phoneNumber ? item.phoneNumber : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for="narration"
                            >Narration</label>
                            <input
                              id="narration"
                              :value="item.narration ? item.narration : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for="sessionId"
                            >Session ID</label>
                            <input
                              id="sessionId"
                              :value="item.sessionId ? item.sessionId : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <pagination
                  :page-number="computedPageNumbers"
                  data="pending-bank-transfer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <confirmation-modal
      id="confirmCommitHeldDisbursement"
      :body="`${selectedObject.tellerpointRef} - sure?`"
      :on-submit="commitHeldDisbursement"
      button-text="Yes"
      header="Update to Successful"
    />

    <confirmation-modal
      id="confirmUpdateToWebhookFailed"
      :body="`${selectedObject.tellerpointRef} - sure?`"
      :on-submit="updateDisbursementStatusToWebhookFailed"
      button-text="Yes"
      header="Update to Failed"
    />

    <confirmation-modal
      id="resetQueryCount"
      :body="`${selectedObject.tellerpointRef} - sure?`"
      :on-submit="() => resetRequeryCount(selectedObject.tellerpointRef)"
      button-text="Yes"
      header="Reset Query Count"
    />

    <provider-response
      :is-open="openProviderResponseModal"
      :obj="selectedObject"
      transaction-type="DISBURSE_TO_BANK"
      @close-modal="hideProviderResponseModal"
    />
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import ProviderResponse from "@/components/ProviderResponse";
  import ConfirmationModal from "@/components/ConfirmationModal";
  import Loader from "@/components/Loader";
  import Search from "@/components/Search";
  import Latency from "@/components/Latency";
  import CommitIcon from "@/components/icons/CommitIcon";
  import MaximizeIcon from "@/components/icons/MaximizeIcon";
  import CancelIcon from "@/components/icons/CancelIcon";
  import ResetIcon from "@/components/icons/ResetIcon";
  import DisbursementOperations from "@/mixins/DisbursementOperations";
  import { Constants } from "@/assets/constants";
  import { dropdownOperations, providerResponseOperations } from "@/mixins/DropdownOperations";
  import { callGetApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  import RecordsFilter from "@/components/Filter.vue";

  export default {
    components: {
      RecordsFilter,
      TitleBar,
      Pagination,
      Search,
      Latency,
      Loader,
      ProviderResponse,
      ConfirmationModal,
      CommitIcon,
      MaximizeIcon,
      CancelIcon,
      ResetIcon
    },
    mixins: [DisbursementOperations, providerResponseOperations, AdminPermissionsHelper, dropdownOperations],
    data() {
      return {
        transactions: [],
        loading: true,
        searchPlaceholder: "Search by tlp ref, provider ref, nuban",
        opened: [],
        pageNumber: "",
        noData: false,
        selectedObject: {},
        startDate: Constants.START_OF_DAY(),
        endDate: Constants.END_OF_DAY(),
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("pendingBankTransferPage")) {
          return JSON.parse(window.localStorage.getItem("pendingBankTransferPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      $route: "fetchTransactions"
    },
    created() {
      this.fetchTransactions();
    },
    methods: {
      clearSearch() {
        this.$router.push({ name: "pending-bank-transfer", query: { currentPage: 1, start: this.startDate, end: this.endDate } });
      },
      fetchTransactions() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi(`pending-transfer?batchSize=20&${query_params}`)
          .then(res => {
            this.noData = res.body.entity.length === 0;
            console.log("nodata", this.noData);
            this.transactions = res.body.entity;
            this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            window.localStorage.setItem("pendingBankTransferPage", this.pageNumber);
            this.loading = false;
          })
          .catch(console.error);
      },
      searchQuery(criteria) {
        this.$router.push({ name: "pending-bank-transfer", query: { criteria } });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      }
    }
  };
</script>
