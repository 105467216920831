<template>
  <div style="float: right;">
    <button
      class="btn btn--primary btn--flex fontNormal"
      @click="toggleDropdown"
    >
      <span class="btn__icon">
        <svg
          enable-background="new 0 0 971.986 971.986"
          version="1.1"
          viewBox="0 0 971.986 971.986"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          style="fill: white;"
        >
          <path
            d="m370.22 459.3c10.2 11.1 15.8 25.6 15.8 40.6v442c0 26.601 32.1 40.101 51.1 21.4l123.3-141.3c16.5-19.8 25.6-29.601 25.6-49.2v-272.8c0-15 5.7-29.5 15.8-40.601l353.8-383.9c26.5-28.8 6.101-75.5-33.1-75.5h-873c-39.2 0-59.7 46.6-33.1 75.5l353.8 383.8z"
          />
        </svg>
      </span>
      <span class="btn__text">Filter</span>
    </button>
    <div
      v-if="showDropdown"
      id="myDropdown"
      class="dropdown-content"
    >
      <section>
        <div>
          <div
            v-if="selected.includes('date')"
            class="form__row form__row__filter m-t-20"
          >
            <div class="form__row__left">
              <label
                for
                class="form__label"
              >Start Date</label>
              <date-picker
                v-model="filters.start"
                :lang="lang"
                class="datepicker_width"
                :placeholder="lang.placeholder.date"
                :append-to-body="false"
                :disabled-date="disallowDaysAfterToday"
                @keyup.enter="filterResults"
              />
            </div>
            <div class="form__row__right">
              <label
                for
                class="form__label"
              >End Date</label>
              <date-picker
                v-model="filters.end"
                :lang="lang"
                class="datepicker_width"
                :placeholder="lang.placeholder.date"
                :disabled-date="disallowDaysAfterTodayOrBeforeStartDate"
                :append-to-body="false"
                @keyup.enter="filterResults"
              />
            </div>
          </div>

          <div
            v-if="selected.includes('name')"
            class="form__row form__row__filter m-t-40"
          >
            <div class="form__row__left">
              <label
                for
                class="form__label"
              >Agent Name</label>
              <v-select
                v-model="filters.businessName"
                :disabled="filters.partnerOrg !== null"
                :options="agents"
                :reduce="agent => agent.businessName"
                append-to-body
                label="businessName"
                placeholder="Agent Name"
                style="min-width: 150px; max-width: 200px;"
                @search="searchAgentByName"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching && search.length > 1">
                    No results found for <em>{{ search }}</em>
                  </template>
                  <em
                    v-else
                    style="opacity: 0.5;"
                  >Agent Name</em>
                </template>
              </v-select>
            </div>
            <div class="form__row__right">
              <label
                for
                class="form__label"
              >Account Manager</label>
              <v-select
                v-model="filters.partnerOrg"
                :disabled="filters.businessName !== null"
                :options="accountManagers"
                :reduce="accountManager => accountManager.fullName"
                append-to-body
                label="fullName"
                placeholder="Account Manager"
                style="min-width: 155px; max-width: 200px;"
                @search="searchAccountManager"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching && search.length > 1">
                    No results found for <em>{{ search }}</em>
                  </template>
                  <em
                    v-else
                    style="opacity: 0.5;"
                  >Account Manager</em>
                </template>
              </v-select>
            </div>
          </div>

          <div
            v-if="selected.includes('transactiontype')"
            class="form__row form__row__filter m-t-40"
          >
            <div class="form__row__left">
              <label
                for
                class="form__label"
              >Transaction Type</label>

              <div class="select select--sm">
                <select
                  v-model="filters.transactionType"
                  class="select__input select__input--noborder p-l-none"
                  @keyup.enter="filterResults"
                >
                  <option
                    value
                    selected
                  >
                    Select Transaction Type
                  </option>
                  <option value="CASHOUT">
                    Cashout
                  </option>
                  <option value="DISBURSE_TO_BANK">
                    Bank Transfer
                  </option>
                  <option value="DISBURSE_TO_WALLET">
                    Wallet Transfer
                  </option>
                  <option value="BILL_PAYMENT">
                    Bill Payment
                  </option>
                  <option value="SALE">
                    EFT (Sale)
                  </option>
                </select>
                <div class="select__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                  >
                    <path
                      fill="#637381"
                      fill-rule="evenodd"
                      d="M6.03 3.03L3.025.025.02 3.03h6.01zM5.88 7.071L2.975 9.975.07 7.07H5.88z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="form__row__right">
              <label
                for
                class="form__label"
              >Sort Value</label>
              <template v-if="filters.transactionType === 'CASHOUT'">
                <div class="select select--sm">
                  <select
                    v-model="filters.ranking"
                    class="select__input select__input--noborder p-l-none"
                    @keyup.enter="filterResults"
                  >
                    <option
                      value="0"
                      selected
                    >
                      Sort Value
                    </option>
                    <option value="ASC">
                      Lowest to Highest
                    </option>
                    <option value="DESC">
                      Highest to Lowest
                    </option>
                  </select>
                  <div class="select__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                    >
                      <path
                        fill="#637381"
                        fill-rule="evenodd"
                        d="M6.03 3.03L3.025.025.02 3.03h6.01zM5.88 7.071L2.975 9.975.07 7.07H5.88z"
                      />
                    </svg>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="select select--sm">
                  <select
                    v-model="filters.ranking"
                    class="select__input select__input--noborder p-l-none"
                  >
                    <option
                      value="0"
                      selected
                    >
                      Sort Count
                    </option>
                    <option value="ASC">
                      Lowest to Highest
                    </option>
                    <option value="DESC">
                      Highest to Lowest
                    </option>
                  </select>
                  <div class="select__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                    >
                      <path
                        fill="#637381"
                        fill-rule="evenodd"
                        d="M6.03 3.03L3.025.025.02 3.03h6.01zM5.88 7.071L2.975 9.975.07 7.07H5.88z"
                      />
                    </svg>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div
            v-if="selected.includes('walletHistory')"
            class="form__row form__row__filter m-t-20"
          >
            <div class="form__row__left">
              <label
                for
                class="form__label"
              >Agent Name</label>
              <v-select
                v-model="merchantId"
                :options="agents"
                placeholder="Agent Name"
                append-to-body
                style="min-width: 150px; max-width: 200px;"
                label="businessName"
                :reduce="agent => agent.id"
                :disabled="filters.partnerOrg !== null"
                @search="searchAgentByName"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching && search.length > 1">
                    No results found for <em>{{ search }}</em>
                  </template>
                  <em
                    v-else
                    style="opacity: 0.5;"
                  >Agent Name</em>
                </template>
              </v-select>
            </div>
            <div class="form__row__right">
              <label
                for
                class="form__label"
              >Transaction Type</label>

              <div class="select select--sm">
                <select
                  v-model="filters.transactionType"
                  class="select__input select__input--noborder p-l-none"
                  @keyup.enter="filterResults"
                >
                  <option
                    value
                    selected
                  >
                    Select Transaction Type
                  </option>
                  <option
                    v-if="$route.name === 'agent-wallet-history'"
                    value="NONE"
                  >
                    All Transactions
                  </option>
                  <option value="CASHOUT">
                    Cashout
                  </option>
                  <option value="DISBURSE_TO_BANK">
                    Bank Transfer
                  </option>
                  <option value="DISBURSE_TO_WALLET">
                    Wallet Transfer
                  </option>
                  <option value="FUND_WALLET">
                    Fund Wallet
                  </option>
                  <option value="BILL_PAYMENT">
                    Bill Payment
                  </option>
                  <option value="SALE">
                    EFT (Sale)
                  </option>
                </select>
                <div class="select__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                  >
                    <path
                      fill="#637381"
                      fill-rule="evenodd"
                      d="M6.03 3.03L3.025.025.02 3.03h6.01zM5.88 7.071L2.975 9.975.07 7.07H5.88z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="header">
        <button
          class="clear_button"
          @click="clearcustom"
        >
          Clear
        </button>
        <button
          class="filter_button"
          @click="filterResults"
        >
          Filter
        </button>
      </section>
    </div>
  </div>
</template>

<script>
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import moment from "moment";
  import { accountManagerOperations, merchantOperations } from "@/mixins/MerchantOperations";
  import { callGetApi } from "@/httpClient";
  import { disallowDaysAfterToday } from "@/util/dates";

  export default {
    components: {
      DatePicker,
      vSelect
    },
    mixins: [
      merchantOperations,
      accountManagerOperations
    ],
    props: {
      data: {
        type: String,
        default: null
      },
      selected: {
        type: Array,
        default: () => []
      },
      showDropdown: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          pickers: [
            "next 7 days",
            "next 30 days",
            "previous 7 days",
            "previous 30 days"
          ],
          placeholder: {
            date: "Select Date",
            dateRange: "Select Date Range"
          }
        },
        shortcuts: [
          {
            text: "Today",
            onClick: () => {
              this.time3 = [new Date(), new Date()];
            }
          }
        ],
        timePickerOptions: {
          start: "00:00",
          step: "00:30",
          end: "23:30"
        },
        agents: [],
        accountManagers: [],
        filters: {
          start: this.$route.query.start
            ? this.decodeDates(this.$route.query.start)
            : moment().format("YYYY-MM-DD HH:mm:ss ZZ"),
          end: this.$route.query.end
            ? this.decodeDates(this.$route.query.end)
            : moment().format("YYYY-MM-DD HH:mm:ss ZZ"),
          businessName: this.$route.query.businessName
            ? this.$route.query.businessName
            : null,
          partnerOrg: this.$route.query.partnerOrg
            ? this.$route.query.partnerOrg
            : null,
          transactionType: this.$route.query.transactionType
            ? this.$route.query.transactionType
            : "CASHOUT",
          ranking: this.$route.query.ranking ? this.$route.query.ranking : "DESC"
        },
        suggest: true,
        merchantId: ""
      };
    },
    computed: {
      hasCurrentPage() {
        return !!this.$route.query.currentPage;
      }
    },
    created() {
      this.decodeDates(this.$route.query.start);
    },
    methods: {
      disallowDaysAfterToday,
      disallowDaysAfterTodayOrBeforeStartDate(date) {
        return date < this.filters.start || disallowDaysAfterToday(date);
      },
      decodeDates(value) {
        value = decodeURIComponent(value);
        return value;
      },
      toggleDropdown() {
        this.$emit("toggle-filter-dropdown", !this.showDropdown);
      },
      searchAgentName() {
        const businessName = this.filters.businessName;

        if (businessName.length < 2) {
          return;
        }

        callGetApi(`merchant?batchSize=20&criteria=${businessName}`)
          .then(res => {
            this.loading = false;
            this.suggest = true;
            this.agents = res.body.entity.merchants;

          })
          .catch(console.error);
      },
      clearcustom() {
        const start = encodeURIComponent(moment().format("YYYY-MM-DD HH:mm:ss ZZ"));
        const end = encodeURIComponent(moment().format("YYYY-MM-DD HH:mm:ss ZZ"));

        this.filters.businessName = null;
        this.filters.partnerOrg = null;
        this.filters.transactionType = "CASHOUT";
        this.filters.ranking = "DESC";
        this.filters.start = start;
        this.filters.end = end;

        this.$emit("toggle-filter-dropdown", false);
        if (this.$route.name === "agent-wallet-history") {
          return;
        }
        if (this.$route.name === "stats") {
          this.$router.replace({ name: this.data });
        } else {
          this.$router.replace({ name: this.data, query: { currentPage: 1, start, end } });
        }
      },
      selectedFilter(filter) {
        const selected = this.selected.indexOf(filter);
        return selected !== -1;
      },
      filterResults() {
        const query = {};
        for (const filter in this.filters) {
          if (this.filters[filter] != null && this.filters[filter] !== "") {
            query[filter] = this.filters[filter];
          }
        }

        if (this.$route.name !== "stats") query.currentPage = 1;

        if (this.filters.start != null) {
          if (this.$route.name === "stats" || this.$route.name === "agent-wallet-history") {
            // Make sure that the start date has the time set to 00:00:00. this is done by using the .replace function below
            let filterStart = String(this.filters.start);

            filterStart = moment(filterStart).format("YYYY-MM-DD HH:mm:ss ZZ");

            const newdate = filterStart;
            const time = newdate.substring(11, 19);
            filterStart = filterStart.replace(time, "00:00:00");
            query.start = encodeURIComponent(filterStart);
          } else {
            query.start = encodeURIComponent(
              moment(this.filters.start).format("YYYY-MM-DD HH:mm:ss ZZ")
            );
          }
        }
        if (this.filters.end != null) {
          if (this.$route.name === "stats" || this.$route.name === "agent-wallet-history") {
            // Make sure that the from date has the time set to 00:00:00. this is done by using the .replace function below
            let filterEnd = String(this.filters.end);

            query.end = this.formatEndDate(filterEnd);
          } else {
            query.end = this.formatEndDate(this.filters.end);
          }
        }

        if (this.$route.name === "stats" || this.$route.name === "fund-wallet") {
          delete query.transactionType;
          delete query.ranking;
        }

        if (this.$route.name === "agent-wallet-history") {
          query.merchantId = this.merchantId;

          if (query.transactionType === "NONE") {
            delete query.transactionType;
          }

          delete query.businessName;
          delete query.ranking;
        }
        this.$router.replace({ name: this.data, query: query });
        this.$emit("toggle-filter-dropdown", false);
      },
      formatEndDate(endDate) {
        const formattedEndDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss ZZ");
        let endTime = formattedEndDate.substring(11, 19);
        let newEndDate = formattedEndDate.replace(endTime, "23:59:59");
        return encodeURIComponent(newEndDate);
      }
    }
  };
</script>


<style scoped>
  .datepicker_width {
    width: 150px;
  }

  .dropdown-content {
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    color: black;
    display: block;
    padding: 12px 16px;
    position: absolute;
    right: 20px;
    text-decoration: none;
    top: 50px;
    width: 420px;
    z-index: 1;
  }

  .dropdown-content::before {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    top: -8px;
    right: 12px;
    z-index: 2;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }

  .dropdown-content::after {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    top: -9px;
    right: 12px;
    z-index: 1;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(33, 43, 54, 0.08);
  }

  .dropdown-content .select {
    width: 150px;
  }

  .select__input {
    background: transparent;
  }

  .dropdown-content .select__input {
    padding: 0 !important;
  }

  .dropdown-content .select__icon {
    right: 1px;
    top: 10px;
  }

  .dropdown-content section {
    border: 2px solid #f1f1f3;
    padding: 12px 16px;
    cursor: pointer;
  }

  .dropdown-content section.header {
    border: 2px solid #f1f1f3;
    padding: 6px 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-content section:hover {
    background-color: #fafcff;
  }

  .dropdown-content section.header:hover {
    background-color: #f9f9f9;
  }

  .filter_button {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    background-color: #3c51dd;
    border-radius: 4px;
    border: none;
    box-shadow: 1px 1px 3px 0 rgba(50, 50, 54, 0.13), 0 0 3px 1px rgba(63, 63, 68, 0.05);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.71;
    margin: 3px;
    outline: none;
    padding: 4px 12px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }

  .clear_button {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    background-color: #fff;
    border-radius: 4px;
    border: none;
    box-shadow: 1px 1px 3px 0 rgba(50, 50, 54, 0.13), 0 0 3px 1px rgba(63, 63, 68, 0.05);
    color: #212b36;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.71;
    margin: 3px;
    outline: none;
    padding: 4px 12px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }

  .filter_button:hover {
    background-color: #1f32b2;
  }
</style>
