export const BankList = [
  {
    "Id": 132,
    "Code": "560",
    "Name": "Page MFBank"
  },
  {
    "Id": 133,
    "Code": "304",
    "Name": "Stanbic Mobile Money"
  },
  {
    "Id": 134,
    "Code": "308",
    "Name": "FortisMobile"
  },
  {
    "Id": 135,
    "Code": "328",
    "Name": "TagPay"
  },
  {
    "Id": 136,
    "Code": "309",
    "Name": "FBNMobile"
  },
  {
    "Id": 137,
    "Code": "011",
    "Name": "First Bank of Nigeria"
  },
  {
    "Id": 138,
    "Code": "326",
    "Name": "Sterling Mobile"
  },
  {
    "Id": 139,
    "Code": "990",
    "Name": "Omoluabi Mortgage Bank"
  },
  {
    "Id": 140,
    "Code": "311",
    "Name": "ReadyCash (Parkway)"
  },
  {
    "Id": 141,
    "Code": "057",
    "Name": "Zenith Bank"
  },
  {
    "Id": 142,
    "Code": "068",
    "Name": "Standard Chartered Bank"
  },
  {
    "Id": 143,
    "Code": "306",
    "Name": "eTranzact"
  },
  {
    "Id": 144,
    "Code": "070",
    "Name": "Fidelity Bank"
  },
  {
    "Id": 145,
    "Code": "023",
    "Name": "CitiBank"
  },
  {
    "Id": 146,
    "Code": "215",
    "Name": "Unity Bank"
  },
  {
    "Id": 147,
    "Code": "323",
    "Name": "Access Money"
  },
  {
    "Id": 148,
    "Code": "302",
    "Name": "Eartholeum"
  },
  {
    "Id": 149,
    "Code": "324",
    "Name": "Hedonmark"
  },
  {
    "Id": 150,
    "Code": "325",
    "Name": "MoneyBox"
  },
  {
    "Id": 151,
    "Code": "301",
    "Name": "JAIZ Bank"
  },
  {
    "Id": 152,
    "Code": "050",
    "Name": "Ecobank Plc"
  },
  {
    "Id": 153,
    "Code": "307",
    "Name": "EcoMobile"
  },
  {
    "Id": 154,
    "Code": "318",
    "Name": "Fidelity Mobile"
  },
  {
    "Id": 155,
    "Code": "319",
    "Name": "TeasyMobile"
  },
  {
    "Id": 156,
    "Code": "999",
    "Name": "NIP Virtual Bank"
  },
  {
    "Id": 157,
    "Code": "320",
    "Name": "VTNetworks"
  },
  {
    "Id": 158,
    "Code": "221",
    "Name": "Stanbic IBTC Bank"
  },
  {
    "Id": 159,
    "Code": "501",
    "Name": "Fortis Microfinance Bank"
  },
  {
    "Id": 160,
    "Code": "329",
    "Name": "PayAttitude Online"
  },
  {
    "Id": 161,
    "Code": "322",
    "Name": "ZenithMobile"
  },
  {
    "Id": 162,
    "Code": "303",
    "Name": "ChamsMobile"
  },
  {
    "Id": 163,
    "Code": "403",
    "Name": "SafeTrust Mortgage Bank"
  },
  {
    "Id": 164,
    "Code": "551",
    "Name": "Covenant Microfinance Bank"
  },
  {
    "Id": 165,
    "Code": "415",
    "Name": "Imperial Homes Mortgage Bank"
  },
  {
    "Id": 166,
    "Code": "552",
    "Name": "NPF MicroFinance Bank"
  },
  {
    "Id": 167,
    "Code": "526",
    "Name": "Parralex"
  },
  {
    "Id": 168,
    "Code": "035",
    "Name": "Wema Bank"
  },
  {
    "Id": 169,
    "Code": "084",
    "Name": "Enterprise Bank"
  },
  {
    "Id": 170,
    "Code": "063",
    "Name": "Diamond Bank"
  },
  {
    "Id": 171,
    "Code": "305",
    "Name": "Paycom"
  },
  {
    "Id": 172,
    "Code": "100",
    "Name": "SunTrust Bank"
  },
  {
    "Id": 173,
    "Code": "317",
    "Name": "Cellulant"
  },
  {
    "Id": 174,
    "Code": "401",
    "Name": "ASO Savings and & Loans"
  },
  {
    "Id": 175,
    "Code": "030",
    "Name": "Heritage"
  },
  {
    "Id": 176,
    "Code": "402",
    "Name": "Jubilee Life Mortgage Bank"
  },
  {
    "Id": 177,
    "Code": "058",
    "Name": "GTBank Plc"
  },
  {
    "Id": 178,
    "Code": "032",
    "Name": "Union Bank"
  },
  {
    "Id": 179,
    "Code": "232",
    "Name": "Sterling Bank"
  },
  {
    "Id": 180,
    "Code": "076",
    "Name": "Skye Bank"
  },
  {
    "Id": 181,
    "Code": "082",
    "Name": "Keystone Bank"
  },
  {
    "Id": 182,
    "Code": "327",
    "Name": "Pagatech"
  },
  {
    "Id": 183,
    "Code": "559",
    "Name": "Coronation Merchant Bank"
  },
  {
    "Id": 184,
    "Code": "601",
    "Name": "FSDH"
  },
  {
    "Id": 185,
    "Code": "313",
    "Name": "Mkudi"
  },
  {
    "Id": 186,
    "Code": "214",
    "Name": "First City Monument Bank"
  },
  {
    "Id": 187,
    "Code": "314",
    "Name": "FET"
  },
  {
    "Id": 188,
    "Code": "523",
    "Name": "Trustbond"
  },
  {
    "Id": 189,
    "Code": "315",
    "Name": "GTMobile"
  },
  {
    "Id": 190,
    "Code": "033",
    "Name": "United Bank for Africa"
  },
  {
    "Id": 191,
    "Code": "044",
    "Name": "Access Bank"
  }
];
