<template>
  <div class="table table--padded">
    <DataTableHead :header-data="tableHeader" />

    <div class="table__section table__section--body">
      <template v-if="(dispense.receiptType === transactionType.DISPENSE_ERROR_REFUND)">
        <div class="table__row">
          <div class="table__data">
            {{ dispense.providerTransactionTime | date }}
          </div>

          <div class="table__data">
            <p class="table__data--main">
              {{ dispense.maskedCardPan }}
            </p>
          </div>

          <div class="table__data">
            {{ dispense.traceRef }}
          </div>
          <div class="table__data">
            {{ dispense.amount | money }}
          </div>

          <div class="table__data">
            <span
              v-if="dispense.status === 'SUCCESSFUL'"
              class="tag tag--green"
            >Refunded</span>
            <span
              v-if="dispense.status === 'REFUNDED' || dispense.status === 'PROCESSING'"
              class="tag tag--yellow"
            >
              <template v-if="dispense.status === 'PROCESSING'">Processing</template>
              <template v-else>Refunded</template>
            </span>
            <span
              v-if="dispense.status === 'REVERSED'"
              class="tag tag--gray"
            >Reversed</span>
            <span
              v-if="dispense.status === 'CANCELED' || dispense.status === 'FAILED'"
              class="tag tag--red"
            >
              <template v-if="dispense.status === 'CANCELED'">Accepted</template>
              <template v-else>Failed</template>
            </span>
          </div>

          <div class="table__data table__data__dispense">
            <span
              v-if="dispense.status === 'PROCESSING'"
              class="cursor-pointer"
              title="Accept claim at bank"
              @click="showConfirmModal(dispense)"
            >
              <svg
                height="22"
                viewBox="0 0 32 40"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                d="m28 24h-1v-13h2a1 1 0 0 0 .47-1.88l-13-7a1 1 0 0 0 -.94 0l-13 7a1 1 0 0 0 .47 1.88h2v13h-1a2 2 0 0 0 -2 2v2a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2v-2a2 2 0 0 0 -2-2zm-3 0h-3v-13h3zm-8 0v-13h3v13zm-5 0v-13h3v13zm4-19.86 9 4.86h-18zm-9 6.86h3v13h-3zm-3 17v-2h24v2z"
              /></svg>
            </span>
          </div>

          <div class="table__data posRelative">
            <download-receipt-options
              v-if="dispense.status === 'SUCCESSFUL' || dispense.status === 'PROCESSING' || (dispense.nuban)"
              class="cursor-pointer"
              title="Generate Receipt"
              receipt-type="dispense-error"
              @download-pdf="generateReceipt(dispense, 'dispense-error')"
              @download-pdf-as-image="generateReceiptAsImage(dispense, 'dispense-error')"
            />
          </div>

          <div class="table__data">
            <span @click="toggleTable(dispense.tellerpointRef)">
              <svg
                height="14"
                viewBox="0 0 90 90"
                width="14"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                d="m80 0h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10v-70c0-5.5-4.5-10-10-10zm6 80c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6v-70c0-3.3 2.7-6 6-6h70c3.3 0 6 2.7 6 6zm-12-62.4v.4 14c0 1.1-.9 2-2 2s-2-.9-2-2v-9.2l-14.6 14.6c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14.6-14.6h-9.2c-1.1 0-2-.9-2-2s.9-2 2-2h14 .4c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1.2.1.4.3.6.6 0 0 .1.1.1.2s.1.1.1.2 0 .1.1.2c-.1-.1-.1-.1 0 0zm-36.6 35c.8.8.8 2 0 2.8l-14.6 14.6h9.2c1.1 0 2 .9 2 2s-.9 2-2 2h-14c-.1 0-.3 0-.4 0s-.1 0-.2-.1c-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.1-.4-.3-.6-.6 0 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1 0-.1-.1-.2 0-.1 0-.3 0-.4v-13.9c0-1.1.9-2 2-2s2 .9 2 2v9.2l14.6-14.6c1-.8 2.2-.8 3 0z"
              /></svg>
            </span>
          </div>
        </div>
        <div
          v-if="opened.includes(dispense.tellerpointRef)"
          :key="dispense.tellerpointRef"
          class="collapsible-table"
        >
          <div class="card-details__content">
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Terminal ID</label>
              <input
                :value="dispense.terminalId || '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Tlp Ref</label>
              <input
                :value="dispense.tellerpointRef || '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >STAN</label>
              <input
                :value="dispense.stan || '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Card Type</label>
              <input
                :value="dispense.cardType || '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Agent Name</label>
              <input
                :value="dispense.agentName || '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for=""
              >Processed Date</label>
              <input
                :value="dispense.creationTime | date"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for="nuban"
              >Nuban</label>
              <input
                id="nuban"
                :value="dispense.nuban || '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label class="form__label form__label--2">Bank Code</label>
              <input
                :value="dispense.bankCode || '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item posRelative form__item__download">
              <label
                class="form__label form__label--2"
                for=""
              >Cardholder Photo</label>
              <template v-if="dispense.photoUrl">
                <button
                  class="btn btn--primary fontNormal"
                  @click="downloadImage(dispense.photoUrl, dispense.stan)"
                >
                  Download
                </button>
              </template>
              <input
                v-else
                :value="'--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
          </div>
        </div>
      </template>

      <template v-if="(dispense.receiptType === transactionType.CASHOUT)">
        <div class="table__row">
          <div class="table__data table__data--full">
            <p class="table__data--main">
              {{ dispense.transactionDate | date }}
              <latency :latency="dispense.latency" />
            </p>
          </div>
          <div class="table__data">
            <p class="table__data--main">
              {{ dispense.maskedCardPan }}
            </p>
          </div>
          <div class="table__data">
            {{ dispense.traceRef }}
          </div>
          <div class="table__data">
            {{ dispense.amount | money }}
          </div>
          <div class="table__data">
            <span
              v-if="dispense.status === 'SUCCESSFUL'"
              class="tag tag--green"
            >
              Successful
            </span>
            <span
              v-if="dispense.status === 'PROCESSING'"
              class="tag tag--yellow"
            >
              Processing
            </span>
            <span
              v-if="dispense.status === 'REVERSED'"
              class="tag tag--gray"
            >
              Reversed
            </span>
            <span
              v-if="dispense.status === 'CANCELED'"
              class="tag tag--red"
            >
              Declined
            </span>
          </div>

          <div class="table__data" />

          <div class="table__data posRelative">
            <download-receipt-options
              v-if="dispense.status === 'SUCCESSFUL' || dispense.statusMessage === 'DECLINED'"
              class="cursor-pointer"
              receipt-type="cashout"
              @download-pdf="generateReceipt(dispense, '')"
              @download-pdf-as-image="generateReceiptAsImage(dispense, '')"
            />
          </div>
          <div class="table__data">
            <span @click="toggleTable(dispense.tellerpointRef)">
              <svg
                height="14"
                width="14"
              >
                <use xlink:href="../assets/icons/expand-icon.svg#expand_icon" />
              </svg>
            </span>
          </div>
        </div>
        <div
          v-if="opened.includes(dispense.tellerpointRef)"
          :key="dispense.id"
          class="collapsible-table"
        >
          <div class="card-details__content">
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Agent Name</label>
              <input
                :value="dispense.agentName ? dispense.agentName : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >STAN</label>
              <input
                :value="dispense.stan ? dispense.stan : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Tlp Ref</label>
              <input
                :value="dispense.tellerpointRef ? dispense.tellerpointRef : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>

            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Terminal ID</label>
              <input
                :value="dispense.terminalId ? dispense.terminalId : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>

            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Provider</label>
              <input
                :value="dispense.provider"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>

            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Provider Response</label>
              <input
                :value="
                  dispense.providerResponseCode
                    ? `${dispense.providerResponseCode} - ${dispense.providerResponseMessage}`
                    : '--'
                "
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>

            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Acquiring Bank</label>
              <input
                :value="dispense.acquiringBank ? `${dispense.acquiringBank}` : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>

            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Settlement Bank</label>
              <input
                :value="dispense.settlementBankName ? `${dispense.settlementBankName} - ${dispense.settlementBankAccountNumber}` : '--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>

            <div class="form__item">
              <label
                class="form__label form__label--2"
                for
              >Cardholder Photo</label>
              <template v-if="dispense.photoUrl">
                <button
                  class="btn btn--primary fontNormal"
                  @click="downloadImage(dispense.photoUrl, dispense.stan)"
                >
                  Download
                </button>
              </template>
              <input
                v-else
                :value="'--'"
                class="form__input capitalize"
                disabled
                type="text"
              >
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import adminClient from "@/api/AdminClient";
  import JsPdf from "jspdf";
  import DownloadReceiptOptions from "@/components/DownloadReceiptOptions";
  import { savePdfAsImage } from "@/downloads/pdfToImage";
  import Latency from "@/components/Latency";
  import { saveAs } from "file-saver";
  import DataTableHead from "@/components/DataTableHead";

  const transactionType = Object.freeze({
    CASHOUT: "CASHOUT",
    DISPENSE_ERROR_REFUND: "DISPENSE_ERROR_REFUND",
  });

  export default {
    name: "ReceiptRecords",
    components: {
      DataTableHead,
      Latency,
      DownloadReceiptOptions
    },
    props: {
      dispense: {
        type: Object,
        required: true
      },
      acceptClaim: {
        type: Function,
        required: true
      },
      showConfirmModal: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        transactionType,
        opened: [],
        tableHeader:  ["Transaction Date","Masked PAN","RRN","Amount","Status","","", ""],
      };
    },
    methods: {
      downloadImage(uri, name) {
        getDataUri(uri, function(dataUri) {
          generate(dataUri);
        });

        function getDataUri(url, cb) {
          const image = new Image();
          image.crossOrigin = "anonymous";

          image.onload = function() {
            const canvas = document.createElement("canvas");
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight;
            canvas.getContext("2d").drawImage(this, 0, 0);
            cb(canvas.toDataURL("image/jpeg"));
          };
          image.src = url;
        }

        function generate(img) {
          const pdf = new JsPdf();
          pdf.addImage(img, "JPG", 15, 40, 100, 100);
          pdf.save("photo_" + name);
        }
      },
      async generateReceipt(item, type) {
        await adminClient.fetchReceiptUrl(item.tellerpointRef, type).then(fileUrl => {
          fetch(fileUrl).then(r => r.blob()).then(blob => {
            saveAs(blob, item.traceRef || "--");
          });
        });
      },
      async generateReceiptAsImage(item, type) {
        try {
          const fileUrl = await adminClient.fetchReceiptUrl(item.tellerpointRef, type);
          savePdfAsImage(fileUrl, item.traceRef);
        } catch (e) {
          console.error(e);
        }
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      }
    }
  };
</script>
