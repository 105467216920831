import { saveAs } from "file-saver";
import * as pdfjsLib from "pdfjs-dist/build/pdf.js";

pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.min.js";

export const savePdfAsImage = async (url, filename) => {
  const pdf = await pdfjsLib.getDocument(url).promise;
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: 1 });

  const canvas = document.createElement("canvas");
  document.body.appendChild(canvas);
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  canvas.style.zIndex = "5";
  canvas.style.display = "none";

  const context = canvas.getContext("2d");
  context.scale(1.9,1.9);

  await page.render({
    canvasContext: context,
    viewport: viewport
  }).promise;

  const dataUrl = canvas.toDataURL("image/png");
  await saveAs(dataUrl, filename);

  document.body.removeChild(canvas);
};
