<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Wallet History">
      <button
        v-if="showEmailReportButton && isAnyOperationPermitted(['generateMerchantWalletHistoryReport'])"
        class="btn btn--flex fontNormal font-size-15px btn--primary m-r-10"
        @click="() => sendReportToEmail() "
      >
        <span class="btn__text">Send report to my email</span>
      </button>

      <records-filter
        v-click-outside="closeDropdown"
        data="agent-wallet-history"
        :selected="['date','walletHistory']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template>
                  <div>
                    No transaction performed by <b>{{ merchantId }}</b> between <b>{{ from.substr(0, 10) }}</b> and
                    <b>{{ to.substr(0, 10) }}</b>
                  </div>
                </template>
              </div>

              <div v-else>
                <div class="table table--padded">
                  <div class="table__section table__section--head">
                    <div class="table__row">
                      <div class="table__header">
                        Transaction Date
                      </div>
                      <div class="table__header">
                        Tlp Ref
                      </div>
                      <div class="table__header">
                        Debit
                      </div>
                      <div class="table__header">
                        Credit
                      </div>
                      <div class="table__header">
                        Balance Before
                      </div>
                      <div class="table__header">
                        Balance After
                      </div>
                      <div class="table__header" />
                    </div>
                  </div>
                  <div class="table__section table__section--body">
                    <template v-for="(item, i) in history">
                      <div
                        :key="i"
                        class="table__row"
                      >
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            {{ item.creationTime | date }}
                          </p>
                        </div>
                        <div class="table__data">
                          {{ item.transactionEventIdentifier }}
                        </div>
                        <div class="table__data red_font">
                          {{
                            ((0 > item.amount) ? Math.abs(item.amount) : 0) | money
                          }}
                        </div>
                        <div class="table__data green_font">
                          {{ ((item.amount > 0) ? item.amount : 0) | money }}
                        </div>
                        <div class="table__data">
                          {{ item.balanceBefore | money }}
                        </div>
                        <div class="table__data">
                          {{ item.balanceAfter | money }}
                        </div>
                        <div class="table__data">
                          <span @click="toggleTable(item.id)">
                            <svg
                              height="14"
                              viewBox="0 0 90 90"
                              width="14"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m80 0h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10v-70c0-5.5-4.5-10-10-10zm6 80c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6v-70c0-3.3 2.7-6 6-6h70c3.3 0 6 2.7 6 6zm-12-62.4v.4 14c0 1.1-.9 2-2 2s-2-.9-2-2v-9.2l-14.6 14.6c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14.6-14.6h-9.2c-1.1 0-2-.9-2-2s.9-2 2-2h14 .4c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1.2.1.4.3.6.6 0 0 .1.1.1.2s.1.1.1.2 0 .1.1.2c-.1-.1-.1-.1 0 0zm-36.6 35c.8.8.8 2 0 2.8l-14.6 14.6h9.2c1.1 0 2 .9 2 2s-.9 2-2 2h-14c-.1 0-.3 0-.4 0s-.1 0-.2-.1c-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.1-.4-.3-.6-.6 0 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1 0-.1-.1-.2 0-.1 0-.3 0-.4v-13.9c0-1.1.9-2 2-2s2 .9 2 2v9.2l14.6-14.6c1-.8 2.2-.8 3 0z"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="opened.includes(item.id)"
                        :key="item.id"
                        class="collapsible-table"
                      >
                        <div class="card-details__content">
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Summary</label>
                            <input
                              :value="item.summary || '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div
                            v-if="item.extraData.transactionEventDescription"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Transaction
                              Event</label>
                            <input
                              :value="item.extraData.transactionEventDescription"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div
                            v-if="item.extraData.status"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Status</label>
                            <input
                              :value="item.extraData.status"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div
                            v-if="item.extraData.beneficiaryName"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Beneficiary
                              Name</label>
                            <input
                              :value="item.extraData.beneficiaryName"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.beneficiaryProviderName"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Beneficiary Bank</label>
                            <input
                              :value="item.extraData.beneficiaryProviderName"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.sourceId"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Merchant ID</label>
                            <input
                              :value="item.extraData.sourceId"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.sourceName"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Merchant Name</label>
                            <input
                              :value="item.extraData.sourceName"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.surcharge"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Surcharge</label>
                            <input
                              :value="item.extraData.surcharge"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.channel"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Channel</label>
                            <input
                              :value="item.extraData.channel"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.sessionId"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Session ID</label>
                            <input
                              :value="item.extraData.sessionId"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.sourceAccountName"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Source Account
                              Name</label>
                            <input
                              :value="item.extraData.sourceAccountName"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.sourceBankName"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Source Bank</label>
                            <input
                              :value="item.extraData.sourceBankName"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.tellerpointRef"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Tellerpoint Ref</label>
                            <input
                              :value="item.extraData.tellerpointRef"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.virtualNuban"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Virtual Nuban</label>
                            <input
                              :value="item.extraData.virtualNuban"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.cardType"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Card Type</label>
                            <input
                              :value="item.extraData.cardType"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.dispenseErrorDate"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Dispense Error
                              Date</label>
                            <input
                              :value="item.extraData.dispenseErrorDate"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.maskedCardPan"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Masked Card Pan</label>
                            <input
                              :value="item.extraData.maskedCardPan"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.terminalId"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Terminal ID</label>
                            <input
                              :value="item.extraData.terminalId"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>

                          <div
                            v-if="item.extraData.providerRef"
                            class="form__item"
                          >
                            <label
                              class="form__label form__label--2"
                              for
                            >Provider Ref</label>
                            <input
                              :value="item.extraData.providerRef"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <pagination
                  :page-number="computedPageNumbers"
                  data="agent-wallet-history"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import RecordsFilter from "@/components/Filter";
  import { dropdownOperations } from "@/mixins/DropdownOperations";
  import { callGetApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "AgentWalletHistory",
    components: {
      TitleBar, Pagination, RecordsFilter
    },
    mixins: [dropdownOperations, AdminPermissionsHelper],
    data() {
      return {
        from: "",
        to: "",
        formattedStartDate: "",
        formattedEndDate: "",
        merchantId: "",
        order: "",
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          pickers: [
            "next 7 days",
            "next 30 days",
            "previous 7 days",
            "previous 30 days"
          ],
          placeholder: {
            date: "Select Date",
            dateRange: "Select Date Range"
          }
        },
        noData: false,
        loading: false,
        history: [],
        opened: [],
        pageNumber: "",
        showDropdown: false,
        showEmailReportButton: false
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("agentWalletHistoryPage")) {
          return JSON.parse(window.localStorage.getItem("agentWalletHistoryPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      "$route": "fetchAgentHistory"
    },
    created() {
      window.localStorage.setItem("agentWalletHistoryPage", 0);
    },
    methods: {
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      },
      fetchAgentHistory: function() {
        this.loading = true;
        const query = this.$route.query;

        const query_params = this.convertToQueryString(query);
        this.from = query.start;
        this.to = query.end;
        this.merchantId = query.merchantId;

        callGetApi(`merchant/wallet-history?batchSize=20&${query_params}`)
          .then(res => {
            this.noData = res.body.entity.length === 0;
            this.history = res.body.entity;
            this.showEmailReportButton = !this.noData;

            this.pageNumber = Math.ceil(
              res.body.pageMetaData.totalNoOfRows /
                res.body.pageMetaData.batchSize
            );

            window.localStorage.setItem(
              "agentWalletHistoryPage",
              Math.ceil(
                res.body.pageMetaData.totalNoOfRows /
                  res.body.pageMetaData.batchSize
              )
            );

            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            this.history = [];
            this.showEmailReportButton = false;
            window.localStorage.setItem("agentWalletHistoryPage", 0);
            console.error(err);
          });

      },
      sendReportToEmail() {
        const query = this.$route.query;
        const query_params = this.convertToQueryString(query);
        callGetApi(`merchant/wallet-history/report?${query_params}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Wallet History Report",
                text: "Report has been sent to your email"
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Wallet History",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error);
      },
      updateDropDownValue(value) {
        this.showDropdown = value;
      }
    }
  };
</script>

<style scoped>
  .red_font {
    color: #d0011b;
  }

  .green_font {
    color: #026440;
  }
</style>
