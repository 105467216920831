import QueryString from "query-string";
import Vue from "../main";

const PDF_MIME_TYPE = "application/pdf";

export class AdminClient {
  commitHeldDisbursements(transactionId) {
    const queryString = QueryString.stringify({
      transactionId
    });
    return Vue.$http.put(`merchant/commit-held-disbursement?${queryString}`);
  }

  updateDisbursementStatusToWebhookFailed(transactionId) {
    return Vue.$http.put(`disbursement/transaction-status/${transactionId}`);
  }

  refundBillPayment(transactionRef) {
    return Vue.$http.put(`bill-payment/refund/${transactionRef}`);
  }

  async fetchReceiptUrl(tellerpointRef, type) {
    let request;
    if (type === "dispense-error") {
      request = Vue.$http.get(`merchant/dispense-error/receipt/${tellerpointRef}`, {
        responseType: "blob",
        headers: { "Accept": PDF_MIME_TYPE }
      });
    } else if (type === "sales") {
      request = Vue.$http.get(`sales/receipt/${tellerpointRef}`, {
        responseType: "blob",
        headers: { "Accept": PDF_MIME_TYPE }
      });
    } else {
      request = Vue.$http.get(`cashout/receipt/${tellerpointRef}`, {
        responseType: "blob",
        headers: { "Accept": PDF_MIME_TYPE }
      });
    }

    return request.then(res => {
      const file = new Blob([res.data], {
        type: PDF_MIME_TYPE
      });

      return URL.createObjectURL(file);
    })
      .catch(console.error);
  }
}

export default new AdminClient();
