<template>
  <div class="balance-card unicolumn posRelative">
    <div class="balance-card__header">
      <p class="balance-card__date">
        {{ time }}
      </p>
      <p class="balance-card__providerName gap-s">
        {{ header }}
      </p>
    </div>

    <div class="unicolumn gap-lm">
      <div
        v-if="title"
        class="unicolumn gap-m"
      >
        <div class="balance-card__title">
          {{ title }}
        </div>
        <div class="balance-card__amount">
          {{ (totalCount ? totalCount : 0) | count }}
        </div>
      </div>
      <div
        v-if="subTitle"
        class="unicolumn gap-m"
      >
        <div class="balance-card__title">
          {{ subTitle }}
        </div>
        <div
          v-if="presentationFilter=='count'"
          class="balance-card__amount"
        >
          {{ (totalValue ? totalValue : 0) | count }}
        </div>
        <div
          v-if="presentationFilter=='money'"
          class="balance-card__amount"
        >
          ₦
          {{ (totalValue ? totalValue : 0) | money }}
        </div>
      </div>
    </div>

    <div
      class="align-flex-end cursor-pointer anchor-to-bottom"
      @click="fetchStats"
    >
      <reload-icon />
    </div>
  </div>
</template>

<style scoped>
  .anchor-to-bottom {
    position: absolute;
    bottom: 0px;
    margin-bottom: 10px;
  }
  .balance-card__date {
    font-size: 12px;
  }
  @media (max-width: 500px) {
    .posRelative {
      min-height: 240px;
    }
  }
</style>

<script>
  import ReloadIcon from "@/components/icons/ReloadIcon";

  export default {
    name: "StatsCard",
    components: {
      ReloadIcon
    },
    filters: {
      count: (number) => new Intl.NumberFormat().format(number)
    },
    props: {
      time: {
        type: String,
        required: true
      },
      header: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      subTitle: {
        type: String,
        required: true
      },
      totalCount: {
        type: Number,
        required: true
      },
      totalValue: {
        type: Number,
        required: true
      },
      presentationFilter: {
        type: String,
        required: true
      }
    },
    methods: {
      fetchStats() {
        this.$emit("fetch-stats");
      }
    }
  };
</script>
