<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Failed Bank Transfers">
      <button
        v-if="autoRefundWebhookFailedStatus !== ''
          && isAnyOperationPermitted(['autoRefundFailedBankDisbursement'])"
        class="btn btn--flex fontNormal font-size-15px"
        :class="[ autoRefundWebhookFailedStatus ? 'btn-disabled m-r-10' : 'btn--primary m-r-10' ]"
        @click="() => setAutoRefundWebhookFailed()"
      >
        <span class="btn__text">{{ autoRefundWebhookFailedStatus ? 'Resume' : 'Pause' }} Reprocess</span>
      </button>

      <records-filter
        v-click-outside="closeDropdown"
        :selected="['date']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>
            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No failed transactions found. See
                    <router-link
                      :to="{ name: 'failed-transfers', query: { currentPage: '1' } }"
                      class="empty__state__link"
                    >
                      all older transactions
                    </router-link>
                    {{ " " }}instead
                  </div>
                </template>
              </div>
              <div v-else>
                <div class="table table--padded">
                  <div class="table__section table__section--head">
                    <div class="table__row">
                      <div class="table__header">
                        Transaction Date
                      </div>
                      <div class="table__header">
                        Account Name
                      </div>
                      <div class="table__header">
                        Tlp Ref
                      </div>
                      <div class="table__header">
                        Amount
                      </div>
                      <div class="table__header">
                        Status
                      </div>
                      <div class="table__header" />
                      <div class="table__header" />
                    </div>
                  </div>
                  <div class="table__section table__section--body">
                    <template v-for="(item, i) in failedtransfers">
                      <div
                        :key="i"
                        class="table__row"
                      >
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            {{ item.transactionDate | date }}
                            <latency :latency="item.latency" />
                          </p>
                        </div>
                        <div class="table__data">
                          {{ item.destinationBank }} | {{ item.nuban }}
                        </div>
                        <div class="table__data">
                          {{ item.tellerpointRef }}
                        </div>
                        <div class="table__data">
                          {{ item.amount | money }}
                        </div>
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            <span
                              v-if="item.status === 'SUCCESSFUL'"
                              class="tag tag--green"
                            >Successful</span>
                            <span
                              v-if="item.status === 'PROCESSING'"
                              class="tag tag--yellow"
                            >Processing</span>
                            <span
                              v-if="item.status === 'REFUNDED'"
                              class="tag tag--gray"
                            >Refunded</span>
                            <span
                              v-if="item.status === 'FAILED' || item.status === 'WEBHOOK_FAILED'"
                              class="tag tag--red"
                            >
                              Failed
                            </span>
                          </p>
                        </div>

                        <div class="table__data transaction-controls">
                          <div
                            v-if="item.status === 'WEBHOOK_FAILED'"
                            class="icon-holder"
                            style="grid-column-start: 1;"
                            title="Update to Successful"
                            @click="showMarkAsSuccessful(item)"
                          >
                            <commit-icon />
                          </div>
                          <div
                            v-if="item.status === 'WEBHOOK_FAILED'"
                            class="icon-holder"
                            style="grid-column-start: 2;"
                            title="Reprocess Transfer"
                            @click="showConfirmRetryFailed(item)"
                          >
                            <refresh-icon />
                          </div>
                          <div
                            v-if="item.status === 'PROCESSING'"
                            class="icon-holder"
                            style="grid-column-start: 3;"
                            title="Update to Successful"
                            @click="showCommitHeldDisbursement(item)"
                          >
                            <commit-icon />
                          </div>
                          <div
                            v-if="item.status === 'WEBHOOK_FAILED'"
                            class="icon-holder"
                            style="grid-column-start: 4;"
                            title="Refund Agent"
                            @click="showConfirmReverseFailed(item)"
                          >
                            <wallet-icon />
                          </div>
                          <div
                            v-if="item.status === 'PROCESSING' || item.status === 'SUCCESSFUL'"
                            class="icon-holder"
                            style="grid-column-start: 5;"
                            title="Update to Failed"
                            @click="showUpdateToWebhookFailed(item)"
                          >
                            <cancel-icon />
                          </div>
                          <div
                            v-if="['FAILED', 'WEBHOOK_FAILED', 'PROCESSING', 'SUCCESSFUL'].find(e => e === item.status)"
                            class="icon-holder"
                            style="grid-column-start: 6;"
                            title="Reconfirm Status"
                            @click="showProviderResponseModal(item)"
                          >
                            <maximize-icon />
                          </div>
                        </div>

                        <div
                          class="table__data"
                          @click="toggleTable(item.tellerpointRef)"
                        >
                          <expand-icon />
                        </div>
                      </div>

                      <div
                        v-if="opened.includes(item.tellerpointRef)"
                        :key="item.tellerpointRef"
                        class="collapsible-table"
                      >
                        <div class="card-details__content">
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Failed Reason</label>
                            <div class="expanded-table-row">
                              {{ item.providerResponseMessage || "--" }}
                            </div>
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Linked Ref</label>
                            <div class="expanded-table-row">
                              {{ item.linkedTellerpointRef || "--" }}
                            </div>
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Account Name</label>
                            <div class="expanded-table-row">
                              {{ item.destinationAccountName || "--" }}
                            </div>
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Provider Name</label>
                            <div class="expanded-table-row">
                              {{ item.providerName || "--" }}
                            </div>
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Provider Ref</label>
                            <div class="expanded-table-row">
                              {{ item.providerRef || "--" }}
                            </div>
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Agent Name</label>
                            <div class="expanded-table-row">
                              {{ item.agentName || "--" }}
                            </div>
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Phone Number</label>
                            <div class="expanded-table-row">
                              {{ isBlank(item.phoneNumber) ? "--" : item.phoneNumber }}
                            </div>
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Narration</label>
                            <div class="expanded-table-row">
                              {{ item.narration || "--" }}
                            </div>
                          </div>

                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for
                            >Session ID</label>
                            <div class="expanded-table-row">
                              {{ item.sessionId || "--" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <pagination
                  :page-number="computedPageNumbers"
                  data="failed-transfers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <confirmation-modal
      id="confirmMarkAsSuccessful"
      :body="`${selectedObject.tellerpointRef} - sure?`"
      :on-submit="markAsSuccessful"
      button-text="Yes"
      header="Update to Successful"
      :disable-button="disableModalButton"
    />

    <confirmation-modal
      id="confirmRetryFailedTransfer"
      :body="`Are you sure you want to reprocess ${selectedObject.tellerpointRef}?`"
      :on-submit="retryFailedTransfer"
      button-text="Yes"
      header="Reprocess Transfer"
      :disable-button="disableModalButton"
    />

    <confirmation-modal
      id="confirmReverseFailedTransfer"
      :body="`Are you sure you want to refund ${selectedObject.tellerpointRef}?`"
      :on-submit="reverseFailedTransfer"
      button-text="Yes"
      header="Refund Failed Transfer"
      :disable-button="disableModalButton"
    />

    <confirmation-modal
      id="confirmCommitHeldDisbursement"
      :body="`${selectedObject.tellerpointRef} - sure?`"
      :on-submit="commitHeldDisbursement"
      button-text="Yes"
      header="Update to Successful"
      :disable-button="disableModalButton"
    />

    <confirmation-modal
      id="confirmUpdateToWebhookFailed"
      :body="`${selectedObject.tellerpointRef} - sure?`"
      :on-submit="updateDisbursementStatusToWebhookFailed"
      button-text="Yes"
      header="Update to Failed"
      :disable-button="disableModalButton"
    />

    <provider-response
      :is-open="openProviderResponseModal"
      :obj="selectedObject"
      transaction-type="DISBURSE_TO_BANK"
      @close-modal="hideProviderResponseModal"
    />
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import moment from "moment";
  import Pagination from "@/components/Pagination";
  import Search from "@/components/Search";
  import ProviderResponse from "@/components/ProviderResponse";
  import ConfirmationModal from "@/components/ConfirmationModal";
  import RefreshIcon from "@/components/icons/RefreshIcon";
  import WalletIcon from "@/components/icons/WalletIcon";
  import ExpandIcon from "@/components/icons/ExpandIcon";
  import MaximizeIcon from "@/components/icons/MaximizeIcon";
  import CancelIcon from "@/components/icons/CancelIcon";
  import CommitIcon from "@/components/icons/CommitIcon";
  import { dropdownOperations, providerResponseOperations } from "@/mixins/DropdownOperations";
  import DisbursementOperations from "@/mixins/DisbursementOperations";
  import { hasBlank } from "@/mixins/ViewHelper";
  import Latency from "@/components/Latency";
  import { callGetApi, callPutApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  import RecordsFilter from "@/components/Filter.vue";

  export default {
    components: {
      RecordsFilter,
      Latency,
      TitleBar,
      CommitIcon,
      CancelIcon,
      MaximizeIcon,
      ExpandIcon,
      WalletIcon,
      RefreshIcon,
      Pagination,
      Search,
      ProviderResponse,
      ConfirmationModal
    },
    mixins: [providerResponseOperations, hasBlank, DisbursementOperations, AdminPermissionsHelper, dropdownOperations],
    data() {
      return {
        failedtransfers: "",
        loading: true,
        searchPlaceholder: "Search by date, provider ref, tlp ref, nuban",
        opened: [],
        pageNumber: "",
        noData: false,
        disableModalButton: false,
        autoRefundWebhookFailedStatus: ""
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("failedTransfersPage")) {
          return JSON.parse(window.localStorage.getItem("failedTransfersPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      $route: "fetchTransactions"
    },
    created() {
      this.fetchTransactions();
      this.getAutoRefundWebhookFailed();
    },
    methods: {
      showConfirmRetryFailed(item) {
        this.selectedObject = item;
        $("#confirmRetryFailedTransfer").modal();
      },
      showCommitHeldDisbursement(disbursement) {
        this.selectedObject = disbursement;
        $("#confirmCommitHeldDisbursement").modal();
      },
      showConfirmReverseFailed(item) {
        this.selectedObject = item;
        $("#confirmReverseFailedTransfer").modal();
      },
      clearSearch() {
        let from = moment(this.from).format("YYYY-MM-DD HH:mm:ss ZZ");
        let to = moment(this.to).format("YYYY-MM-DD HH:mm:ss ZZ");

        // Make sure that the from date has the time set to 00:00:00. this is done by using the .replace function below
        const newdate = from;
        const time = newdate.substring(11, 19);
        from = from.replace(time, "00:00:00");

        to = to.replace(time, "23:59:59");

        from = encodeURIComponent(from);
        to = encodeURIComponent(to);

        this.$router.push({
          name: "failed-transfers",
          query: { currentPage: 1, start: from, end: to }
        });
      },
      fetchTransactions() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi(`failed-bank-transfer?batchSize=20&${query_params}`)
          .then(res => {
            this.noData = res.body.entity.length === 0;
            this.failedtransfers = res.body.entity;
            this.pageNumber = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            window.localStorage.setItem("failedTransfersPage", this.pageNumber);

            this.loading = false;
          })
          .catch(console.error);
      },
      searchQuery(criteria) {
        this.$router.push({
          name: "failed-transfers",
          query: { criteria }
        });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      },
      selectItem(value) {
        this.selectedObject = value;
      },
      retryFailedTransfer() {
        this.disableModalButton = true;
        callPutApi(`failed-bank-transfer/retry/${this.selectedObject.tellerpointRef}`)
          .then(res => {
            this.disableModalButton = false;
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Retry Failed Transfer",
                text: "Transfer retried successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Retry Failed Transfer",
                type: "warn",
                text: res.body.status.description
              });
            }
            $("#confirmRetryFailedTransfer").modal("hide");
            this.fetchTransactions();
          })
          .catch(err => {
            this.disableModalButton = false;
            console.error(err);
          });
      },
      reverseFailedTransfer() {
        this.disableModalButton = true;
        callPutApi(`failed-bank-transfer/reverse/${this.selectedObject.tellerpointRef}`)
          .then(res => {
            this.disableModalButton = false;
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Reverse Failed Transfer",
                text: "Transfer reversed successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Reverse Failed Transfer",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmReverseFailedTransfer").modal("hide");
            this.fetchTransactions();
          });
      },
      getAutoRefundWebhookFailed() {
        callGetApi("bank-transfer/auto-refund-failed/status")
          .then(res => {
            if (res.body.status.code === 0) {
              this.autoRefundWebhookFailedStatus = res.body.entity;
            }
          })
          .catch(err => {
            console.error(err);
          });
      },
      setAutoRefundWebhookFailed() {
        const action = this.autoRefundWebhookFailedStatus ? "Resumed" : "Paused";
        const status = !this.autoRefundWebhookFailedStatus;
        callPutApi(`bank-transfer/auto-refund-failed/${status}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Reprocess",
                text: `Successfully ${action} Reprocess`
              });
            } else {
              this.$notify({
                group: "general",
                title: "Reprocess",
                type: "warn",
                text: res.body.status.description
              });
            }

          })
          .catch(err => {
            console.error(err);
          }).finally(() => this.getAutoRefundWebhookFailed());
      }
    }
  };
</script>

<style scoped>
.expanded-table-row {
  border-block-end: 2px solid rgba(124, 136, 157, 0.15);
  padding-block-end: 8px;

  font-weight: 400;
  font-size: larger;
  line-height: 1.71;
}
.btn-disabled {
  color: #fff;
  background-color: #FFB648;
  box-shadow: 0 1px 2px 0 rgb(213 213 213 / 22%);
}

.font-size-15px {
  font-size: 15px;
}

.transaction-controls {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.icon-holder {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
