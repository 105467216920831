<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Account Managers">
      <button
        v-if="isAnyOperationPermitted(['registerAccountManager'])"
        class="btn btn--primary btn--flex"
        data-target="#newAM"
        data-toggle="modal"
      >
        <span class="btn__icon">
          <svg
            height="14"
            width="14"
          >
            <use xlink:href="../../assets/icons/addIcon.svg#add_icon" />
          </svg>
        </span>
        <span class="btn__text">Register Account Manager</span>
      </button>
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <!-- Table Data -->
              <div class="table table--padded">
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      First Name
                    </div>
                    <div class="table__header">
                      Last Name
                    </div>
                    <div class="table__header">
                      Email Address
                    </div>
                    <div class="table__header">
                      Phone Number
                    </div>
                    <div class="table__header">
                      BVN
                    </div>
                    <div class="table__header" />
                    <div class="table__header" />
                  </div>
                </div>
                <div class="table__section table__section--body">
                  <template v-for="(am, i) in accountManagers">
                    <div
                      :key="i"
                      class="table__row"
                    >
                      <div class="table__data table__data--full">
                        <p class="table__data--main">
                          {{ am.firstName }}
                        </p>
                      </div>
                      <div class="table__data">
                        <p class="table__data--main">
                          {{ am.lastName }}
                        </p>
                      </div>
                      <div class="table__data">
                        <span class="link">{{ am.email }}</span>
                      </div>
                      <div class="table__data">
                        {{ am.phone }}
                      </div>
                      <div class="table__data">
                        {{ am.bvn }}
                      </div>
                      <div class="table__data">
                        <span
                          data-target="#updateAM"
                          data-toggle="modal"
                          title="Edit account manager"
                          @click="setSelectedAm(am)"
                        >
                          <svg
                            height="14px"
                            width="14px"
                          >
                            <use xlink:href="../../assets/icons/edit-icon.svg#edit_icon" />
                          </svg>
                        </span>
                      </div>
                      <div class="table__data">
                        <span
                          data-target="#disableAccountManager"
                          data-toggle="modal"
                          @click="setSelectedAm(am)"
                        >
                          <svg
                            :class="{enableTerminal: am.enabled, disableTerminal: !am.enabled}"
                            height="14"
                            width="14"
                          >
                            <use xlink:href="../../assets/icons/disable-icon.svg#disable_icon" />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="opened.includes(am.id)"
                      :key="am.email"
                      class="collapsible-table"
                    >
                      <div class="card-details__content">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for
                          >Default</label>
                          <input
                            :value="am.default"
                            class="form__input"
                            disabled
                            type="text"
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <pagination
                :page-number="computedPageNumbers"
                data="account-managers"
              />
            </div>

            <div
              id="disableAccountManager"
              aria-labelledby="disableAccountManagerLabel"
              class="modal fade"
              role="dialog"
              tabindex="-1"
            >
              <div
                class="modal__dialog modal__dialog--sm"
                role="document"
              >
                <div class="modal__content">
                  <div class="modal__header">
                    <div class="modal__title width70percent text-center">
                      {{ selectedAm.enabled ? "Disable " : "Enable " }} {{ selectedAm.firstName }}
                      {{ selectedAm.lastName }}
                    </div>
                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                    >
                      <svg
                        height="13"
                        viewBox="0 0 13 13"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                          fill="#C0C6D8"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="modal__body modal__body--pad">
                    <form
                      class="form"
                      @submit.prevent="disableAm"
                    >
                      <div class="form__pad">
                        <p class="m-b-20 textCenter">
                          Are you sure you want to {{ selectedAm.enabled ? "disable" : "enable" }} this account manager?
                        </p>
                      </div>

                      <div class="form__footer">
                        <button
                          class="btn btn--primary btn--md btn--block"
                          type="submit"
                        >
                          Yes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="newAM"
              aria-labelledby="newAMLabel"
              class="modal fade"
              role="dialog"
              tabindex="-1"
            >
              <div
                class="modal__dialog modal__dialog--sm"
                role="document"
              >
                <div class="modal__content">
                  <div class="modal__header">
                    <div class="modal__title width70percent text-center">
                      <p>Register Account Manager</p>
                    </div>
                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                    >
                      <svg
                        height="13"
                        viewBox="0 0 13 13"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                          fill="#C0C6D8"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="modal__body modal__body--pad">
                    <form
                      class="form"
                      @submit.prevent="registerAM"
                    >
                      <div class="form__pad">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="firstName"
                          >First Name</label>
                          <input
                            id="firstName"
                            v-model="newAM.firstName"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="lastName"
                          >Last Name</label>
                          <input
                            id="lastName"
                            v-model="newAM.lastName"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="email"
                          >Email</label>
                          <input
                            id="email"
                            v-model="newAM.email"
                            class="form__input fontNormal"
                            type="email"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="phone"
                          >Phone</label>
                          <input
                            id="phone"
                            v-model="newAM.phone"
                            class="form__input fontNormal"
                            type="number"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="address"
                          >Address</label>
                          <input
                            id="address"
                            v-model="newAM.address"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="bvn"
                          >BVN</label>
                          <input
                            id="bvn"
                            v-model="newAM.bvn"
                            class="form__input fontNormal"
                            type="text"
                            required
                          >
                        </div>
                        <div class="form__item">
                          <label class="form__label--2">Partner Organization</label>
                          <v-select
                            v-model="newAM.partnerOrganizationId"
                            :options="partnerOrganizations"
                            :reduce="partner => partner.id"
                            class="m-t-20"
                            height="34px"
                            label="appId"
                            placeholder="Select Partner Organization"
                          />
                        </div>
                      </div>

                      <div class="form__footer m-t-30">
                        <button
                          class="btn btn--primary btn--md btn--block"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="updateAM"
              aria-labelledby="updateAMLabel"
              class="modal fade"
              role="dialog"
              tabindex="-1"
            >
              <div
                class="modal__dialog modal__dialog--sm"
                role="document"
              >
                <div class="modal__content">
                  <div class="modal__header">
                    <div class="modal__title width70percent text-center">
                      <p>Update {{ selectedAm.firstName }} {{ selectedAm.lastName }}</p>
                    </div>
                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                    >
                      <svg
                        height="13"
                        viewBox="0 0 13 13"
                        width="13"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                          fill="#C0C6D8"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="modal__body modal__body--pad">
                    <form
                      class="form"
                      @submit.prevent="updateAM"
                    >
                      <div class="form__pad">
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="firstNameUpdate"
                          >First Name</label>
                          <input
                            id="firstNameUpdate"
                            v-model="selectedAm.firstName"
                            class="form__input fontNormal"
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="lastNameUpdate"
                          >Last Name</label>
                          <input
                            id="lastNameUpdate"
                            v-model="selectedAm.lastName"
                            class="form__input fontNormal"
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="emailUpdate"
                          >Email</label>
                          <input
                            id="emailUpdate"
                            v-model="selectedAm.email"
                            class="form__input fontNormal"
                            type="email"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="phoneUpdate"
                          >Phone</label>
                          <input
                            id="phoneUpdate"
                            v-model="selectedAm.phone"
                            class="form__input fontNormal"
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="addressUpdate"
                          >Address</label>
                          <input
                            id="addressUpdate"
                            v-model="selectedAm.address"
                            class="form__input fontNormal"
                            type="text"
                          >
                        </div>
                        <div class="form__item">
                          <label
                            class="form__label form__label--2"
                            for="bvnUpdate"
                          >BVN</label>
                          <input
                            id="bvnUpdate"
                            v-model="selectedAm.bvn"
                            class="form__input fontNormal"
                            type="text"
                          >
                        </div>
                      </div>

                      <div class="form__footer m-t-30">
                        <button
                          class="btn btn--primary btn--md btn--block"
                          type="submit"
                        >
                          Yes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "../../components/Search";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { callGetApi, callPostApi, callPutApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "AccountManagers",
    components: {
      TitleBar,
      Pagination,
      Search,
      vSelect
    },
    mixins: [AdminPermissionsHelper],
    data() {
      return {
        accountManagers: "",
        searchPlaceholder: "Search account manager by name, email, bvn",
        opened: [],
        loading: true,
        pageNumber: "",
        selectedAm: {},
        newAM: {},
        partnerOrganizations: this.$store.getters.adminAccountInfo.partnerOrganizations
      };
    },
    computed: {
      randomKey() {
        return Date.now();
      },
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("accountManagersPage")) {
          return JSON.parse(window.localStorage.getItem("accountManagersPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      $route: "fetchAccountManagers"
    },
    created() {
      this.fetchAccountManagers();
    },
    methods: {
      clearSearch() {
        this.$router.push({ name: "account-managers", query: { currentPage: 1 } });
      },
      fetchAccountManagers() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi(`account-manager?batchSize=20&${query_params}` )
          .then(res => {
            this.loading = false;
            this.accountManagers = res.body.entity;
            let pages = Math.ceil(res.body.pageMetaData.totalNoOfRows / res.body.pageMetaData.batchSize);
            this.pageNumber = pages;
            window.localStorage.setItem("accountManagersPage", pages);
          })
          .catch(console.error);
      },
      searchQuery(criteria) {
        this.$router.push({ name: "account-managers", query: { criteria } });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");
      },
      setSelectedAm(am) {
        this.selectedAm = am;
      },
      disableAm() {
        const status = !this.selectedAm.enabled;
        const action = status ? "Enable" : "Disable";
        callPutApi(`account-manager/disable/${this.selectedAm.id}/${status}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: `${action} Account Manager`,
                text: `Account Manager ${action}d successfully`
              });
            } else {
              this.$notify({
                group: "general",
                title: `${action} Account Manager`,
                type: "warn",
                text: res.body.status.description
              });
            }
            $("#disableAccountManager").modal("hide");
            this.fetchAccountManagers();
          })
          .catch(console.error);
      },
      registerAM() {
        let newAM = this.newAM;
        newAM.transactionPin = Math.floor(1000 + Math.random() * 9000).toString();
        newAM.password = Math.floor(100000 + Math.random() * 900000).toString();
        newAM.default = false;
        newAM.settledByTellerpoint = true;

        callPostApi("account-manager", { body: newAM })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Register Account Manager",
                text: `${newAM.firstName} ${newAM.lastName} registered successfully`
              });
            } else {
              this.$notify({
                group: "general",
                title: "Register Account Manager",
                type: "warn",
                text: res.body.status.description
              });
            }
            $("#newAM").modal("hide");
            this.fetchAccountManagers();
          })
          .catch(err => {
            console.error(err);
            this.$notify({
              group: "general",
              title: "Server Error",
              type: "error",
              text: err
            });
            setTimeout(this.$router.go, 1500);
          });
      },
      updateAM() {
        const am = this.selectedAm;
        const body = {
          firstName: am.firstName,
          lastName: am.lastName,
          phone: am.phone,
          email: am.email,
          address: am.address,
          bvn: am.bvn
        };

        callPutApi(`account-manager/${am.id}`, { body })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Update Account Manager",
                text: "Account Manager updated successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Update Account Manager",
                type: "warn",
                text: res.body.status.description
              });
            }
            $("#updateAM").modal("hide");
            this.fetchAccountManagers();
          })
          .catch(console.error);
      }
    }
  };
</script>
