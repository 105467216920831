<template>
  <div>
    <div class="transaction__header">
      <div class="section-title">
        Payment and other Details
      </div>
    </div>
    <div class="transaction__content">
      <div class="card-details__content">
        <div
          v-if="saleDetails.name"
          class="form__item"
        >
          <label
            class="form__label form__label--2"
            for=""
          >Sale Name</label>
          <p class="form__input capitalize">
            {{ saleDetails.name }}
          </p>
        </div>

        <div class="form__item">
          <label
            class="form__label form__label--2"
            for=""
          >Cashier</label>
          <p class="form__input capitalize">
            {{ saleDetails.cashierName }}
          </p>
        </div>

        <div class="form__item">
          <label
            class="form__label form__label--2"
            for=""
          >Merchant</label>
          <p class="form__input capitalize">
            {{ saleDetails.businessName }}
          </p>
        </div>

        <div
          v-if="saleDetails.paymentSource"
          class="form__item"
        >
          <label
            class="form__label form__label--2"
            for=""
          >Payment Method</label>
          <p class="form__input capitalize">
            {{ saleDetails.paymentSource }}
          </p>
        </div>

        <div
          v-if="saleDetails.terminalId"
          class="form__item"
        >
          <label
            class="form__label form__label--2"
            for=""
          >Terminal ID</label>
          <p class="form__input capitalize">
            {{ saleDetails.terminalId }}
          </p>
        </div>

        <div
          v-if="paymentDetails.status"
          class="form__item"
        >
          <label
            class="form__label form__label--2"
            for=""
          >Transaction Status</label>
          <p class="form__input capitalize">
            {{ paymentDetails.status }}
          </p>
        </div>

        <div
          v-if="paymentDetails.cardHolderName"
          class="form__item"
        >
          <label
            class="form__label form__label--2"
            for=""
          >Card PAN</label>
          <p class="form__input capitalize">
            {{ paymentDetails.maskedCardPan }}
          </p>
        </div>

        <div
          v-if="paymentDetails.cardHolderName"
          class="form__item"
        >
          <label
            class="form__label form__label--2"
            for=""
          >CardHolder Name</label>
          <p class="form__input capitalize">
            {{ paymentDetails.cardHolderName }}
          </p>
        </div>

        <div
          v-if="paymentDetails.traceRef"
          class="form__item"
        >
          <label
            class="form__label form__label--2"
            for=""
          >RRN</label>
          <p class="form__input capitalize">
            {{ paymentDetails.traceRef }}
          </p>
        </div>

        <div
          v-if="paymentDetails.tellerpointRef"
          class="form__item"
        >
          <label
            class="form__label form__label--2"
            for=""
          >Tlp Ref</label>
          <p class="form__input capitalize">
            <router-link
              class="eft-ref-link"
              :to="{ name: 'transaction-lookup', query: { ref: paymentDetails.tellerpointRef } }"
              target="_blank"
            >
              {{ paymentDetails.tellerpointRef }}

              <svg
                class="new-tab-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M3 12h18M12 3l9 9-9 9" />
              </svg>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderLookupTable",
    props: {
      saleDetails: {
        type : Object,
        required: true
      },
      paymentDetails: {
        type : Object,
        default: undefined
      }
    }
  };
</script>
<style scoped>
.section-title {
  font-size: 20px;
}
.eft-ref-link {
  color: #000 !important;
}

.new-tab-icon {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  vertical-align: text-top;
}

.transaction__header {
  margin-bottom: 24px;
}
</style>
