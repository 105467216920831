<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="dashboard__main__content__container">
    <title-bar title="Fund Sweep">
      <div class="inline gap-l">
        <records-filter
          v-click-outside="closeDropdown"
          data="fund-sweep"
          :selected="['date']"
          :show-dropdown="showDropdown"
          @toggle-filter-dropdown="updateDropDownValue($event)"
        />
        <button
          v-if="isAnyOperationPermitted(['sweepProviderFunds'])"
          class="btn btn--flex"
          data-target="#sweepFund"
          data-toggle="modal"
          @click="openModal"
        >
          Sweep Funds
        </button>
      </div>
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchFundSweep($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No Fund Sweep transactions today. See
                    <router-link
                      :to="{ name: 'fund-sweep', query: { currentPage: '1' }}"
                      class="empty__state__link"
                    >
                      all older transactions
                    </router-link>
                    {{ " " }}instead
                  </div>
                </template>
              </div>

              <div
                v-else
                class="table table--padded"
              >
                <div class="table__section table__section--head">
                  <div class="table__row">
                    <div class="table__header">
                      Transaction Date
                    </div>
                    <div class="table__header">
                      Amount
                    </div>
                    <div class="table__header">
                      Source
                    </div>
                    <div class="table__header">
                      Beneficiary
                    </div>
                    <div class="table__header" />
                    <div class="table__header" />
                  </div>
                </div>
                <template v-for="(fundSweep, i) in fundSweeps">
                  <div
                    :key="i"
                    class="table__row"
                  >
                    <div class="table__data table__data">
                      <p class="table__data--main">
                        {{ fundSweep.creationTime | date }}
                      </p>
                    </div>
                    <div class="table__data table__data">
                      <p class="table__data--main">
                        {{ fundSweep.amount | money }}
                      </p>
                    </div>
                    <div class="table__data">
                      {{ fundSweep.sourceProvider }}
                    </div>
                    <div class="table__data">
                      {{ fundSweep.destinationAccountName }} - {{ fundSweep.destinationNuban }} -
                      {{ fundSweep.destinationBank }}
                    </div>

                    <div
                      class="table__data"
                      title="Reconfirm Status"
                      @click="showProviderResponseModal(fundSweep)"
                    >
                      <maximize-icon />
                    </div>

                    <div class="table__data">
                      <span @click="toggleTable(fundSweep.tellerpointRef)">
                        <svg
                          height="14"
                          width="14"
                        >
                          <use xlink:href="../../assets/icons/expand-icon.svg#expand_icon" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="opened.includes(fundSweep.tellerpointRef)"
                    :key="fundSweep.id"
                    class="collapsible-table"
                  >
                    <div class="card-details__content">
                      <div class="form__item">
                        <label
                          class="form__label form__label--2"
                          for
                        >Provider Response</label>
                        <input
                          :value="isBlank(fundSweep.providerResponseMessage) ? '--' : fundSweep.providerResponseMessage"
                          class="form__input capitalize"
                          disabled
                          type="text"
                        >
                      </div>
                      <div class="form__item">
                        <label class="form__label form__label--2">Provider Ref</label>
                        <input
                          :value="isBlank(fundSweep.providerRef) ? '--' : fundSweep.providerRef"
                          class="form__input capitalize"
                          disabled
                          type="text"
                        >
                      </div>
                      <div class="form__item">
                        <label class="form__label form__label--2">Tellerpoint Ref</label>
                        <input
                          :value="isBlank(fundSweep.tellerpointRef) ? '--' : fundSweep.tellerpointRef"
                          class="form__input capitalize"
                          disabled
                          type="text"
                        >
                      </div>
                      <div class="form__item">
                        <label
                          class="form__label form__label--2"
                          for
                        >Narration</label>
                        <input
                          :value="isBlank(fundSweep.narration) ? '--' : fundSweep.narration"
                          class="form__input capitalize"
                          disabled
                          type="text"
                        >
                      </div>
                      <div class="form__item">
                        <label
                          class="form__label form__label--2"
                          for
                        >Admin User</label>
                        <input
                          :value="isBlank(fundSweep.adminUser) ? '--' : fundSweep.adminUser"
                          class="form__input capitalize"
                          disabled
                          type="text"
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <pagination
                :page-number="computedPageNumbers"
                data="fund-sweep"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <provider-response
      :obj="selectedObject"
      :is-open="openProviderResponseModal"
      transaction-type="FUNDS_SWEEP"
      @close-modal="hideProviderResponseModal"
    />
    <!--Modal-->
    <div
      id="sweepFund"
      aria-labelledby="sweepFunds"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal__dialog modal__dialog--sm"
        role="document"
      >
        <div class="modal__content">
          <div class="modal__header">
            <div class="modal__title text-center">
              Fund Sweep
            </div>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <svg
                height="13"
                width="13"
              >
                <use xlink:href="../../assets/icons/close-dropdown.svg#close_dropdown" />
              </svg>
            </button>
          </div>
          <div class="modal__body modal__body--pad">
            <form
              class="form"
              @submit.prevent="sweepFunds"
            >
              <div class="form__pad m-b-30">
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="provider"
                  >Provider</label>
                  <v-select
                    id="provider"
                    v-model="provider"
                    :options="providers"
                    placeholder="Select Provider"
                  />
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="beneficiary"
                  >Beneficiary</label>
                  <v-select
                    id="beneficiary"
                    v-model="beneficiary"
                    :options="beneficiaries"
                    label="accountName"
                    placeholder="Select Beneficiary"
                  >
                    <template v-slot:option="option">
                      {{ option.accountName }} - {{ option.accountNumber }} - {{ option.bankName }}
                    </template>
                  </v-select>
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="amount"
                  >Amount</label>
                  <input
                    id="amount"
                    v-model="amount"
                    class="form__input capitalize fontNormal"
                    placeholder="Amount"
                    type="number"
                    min="1"
                    step="any"
                  >
                </div>
                <div class="form__item">
                  <label
                    class="form__label form__label--2"
                    for="narration"
                  >Narration</label>
                  <input
                    id="narration"
                    v-model="narration "
                    class="form__input capitalize fontNormal"
                    placeholder="Narration"
                    type="text"
                  >
                </div>
              </div>

              <div class="form__footer m-t-20">
                <button
                  class="btn btn--primary btn--md btn--block"
                  type="submit"
                  :disabled="submitted"
                >
                  Sweep Funds
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import vSelect from "vue-select";
  import Pagination from "@/components/Pagination";
  import RecordsFilter from "@/components/Filter";
  import "vue-select/dist/vue-select.css";
  import { formatQueryParam } from "@/mixins/ApiHelper";
  import { dropdownOperations, providerResponseOperations } from "@/mixins/DropdownOperations";
  import { collapsibleTable, hasBlank } from "@/mixins/ViewHelper";
  import { pagination } from "@/mixins/Pagination";
  import Search from "../../components/Search";
  import ProviderResponse from "@/components/ProviderResponse";
  import MaximizeIcon from "@/components/icons/MaximizeIcon";
  import { callGetApi, callPostApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";

  export default {
    name: "FundSweep",
    components: {
      TitleBar,
      vSelect,
      Pagination,
      RecordsFilter,
      Search,
      ProviderResponse,
      MaximizeIcon
    },
    mixins: [
      formatQueryParam,
      collapsibleTable,
      hasBlank,
      pagination,
      dropdownOperations,
      providerResponseOperations,
      AdminPermissionsHelper
    ],
    data() {
      return {
        noData: false,
        activeDropdown: false,
        searchPlaceholder: "Search by tlp ref, provider, nuban, narration, bank name",
        loading: false,
        opened: [],
        fundSweeps: [],
        providers: [],
        beneficiaries: [],
        beneficiary: undefined,
        provider: "",
        amount: "",
        narration: "",
        bankCode: "",
        bankName: "",
        accountName: "",
        accountNumber: "",
        pageNumber: 0,
        pageName: "fundsweepPage",
        submitted: false
      };
    },
    watch: {
      "$route": "getFundSweep"
    },
    created() {
      this.getFundSweep();
    },
    methods: {
      searchFundSweep(criteria) {
        this.$router.push({
          name: "fund-sweep",
          query: { criteria }
        });
      },
      getFundSweep() {
        this.loading = true;
        const query_params = this.convertToQueryString(this.$route.query);
        callGetApi(`funds-sweep?batchSize=20&${query_params}`)
          .then(res => {
            let response = res.body;
            const pageMetaData = response.pageMetaData;

            this.noData = response.entity.length === 0;
            this.fundSweeps = response.entity;
            this.setPageDetails(pageMetaData.totalNoOfRows, pageMetaData.batchSize);
            this.loading = false;
          })
          .catch(console.error);
      },
      clearSearch() {
        this.$router.push({ name: "fund-sweep", query: { currentPage: 1 } });
      },
      openModal() {
        this.fetchProviders();
        this.fetchBeneficiaries();
        this.submitted = false;
      },
      fetchProviders() {
        if(this.providers.length === 0) {
          callGetApi("service-providers/pay-to-bank")
            .then(res => {
              this.providers = res.body.entity;
            })
            .catch(console.error);
        }
      },
      fetchBeneficiaries() {
        if (this.beneficiaries.length === 0) {
          callGetApi("funds-sweep/account-details")
            .then(res => {
              this.beneficiaries = res.body.entity;
            })
            .catch(console.error);
        }
      },
      sweepFunds() {
        this.submitted = true;
        const body = {
          provider: this.provider,
          amount: this.amount,
          narration: this.narration,
          accountDetails: this.beneficiary
        };
        callPostApi("funds-sweep", { body })
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Fund Sweep",
                text: "Fund Sweep Successful"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Fund Sweep",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            setTimeout(this.$router.go, 1000);
          });

      }
    }
  };
</script>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
