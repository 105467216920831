<template>
  <tbody>
    <tr
      v-for="(item, i) in saleDeductions"
      :key="i"
    >
      <td colspan="2">
        {{ item.name }}
      </td>
      <td class="right-align">
        {{ item.amount | money }}
      </td>
    </tr>
  </tbody>
</template>
<script>
  export default {
    name: "OrderDeductionsRow",
    props: {
      saleDeductions: {
        type : Array,
        default: undefined
      }
    }
  };
</script>
<style scoped>
td {
  padding: 12px;
  text-align: left;
}

td:nth-child(1) {
  text-align: left;
}

td:nth-child(2) {
  text-align: right;
}

.total-row td {
  font-weight: bold;
}

</style>
