<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Finance Report" />

    <div class="dashboard__main__content__home">
      <div
        class="dashboard__main__content__home__left dashboard__main__content__home__left__finance-report"
      >
        <div class="big-card unicolumn gap-xl finance-report-card">
          <div class="inline full-width gap-xl">
            <div class="unicolumn gap-m">
              <p>Start Date</p>
              <date-picker
                v-model="from"
                :lang="lang"
                :placeholder="lang.placeholder.date"
                :disabled-date="disallowDaysAfterToday"
              />
            </div>

            <div class="unicolumn gap-m">
              <p>End Date</p>
              <date-picker
                v-model="to"
                :lang="lang"
                :not-before="from"
                :placeholder="lang.placeholder.date"
                :disabled-date="disallowDaysAfterTodayOrBeforeStartDate"
              />
            </div>
          </div>

          <div class="unicolumn gap-l">
            <div class="unicolumn gap-m">
              <p>Report Type</p>
              <v-select
                v-model="reportType"
                label="name"
                :options="reportOptions"
                placeholder="Select Report Type"
                :reduce="reportOption => reportOption.machineName"
                height="34px"
              />
            </div>
            <button
              class="btn btn--primary m-t-10"
              @click="downloadReport"
            >
              Send report to my email
            </button>
          </div>
        </div>
      </div>
      <div class="dashboard__main__content__home__right" />
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import moment from "moment";
  import { callGetApi } from "@/httpClient";
  import { disallowDaysAfterToday } from "@/util/dates";

  const currentDate = new Date().toISOString().slice(0, 10);

  export default {
    components: {
      DatePicker,
      TitleBar,
      vSelect
    },
    data() {
      return {
        from: currentDate,
        to: currentDate,
        reportType: "ALL_REPORT",
        reportOptions: [
          {
            name: "All Report",
            machineName: "ALL_REPORT"
          },
          {
            name: "Transfer to Wallet",
            machineName: "DISBURSE_TO_WALLET"
          },
          {
            name: "Transfer to Bank",
            machineName: "DISBURSE_TO_BANK"
          },
          {
            name: "Cash Withdrawal",
            machineName: "CASHOUT"
          },
          {
            name: "EFT Sale",
            machineName: "EFT_SALE"
          },
          {
            name: "Dispense Error Refund",
            machineName: "DISPENSE_ERROR_REFUND"
          },
          {
            name: "Wallet Balance",
            machineName: "WALLET_BALANCE"
          },
          {
            name: "BVN Validation",
            machineName: "BVN_USAGE"
          },
          {
            name: "Fund Wallet",
            machineName: "FUND_WALLET"
          },
          {
            name: "Fund Sweep",
            machineName: "FUNDS_SWEEPING"
          },
          {
            name: "Bill Payment",
            machineName: "BILL_PAYMENT"
          },
          {
            name: "Terminal Stock",
            machineName: "TERMINAL_STOCK"
          },
          {
            name: "Terminal Activity",
            machineName: "TERMINAL_ACTIVITY"
          }
        ],
        // custom lang
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          pickers: [
            "next 7 days",
            "next 30 days",
            "previous 7 days",
            "previous 30 days"
          ],
          placeholder: {
            date: "Select Date",
            dateRange: "Select Date Range"
          }
        },
        // custom range shortcuts
        shortcuts: [
          {
            text: "Today",
            onClick: () => {
              this.time3 = [new Date(), new Date()];
            }
          }
        ],
        timePickerOptions: {
          start: "00:00",
          step: "00:30",
          end: "23:30"
        }
      };
    },
    methods: {
      disallowDaysAfterToday,
      disallowDaysAfterTodayOrBeforeStartDate(date) {
        return date < this.from || disallowDaysAfterToday(date);
      },
      downloadReport() {
        let from = "";
        let to = "";

        if (this.from && this.to) {
          from = moment(this.from).format("YYYY-MM-DD 00:00:00 ZZ");
          to = moment(this.to).format("YYYY-MM-DD 23:59:59 ZZ");
        }

        const newFrom = from ? encodeURIComponent(from) : "";
        const newTo = to ? encodeURIComponent(to) : "";

        callGetApi(`report/finance-report?start=${newFrom}&end=${newTo}&reportType=${this.reportType}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Finance Report",
                text: "Report has been sent to your email"
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Finance Report",
                type: "warn",
                text: res.body.status.description
              });
            }
            setTimeout(this.$router.go, 1000);
          })
          .catch(console.error);
      }
    }
  };
</script>

<style scoped>
  .finance-report-card {
    width: 492px;
  }
</style>
