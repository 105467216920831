<template>
  <form @submit.prevent="search">
    <div class="search">
      <div class="search__icon">
        <search-icon />
      </div>
      <div
        v-if="criteria"
        class="search__clearfilter"
        @click="clearSearch"
      >
        <clear-search-icon />
      </div>
      <input
        v-model="criteria"
        class="search__input"
        :placeholder="searchPlaceholder"
        type="text"
      >
    </div>
  </form>
</template>

<script>
  import ClearSearchIcon from "@/components/icons/ClearSearchIcon";
  import SearchIcon from "@/components/icons/SearchIcon";

  export default {
    name: "Search",
    components: { SearchIcon, ClearSearchIcon },
    props: {
      searchPlaceholder: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        criteria: this.$route.query.criteria ? this.$route.query.criteria : ""
      };
    },
    methods: {
      search() {
        this.$emit("search", this.criteria);
      },
      clearSearch() {
        this.criteria = "";
        this.$emit("clear-search");
      }
    }
  };
</script>
